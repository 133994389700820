import React, { ReactNode } from "react";
import Concular from "assets/images/concular.svg";
import AuthImage from "assets/images/auth-img.png";
import Toast from "components/Toast";
import { globalStore } from "store/GlobalStore";
import { observer } from "mobx-react-lite";

interface IProps {
  children?: ReactNode;
  noLogo?: boolean;
  className?: string;
}

const AuthLayout = ({ children, noLogo, className }: IProps) => {
  return (
    <div className={`${"flex min-w-full"} ${className}`}>
      <div
        id="auth-container"
        className="flex justify-center mx-auto xl:w-2/3 lg:w-3/4 sm:w-full items-center xs:min-h-screen"
      >
        <div className=" lg:mb-0 md:min-w-[500px] mx-auto flex flex-col items-center justify-center">
          {!noLogo && (
            <img
              width={200}
              src={Concular}
              alt="Concular"
              className="mx-auto mb-6"
            />
          )}
          {children}
        </div>
      </div>

      <div id="side-image" className="w-1/3 hidden md:block overflow-hidden">
        <img
          src={AuthImage}
          alt="Building"
          className="min-h-full object-cover"
        />
      </div>

      <Toast toastList={globalStore.toastList} />
    </div>
  );
};

export default observer(AuthLayout);
