import React, { Fragment } from "react";
import CompliantInfo from "./CompliantInfo";
import { searchStore } from "store/IfcMapping/SearchStore";
import { observer } from "mobx-react-lite";
import ProductRow from "./SearchRow";
import { MaterialFileObject } from "store/IfcMapping/types";
import useFeatureFlag from "hooks/useFeatureFlag";
import ActionMenu from "components/ActionMenu";
import { ProductSearchOutput } from "api-client";
import clsx from "clsx";

interface SettingItem {
  name: React.ReactElement | string;
  icon: React.ReactElement;
  onClick: () => void;
  className: string;
  disabled?: boolean;
}

export interface RowsListProps {
  match: (product_id: string) => Promise<void | MaterialFileObject[]>;
  settingsItems?: (
    product: ProductSearchOutput | null | undefined
  ) => SettingItem[];
  containerClassName?: string;
  settingsDisabled?: boolean[];
}

function RowsList(props: RowsListProps): React.ReactNode {
  const { compliantItemsInfo } = searchStore;
  const [isMappingInProgress, setIsMappingInProgress] = React.useState(false);
  const { isFeatureVisible } = useFeatureFlag();

  const createSettings = (item: ProductSearchOutput | null | undefined) => {
    const items = props.settingsItems?.(item);
    if (!items) return;
    return items.map((rec, index) => (
      <div
        key={index}
        className={clsx("flex items-center w-full py-2", rec.className)}
        onClick={() => !rec.disabled && rec.onClick()}
      >
        {rec.icon}
        {rec.name}
      </div>
    ));
  };

  return (
    <div className={props.containerClassName}>
      <CompliantInfo isCompliant />
      {searchStore.searchResults.items.map((item, index) => {
        const nonCompliantStarted =
          !compliantItemsInfo.hasMore &&
          compliantItemsInfo.totalCompliant === index;
        return (
          <Fragment key={index}>
            {nonCompliantStarted ? <CompliantInfo isCompliant={false} /> : null}
            <ProductRow
              key={`${item.id}-${index}`}
              mappingProgress={isMappingInProgress}
              onMappingProgressChange={(isInProgress: boolean) => {
                setIsMappingInProgress(isInProgress);
              }}
              product={item}
              match={props.match}
              testID={`linkProduct_row_${index}`}
              settings={
                isFeatureVisible("custom_product_menu") ? (
                  <ActionMenu
                    items={createSettings(item) as []}
                    itemDisabled={
                      props
                        .settingsItems?.(item)
                        ?.map((item) => item.disabled) as boolean[]
                    }
                    className="ml-2"
                    containerClassName="rounded"
                    itemClassName="!py-0"
                    itemsClassName="right-4 !-top-6 whitespace-nowrap"
                    dotSize={16}
                  />
                ) : null
              }
            />
          </Fragment>
        );
      })}
    </div>
  );
}

export default observer(RowsList);
