import React from "react";
import { observer } from "mobx-react-lite";
import { globalStore, Tooltip } from "store/GlobalStore";
import { InformationCircleIcon } from "@heroicons/react/solid";
import clsx from "clsx";

interface TooltipProps extends Tooltip {
  tooltipClassName?: string;
  iconClassName?: string;
  className?: string;
  truncate?: boolean;
  noIcon?: boolean;
}

export default observer(function Tooltip(props: TooltipProps) {
  let setterTimeout: string | number | NodeJS.Timeout | undefined;
  let removerTimeout: string | number | NodeJS.Timeout | undefined;
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    return () => clearTooltip();
  }, []);

  const checkTextTruncation = () => {
    const isTruncated =
      wrapperRef.current &&
      wrapperRef.current.scrollWidth > wrapperRef.current.clientWidth;
    return Boolean(isTruncated);
  };

  const setTooltip = () => {
    globalStore.setTootipWrapperHover(true);
    setterTimeout = setTimeout(() => {
      if (!wrapperRef.current || !globalStore.tootipWrapperHover) return;
      if (props.truncate && !checkTextTruncation()) return;
      const { top, left, width } = wrapperRef.current.getBoundingClientRect();
      const leftOffset = props?.align === "right" ? 25 : width / 2;
      globalStore.setTooltipContent({
        content: props.content ?? props.children,
        children: props.children,
        delay: props.delay,
        position: {
          left: left + leftOffset,
          top: top,
        },
        className: props.tooltipClassName,
        whiteTheme: props.whiteTheme,
        disableStickOnHover: props.disableStickOnHover,
        align: props.align,
        active: true,
      });
    }, props.delay ?? 400);
  };

  const clearTooltip = () => {
    globalStore.setTootipWrapperHover(false);
    removerTimeout = setTimeout(() => {
      if (
        !globalStore.tooltip?.hoverOnTooltip ||
        globalStore.tooltip?.disableStickOnHover
      ) {
        globalStore.setTooltipContent(undefined);
        clearTimeout(setterTimeout);
        clearTimeout(removerTimeout);
      }
    }, 100);
  };

  return (
    <div
      onMouseEnter={setTooltip}
      onMouseLeave={clearTooltip}
      ref={wrapperRef}
      className={clsx(
        "items-center cursor-pointer",
        props.truncate ? "truncate transition" : "flex",
        props.className
      )}
    >
      {props.children}
      {!props.children && !props.noIcon ? (
        <InformationCircleIcon
          className={`ml-1 w-4 min-w-[16px] ${props.iconClassName}`}
        />
      ) : null}
    </div>
  );
});
