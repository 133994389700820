import { SearchTabsProps } from "store/IfcMapping/types";

export const OTHER_PROPERTIES_OPTIONS = [
  {
    id: "is_external",
    name_de: "Teil der Gebäudehülle",
    name: "Part of the building shell",
    subtitle: { name: "isExternal" },
  },
  {
    id: "is_load_bearing",
    name_de: "Tragendes Bauteil",
    name: "Load bearing component",
    subtitle: { name: "isLoadbearing" },
  },
];

export const REFERENCE_UNITS = [
  { id: "PCS", name: "Pieces", name_de: "Stück" },
  { id: "M", name: "m", name_de: "m" },
  { id: "M2", name: "m²", name_de: "m²" },
  { id: "M3", name: "m³", name_de: "m³" },
  { id: "KG", name: "kg", name_de: "kg" },
];

export const NO_OBJECT_DATA_DOCS_URL =
  "https://concular.notion.site/Werden-bestimmte-IFC-Entit-ten-beim-Import-vom-System-ignoriert-a14fcb3ea9564e09967115016f18f2c0?pvs=4";

export const FIELDSSET_TITLE_CLASSES =
  "text-base font-semibold mb-3 text-gray-900";
export const FIELD_LABEL_CLASSES = "text-sm font-medium mb-1 text-gray-600";
export const FIELD_VALUE_CLASSES = "text-sm font-normal pb-4 text-gray-500";

export const SEARCH_TABS_LIST: SearchTabsProps[] = [
  {
    id: "concular_products",
    type: "PRODUCT",
    source: undefined,
  },
  {
    id: "custom_products",
    type: "PRODUCT",
    source: "custom",
  },
  {
    id: "concular_components",
    type: "COMPONENT",
    source: undefined,
  },
  {
    id: "custom_components",
    type: "COMPONENT",
    source: "custom",
  },
];

export const COMPONENT_DATA_LOCAL_KEY = "tempt_component_data";
