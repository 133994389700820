import React from "react";
import Modal, { ModalProps } from "components/Modal";
import {
  ComponentDetailsByFileOut,
  InventoryProductDetailsByFileOut,
  ProductSearchOutput,
} from "api-client";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import Alert from "components/Alert";
import { userStore } from "store/UserStore";
import { useComplianceInfo } from "hooks/useComplianceInfo";
import { IconCube, IconStack2 } from "@tabler/icons-react";
import ComponentOverview from "./ComponentOverview";
import { observer } from "mobx-react-lite";
import { searchStore } from "store/IfcMapping/SearchStore";
import ComponentDetails from "./ComponentDetails";
import ProductDetails from "./ProductDetails";
import { mappingEditorStore } from "store/IfcMapping/MEStore";
import CommonDetails from "./CommonDetails";

interface ProductDetailsPopupProps extends ModalProps {
  product: ProductSearchOutput | null | undefined;
}

function DetailsPopup({ product, ...props }: ProductDetailsPopupProps) {
  const { getCurrentCertification, getCurrentConformity } = useComplianceInfo();
  const { t } = useTranslation();
  const { ifc_id } = useParams();
  const { authHeader } = userStore;
  const [details, setDetails] = React.useState<
    InventoryProductDetailsByFileOut | ComponentDetailsByFileOut
  >();

  async function getDetails() {
    if (!product?.id || !ifc_id || !authHeader) return;
    const fetchedData =
      product.type === "PRODUCT"
        ? await searchStore.getProductDetails(product.id, ifc_id)
        : await searchStore.getComponentDetails(product.id, ifc_id);
    if (fetchedData) {
      setDetails(fetchedData);
      searchStore.setCurrentDetailNode(
        fetchedData as ComponentDetailsByFileOut
      );
    }
  }

  React.useEffect(() => {
    if (props.open && !details) {
      getDetails();
    } else {
      if (product?.id === details?.id) {
        searchStore.setCurrentDetailNode(undefined);
        setDetails(undefined);
      }
    }
  }, [props.open]);

  return (
    <Modal
      isOpen={props.open}
      setIsOpen={props.close}
      leadingIcon={
        product?.type === "COMPONENT" ? (
          <IconStack2
            stroke={3}
            className="bg-cyan-300 rounded-full p-[5px] w-6 h-6 mr-2 text-cyan-900"
          />
        ) : (
          <IconCube
            stroke={3}
            className="bg-teal-300 rounded-full p-[5px] w-6 h-6 mr-2 text-teal-900"
          />
        )
      }
      title={product?.name as string}
      containerClassName="min-w-[1376px]"
      closeButton
      disableOutsideClick
      position="top"
    >
      {details ? (
        <div className="flex">
          {product?.type === "COMPONENT" ? (
            <ComponentOverview details={details as ComponentDetailsByFileOut} />
          ) : null}
          <div className="p-6 overflow-auto w-full">
            {product?.type === "COMPONENT" ? (
              <div className="text-lg font-semibold mb-6">
                {searchStore.currentDetailNode?.id === details.id
                  ? t("mapping.overview")
                  : searchStore.currentDetailNode?.name}
              </div>
            ) : null}

            {!searchStore.currentDetailNode?.is_compliant_by_file &&
            mappingEditorStore.showComplianceAlertsInDetails ? (
              <Alert
                type="attention"
                description={t("mapping.notComplianceAlert", {
                  conformity: getCurrentConformity(),
                  certification: getCurrentCertification()?.name,
                })}
                className="mb-6"
              />
            ) : null}

            <CommonDetails />

            {product?.type === "PRODUCT" ? (
              <ProductDetails epdGroupsData={details.epd_groups} />
            ) : (
              <ComponentDetails />
            )}
          </div>
        </div>
      ) : (
        skeleton()
      )}
    </Modal>
  );
}

export default observer(DetailsPopup);

function skeleton() {
  return (
    <div className="animate-pulse p-6">
      <div className="h-20 w-full bg-gray-200 rounded-lg dark:bg-gray-700 mb-2.5"></div>
      <div className="h-20 w-full bg-gray-200 rounded-lg dark:bg-gray-700 mb-2.5"></div>
      <div className="h-20 w-full bg-gray-200 rounded-lg dark:bg-gray-700 mb-2.5"></div>
    </div>
  );
}
