import { observer } from "mobx-react-lite";
import { buildingStore } from "store/Building/BuildingStore";
import { create_building_filter } from "./filters";
import PowerBIReport from "./PowerBIReport";
import { models } from "powerbi-client";
import EmptyState from "components/EmptyState";
import { BaseDashboardProps } from "./DashboardActiveFiles";

const TABLE_NAME = "buildings";

const DashboardVariant = (props: BaseDashboardProps) => {
  const { buildingID, materialResources } = buildingStore;
  const reportID = process.env.REACT_APP_POWER_BI_VARIANTS_DASHBOARD_ID;
  const allFileIDsList = materialResources.items.map((item) => item.id);

  const emptyFiles = allFileIDsList.length == 0;
  const buildingFilter = create_building_filter(TABLE_NAME, buildingID);
  const filters = [buildingFilter] as models.ReportLevelFilters[];

  if (emptyFiles && materialResources.loading) {
    buildingStore.getAllMaterialResources();
    return <EmptyState type="chart" loading />;
  }

  if (emptyFiles) {
    return <EmptyState type="chart" />;
  }

  return (
    <PowerBIReport
      reportID={reportID}
      containerClassName={props.containerClassName}
      filters={filters}
    />
  );
};

export default observer(DashboardVariant);
