import React from "react";
import clsx from "clsx";
import Tooltip from "components/Tooltip";
import { useTranslation } from "react-i18next";

const CertificationExporterItem = ({ disabled }: { disabled: boolean }) => {
  const { t } = useTranslation();

  return (
    <div key={2} className="w-full flex flex-row items-center justify-between">
      <div
        className={clsx(
          disabled ? "text-gray-400" : "text-gray-900",
          "font-[400]"
        )}
      >
        {t("certificationExport.title")}
      </div>
      {disabled && (
        <Tooltip content={t("certificationExport.tooltipDisableMsg")} />
      )}
    </div>
  );
};

export default CertificationExporterItem;
