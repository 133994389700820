import React from "react";
import Badge from "components/Badge";
import Tooltip from "components/Tooltip";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { searchStore } from "store/IfcMapping/SearchStore";
import { observer } from "mobx-react-lite";

const CompliantInfo = (props: {
  isCompliant: boolean | undefined;
  containerClassName?: string;
}) => {
  const { t } = useTranslation();

  const count = props.isCompliant
    ? searchStore.compliantItemsInfo.compliant
    : searchStore.compliantItemsInfo.nonCompliant;

  const getTooltipTexts = () =>
    props.isCompliant
      ? t("mapping.compliantTooltipText")
      : t("mapping.nonCompliantTooltipText");

  const getTotalCompliantInfo = () =>
    t("mapping.totalCompliantProducts", { total: count });

  if (!count || searchStore.productSearchLoading) return <></>;
  return (
    <div
      test-id="test-compliant-info"
      className={clsx(
        "border-b border-gray-200 flex items-center justify-center mt-2 mb-6",
        !props.isCompliant && searchStore.compliantItemsInfo.totalCompliant > 0
          ? "mt-6"
          : "",
        props.containerClassName
      )}
    >
      <span className="absolute bg-white px-4 text-sm font-normal text-gray-600">
        {getTotalCompliantInfo()}
        <Badge
          size="small"
          className="ml-2 !px-2 !py-[1px] text-xs bg-green-100 !h-auto"
          type={props.isCompliant ? "success" : "attention"}
        >
          {props.isCompliant
            ? t("mapping.compliantInfoNoCert")
            : t("mapping.nonCompliantInfo")}
          <Tooltip
            iconClassName="w-3 h-3 !ml-0.5"
            content={getTooltipTexts()}
          />
        </Badge>
      </span>
    </div>
  );
};

export default observer(CompliantInfo);
