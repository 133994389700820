import React from "react";
import { useTranslation } from "react-i18next";
import ListSectionHeader from "./ListSectionHeader";
import Collapse from "components/Collapse";
import { CIRCULARITY_DOCUMENT_URL } from "utils";
import {
  Circularity,
  CircularityPerformanceIndexValue,
  RecyclabilityDescriptionValue,
  RecyclabilityScoreValue,
  SingularCircularityValue,
} from "api-client";
import { observer } from "mobx-react-lite";

interface Props {
  circularity: Circularity | null | undefined;
}

const CircularityListItem = ({ circularity }: Props) => {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";

  const itemSingularElement = (item: SingularCircularityValue) => {
    const field_name = isEng
      ? item.field_name
      : item.field_name_de ?? item.field_name;

    return (
      <li
        key={field_name}
        className={`border-b text-sm font-medium border-gray-300 text-gray-600 p-2 flex justify-between items-center`}
      >
        {field_name}
        <div className="text-sm font-normal text-right max-w-[200px]">
          {item.value ?? ""}
        </div>
      </li>
    );
  };

  const itemElement = (
    key: string | null | undefined,
    value: string | null | undefined,
    isLast: boolean
  ) => {
    return (
      <li
        key={key}
        className={`${
          isLast ? "last:border-b-0" : "border-b-gray-300"
        } border-b border-b-gray-100 text-sm font-medium border-gray-300 text-gray-600 p-2 flex justify-between items-center`}
      >
        {key}
        <div className="text-sm font-normal max-w-[300px] text-right">
          {value}
        </div>
      </li>
    );
  };

  const cpx = circularity?.circularity_performance_index;
  const recyclabilityScore = circularity?.recyclability_score;
  const recyclabilityDescription = circularity?.recyclability_description;

  function checkNullValue(
    values:
      | CircularityPerformanceIndexValue[]
      | RecyclabilityScoreValue[]
      | RecyclabilityDescriptionValue[]
      | undefined
  ) {
    if (!values) return false;
    for (const item of values) {
      if (item.value !== null) {
        return true;
      }
    }
    return false;
  }

  return (
    <>
      <ListSectionHeader
        title={t("detailView.reportsTab.circularity")}
        tooltip={
          <div
            dangerouslySetInnerHTML={{
              __html: t("detailView.reportsTab.circularityTooltip", {
                link: CIRCULARITY_DOCUMENT_URL,
              }),
            }}
          ></div>
        }
      />
      <ul className="border border-gray-300 rounded-md p-0 ">
        {cpx && checkNullValue(cpx.values) && (
          <Collapse
            defaultClose
            headerClassName="bg-transparent !p-2 border-b"
            headerTitle={
              isEng ? cpx.field_name : cpx.field_name_de ?? cpx.field_name
            }
          >
            {cpx.values?.map(
              (item) =>
                item.value &&
                itemElement(item?.material_name, item.value, false)
            )}
          </Collapse>
        )}
        {circularity?.singular_values?.map(
          (item) => item.value && itemSingularElement(item)
        )}
        {recyclabilityScore && checkNullValue(recyclabilityScore.values) && (
          <Collapse
            defaultClose
            headerClassName="bg-transparent !p-2 border-b"
            headerTitle={
              isEng
                ? recyclabilityScore.field_name
                : recyclabilityScore.field_name_de ??
                  recyclabilityScore.field_name
            }
          >
            {recyclabilityScore.values?.map(
              (item) =>
                item.value &&
                itemElement(item?.material_name, item.value, false)
            )}
          </Collapse>
        )}
        {recyclabilityDescription &&
          checkNullValue(recyclabilityDescription.values) && (
            <Collapse
              defaultClose
              headerClassName="bg-transparent !p-2"
              headerTitle={
                isEng
                  ? recyclabilityDescription.field_name
                  : recyclabilityDescription.field_name_de ??
                    recyclabilityDescription.field_name
              }
            >
              {recyclabilityDescription.values?.map(
                (item) =>
                  item.value &&
                  itemElement(item?.material_name, item.value, true)
              )}
            </Collapse>
          )}
      </ul>
    </>
  );
};

export default observer(CircularityListItem);
