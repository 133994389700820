import clsx from "clsx";
import React from "react";
import {
  CameraIcon,
  PhotographIcon,
  UploadIcon,
} from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import ImageUploaderThumbnail from "components/ImageUploaderThumbnail";
import { LocalImage } from "../../api-client-local/db_interfaces";
import LoadingIcon from "components/LoadingIcon";

interface ImageUploaderLocalProps extends React.HTMLProps<HTMLInputElement> {
  containerClassName?: string;
  labelName?: string;
  images?: (File | LocalImage)[];
  onRemove?: (id: string) => void;
  handleChange?: (images: (File | LocalImage)[]) => void | Promise<void>;
  multiple?: boolean;
  onDraged?: (filesList: File[]) => Promise<void>;
  onRemoveSelected?: (file: File) => void;
}

type SelectedFiles = (File | LocalImage)[];

const ImageUploaderLocal = React.forwardRef(
  (props: ImageUploaderLocalProps, ref: React.Ref<HTMLInputElement>) => {
    const { t } = useTranslation();
    const [selectedFiles, setSelectedFiles] = React.useState<SelectedFiles>(
      props.images ?? []
    );

    const [compressing, setCompressing] = React.useState(false);

    const LoadingView = ({ w = "7", h = "7" }: { w?: string; h?: string }) => {
      return (
        <div className="group border rounded-md flex flex-wrap py-4 whitespace-normal items-center justify-center flex-col w-[120px] h-[100%] font-[500] text-[12px] text-gray-500">
          <LoadingIcon type="primary" className={`w-${w} h-${h} mb-4`} />
          <p className="text-center">{t("audits.compressingtheImage")}</p>
        </div>
      );
    };

    const handleImageCompress = async (files: FileList | null) => {
      if (!files) return;

      setCompressing(true);
      const promises = [];

      for (const file of files) {
        promises.push(processFile(file));
      }

      try {
        const results = await Promise.all(promises);
        setSelectedFiles(results as File[]);
        handleChange(results as File[]).catch((err) => console.error(err));
        setCompressing(false);
        return results;
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    const processFile = (file: File) => {
      return new Promise((resolve) => {
        const maxSizeInBytes = 1 * 1024 * 1024; // Maximum file size in bytes (1MB)
        const maxWidthOrHeight = 2000; // Maximum width or height in pixels
        const quality = 0.8; // Quality parameter (80%)
        if (file.size > maxSizeInBytes) {
          printImageSize(file.size, "ORIGINAL");
          const image = new Image();
          image.src = URL.createObjectURL(file);

          new Promise<File>(() => {
            image.onload = () => {
              let width = image.width;
              let height = image.height;

              if (width > height && width > maxWidthOrHeight) {
                height *= maxWidthOrHeight / width;
                width = maxWidthOrHeight;
              } else if (height > maxWidthOrHeight) {
                width *= maxWidthOrHeight / height;
                height = maxWidthOrHeight;
              }

              const canvas = document.createElement("canvas");
              canvas.width = width;
              canvas.height = height;

              const context = canvas.getContext("2d");
              if (context) {
                context.drawImage(image, 0, 0, width, height);

                canvas.toBlob(
                  (compressedBlob) => {
                    if (compressedBlob) {
                      const compressedFile = new File(
                        [compressedBlob],
                        file.name,
                        {
                          type: file.type,
                          lastModified: Date.now(),
                        }
                      );
                      resolve(compressedFile);
                      printImageSize(compressedFile.size, "COMPRESSED");
                    } else {
                      console.error("Could not generate compressed blob");
                      resolve(file);
                      printImageSize(file.size, "COMPRESSED");
                    }
                  },
                  file.type,
                  quality
                );
              } else {
                console.error("Could not retrieve canvas 2D context");
                resolve(file); // Fallback to original file
              }
            };
          });
        } else {
          resolve(file);
          console.log("Image compression not needed...");
        }
      });
    };

    function printImageSize(size: number, type: string) {
      const fileSizeInKBs = size / 1024;
      console.log(
        `${type} image size: ${fileSizeInKBs.toFixed(2)} KB  which is = ${(
          fileSizeInKBs / 1024
        ).toFixed(2)} MB`
      );
    }

    async function removeFile(file: File | LocalImage) {
      const files = selectedFiles.filter((item) => {
        if (file instanceof File && item instanceof File) {
          return item !== file;
        }
        if ("file" in file && "file" in item) {
          return item.id !== file.id;
        }
        return item;
      });
      if (props.handleChange && props.images) {
        await props.handleChange?.(files);
      } else {
        setSelectedFiles(files);
        await props.handleChange?.(files);
        props.onRemoveSelected?.(file as File);
      }
    }

    const handleDrop = async (e: React.DragEvent<HTMLLabelElement>) => {
      e.preventDefault();
      if (e.dataTransfer.files.length) {
        await handleImageCompress(e.dataTransfer.files)
          .then((response) => {
            props.onDraged?.(response as File[]);
          })
          .catch((error) => {
            console.error("Error compressing image: ", error);
            handleChange(e.dataTransfer.files);
          });
      }
    };

    const handleChange = async (filesList: File[] | FileList) => {
      const nextState = Array.from(
        new Set([...selectedFiles, ...Array.from(filesList)])
      );
      if (props.handleChange && props.images) {
        console.log("ImageUploaderLocal.nextState", nextState);
        await props.handleChange?.(nextState);
      } else {
        console.log("ImageUploaderLocal.nextState.noExternal", nextState);
        setSelectedFiles(nextState);
        await props.handleChange?.(nextState);
      }
    };

    React.useEffect(() => {
      if (props.images) {
        setSelectedFiles(props.images);
      }
    }, [props.images]);

    return (
      <div className={`flex flex-col mt-1 mb-2 ${props.containerClassName}`}>
        {props.labelName && (
          <label className="text-xs text-gray-700">{props.labelName}</label>
        )}
        <label
          className={clsx(
            "hidden lg:flex flex-col items-center justify-center p-6 transition border bg-white mt-0.5",
            "border-gray-300 border-dashed appearance-none text-gray-700 hover:bg-gray-50",
            "cursor-pointer hover:border-gray-400 focus:outline-none h-56 w-full rounded-md"
          )}
          onDrop={(e) => {
            handleDrop(e).catch((err) => console.error(err));
          }}
          onDragOver={(e) => e.preventDefault()}
          onDragEnter={(e) => e.preventDefault()}
          onDragLeave={(e) => e.preventDefault()}
        >
          <PhotographIcon width={64} className="text-gray-400 mb-4" />
          <span className="mb-1 text-sm">
            {t("commons.dragAndDropMessage")}
          </span>
          <span className="mb-3 text-sm">{t("commons.or")}</span>
          <input
            ref={ref}
            type="file"
            onBlur={props.onBlur}
            name={props.name}
            id={props.id}
            required={props.required}
            className="hidden"
            accept="image/*"
            multiple={props.multiple}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.files) {
                handleImageCompress(e.target.files)
                  .then(() => {
                    props.onChange?.(e);
                    console.log("Image compressed successfully");
                  })
                  .catch((error) => {
                    console.error("Error compressing image: ", error);
                    props.onChange?.(e);
                    if (e.target.files) {
                      handleChange(e.target.files);
                    }
                  });
              }
            }}
          />
          <div
            className={clsx(
              "bg-indigo-100 text-indigo-700 hover:bg-indigo-200 h-10",
              "flex px-4 py-3 text-sm justify-center items-center font-semibold rounded-md"
            )}
          >
            <UploadIcon className="-ml-1 mr-2 h-5 w-5" />
            {t("commons.uploadFile")}
          </div>
        </label>

        <div className="grid lg:hidden grid-cols-2 grid-flow-col mt-1 gap-2">
          <div
            className={
              "bg-indigo-100 text-indigo-700 hover:bg-indigo-200 h-10 flex px-4 py-3 text-sm justify-center items-center font-semibold rounded-md overflow-hidden relative"
            }
          >
            <input
              type="file"
              onBlur={props.onBlur}
              name={props.name}
              id={`${props.id}-camera`}
              required={props.required}
              className="left-0 h-full top-0 opacity-0 absolute"
              accept="image/*"
              capture="environment"
              multiple={props.multiple}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.files) {
                  handleImageCompress(e.target.files)
                    .then(() => {
                      props.onChange?.(e);
                      console.log("Image compressed successfully");
                    })
                    .catch((error) => {
                      console.error("Error compressing image: ", error);
                      props.onChange?.(e);
                      if (e.target.files) {
                        handleChange(e.target.files);
                      }
                    });
                }
              }}
            />
            <CameraIcon className="-ml-1 mr-2 h-5 w-5" />
            {t("audits.byCamera")}
          </div>
          <div
            className={
              "bg-indigo-100 text-indigo-700 hover:bg-indigo-200 h-10 flex px-4 py-3 text-sm justify-center items-center font-semibold rounded-md overflow-hidden relative"
            }
          >
            <input
              type="file"
              onBlur={props.onBlur}
              name={props.name}
              id={`${props.id}-gallery`}
              required={props.required}
              className="left-0 h-full top-0 opacity-0 absolute"
              accept="image/*"
              multiple={props.multiple}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.files) {
                  handleImageCompress(e.target.files)
                    .then(() => {
                      console.log("Image compressed successfully");
                      props.onChange?.(e);
                    })
                    .catch((error) => {
                      console.error("Error compressing image: ", error);
                      props.onChange?.(e);
                      if (e.target.files) {
                        handleChange(e.target.files);
                      }
                    });
                }
              }}
            />
            <PhotographIcon className="-ml-1 mr-2 h-5 w-5" />
            {t("audits.fromGallery")}
          </div>
        </div>
        <div className="grid grid-cols-4 sm:grid-cols-6 md:flex md:flex-wrap gap-1 items-start mt-4">
          {compressing && <LoadingView />}
          {selectedFiles.length ? (
            <>
              {selectedFiles?.map((image, i) => (
                <ImageUploaderThumbnail
                  key={i}
                  onRemove={removeFile}
                  item={image as LocalImage}
                />
              ))}
            </>
          ) : null}
          {selectedFiles.length && !props.multiple ? null : props.children}
        </div>
      </div>
    );
  }
);

ImageUploaderLocal.displayName = "ImageUploaderLocal";

export default ImageUploaderLocal;
