import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ColumnDef, Row } from "@tanstack/react-table";
import { IconPlus, IconSearch } from "@tabler/icons-react";
import useSelectColumn from "components/Table/useSelectColumn";
import { searchStore } from "store/IfcMapping/SearchStore";
import { ColumnGroup, dynamicMEStore } from "store/IfcMapping/DME/DMEStore";
import { DynamicColumnOUT } from "api-client";
import { MappingStatus } from "./utils";

const useTableColumns = () => {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";

  const headerCell = (name: string) => (
    <div className="flex justify-between w-full text-gray-500 items-center whitespace-nowrap py-1 text-xs">
      <span>{name}</span>
      <IconPlus className="text-gray-500 cursor-pointer" size={18} />
    </div>
  );

  function roundToThreeDecimals(num: number | null | undefined) {
    if (num === null || num === undefined) return "-";
    const roundedValue = num.toFixed(3);
    const isRoundedToZero = parseFloat(roundedValue) === 0 && num !== 0;
    return `${isRoundedToZero ? "~" : ""}${roundedValue}`.replace(".", ",");
  }

  const columnCell = (col: DynamicColumnOUT) => ({
    accessorFn: (row: { [key: string]: string }) => row[col.key_name],
    id: col.key_name,
    header: () => (
      <div>
        <div className="flex">
          <span className="truncate">
            {isEng ? col.friendly_name : col.friendly_name_de}
          </span>
          {/* {col.tooltip_active ? <Tooltip content={"tooltip"}></Tooltip> : null} */}
        </div>
        <span className="text-xs text-gray-500">
          {isEng ? col.subtitle : col.subtitle_de}
        </span>
      </div>
    ),
    cell: (info: { row: { original: { [key: string]: string | number } } }) => {
      const value =
        col.type === "NUMERIC"
          ? roundToThreeDecimals(Number(info.row.original[col.key_name]))
          : info.row.original[col.key_name];
      return (
        <div className={col.type === "NUMERIC" ? "text-right" : "text-left"}>
          {value ?? "-"}
        </div>
      );
    },
    enableColumnFilter: false,
    enableSorting: false,
  });

  const groupCell = (currentCategory: ColumnGroup) => {
    return {
      id: currentCategory.group_name,
      header: () =>
        headerCell(
          isEng
            ? currentCategory?.group_name ?? ""
            : currentCategory?.group_name_de ?? ""
        ),
      columns: currentCategory.columns?.map((col) => columnCell(col)),
    };
  };

  function onClickMappingCell(
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    info: { row: Row<unknown> }
  ) {
    e.stopPropagation();
    dynamicMEStore.setCurrentRow(info.row);
    !dynamicMEStore.openedProductDetailsPopup &&
      searchStore.setOpenProductSearch(true);
  }

  const onSelectAllChanged = (checked: boolean) => {
    dynamicMEStore.setSelectAllChecked(checked);
  };

  const selectCell = () => {
    return {
      id: "selectGroup",
      header: () => <div className="w-[14px]"></div>,
      disableResizing: true,
      columns: [useSelectColumn(onSelectAllChanged)],
    };
  };

  const mappingCell = () => ({
    id: "mappingGroup",
    header: () => <></>,
    columns: [
      {
        id: "mapping",
        header: () => t("mapping.objectMapping"),
        cell: (info: { row: Row<unknown> }) => (
          <div
            className="flex justify-between items-center cursor-pointer group"
            onClick={(e) => onClickMappingCell(e, info)}
            test-id={`manualmapping_row_${info.row.index}`}
          >
            <MappingStatus row={info.row as Row<unknown>} />
            <IconSearch className="w-5 transition stroke-gray-500 group-hover:stroke-gray-500" />
          </div>
        ),
        enableColumnFilter: false,
        size: 340,
        minWidth: 340,
        maxWidth: 340,
        enableSorting: false,
        disableResizing: true,
      },
    ],
  });

  return useMemo<ColumnDef<{ [key: string]: string }>[]>(
    () => [
      selectCell(),
      ...dynamicMEStore.columnGroups.map((category) => groupCell(category)),
      mappingCell(),
    ],
    []
  );
};

export default useTableColumns;
