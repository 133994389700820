import React from "react";
import clsx from "clsx";

interface IProps extends React.HTMLProps<HTMLInputElement> {
  labelElement?: React.ReactElement | string;
  containerClassName?: string;
}

const Checkbox = React.forwardRef(
  (
    { labelElement, containerClassName, ...props }: IProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    return (
      <div
        className={clsx(
          "flex items-center py-2 text-sm text-gray-700",
          containerClassName,
          props.disabled ? "cursor-not-allowed" : "cursor-pointer"
        )}
      >
        <input
          className={clsx(
            "bg-white border-gray-300 checked:bg-indigo-600 ring-0 focus:outline-none",
            "checked:hover:bg-indigo-600 rounded focus:checked:bg-indigo-600 focus:ring-0",
            props.disabled ? "cursor-not-allowed" : "cursor-pointer"
          )}
          ref={ref}
          type="checkbox"
          {...props}
        />
        {labelElement && (
          <label className="pl-2" htmlFor={props.id ?? undefined}>
            {labelElement}
          </label>
        )}
      </div>
    );
  }
);

Checkbox.displayName = "Checkbox";

export default Checkbox;
