import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import SearchSortTable from "components/Table";
import { ColumnDef } from "@tanstack/react-table";
import { IconX } from "@tabler/icons-react";
import { BuildingMemberOUT } from "api-client";
import Alert from "components/Alert";

interface IProps {
  confirmedGuests: BuildingMemberOUT[];
  removeGuestFromBuilding: (guestId: string, email: string) => void;
}

const ManageGuests = ({ confirmedGuests, removeGuestFromBuilding }: IProps) => {
  const { t } = useTranslation();

  const accountCell = (info: {
    getValue: () => {
      user: {
        first_name: string;
        last_name: string;
        email: string;
      };
    };
  }) => (
    <>
      <div className="">
        {`${info?.getValue()?.user.first_name || "-"} ${
          info?.getValue()?.user.last_name || ""
        }` ?? ""}
      </div>
      <div className="text-gray-500">
        {`${info?.getValue()?.user.email || ""}`}{" "}
      </div>
    </>
  );

  const removeCell = (info: {
    getValue: () => {
      id: string;
      user: {
        email: string;
      };
    };
  }) => (
    <div className="flex justify-center">
      <Button
        type="danger"
        leadingIcon={<IconX />}
        onClick={removeUser(info)}
        width="fit-content"
        className="border-none"
      ></Button>
    </div>
  );

  function removeUser(info: {
    getValue: () => {
      id: string;
      user: {
        email: string;
      };
    };
  }): ((e: React.MouseEvent<Element, MouseEvent>) => void) | undefined {
    return () =>
      removeGuestFromBuilding(
        info?.getValue()?.id,
        info?.getValue()?.user.email
      );
  }

  const getColumns = () => {
    const columns = useMemo(
      () => [
        {
          accessorFn: (row: {
            user: {
              first_name: string;
              last_name: string;
              email: string;
            };
          }) => row,
          id: "account",
          cell: accountCell,
          header: () => <div>{t("commons.user")}</div>,
          enableColumnFilter: false,
          enableSorting: false,
        },
        {
          accessorFn: (row: { id: string; user: { email: string } }) => row,
          id: "Remove",
          cell: removeCell,
          header: () => (
            <div className="m-auto">{t("shareBuilding.remove")}</div>
          ),
          enableColumnFilter: false,
          enableSorting: false,
          size: 60,
        },
      ],
      [confirmedGuests]
    );
    return columns;
  };

  return (
    <div className="px-3 py-6">
      {confirmedGuests && confirmedGuests.length > 0 ? (
        <SearchSortTable
          data={confirmedGuests}
          getColumns={getColumns as () => ColumnDef<unknown, unknown>[]}
          borderedStyle
          scrollDivClassName="overflow-y-auto max-h-[375px]"
        />
      ) : (
        <Alert
          type="info"
          title={t("shareBuilding.manageEmptyStateTitle")}
          description={t("shareBuilding.manageEmptyStateInfo")}
        />
      )}
    </div>
  );
};

export default ManageGuests;
