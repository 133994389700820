import { useTranslation } from "react-i18next";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AuthLayout from "layouts/AuthLayout";
import Button from "components/Button";
import PingAnimation from "components/PingAnimation";
import { useAuth0 } from "@auth0/auth0-react";
import Alert from "components/Alert";
import { useQuery } from "hooks/useQuery";
import { userStore } from "store/UserStore";
import { observer } from "mobx-react-lite";

const LogIn = () => {
  const { t } = useTranslation();
  const { isLoading, loginWithRedirect } = useAuth0();
  const { onboarded } = userStore;
  const navigate = useNavigate();
  const query = useQuery();
  const location = useLocation();

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    localStorage.removeItem("org-accordion-status");
    try {
      await loginWithRedirect({
        redirectUri:
          location.pathname === "/revit-setup"
            ? process.env.REACT_APP_REVIT_URL
            : "",
      });
      console.log("login submitted ✅");
      onboarded
        ? navigate("/dashboard", { replace: true })
        : navigate("/onboarding", { replace: true });
    } catch (error) {
      console.error("error", error);
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="min-h-screen justify-center flex items-center">
          <PingAnimation size="large" color="gray" />
        </div>
      ) : (
        <AuthLayout className="min-h-screen max-h-screen">
          <div className="text-left min-w-full">
            {location.state === "confirm" && (
              <Alert
                type="success"
                title={t("signIn.successfullyVerified")}
                className="mb-5"
              />
            )}
            <div className="flex pt-4 items-center justify-between">
              <Button
                type="submit"
                htmlType="submit"
                disabledClasses="w-full"
                className="bg-gray-800 m-auto text-white font-light hover:bg-gray-700"
                onClick={handleLogin}
                testID="login_button"
              >
                {query.get("screen") === "signup"
                  ? t("signIn.clickToSignup")
                  : t("signIn.clickToLogin")}
              </Button>
            </div>
          </div>
        </AuthLayout>
      )}
    </>
  );
};

export default observer(LogIn);
