import React from "react";
import { IconCube, IconStack2 } from "@tabler/icons-react";
import Tooltip from "components/Tooltip";
import { getCurrentReferenceValue } from "./utils";
import { ProductSearchOutput } from "api-client";
import { useTranslation } from "react-i18next";
import { useComplianceInfo } from "hooks/useComplianceInfo";
import { observer } from "mobx-react-lite";

function ProductComponentInfo({ item }: { item: ProductSearchOutput }) {
  const { i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const itemReferenceUnit = getCurrentReferenceValue(
    item.reference_unit.toUpperCase()
  );
  const { getCertificationChipsGroup, getConformityChipsGroup } =
    useComplianceInfo();

  if (!item) return <></>;
  return (
    <div className="overflow-hidden">
      <div className="flex w-full items-center pr-6">
        {item.type === "PRODUCT" ? (
          <IconCube
            stroke={3}
            className="bg-teal-300 rounded-full p-[5px] min-w-[24px] min-h-[24px] mr-2 text-teal-900"
          />
        ) : (
          <IconStack2
            stroke={3}
            className="bg-cyan-300 rounded-full p-[5px] min-w-[26px] min-h-[26px] mr-2 text-cyan-900"
          />
        )}
        <span className="text-base font-semibold text-gray-800 overflow-hidden">
          <Tooltip truncate className="!cursor-auto">
            {item.name}
          </Tooltip>
        </span>
        {item.reference_unit && (
          <div className="bg-gray-200 text-xs rounded-full py-1 px-2 ml-2 flex items-center">
            {isEng ? itemReferenceUnit?.name : itemReferenceUnit?.name_de}
          </div>
        )}
        {item.din_category_number && (
          <span className="text-gray-500 text-sm pl-2">
            {item.din_category_number}
          </span>
        )}
        {item.din_category && (
          <span className="text-gray-500 text-sm pl-1 overflow-hidden">
            <Tooltip truncate className="!cursor-auto">
              {item.din_category}
            </Tooltip>
          </span>
        )}
      </div>
      <div className="flex mt-2">
        <div className="flex border-r border-gray-200 mr-1">
          {getConformityChipsGroup(item.compliance?.conformities)}
        </div>
        {getCertificationChipsGroup(item.compliance?.certifications)}
      </div>
    </div>
  );
}

export default observer(ProductComponentInfo);
