import { Row } from "@tanstack/react-table";
import {
  // InventoryProductOut,
  SingleObject,
} from "api-client";
import React from "react";
import { useTranslation } from "react-i18next";
// import { MaterialFileObject } from "store/IfcMapping/types";
import MappingChip from "./MappingChip";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";
import { useParams } from "react-router";

export const MappingStatus = ({
  row,
  i,
}: {
  row: Row<unknown>;
  i?: string;
}) => {
  const { t } = useTranslation();
  const { ifc_id } = useParams();
  const object = row.original as SingleObject;
  const [loading, setLoading] = React.useState(false);

  const handleUnmatch = React.useCallback(async () => {
    setLoading(true);
    await dynamicMEStore.matchProduct(ifc_id, null, object.id);
    setLoading(false);
  }, [row]);

  if (object.product_match?.id) {
    return (
      <MappingChip
        object={object}
        product={object.product_match}
        unmatch={handleUnmatch}
        loading={loading}
      />
    );
  }
  return (
    <div key={i} className="text-xs text-gray-400 w-max">
      {t("mapping.searchAndMap")}
    </div>
  );
};
