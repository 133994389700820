import { Entity, Viewer } from "@xeokit/xeokit-sdk";
import {
  inventoryMappingEditorAPI,
  InventoryPhysicalObjectDetailOut,
} from "api-client";
import { getLocalAuthHeader } from "api-client-local/utils";
import { makeAutoObservable } from "mobx";

class BimViewerStore {
  currentObject: Entity | undefined = undefined;
  objectDetail: InventoryPhysicalObjectDetailOut[] | undefined = undefined;
  viewer: Viewer | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchDetailsInfo(ifcId: string, objectId: string) {
    const authHeader = await getLocalAuthHeader();
    await inventoryMappingEditorAPI
      .inventoryApiV1RoutersMappingEditorGetPhysicalObjectDetailsWithGuid(
        ifcId,
        objectId,
        authHeader
      )
      .then((response) => {
        this.setObjectDetail(response.data);
      })
      .catch((error) => console.error("Failed to fetchDetailsInfo:", error));
  }

  setCurrentObject(currentObject: Entity | undefined) {
    this.currentObject = currentObject;
  }

  setObjectDetail(
    objectDetail: InventoryPhysicalObjectDetailOut[] | undefined
  ) {
    this.objectDetail = objectDetail;
  }

  setViewer(viewer: Viewer) {
    this.viewer = viewer;
  }

  initialZoomToIfcSite() {
    if (this.viewer) {
      const ifSiteID = this.viewer?.metaScene.getObjectIDsByType("IfcSite")[0];
      const objetcIDs = this.viewer?.metaScene.getObjectIDsInSubtree(ifSiteID);
      this.viewer.scene.setObjectsVisible(this.viewer.scene.objectIds, true);
      this.viewer.cameraFlight.flyTo({
        aabb: this.viewer.scene.getAABB(objetcIDs),
      });
    }
  }

  static instance: BimViewerStore;

  static getInstance(): BimViewerStore {
    if (!BimViewerStore.instance) {
      BimViewerStore.instance = new BimViewerStore();
    }
    return BimViewerStore.instance;
  }
}

export const bimViewerStore = BimViewerStore.getInstance();
