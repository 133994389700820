import React from "react";
import { observer } from "mobx-react-lite";
import Retool from ".";
import MainLayout from "layouts/MainLayout";
import { useParams } from "react-router";

const PortfolioDashboard = () => {
  const reportID = "878c54a0-12d0-11ef-b00e-af3279523dc3";
  const { organisation_id } = useParams();

  const embeddedData = {
    organisation_id: organisation_id,
  };

  return (
    <MainLayout headerTitle="Portfolio Dashboard">
      <Retool url={reportID} embeddedData={embeddedData} />
    </MainLayout>
  );
};

export default observer(PortfolioDashboard);
