import Heading from "components/Heading";
import { useTranslation } from "react-i18next";
import useFeatureFlag from "hooks/useFeatureFlag";
import { observer } from "mobx-react-lite";
import ComplianceSettingsTile from "./ComplianceSettingsTile";
import DashboardPassport from "features/ReportDashboards/DashboardPassport";

export default observer(function LCPReport() {
  const { t } = useTranslation();
  const { isFeatureVisible } = useFeatureFlag();

  return (
    <>
      <Heading size="title" className="mb-0">
        {t("buildingPassport.reports")}
      </Heading>
      {isFeatureVisible("compliance_settings") ? (
        <ComplianceSettingsTile />
      ) : null}
      {isFeatureVisible("power_bi_dashboard") && (
        <DashboardPassport containerClassName="mt-4" />
      )}
    </>
  );
});
