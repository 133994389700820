import React from "react";
import { IconSearch, IconSparkles } from "@tabler/icons-react";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";
import { useParams } from "react-router";
import { OperationType } from "api-client";
import { searchStore } from "store/IfcMapping/SearchStore";

const BulkActionBar = () => {
  const { t } = useTranslation();
  const { ifc_id } = useParams();

  const handleAutoMapp = async (mode: OperationType) => {
    if (dynamicMEStore.selectAllChecked) {
      await dynamicMEStore.runFileOperation(ifc_id, mode);
      await dynamicMEStore.fetchObjectsList(0, true, ifc_id);
      dynamicMEStore.setSelectAllChecked(false);
      dynamicMEStore.setSelectedRows([]);
    } else {
      await dynamicMEStore.runSelectedObjectOperations(ifc_id, mode);
    }
  };

  const openProductSearch = () => {
    dynamicMEStore.setMatchForSelectedItems(true);
    searchStore.setOpenProductSearch(true);
  };

  React.useEffect(() => {
    searchStore.openProductSearch === false &&
      dynamicMEStore.setMatchForSelectedItems(false);
  }, [searchStore.openProductSearch]);

  if (dynamicMEStore.selectedRows.length === 0) return <></>;
  return (
    <div
      className={clsx(
        "h-10 flex items-center justify-center absolute bottom-32 bg-gray-50 border text-sm",
        "border-gray-300 shadow-lg w-fit rounded-[20px] px-3 py-2 text-indigo-600"
      )}
    >
      {t("mappingEditor.actionBarText", {
        amount: dynamicMEStore.selectAllChecked
          ? t("mappingEditor.all")
          : dynamicMEStore.selectedRows.length,
      })}
      <div className="text-gray-500 flex ml-4">
        <IconSparkles
          className={clsx("mr-1 cursor-pointer", {
            "cursor-not-allowed pointer-events-none opacity-50":
              dynamicMEStore.showMappingLoading,
          })}
          onClick={() => handleAutoMapp("auto_map_match")}
        />
        <IconSearch className="cursor-pointer" onClick={openProductSearch} />
      </div>
    </div>
  );
};

export default observer(BulkActionBar);
