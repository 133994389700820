import React from "react";
import Modal, { ModalProps } from "components/Modal";
import { InventoryCategoryAggregateOut } from "api-client";
import Filters from "./Filters";
import LinearLoading from "components/LinearLoading";
import {
  FiltersOptions,
  MaterialFileObject,
  SearchTabsProps,
} from "store/IfcMapping/types";
import { observer } from "mobx-react-lite";
import CustomProducts from "./tabs/CustomProducts";
import useFeatureFlag from "hooks/useFeatureFlag";
import SearchBar from "./SearchBar";
import DefaultProducts from "./tabs/DefaultProducts";
import { searchStore } from "store/IfcMapping/SearchStore";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import DefaultComponents from "./tabs/DefaultComponents";
import CustomComponents from "./tabs/CustomComponents";
import { IconCube, IconStack2 } from "@tabler/icons-react";
import { SEARCH_TABS_LIST } from "../consts";
import { useMappingTools } from "../useMappingTools";

interface ProductSearchProps extends ModalProps {
  currentRow?: InventoryCategoryAggregateOut;
  refresh?: (item: MaterialFileObject) => void;
  matchProductAndUpdate?: (product_id: string) => void;
  isDynamic?: boolean;
}

export default observer(function ProductSearch({
  open,
  close,
  currentRow,
  refresh,
  matchProductAndUpdate,
  isDynamic,
}: ProductSearchProps) {
  const { t } = useTranslation();
  const [query, setQuery] = React.useState<string>("");
  const [filters, setFilters] = React.useState<FiltersOptions>({
    DIN_categories: [],
    materials: [],
    conformity: [],
    certifications: [],
    other_properties: [],
  });

  const { isFeatureVisible } = useFeatureFlag();
  const { mappingMatch } = useMappingTools();

  React.useEffect(() => {
    searchStore.resetSearchResults();
    searchStore.resetCompliantItemsInfo();
  }, []);

  const onSearch = (value: string | number) => {
    searchStore.setCurrentSearchPage(1);
    setQuery(String(value));
  };

  function showSelectedTabContent() {
    switch (searchStore.selectedTab.id) {
      case SEARCH_TABS_LIST[0].id:
        return <DefaultProducts match={matchProduct} />;
      case SEARCH_TABS_LIST[1].id:
        return <CustomProducts match={matchProduct} />;
      case SEARCH_TABS_LIST[2].id:
        return <DefaultComponents match={matchProduct} />;
      case SEARCH_TABS_LIST[3].id:
        return <CustomComponents match={matchProduct} />;
    }
  }

  const matchProduct = async (product_id: string) => {
    if (isDynamic) {
      matchProductAndUpdate?.(product_id);
    } else {
      const response = await mappingMatch(product_id, currentRow);
      response?.length && refresh?.(response[0]);
    }
    close();
  };

  const onClickTab = (tab: SearchTabsProps) => {
    searchStore.setSelectedTab(tab);
    searchStore.setCurrentSearchPage(1);
  };

  function updateFilters(name: string, values: unknown[]) {
    setFilters((state) => ({ ...state, [name]: values }));
  }

  return (
    <Modal
      isOpen={open}
      setIsOpen={close}
      className="p-6"
      containerClassName="md:min-w-[1060px] min-h-[500px] overflow-visible overflow-x-hidden"
      position="top"
    >
      <SearchBar value={query} onChange={onSearch} onCancel={close} />
      {isFeatureVisible("bpe_tabs") ? (
        <>
          <div className="flex h-[36px] my-2">
            {SEARCH_TABS_LIST.map((tab, index) => (
              <div
                key={tab.id}
                className={clsx(
                  "flex flex-1 justify-center cursor-pointer p-2 text-center items-center text-sm active:bg-gray-100",
                  searchStore.selectedTab.id === tab.id
                    ? `bg-indigo-600 text-white`
                    : `bg-white text-gray-900 border border-gray-200`,
                  {
                    "opacity-70 pointer-events-none !cursor-not-allowed":
                      searchStore.productSearchLoading,
                    "rounded-l-md": index === 0,
                    "rounded-r-md": index === SEARCH_TABS_LIST.length - 1,
                  }
                )}
                onClick={() => onClickTab(tab)}
              >
                <span className="mr-2">
                  {tab.type === "PRODUCT" ? (
                    <IconCube width={18} />
                  ) : (
                    <IconStack2 width={18} />
                  )}
                </span>
                {t(`mapping.${tab.id}`)}
              </div>
            ))}
          </div>
          <Filters query={query} filters={filters} setFilters={updateFilters} />
          <div className="min-h-[calc(100vh-360px)]">
            {searchStore.productSearchLoading ? <LinearLoading /> : null}
            <div className="flex flex-col space-y-3">
              {showSelectedTabContent()}
            </div>
          </div>
        </>
      ) : (
        <>
          <Filters query={query} filters={filters} setFilters={updateFilters} />
          {searchStore.productSearchLoading && <LinearLoading />}
          <div className="flex flex-col space-y-3">
            {showSelectedTabContent()}
          </div>
        </>
      )}
    </Modal>
  );
});
