import React, { ReactNode } from "react";
import { Combobox } from "@headlessui/react";
import clsx from "clsx";
import { IconChevronDown } from "@tabler/icons-react";
import OptionsMenu from "./OptionsMenu";

export interface DropdownOption {
  id?: string | number;
  name: string;
  name_de?: string;
  icon?: ReactNode;
  title?: string;
  title_de?: string;
  value?: string;
  children?: DropdownOption[];
}

export interface DropdownWithSearchProps<T>
  extends React.HTMLProps<HTMLInputElement> {
  labelName?: string;
  items?: T[];
  displayKey: keyof T;
  detailKey?: keyof T;
  handleChange?: (value: string) => void;
  onReset?: () => void | Promise<void>;
  handleSelect?: (value: T[], name?: string) => void;
  className?: string;
  containerClassName?: string;
  optionsClassName?: string;
  height?: "small" | "large" | "middle";
  labelWithSearch?: boolean;
  labelWithOutSearch?: boolean;
  hideOptionsSearch?: boolean;
  checkedItems?: T[];
  searchClassName?: string;
  disabled?: boolean;
  singleSelect?: boolean;
  hasSelectInput?: boolean;
  selectInputClassName?: string;
  fixedPos?: boolean;
}

export default function DropdownBrowser<Item extends DropdownOption>(
  props: DropdownWithSearchProps<Item>
) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedItems, setSelectedItems] = React.useState<Item[]>(
    props.checkedItems ?? []
  );
  const [cursorPosition, setCursorPosition] = React.useState({ x: 0, y: 0 });

  React.useEffect(() => {
    props.checkedItems && setSelectedItems(props.checkedItems);
  }, [props.checkedItems]);

  const handleOnClick = (e: { clientX: number; clientY: number }) => {
    setCursorPosition({ x: e.clientX, y: e.clientY });
  };

  return (
    <div className={clsx(props.containerClassName)}>
      <Combobox>
        <Combobox.Button
          className={clsx(
            props.hasSelectInput
              ? "flex items-center rounded-md border border-gray-300 px-2 py-1.5 h-9"
              : "flex items-center"
          )}
          onClick={handleOnClick}
        >
          {({ open }) => {
            setIsOpen(open);
            return (
              <div className="flex items-center">
                {props.hasSelectInput ? (
                  <input
                    className={clsx(
                      "border-none bg-transparent h-auto p-0 cursor-pointer mr-0.5 text-sm",
                      props.selectInputClassName,
                      { "text-gray-500 text-sm": !selectedItems.length }
                    )}
                    value={
                      selectedItems.length
                        ? selectedItems
                            .map((item) => item[props.displayKey])
                            .join(", ")
                        : props.placeholder
                    }
                    readOnly
                  />
                ) : null}
                <IconChevronDown
                  className={clsx("h-5 w-5", open ? "rotate-180" : "")}
                  aria-hidden="true"
                />
              </div>
            );
          }}
        </Combobox.Button>
        {isOpen ? (
          <OptionsMenu<Item>
            displayKey={props.displayKey}
            setSelectedItems={setSelectedItems}
            cursorPosition={cursorPosition}
            optionsClassName={props.optionsClassName}
            containerClassName={props.containerClassName}
            disabled={props.disabled}
            labelWithSearch={props.labelWithSearch}
            hideOptionsSearch={props.hideOptionsSearch}
            searchClassName={props.searchClassName}
            items={props?.items}
            checkedItems={props.checkedItems}
            placeholder={props.placeholder}
            selectedItems={selectedItems}
            handleSelect={props.handleSelect}
            fixedPos={props.fixedPos}
            singleSelect={props.singleSelect}
          />
        ) : null}
      </Combobox>
    </div>
  );
}
