import ImageUploader from "components/ImageUploader";
import Input from "components/Input";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import SimpleSelect from "components/SimpleSelect";
import { locationsApi, SuggestOUT } from "api-client";
import SearchInput from "components/SearchInput";
import { placeDetailsToString } from "utils";
import { observer } from "mobx-react-lite";
import { buildingStore } from "store/Building/BuildingStore";
import { FormValue } from ".";
import { buildingSettingsStore } from "store/Building/BuildingSettingsStore";
import { userStore } from "store/UserStore";

export interface BuildingFormOnchange {
  handleChange: (value: FormValue, name?: string) => void;
}

export default observer(function GeneralInformation({
  handleChange,
}: BuildingFormOnchange) {
  const { t } = useTranslation();
  const { authHeader } = userStore;
  const [selectedLocationSuggestion, setSelectedLocationSuggestion] =
    useState<SuggestOUT>();
  const { buildingTypes } = buildingStore;
  const { buildingFormDetails: formData, showMandatoryAlert } =
    buildingSettingsStore;

  const onRemoveImages = React.useCallback((id: string) => {
    buildingSettingsStore.setImagesToRemove([
      ...buildingSettingsStore.imagesToRemove,
      id,
    ]);
  }, []);

  const [locationSuggestions, setLocationSuggestions] =
    useState<Array<SuggestOUT>>();

  const suggestionToPlaceDetails = async (suggestion: SuggestOUT) => {
    if (!authHeader) return;
    return await locationsApi
      .concularApiV1RoutersLocationsPlaceDetails(
        suggestion.place_id,
        authHeader
      )
      .then((response) => {
        const place_details = response.data;
        handleChange(placeDetailsToString(place_details), "string_address");
        return place_details;
      });
  };

  useEffect(() => {
    if (!selectedLocationSuggestion) {
      return;
    }
    suggestionToPlaceDetails(selectedLocationSuggestion).then(
      (placeDetails) => {
        placeDetails &&
          buildingSettingsStore.updateBuildingDetails({
            ...formData,
            address: {
              ...formData.address,
              ...placeDetails,
            },
            string_address: placeDetailsToString(placeDetails),
          });
      }
    );
  }, [selectedLocationSuggestion]);

  function imageUploaderSection() {
    return (
      <div className="pb-1">
        <div className="text-sm text-gray-700 pb-5">
          {t("addBuilding.photosInformations")}
        </div>
        <ImageUploader
          name="images"
          multiple
          labelName={t("commons.photos")}
          onChange={(value) => handleChange(value, "selectedImages")}
          images={formData.images}
          onRemove={onRemoveImages}
        />
      </div>
    );
  }

  const searchAddress = (value: string, name?: string) => {
    if (!authHeader) return;
    name && handleChange(value, name);
    !value && handleChange({}, "address");
    locationsApi
      .concularApiV1RoutersLocationsSuggestPlaces(value, 5, 0, authHeader)
      .then((response) => {
        setLocationSuggestions(response.data.items);
      });
  };

  return (
    <div className="border-b pb-1">
      <div className="text-xl font-semibold mb-2">
        {t("addBuilding.generalInformations")}
      </div>
      {imageUploaderSection()}
      <Input
        labelName="Name"
        name="name"
        required
        onChange={handleChange}
        value={formData.name}
        showMandatoryAlert={showMandatoryAlert}
        errorMessage={t("addBuilding.nameErrorMsg")}
        testID="nameofbuilding_field"
      />
      <SearchInput
        labelName={t("addBuilding.address")}
        name="string_address"
        onChange={searchAddress}
        onSelect={setSelectedLocationSuggestion}
        placeholder={t("addBuilding.addressPlaceholder")}
        items={locationSuggestions}
        value={formData.string_address}
        testID="addressbuilding_field"
      />
      <SimpleSelect
        labelName={t("addBuilding.buildingType")}
        options={buildingTypes}
        name="building_type_id"
        onChange={handleChange}
        placeholder={t("addBuilding.buildingTypePlaceholder")}
        current={formData.building_type?.id}
        testID="buildingtype_filed"
      />
      <div className="flex gap-4 justify-between">
        <Input
          type="number"
          labelName={t("addBuilding.grossFloorArea")}
          containerClassName="w-full"
          placeholder="0,00"
          unit="m²"
          name="gross_floor_area"
          min={1}
          required
          errorMessage={t("addBuilding.grossRoomAreaErrorMsg")}
          showMandatoryAlert={showMandatoryAlert}
          value={String(formData.gross_floor_area)}
          onChange={handleChange}
          testID="grossFloorArea_field"
        />
        <Input
          type="number"
          labelName={t("addBuilding.netRoomArea")}
          containerClassName="w-full"
          placeholder="0,00"
          name="net_floor_area"
          unit="m²"
          min={1}
          required
          errorMessage={t("addBuilding.netRoomAreaErrorMsg")}
          showMandatoryAlert={showMandatoryAlert}
          value={String(formData.net_floor_area)}
          onChange={handleChange}
          testID="netarea_field"
        />
      </div>
      <div className="text-sm text-gray-500 front-[500] pb-3 -mt-4">
        {t("addBuilding.grossNetAreaInfo")}
      </div>
    </div>
  );
});
