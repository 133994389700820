import React from "react";
import Logo from "assets/images/concular.svg";
import { useLocation, useNavigate } from "react-router";
import clsx from "clsx";
import { ChevronDoubleLeftIcon } from "@heroicons/react/outline";
import { IconLayoutDashboard } from "@tabler/icons-react";

const SidebarTop = ({ toggleSidebar }: { toggleSidebar: () => void }) => {
  const navigate = useNavigate();
  const location = useLocation();

  function dashboardLink() {
    return (
      <div
        className={clsx(
          "flex px-2 py-2 text-gray-700 text-sm font-medium items-center mt-[15px] mx-2 mb-2 cursor-pointer",
          { "bg-indigo-50 rounded": location.pathname === "/dashboard" }
        )}
        onClick={() => navigate("/dashboard")}
        test-id="dashboard_button"
      >
        <IconLayoutDashboard className="text-gray-500 mr-2" />
        Dashboard
      </div>
    );
  }

  return (
    <div className="mt-4 flex flex-col">
      <div className="flex flex-shrink-0 items-center px-3 justify-between">
        <img
          className="h-5 w-auto cursor-pointer"
          src={Logo}
          alt="Concular"
          onClick={() => navigate("/")}
        />
        <ChevronDoubleLeftIcon
          width={18}
          className="cursor-pointer hover:text-black rounded-full text-gray-500"
          onClick={toggleSidebar}
        />
      </div>
      {dashboardLink()}
    </div>
  );
};

export default SidebarTop;
