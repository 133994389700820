import React, { useEffect } from "react";
import { MaterialFileObject } from "store/IfcMapping/types";
import { useParams } from "react-router";
import { observer } from "mobx-react-lite";
import DetailsView from "./DetailsView";
import { ifcMappingStore } from "store/IfcMapping/IFCMappingStore";

export default observer(function MEObjectDetail({
  open,
  close,
  object,
  refresh,
}: {
  open: boolean;
  close: () => void;
  object: MaterialFileObject;
  refresh?: (item: MaterialFileObject) => Promise<void>;
}) {
  const { objectDetail } = ifcMappingStore;
  const { ifc_id } = useParams();

  useEffect(() => {
    if (!open || !object) return;
    ifcMappingStore.setObjectDetail(undefined);
    object?.id && ifcMappingStore.getObjectDetails(object.id);
  }, [object, open]);

  const unmatch = async () => {
    if (!ifc_id || !object.id) return;
    const response = await ifcMappingStore.matchObject(ifc_id, object.id);
    const returnedData = response?.[0] as MaterialFileObject;
    if (returnedData.product_match) return;
    await ifcMappingStore.getObjectDetails(object.id);
    if (object.total_objects) {
      object.products = [];
    } else {
      object.product_match = null;
    }
    await refresh?.(object);
  };

  const refreshAfterMatching = async (item: MaterialFileObject) => {
    if (!object?.id) return;
    await ifcMappingStore.getObjectDetails(object.id);
    if (item.product_match) {
      if (object?.total_objects) {
        object.products = [item.product_match];
      } else {
        object.product_match = item.product_match;
        object.gwp = item.gwp;
        object.mass = item.mass;
      }
      await refresh?.(object);
    }
  };

  return (
    <DetailsView
      open={open}
      close={close}
      physicalObjectDetails={objectDetail ? [objectDetail] : []}
      unmatch={unmatch}
      refreshAfterMatching={refreshAfterMatching}
    />
  );
});
