import { observer } from "mobx-react-lite";
import DashboardActiveFiles, {
  BaseDashboardProps,
} from "./DashboardActiveFiles";

const TABLE_NAME = "flat_indicators";

const DashboardSavingsPotential = (props: BaseDashboardProps) => {
  const reportID = process.env.REACT_APP_POWER_BI_DB_DASHBOARD_ID;

  return (
    <DashboardActiveFiles
      reportID={reportID}
      containerClassName={props.containerClassName}
      buildingsTable={TABLE_NAME}
      filesTable={TABLE_NAME}
    />
  );
};

export default observer(DashboardSavingsPotential);
