import React from "react";
import DropdownBrowser, { DropdownOption } from "components/DropdownBrowser";
import { useTranslation } from "react-i18next";
import { IconPlus, IconTrash } from "@tabler/icons-react";
import clsx from "clsx";
import SimpleSelect from "components/SimpleSelect";
import {
  EMPTY_FILTER_RULE,
  LOGICAL_OPERATORS,
  PROPERTY_RULE_OPERATORS,
} from "../../const";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";
import { LogicalOperatorENUM, PropertyBrowserTreeNode } from "api-client";
import useWindowOverflow from "hooks/useWindowOverflow";
import { FilterRule } from ".";
import { observer } from "mobx-react-lite";
import DebouncedInput from "components/DebounceInput";

function FilterMenu({
  cursorPosition,
  items,
}: {
  cursorPosition: { x: number; y: number };
  items: PropertyBrowserTreeNode[];
}) {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const { getLeftPos, containerRef } = useWindowOverflow();
  const scrollAreaRef = React.useRef<HTMLDivElement>(null);

  const onChangeProperty = (value: DropdownOption[], index: number) => {
    const rules = [...dynamicMEStore.selectedFilterByItems.rules];
    rules[index].property = value[0] as PropertyBrowserTreeNode;
    updateRules(rules);
  };

  function updateRules(rules: FilterRule[]) {
    dynamicMEStore.setSelectedFilterByItems({
      ...dynamicMEStore.selectedFilterByItems,
      rules: [...rules],
    });
  }
  const onChangeOperator = (value: string | undefined, index: number) => {
    const rules = [...dynamicMEStore.selectedFilterByItems.rules];
    rules[index].operator = String(value);
    updateRules(rules);
  };

  const onChangeTerm = (value: string | number | undefined, index: number) => {
    const rules = [...dynamicMEStore.selectedFilterByItems.rules];
    rules[index].value = String(value);
    updateRules(rules);
  };

  const addRule = () => {
    updateRules([
      ...dynamicMEStore.selectedFilterByItems.rules,
      { ...EMPTY_FILTER_RULE },
    ]);
  };

  const removeAllRules = () => updateRules([{ ...EMPTY_FILTER_RULE }]);

  const removeCurrentRow = (index: number) => {
    const rules = [...dynamicMEStore.selectedFilterByItems.rules];
    rules.splice(index, 1);
    updateRules(rules);
  };

  const onChangeLogicalOperator = (value: string | undefined) => {
    dynamicMEStore.setSelectedFilterByItems({
      ...dynamicMEStore.selectedFilterByItems,
      logical_operator: value as LogicalOperatorENUM,
    });
  };

  const filterRow = (rule: FilterRule, index: number) => {
    return (
      <div className="flex items-center p-2 gap-2 min-w-[595px]" key={index}>
        {index === 0 ? (
          <span className="min-w-[85px] text-right">
            {t("mappingEditor.where")}
          </span>
        ) : (
          <SimpleSelect
            options={LOGICAL_OPERATORS}
            noMargin
            className="!mb-0 !mt-0"
            current={dynamicMEStore.selectedFilterByItems.logical_operator}
            containerClassName="min-w-[85px]"
            onChange={(value) => onChangeLogicalOperator(value)}
          />
        )}
        <DropdownBrowser<DropdownOption>
          displayKey={isEng ? "name" : "name_de"}
          handleSelect={(value) => onChangeProperty(value, index)}
          name="filter_by"
          items={items as DropdownOption[]}
          checkedItems={rule.property ? [rule.property] : []}
          placeholder={t("mappingEditor.findProperty")}
          optionsClassName="!max-w-fit min-w-[246px]"
          containerClassName="ml-1 relative"
          singleSelect
          hasSelectInput
          selectInputClassName="max-w-[135px]"
          fixedPos
        />
        <SimpleSelect
          options={PROPERTY_RULE_OPERATORS}
          noMargin
          className="!mb-0 !mt-0 w-[120px]"
          onChange={(value) => onChangeOperator(value, index)}
          current={rule.operator}
        />
        <DebouncedInput
          containerClassName="mt-0 max-w-[130px] min-w-[130px]"
          height="middle"
          onChange={(value) => onChangeTerm(value, index)}
          value={rule.value}
          icon={<></>}
          placeholder={t("mappingEditor.enterValue")}
          className="placeholder-gray-500"
        />
        <IconTrash
          className={clsx(
            "cursor-pointer ml-2 text-gray-500 transition hover:text-gray-900 min-w-[20px]",
            dynamicMEStore.selectedFilterByItems.rules.length === 1
              ? "cursor-not-allowed pointer-events-none opacity-50"
              : "hover:text-gray-900"
          )}
          size={20}
          onClick={() => removeCurrentRow(index)}
        />
      </div>
    );
  };

  return (
    <div
      className={clsx(
        "absolute z-5 my-1 w-full overflow-visible rounded-md bg-white text-sm text-gray-700 !max-w-fit",
        "shadow-lg border border-gray-300 min-w-auto w-auto max-w-md focus:outline-none sm:text-sm"
      )}
      ref={containerRef}
      style={{
        left: getLeftPos(cursorPosition.x, 20),
      }}
    >
      <div
        className={clsx("p-2 max-h-[325px]", {
          "overflow-y-auto":
            dynamicMEStore.selectedFilterByItems.rules.length > 6,
        })}
      >
        {dynamicMEStore.selectedFilterByItems.rules.map((rule, index) =>
          filterRow(rule, index)
        )}
        <div className="h-1.5" ref={scrollAreaRef}></div>
      </div>
      <div className="border-t border-gray-200 transition text-gray-700 hover:text-gray-900">
        <div
          className="flex px-4 py-2 items-center cursor-pointer"
          onClick={addRule}
        >
          <IconPlus className="mr-2" size={20} />
          <span>{t("mappingEditor.addFilterRule")}</span>
        </div>
        <div
          className="flex px-4 py-2 items-center cursor-pointer"
          onClick={removeAllRules}
        >
          <IconTrash className="mr-2" size={20} />
          <span>{t("mappingEditor.removeFilterRule")}</span>
        </div>
      </div>
    </div>
  );
}

export default observer(FilterMenu);
