import { globalStore } from "store/GlobalStore";
import { faker } from "@faker-js/faker";
import { NotificationType } from "components/Toast";

const useToast = () => {
  const toast = (message: string, type: NotificationType) => {
    globalStore.setToastList([
      ...globalStore.toastList,
      { id: faker.datatype.uuid(), message, type },
    ]);
  };

  return toast;
};

export default useToast;
