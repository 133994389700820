import React from "react";
import Alert from "components/Alert";
import { useTranslation } from "react-i18next";
import { BuildingFormOnchange } from "./GeneralInformation";
import { observer } from "mobx-react-lite";
import useFeatureFlag from "hooks/useFeatureFlag";
import SettingsCheckbox from "./SettingsCheckbox";
import { buildingSettingsStore } from "store/Building/BuildingSettingsStore";

export default observer(function MappingEditorTile({
  handleChange,
}: BuildingFormOnchange) {
  const { t } = useTranslation();
  const { isFeatureVisible } = useFeatureFlag();

  if (!isFeatureVisible("pre_auto_mapping")) return <></>;

  const preMappingItems = [
    {
      id: "premapping_enabled",
      title: t("addBuilding.preMappingtitle"),
      subTitle: t("addBuilding.preMappingSubtitle"),
    },
    {
      id: "auto_premap",
      title: t("addBuilding.runAutoMappingtitle"),
      subTitle: t("addBuilding.runAutoMappingSubtitle"),
    },
  ];

  return (
    <>
      <div className="text-xl font-semibold mt-5 mb-2 border-t pt-4">
        {t("addBuilding.mappingEditor")}
      </div>
      <div className="text-gray-700 text-sm font-normal">
        {t("addBuilding.mappingEditorSubline")}
      </div>
      {preMappingItems.map((item) => {
        const checked =
          item.id === "auto_premap"
            ? buildingSettingsStore.buildingFormDetails.auto_premap
            : buildingSettingsStore.buildingFormDetails.premapping_enabled;
        return (
          <SettingsCheckbox
            key={item.id}
            id={item.id}
            handleChange={handleChange}
            title={item.title}
            subTitle={item.subTitle}
            checked={checked}
          />
        );
      })}
      <Alert type="info" description={t("addBuilding.runAutoMappingAlert")} />
    </>
  );
});
