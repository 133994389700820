import React from "react";
import TreeNode from "./TreeNode";

export interface TreeViewNode {
  id: string;
  name?: string | null;
  isOpen: boolean;
  childrenNodes: TreeViewNode[];
  icon?: React.ReactNode;
  detail?: string;
  onClick?: () => void;
  activeNode?: boolean;
}

export interface SelectedNodeID {
  id: string;
  index: number;
}

const TreeView = ({
  data,
  nodeClicked,
  highlightSelected,
}: {
  data: TreeViewNode[];
  nodeClicked: (node: TreeViewNode) => TreeViewNode | void;
  highlightSelected?: boolean;
}) => {
  const [treeData, setTreeData] = React.useState<TreeViewNode[]>([]);

  React.useEffect(() => {
    setTreeData(data);
  }, [data]);

  const toggleNode = (clickedNode: TreeViewNode) => {
    setTreeData((prevTreeData) => {
      return prevTreeData.map((node) => {
        if (node === clickedNode) {
          return { ...node, isOpen: !node.isOpen };
        } else if (node.childrenNodes && node.childrenNodes.length > 0) {
          return {
            ...node,
            childrenNodes: toggleNodeInTree(node.childrenNodes, clickedNode),
          };
        } else {
          return node;
        }
      });
    });
  };

  const toggleNodeInTree = (
    childrenNodes: TreeViewNode[],
    clickedNode: TreeViewNode
  ): TreeViewNode[] => {
    return childrenNodes.map((child) => {
      if (child === clickedNode) {
        return { ...child, isOpen: !child.isOpen };
      } else if (child.childrenNodes && child.childrenNodes.length > 0) {
        return {
          ...child,
          childrenNodes: toggleNodeInTree(child.childrenNodes, clickedNode),
        };
      } else {
        return child;
      }
    });
  };

  const onNodeClicked = (clickedNode: TreeViewNode) => {
    const updated = treeData.map((node) => {
      node.activeNode = false;
      if (node === clickedNode) {
        node.activeNode = true;
      }
      if (node.childrenNodes && node.childrenNodes.length > 0) {
        node.childrenNodes = onNodeClickedInTree(
          node.childrenNodes,
          clickedNode
        );
      }
      return node;
    });
    setTreeData([...updated]);
    nodeClicked(clickedNode);
  };

  const onNodeClickedInTree = (
    childrenNodes: TreeViewNode[],
    clickedNode: TreeViewNode
  ): TreeViewNode[] => {
    return childrenNodes.map((child) => {
      child.activeNode = false;
      if (child === clickedNode) {
        child.activeNode = true;
      }
      if (child.childrenNodes && child.childrenNodes.length > 0) {
        child.childrenNodes = onNodeClickedInTree(
          child.childrenNodes,
          clickedNode
        );
      }
      return child;
    });
  };

  return (
    <ul>
      {treeData.map((node, index) => (
        <TreeNode
          key={index}
          node={node}
          onToggle={toggleNode}
          onClick={onNodeClicked}
          highlightNode={highlightSelected}
        />
      ))}
    </ul>
  );
};

export default TreeView;
