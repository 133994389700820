import { observer } from "mobx-react-lite";
import { buildingStore } from "store/Building/BuildingStore";
import {
  create_building_filter,
  create_default_file_filter,
  create_module_filter,
} from "./filters";
import PowerBIReport from "./PowerBIReport";
import { models } from "powerbi-client";
import EmptyState from "components/EmptyState";

export interface BaseDashboardProps {
  containerClassName?: string;
}

export interface DashboardActiveFilesProps {
  reportID: string | undefined;
  buildingsTable: string;
  filesTable: string;
  modulesTable?: string;
  containerClassName?: string;
  reloadOnChangeNRF?: boolean;
}

const DashboardActiveFiles = (props: DashboardActiveFilesProps) => {
  const { buildingID } = buildingStore;

  const buildingFilter = create_building_filter(
    props.buildingsTable,
    buildingID
  );
  const fileFilter = create_default_file_filter(
    props.filesTable,
    buildingStore.getActiveFileIDs()
  );

  const filters = [buildingFilter, fileFilter] as models.ReportLevelFilters[];
  if (props.modulesTable) {
    const certificationModules = buildingStore.getModuleNamesPerCertification();

    const moduleFilter = create_module_filter(
      props.modulesTable,
      certificationModules
    );

    filters.push(moduleFilter as models.ReportLevelFilters);
  }

  if (buildingStore.checkIfNoBuildingDashboard()) {
    return (
      <EmptyState type="chart" containerClassName={props.containerClassName} />
    );
  }

  return (
    <PowerBIReport
      reportID={props.reportID}
      containerClassName={props.containerClassName}
      filters={filters}
      reloadOnChangeNRF={props.reloadOnChangeNRF}
    />
  );
};

export default observer(DashboardActiveFiles);
