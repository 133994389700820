/* tslint:disable */
/* eslint-disable */
/**
 * Concular API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AWSLocationOUT
 */
export interface AWSLocationOUT {
    /**
     *
     * @type {string}
     * @memberof AWSLocationOUT
     */
    'region': string;
    /**
     *
     * @type {string}
     * @memberof AWSLocationOUT
     */
    'street': string;
    /**
     *
     * @type {string}
     * @memberof AWSLocationOUT
     */
    'house_number': string;
    /**
     *
     * @type {number}
     * @memberof AWSLocationOUT
     */
    'latitude': number;
    /**
     *
     * @type {number}
     * @memberof AWSLocationOUT
     */
    'longitude': number;
    /**
     *
     * @type {string}
     * @memberof AWSLocationOUT
     */
    'postal_code': string;
    /**
     *
     * @type {string}
     * @memberof AWSLocationOUT
     */
    'country_code': string;
}
/**
 *
 * @export
 * @interface AuditIN
 */
export interface AuditIN {
    /**
     *
     * @type {string}
     * @memberof AuditIN
     */
    'id': string;
    /**
     *
     * @type {Array<BlueprintOUT>}
     * @memberof AuditIN
     */
    'blueprint_set': Array<BlueprintOUT>;
    /**
     *
     * @type {Array<string>}
     * @memberof AuditIN
     */
    'limit_to'?: Array<string>;
}
/**
 *
 * @export
 * @interface AuditInvitationDetailsOUT
 */
export interface AuditInvitationDetailsOUT {
    /**
     *
     * @type {string}
     * @memberof AuditInvitationDetailsOUT
     */
    'id': string;
    /**
     *
     * @type {number}
     * @memberof AuditInvitationDetailsOUT
     */
    'role': number;
    /**
     *
     * @type {string}
     * @memberof AuditInvitationDetailsOUT
     */
    'audit_id': string;
    /**
     *
     * @type {number}
     * @memberof AuditInvitationDetailsOUT
     */
    'status': number;
    /**
     *
     * @type {string}
     * @memberof AuditInvitationDetailsOUT
     */
    'email': string;
    /**
     *
     * @type {string}
     * @memberof AuditInvitationDetailsOUT
     */
    'modified': string;
}
/**
 *
 * @export
 * @interface AuditInvitationIN
 */
export interface AuditInvitationIN {
    /**
     *
     * @type {string}
     * @memberof AuditInvitationIN
     */
    'audit_id': string;
    /**
     *
     * @type {string}
     * @memberof AuditInvitationIN
     */
    'email': string;
    /**
     *
     * @type {RoleEnum}
     * @memberof AuditInvitationIN
     */
    'role': RoleEnum;
}


/**
 *
 * @export
 * @interface AuditInvitationOUT
 */
export interface AuditInvitationOUT {
    /**
     *
     * @type {AuditInvitationDetailsOUT}
     * @memberof AuditInvitationOUT
     */
    'invitation_details'?: AuditInvitationDetailsOUT | null;
}
/**
 *
 * @export
 * @interface AuditMemberOUT
 */
export interface AuditMemberOUT {
    /**
     *
     * @type {AuditUserOUT}
     * @memberof AuditMemberOUT
     */
    'user': AuditUserOUT;
    /**
     *
     * @type {number}
     * @memberof AuditMemberOUT
     */
    'role': number;
}
/**
 *
 * @export
 * @interface AuditOUT
 */
export interface AuditOUT {
    /**
     *
     * @type {string}
     * @memberof AuditOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof AuditOUT
     */
    'title': string;
    /**
     *
     * @type {string}
     * @memberof AuditOUT
     */
    'building_id': string;
    /**
     *
     * @type {string}
     * @memberof AuditOUT
     */
    'created': string;
    /**
     *
     * @type {string}
     * @memberof AuditOUT
     */
    'modified': string;
    /**
     *
     * @type {UserProfileOUT}
     * @memberof AuditOUT
     */
    'created_by': UserProfileOUT;
    /**
     *
     * @type {Array<BlueprintOUT>}
     * @memberof AuditOUT
     */
    'blueprint_set': Array<BlueprintOUT>;
    /**
     *
     * @type {Array<FloorOUT>}
     * @memberof AuditOUT
     */
    'floor_set': Array<FloorOUT>;
    /**
     *
     * @type {Array<RoomOUT>}
     * @memberof AuditOUT
     */
    'room_set': Array<RoomOUT>;
}
/**
 *
 * @export
 * @interface AuditUserOUT
 */
export interface AuditUserOUT {
    /**
     *
     * @type {string}
     * @memberof AuditUserOUT
     */
    'username': string;
    /**
     *
     * @type {string}
     * @memberof AuditUserOUT
     */
    'email': string;
    /**
     *
     * @type {string}
     * @memberof AuditUserOUT
     */
    'first_name': string;
    /**
     *
     * @type {string}
     * @memberof AuditUserOUT
     */
    'last_name': string;
    /**
     *
     * @type {number}
     * @memberof AuditUserOUT
     */
    'id': number;
}
/**
 *
 * @export
 * @interface AutomappingResultsOut
 */
export interface AutomappingResultsOut {
    /**
     *
     * @type {number}
     * @memberof AutomappingResultsOut
     */
    'objects_requested': number;
    /**
     *
     * @type {number}
     * @memberof AutomappingResultsOut
     */
    'objects_mapped': number;
}
/**
 *
 * @export
 * @interface AutomappingUndoResultsOut
 */
export interface AutomappingUndoResultsOut {
    /**
     *
     * @type {number}
     * @memberof AutomappingUndoResultsOut
     */
    'matches_deleted': number;
}
/**
 *
 * @export
 * @interface AvailableColumnsOUT
 */
export interface AvailableColumnsOUT {
    /**
     *
     * @type {string}
     * @memberof AvailableColumnsOUT
     */
    'property_name': string;
    /**
     *
     * @type {string}
     * @memberof AvailableColumnsOUT
     */
    'friendly_name_en'?: string | null;
    /**
     *
     * @type {string}
     * @memberof AvailableColumnsOUT
     */
    'friendly_name_de'?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof AvailableColumnsOUT
     */
    'is_available': boolean;
    /**
     *
     * @type {boolean}
     * @memberof AvailableColumnsOUT
     */
    'in_default_view': boolean;
    /**
     *
     * @type {boolean}
     * @memberof AvailableColumnsOUT
     */
    'in_debug': boolean;
    /**
     *
     * @type {string}
     * @memberof AvailableColumnsOUT
     */
    'group_key'?: string | null;
    /**
     *
     * @type {string}
     * @memberof AvailableColumnsOUT
     */
    'filter_function': string;
    /**
     *
     * @type {string}
     * @memberof AvailableColumnsOUT
     */
    'ui_element': string;
    /**
     *
     * @type {number}
     * @memberof AvailableColumnsOUT
     */
    'order'?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof AvailableColumnsOUT
     */
    'tooltip_active': boolean;
}
/**
 *
 * @export
 * @interface BlueprintIN
 */
export interface BlueprintIN {
    /**
     *
     * @type {string}
     * @memberof BlueprintIN
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof BlueprintIN
     */
    'name_de'?: string | null;
    /**
     *
     * @type {string}
     * @memberof BlueprintIN
     */
    'extra_info'?: string | null;
    /**
     *
     * @type {string}
     * @memberof BlueprintIN
     */
    'audit_id': string;
    /**
     *
     * @type {string}
     * @memberof BlueprintIN
     */
    'product_group_id': string;
    /**
     *
     * @type {Array<PropertyFieldsSchema>}
     * @memberof BlueprintIN
     */
    'blueprintproperty_set': Array<PropertyFieldsSchema>;
    /**
     *
     * @type {Array<BlueprintIN>}
     * @memberof BlueprintIN
     */
    'components'?: Array<BlueprintIN>;
    /**
     *
     * @type {string}
     * @memberof BlueprintIN
     */
    'manufacturer_id'?: string | null;
    /**
     *
     * @type {number}
     * @memberof BlueprintIN
     */
    'as_component_amount': number;
}
/**
 *
 * @export
 * @enum {string}
 */

export const BlueprintListSortBy = {
    Name: 'name',
    Created: 'created'
} as const;

export type BlueprintListSortBy = typeof BlueprintListSortBy[keyof typeof BlueprintListSortBy];


/**
 *
 * @export
 * @interface BlueprintOUT
 */
export interface BlueprintOUT {
    /**
     *
     * @type {string}
     * @memberof BlueprintOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof BlueprintOUT
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof BlueprintOUT
     */
    'name_de'?: string | null;
    /**
     *
     * @type {string}
     * @memberof BlueprintOUT
     */
    'extra_info'?: string | null;
    /**
     *
     * @type {string}
     * @memberof BlueprintOUT
     */
    'audit_id': string;
    /**
     *
     * @type {string}
     * @memberof BlueprintOUT
     */
    'product_group_id': string;
    /**
     *
     * @type {string}
     * @memberof BlueprintOUT
     */
    'created': string;
    /**
     *
     * @type {string}
     * @memberof BlueprintOUT
     */
    'modified': string;
    /**
     *
     * @type {UserProfileOUT}
     * @memberof BlueprintOUT
     */
    'created_by': UserProfileOUT | null;
    /**
     *
     * @type {Array<BlueprintPropertyOUT>}
     * @memberof BlueprintOUT
     */
    'blueprintproperty_set': Array<BlueprintPropertyOUT>;
    /**
     *
     * @type {Array<InstanceOUT>}
     * @memberof BlueprintOUT
     */
    'instance_set': Array<InstanceOUT>;
    /**
     *
     * @type {Array<BlueprintOUT>}
     * @memberof BlueprintOUT
     */
    'components'?: Array<BlueprintOUT>;
    /**
     *
     * @type {number}
     * @memberof BlueprintOUT
     */
    'as_component_amount': number;
    /**
     *
     * @type {ManufacturerOUT}
     * @memberof BlueprintOUT
     */
    'manufacturer'?: ManufacturerOUT | null;
    /**
     *
     * @type {Array<ImageAlbumOUT>}
     * @memberof BlueprintOUT
     */
    'albums': Array<ImageAlbumOUT>;
}
/**
 *
 * @export
 * @interface BlueprintPropertyIN
 */
export interface BlueprintPropertyIN {
    /**
     *
     * @type {string}
     * @memberof BlueprintPropertyIN
     */
    'field_id': string;
    /**
     *
     * @type {string}
     * @memberof BlueprintPropertyIN
     */
    'value_string'?: string | null;
    /**
     *
     * @type {number}
     * @memberof BlueprintPropertyIN
     */
    'value_numeric'?: number | null;
    /**
     *
     * @type {string}
     * @memberof BlueprintPropertyIN
     */
    'value_datetime'?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof BlueprintPropertyIN
     */
    'value_field_option_ids'?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof BlueprintPropertyIN
     */
    'blueprint_id': string;
}
/**
 *
 * @export
 * @interface BlueprintPropertyOUT
 */
export interface BlueprintPropertyOUT {
    /**
     *
     * @type {string}
     * @memberof BlueprintPropertyOUT
     */
    'field_id': string;
    /**
     *
     * @type {string}
     * @memberof BlueprintPropertyOUT
     */
    'value_string'?: string | null;
    /**
     *
     * @type {number}
     * @memberof BlueprintPropertyOUT
     */
    'value_numeric'?: number | null;
    /**
     *
     * @type {string}
     * @memberof BlueprintPropertyOUT
     */
    'value_datetime'?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof BlueprintPropertyOUT
     */
    'value_field_option_ids'?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof BlueprintPropertyOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof BlueprintPropertyOUT
     */
    'blueprint_id': string;
    /**
     *
     * @type {string}
     * @memberof BlueprintPropertyOUT
     */
    'created': string;
    /**
     *
     * @type {string}
     * @memberof BlueprintPropertyOUT
     */
    'modified': string;
}
/**
 *
 * @export
 * @interface BlueprintPropertyUpdateIN
 */
export interface BlueprintPropertyUpdateIN {
    /**
     *
     * @type {string}
     * @memberof BlueprintPropertyUpdateIN
     */
    'blueprint_id'?: string | null;
    /**
     *
     * @type {string}
     * @memberof BlueprintPropertyUpdateIN
     */
    'field_id'?: string | null;
    /**
     *
     * @type {string}
     * @memberof BlueprintPropertyUpdateIN
     */
    'value_string'?: string | null;
    /**
     *
     * @type {number}
     * @memberof BlueprintPropertyUpdateIN
     */
    'value_numeric'?: number | null;
    /**
     *
     * @type {string}
     * @memberof BlueprintPropertyUpdateIN
     */
    'value_datetime'?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof BlueprintPropertyUpdateIN
     */
    'value_field_option_ids'?: Array<string>;
}
/**
 *
 * @export
 * @interface BlueprintUpdateIN
 */
export interface BlueprintUpdateIN {
    /**
     *
     * @type {string}
     * @memberof BlueprintUpdateIN
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof BlueprintUpdateIN
     */
    'name_de': string;
    /**
     *
     * @type {string}
     * @memberof BlueprintUpdateIN
     */
    'extra_info': string;
    /**
     *
     * @type {string}
     * @memberof BlueprintUpdateIN
     */
    'audit_id': string;
    /**
     *
     * @type {string}
     * @memberof BlueprintUpdateIN
     */
    'product_group_id': string;
    /**
     *
     * @type {Array<PropertyFieldsSchema>}
     * @memberof BlueprintUpdateIN
     */
    'blueprintproperty_set': Array<PropertyFieldsSchema>;
    /**
     *
     * @type {Array<BlueprintUpdateIN>}
     * @memberof BlueprintUpdateIN
     */
    'components': Array<BlueprintUpdateIN>;
    /**
     *
     * @type {string}
     * @memberof BlueprintUpdateIN
     */
    'manufacturer_id': string;
    /**
     *
     * @type {number}
     * @memberof BlueprintUpdateIN
     */
    'as_component_amount': number;
}
/**
 *
 * @export
 * @interface BuildingIN
 */
export interface BuildingIN {
    /**
     *
     * @type {string}
     * @memberof BuildingIN
     */
    'organisation_id': string;
    /**
     *
     * @type {string}
     * @memberof BuildingIN
     */
    'name': string;
    /**
     *
     * @type {number}
     * @memberof BuildingIN
     */
    'construction_year'?: number | null;
    /**
     *
     * @type {string}
     * @memberof BuildingIN
     */
    'building_type_id'?: string | null;
    /**
     *
     * @type {object}
     * @memberof BuildingIN
     */
    'address'?: object | null;
    /**
     *
     * @type {number}
     * @memberof BuildingIN
     */
    'gross_floor_area'?: number | null;
    /**
     *
     * @type {number}
     * @memberof BuildingIN
     */
    'net_floor_area'?: number | null;
    /**
     *
     * @type {number}
     * @memberof BuildingIN
     */
    'current_state'?: number | null;
    /**
     *
     * @type {string}
     * @memberof BuildingIN
     */
    'upcoming_operation'?: string | null;
    /**
     *
     * @type {string}
     * @memberof BuildingIN
     */
    'start_date'?: string | null;
    /**
     *
     * @type {string}
     * @memberof BuildingIN
     */
    'end_date'?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof BuildingIN
     */
    'images'?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof BuildingIN
     */
    'certification_id'?: string | null;
    /**
     *
     * @type {string}
     * @memberof BuildingIN
     */
    'conformity_id'?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof BuildingIN
     */
    'auto_premap'?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof BuildingIN
     */
    'premapping_enabled'?: boolean | null;
}
/**
 *
 * @export
 * @interface BuildingImageAddRemoveIN
 */
export interface BuildingImageAddRemoveIN {
    /**
     *
     * @type {Array<string>}
     * @memberof BuildingImageAddRemoveIN
     */
    'images': Array<string>;
}
/**
 *
 * @export
 * @interface BuildingImageOUT
 */
export interface BuildingImageOUT {
    /**
     *
     * @type {string}
     * @memberof BuildingImageOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof BuildingImageOUT
     */
    'image': string;
}
/**
 *
 * @export
 * @interface BuildingInviteeIN
 */
export interface BuildingInviteeIN {
    /**
     *
     * @type {string}
     * @memberof BuildingInviteeIN
     */
    'building_id': string;
    /**
     *
     * @type {string}
     * @memberof BuildingInviteeIN
     */
    'email': string;
    /**
     *
     * @type {number}
     * @memberof BuildingInviteeIN
     */
    'role': number;
}
/**
 *
 * @export
 * @interface BuildingInviteeOUT
 */
export interface BuildingInviteeOUT {
    /**
     *
     * @type {string}
     * @memberof BuildingInviteeOUT
     */
    'id': string;
    /**
     *
     * @type {number}
     * @memberof BuildingInviteeOUT
     */
    'role': number;
    /**
     *
     * @type {string}
     * @memberof BuildingInviteeOUT
     */
    'building_id': string;
    /**
     *
     * @type {number}
     * @memberof BuildingInviteeOUT
     */
    'status': number;
    /**
     *
     * @type {UserProfileOUT}
     * @memberof BuildingInviteeOUT
     */
    'user'?: UserProfileOUT | null;
    /**
     *
     * @type {string}
     * @memberof BuildingInviteeOUT
     */
    'email'?: string | null;
    /**
     *
     * @type {string}
     * @memberof BuildingInviteeOUT
     */
    'modified': string;
}
/**
 *
 * @export
 * @interface BuildingMappingStatus
 */
export interface BuildingMappingStatus {
    /**
     *
     * @type {string}
     * @memberof BuildingMappingStatus
     */
    'building_id': string;
    /**
     *
     * @type {boolean}
     * @memberof BuildingMappingStatus
     */
    'has_mapped_objects': boolean;
    /**
     *
     * @type {boolean}
     * @memberof BuildingMappingStatus
     */
    'has_compliant_matches': boolean;
}
/**
 *
 * @export
 * @interface BuildingMemberOUT
 */
export interface BuildingMemberOUT {
    /**
     *
     * @type {string}
     * @memberof BuildingMemberOUT
     */
    'id': string;
    /**
     *
     * @type {UserProfileOUT}
     * @memberof BuildingMemberOUT
     */
    'user': UserProfileOUT;
    /**
     *
     * @type {number}
     * @memberof BuildingMemberOUT
     */
    'role': number;
}
/**
 *
 * @export
 * @interface BuildingOUT
 */
export interface BuildingOUT {
    /**
     *
     * @type {string}
     * @memberof BuildingOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof BuildingOUT
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof BuildingOUT
     */
    'calculation_status'?: string | null;
    /**
     *
     * @type {number}
     * @memberof BuildingOUT
     */
    'construction_year'?: number | null;
    /**
     *
     * @type {BuildingTypeOUT}
     * @memberof BuildingOUT
     */
    'building_type': BuildingTypeOUT | null;
    /**
     *
     * @type {object}
     * @memberof BuildingOUT
     */
    'address'?: object | null;
    /**
     *
     * @type {string}
     * @memberof BuildingOUT
     */
    'postal_code'?: string | null;
    /**
     *
     * @type {string}
     * @memberof BuildingOUT
     */
    'region'?: string | null;
    /**
     *
     * @type {number}
     * @memberof BuildingOUT
     */
    'gross_floor_area'?: number | null;
    /**
     *
     * @type {number}
     * @memberof BuildingOUT
     */
    'net_floor_area'?: number | null;
    /**
     *
     * @type {number}
     * @memberof BuildingOUT
     */
    'current_state'?: number | null;
    /**
     *
     * @type {string}
     * @memberof BuildingOUT
     */
    'upcoming_operation'?: string | null;
    /**
     *
     * @type {string}
     * @memberof BuildingOUT
     */
    'start_date'?: string | null;
    /**
     *
     * @type {string}
     * @memberof BuildingOUT
     */
    'end_date'?: string | null;
    /**
     *
     * @type {Array<BuildingImageOUT>}
     * @memberof BuildingOUT
     */
    'images': Array<BuildingImageOUT>;
    /**
     *
     * @type {string}
     * @memberof BuildingOUT
     */
    'organisation_id'?: string | null;
    /**
     *
     * @type {string}
     * @memberof BuildingOUT
     */
    'default_ifc_file'?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof BuildingOUT
     */
    'active_ifc_files'?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof BuildingOUT
     */
    'certification_id'?: string | null;
    /**
     *
     * @type {string}
     * @memberof BuildingOUT
     */
    'conformity_id'?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof BuildingOUT
     */
    'auto_premap': boolean;
    /**
     *
     * @type {boolean}
     * @memberof BuildingOUT
     */
    'exports_available': boolean;
    /**
     *
     * @type {boolean}
     * @memberof BuildingOUT
     */
    'premapping_enabled': boolean;
}
/**
 *
 * @export
 * @interface BuildingReportOUT
 */
export interface BuildingReportOUT {
    /**
     *
     * @type {string}
     * @memberof BuildingReportOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof BuildingReportOUT
     */
    'filename'?: string | null;
    /**
     *
     * @type {string}
     * @memberof BuildingReportOUT
     */
    'download_url'?: string | null;
    /**
     *
     * @type {string}
     * @memberof BuildingReportOUT
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof BuildingReportOUT
     */
    'creation_date'?: string;
    /**
     *
     * @type {UserProfileOUT}
     * @memberof BuildingReportOUT
     */
    'created_by'?: UserProfileOUT | null;
    /**
     *
     * @type {string}
     * @memberof BuildingReportOUT
     */
    'status': string;
}
/**
 *
 * @export
 * @interface BuildingTypeOUT
 */
export interface BuildingTypeOUT {
    /**
     *
     * @type {string}
     * @memberof BuildingTypeOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof BuildingTypeOUT
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof BuildingTypeOUT
     */
    'name_de': string;
}
/**
 *
 * @export
 * @interface BuildingUpdateIN
 */
export interface BuildingUpdateIN {
    /**
     *
     * @type {string}
     * @memberof BuildingUpdateIN
     */
    'organisation_id'?: string | null;
    /**
     *
     * @type {string}
     * @memberof BuildingUpdateIN
     */
    'name'?: string | null;
    /**
     *
     * @type {number}
     * @memberof BuildingUpdateIN
     */
    'construction_year'?: number | null;
    /**
     *
     * @type {string}
     * @memberof BuildingUpdateIN
     */
    'building_type_id'?: string | null;
    /**
     *
     * @type {object}
     * @memberof BuildingUpdateIN
     */
    'address'?: object | null;
    /**
     *
     * @type {number}
     * @memberof BuildingUpdateIN
     */
    'gross_floor_area'?: number | null;
    /**
     *
     * @type {number}
     * @memberof BuildingUpdateIN
     */
    'net_floor_area'?: number | null;
    /**
     *
     * @type {number}
     * @memberof BuildingUpdateIN
     */
    'current_state'?: number | null;
    /**
     *
     * @type {string}
     * @memberof BuildingUpdateIN
     */
    'upcoming_operation'?: string | null;
    /**
     *
     * @type {string}
     * @memberof BuildingUpdateIN
     */
    'start_date'?: string | null;
    /**
     *
     * @type {string}
     * @memberof BuildingUpdateIN
     */
    'end_date'?: string | null;
    /**
     *
     * @type {string}
     * @memberof BuildingUpdateIN
     */
    'certification_id'?: string | null;
    /**
     *
     * @type {string}
     * @memberof BuildingUpdateIN
     */
    'conformity_id'?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof BuildingUpdateIN
     */
    'auto_premap'?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof BuildingUpdateIN
     */
    'premapping_enabled'?: boolean | null;
}
/**
 *
 * @export
 * @interface CPXValueOUT
 */
export interface CPXValueOUT {
    /**
     *
     * @type {string}
     * @memberof CPXValueOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof CPXValueOUT
     */
    'category'?: string | null;
    /**
     *
     * @type {string}
     * @memberof CPXValueOUT
     */
    'name'?: string | null;
    /**
     *
     * @type {number}
     * @memberof CPXValueOUT
     */
    'numeric_value'?: number | null;
    /**
     *
     * @type {string}
     * @memberof CPXValueOUT
     */
    'qualification'?: string | null;
}
/**
 *
 * @export
 * @interface Category
 */
export interface Category {
    /**
     *
     * @type {string}
     * @memberof Category
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof Category
     */
    'name'?: string | null;
    /**
     *
     * @type {string}
     * @memberof Category
     */
    'name_de'?: string | null;
    /**
     *
     * @type {string}
     * @memberof Category
     */
    'category_number'?: string | null;
}
/**
 *
 * @export
 * @interface CategoryFullOut
 */
export interface CategoryFullOut {
    /**
     *
     * @type {string}
     * @memberof CategoryFullOut
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof CategoryFullOut
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof CategoryFullOut
     */
    'name_de': string | null;
    /**
     *
     * @type {string}
     * @memberof CategoryFullOut
     */
    'category_system': string;
    /**
     *
     * @type {string}
     * @memberof CategoryFullOut
     */
    'category_number'?: string | null;
}
/**
 *
 * @export
 * @interface CategoryOUT
 */
export interface CategoryOUT {
    /**
     *
     * @type {string}
     * @memberof CategoryOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof CategoryOUT
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof CategoryOUT
     */
    'name_de': string | null;
}
/**
 *
 * @export
 * @interface CertificationOUT
 */
export interface CertificationOUT {
    /**
     *
     * @type {string}
     * @memberof CertificationOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof CertificationOUT
     */
    'name': string;
}
/**
 *
 * @export
 * @interface CertificationOut
 */
export interface CertificationOut {
    /**
     *
     * @type {string}
     * @memberof CertificationOut
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof CertificationOut
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof CertificationOut
     */
    'name_de'?: string | null;
    /**
     *
     * @type {string}
     * @memberof CertificationOut
     */
    'name_en'?: string | null;
    /**
     *
     * @type {Array<EPDConformityOut>}
     * @memberof CertificationOut
     */
    'conformities': Array<EPDConformityOut>;
}
/**
 *
 * @export
 * @interface Circularity
 */
export interface Circularity {
    /**
     *
     * @type {CircularityPerformanceIndex}
     * @memberof Circularity
     */
    'circularity_performance_index'?: CircularityPerformanceIndex | null;
    /**
     *
     * @type {Array<SingularCircularityValue>}
     * @memberof Circularity
     */
    'singular_values'?: Array<SingularCircularityValue>;
    /**
     *
     * @type {RecyclabilityScore}
     * @memberof Circularity
     */
    'recyclability_score'?: RecyclabilityScore | null;
    /**
     *
     * @type {RecyclabilityDescription}
     * @memberof Circularity
     */
    'recyclability_description'?: RecyclabilityDescription | null;
}
/**
 *
 * @export
 * @interface CircularityPerformanceIndex
 */
export interface CircularityPerformanceIndex {
    /**
     *
     * @type {string}
     * @memberof CircularityPerformanceIndex
     */
    'field_name': string;
    /**
     *
     * @type {string}
     * @memberof CircularityPerformanceIndex
     */
    'field_name_de': string;
    /**
     *
     * @type {Array<CircularityPerformanceIndexValue>}
     * @memberof CircularityPerformanceIndex
     */
    'values'?: Array<CircularityPerformanceIndexValue>;
}
/**
 *
 * @export
 * @interface CircularityPerformanceIndexValue
 */
export interface CircularityPerformanceIndexValue {
    /**
     *
     * @type {string}
     * @memberof CircularityPerformanceIndexValue
     */
    'material_name'?: string | null;
    /**
     *
     * @type {string}
     * @memberof CircularityPerformanceIndexValue
     */
    'value'?: string | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ColumnConfiguration = {
    All: 'all',
    Default: 'default'
} as const;

export type ColumnConfiguration = typeof ColumnConfiguration[keyof typeof ColumnConfiguration];


/**
 *
 * @export
 * @enum {string}
 */

export const ColumnSourceEnum = {
    PhysicalObject: 'PhysicalObject',
    SummaryTable: 'SummaryTable',
    IfcProperties: 'IFCProperties'
} as const;

export type ColumnSourceEnum = typeof ColumnSourceEnum[keyof typeof ColumnSourceEnum];


/**
 *
 * @export
 * @interface ColumnToDisplay
 */
export interface ColumnToDisplay {
    /**
     *
     * @type {string}
     * @memberof ColumnToDisplay
     */
    'column_key': string;
    /**
     *
     * @type {ColumnSourceEnum}
     * @memberof ColumnToDisplay
     */
    'column_source': ColumnSourceEnum;
}


/**
 *
 * @export
 * @interface ColumnValueSchemaIN
 */
export interface ColumnValueSchemaIN {
    /**
     *
     * @type {Array<string>}
     * @memberof ColumnValueSchemaIN
     */
    'columns': Array<string>;
    /**
     *
     * @type {string}
     * @memberof ColumnValueSchemaIN
     */
    'building_id': string;
}
/**
 *
 * @export
 * @interface ComplianceOUT
 */
export interface ComplianceOUT {
    /**
     *
     * @type {Array<string>}
     * @memberof ComplianceOUT
     */
    'conformities'?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof ComplianceOUT
     */
    'certifications'?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof ComplianceOUT
     */
    'dataset_sources'?: Array<string>;
}
/**
 *
 * @export
 * @interface CompliantCertification
 */
export interface CompliantCertification {
    /**
     *
     * @type {string}
     * @memberof CompliantCertification
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof CompliantCertification
     */
    'name_de'?: string | null;
    /**
     *
     * @type {string}
     * @memberof CompliantCertification
     */
    'name_en'?: string | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ComponentAvailabilityEnum = {
    Global: 'GLOBAL',
    Organisation: 'ORGANISATION',
    Building: 'BUILDING'
} as const;

export type ComponentAvailabilityEnum = typeof ComponentAvailabilityEnum[keyof typeof ComponentAvailabilityEnum];


/**
 *
 * @export
 * @interface ComponentChildIN
 */
export interface ComponentChildIN {
    /**
     *
     * @type {string}
     * @memberof ComponentChildIN
     */
    'product_id': string;
    /**
     *
     * @type {number}
     * @memberof ComponentChildIN
     */
    'unit_conversion'?: number;
    /**
     *
     * @type {string}
     * @memberof ComponentChildIN
     */
    'comment'?: string | null;
}
/**
 *
 * @export
 * @interface ComponentComplianceIN
 */
export interface ComponentComplianceIN {
    /**
     *
     * @type {Array<string>}
     * @memberof ComponentComplianceIN
     */
    'product_ids': Array<string>;
}
/**
 *
 * @export
 * @interface ComponentComplianceOUT
 */
export interface ComponentComplianceOUT {
    /**
     *
     * @type {Array<string>}
     * @memberof ComponentComplianceOUT
     */
    'dataset_sources'?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof ComponentComplianceOUT
     */
    'conformities'?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof ComponentComplianceOUT
     */
    'compliant_certifications'?: Array<string>;
}
/**
 *
 * @export
 * @interface ComponentCreateIN
 */
export interface ComponentCreateIN {
    /**
     *
     * @type {string}
     * @memberof ComponentCreateIN
     */
    'name': string;
    /**
     *
     * @type {Array<string>}
     * @memberof ComponentCreateIN
     */
    'tags_automapper'?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof ComponentCreateIN
     */
    'ifc_is_external'?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof ComponentCreateIN
     */
    'ifc_is_loadbearing'?: boolean | null;
    /**
     *
     * @type {ComponentAvailabilityEnum}
     * @memberof ComponentCreateIN
     */
    'availability'?: ComponentAvailabilityEnum | null;
    /**
     *
     * @type {ComponentReferenceUnitEnum}
     * @memberof ComponentCreateIN
     */
    'reference_unit': ComponentReferenceUnitEnum;
    /**
     *
     * @type {Array<ComponentChildIN>}
     * @memberof ComponentCreateIN
     */
    'children'?: Array<ComponentChildIN>;
    /**
     *
     * @type {string}
     * @memberof ComponentCreateIN
     */
    'building'?: string | null;
}


/**
 *
 * @export
 * @interface ComponentCreateOUT
 */
export interface ComponentCreateOUT {
    /**
     *
     * @type {string}
     * @memberof ComponentCreateOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof ComponentCreateOUT
     */
    'name'?: string | null;
    /**
     *
     * @type {string}
     * @memberof ComponentCreateOUT
     */
    'type': string;
    /**
     *
     * @type {string}
     * @memberof ComponentCreateOUT
     */
    'comment'?: string | null;
    /**
     *
     * @type {Array<ComponentCreateOUT>}
     * @memberof ComponentCreateOUT
     */
    'children'?: Array<ComponentCreateOUT>;
}
/**
 *
 * @export
 * @interface ComponentDINCategoryIN
 */
export interface ComponentDINCategoryIN {
    /**
     *
     * @type {Array<string>}
     * @memberof ComponentDINCategoryIN
     */
    'product_ids': Array<string>;
}
/**
 *
 * @export
 * @interface ComponentDINCategoryOUT
 */
export interface ComponentDINCategoryOUT {
    /**
     *
     * @type {string}
     * @memberof ComponentDINCategoryOUT
     */
    'din_category'?: string | null;
}
/**
 *
 * @export
 * @interface ComponentDetailsByFileOut
 */
export interface ComponentDetailsByFileOut {
    /**
     *
     * @type {string}
     * @memberof ComponentDetailsByFileOut
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof ComponentDetailsByFileOut
     */
    'name'?: string | null;
    /**
     *
     * @type {string}
     * @memberof ComponentDetailsByFileOut
     */
    'din_category_level_1'?: string | null;
    /**
     *
     * @type {string}
     * @memberof ComponentDetailsByFileOut
     */
    'din_category_level_1_number'?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ComponentDetailsByFileOut
     */
    'is_compliant_by_file'?: boolean;
    /**
     *
     * @type {string}
     * @memberof ComponentDetailsByFileOut
     */
    'selected_epd_group_id'?: string | null;
    /**
     *
     * @type {number}
     * @memberof ComponentDetailsByFileOut
     */
    'life_span': number;
    /**
     *
     * @type {boolean}
     * @memberof ComponentDetailsByFileOut
     */
    'is_external'?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof ComponentDetailsByFileOut
     */
    'is_load_bearing'?: boolean | null;
    /**
     *
     * @type {Array<ComponentDetailsEPDGroupOUT>}
     * @memberof ComponentDetailsByFileOut
     */
    'epd_groups'?: Array<ComponentDetailsEPDGroupOUT>;
    /**
     *
     * @type {ComplianceOUT}
     * @memberof ComponentDetailsByFileOut
     */
    'compliance'?: ComplianceOUT | null;
    /**
     *
     * @type {Array<string>}
     * @memberof ComponentDetailsByFileOut
     */
    'automapping_tags'?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof ComponentDetailsByFileOut
     */
    'reference_unit': string;
    /**
     *
     * @type {string}
     * @memberof ComponentDetailsByFileOut
     */
    'type': string;
    /**
     *
     * @type {number}
     * @memberof ComponentDetailsByFileOut
     */
    'unit_conversion'?: number | null;
    /**
     *
     * @type {string}
     * @memberof ComponentDetailsByFileOut
     */
    'comment'?: string | null;
    /**
     *
     * @type {Array<ComponentDetailsByFileOut>}
     * @memberof ComponentDetailsByFileOut
     */
    'children'?: Array<ComponentDetailsByFileOut>;
}
/**
 *
 * @export
 * @interface ComponentDetailsEPDGroupOUT
 */
export interface ComponentDetailsEPDGroupOUT {
    /**
     *
     * @type {string}
     * @memberof ComponentDetailsEPDGroupOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof ComponentDetailsEPDGroupOUT
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof ComponentDetailsEPDGroupOUT
     */
    'conformity'?: string | null;
    /**
     *
     * @type {Array<InventoryCertificationOUT>}
     * @memberof ComponentDetailsEPDGroupOUT
     */
    'certifications'?: Array<InventoryCertificationOUT>;
    /**
     *
     * @type {Array<InventoryProductEPDForProductDetailsByFileOUT>}
     * @memberof ComponentDetailsEPDGroupOUT
     */
    'epds': Array<InventoryProductEPDForProductDetailsByFileOUT>;
}
/**
 *
 * @export
 * @interface ComponentEditIN
 */
export interface ComponentEditIN {
    /**
     *
     * @type {string}
     * @memberof ComponentEditIN
     */
    'name': string;
    /**
     *
     * @type {Array<string>}
     * @memberof ComponentEditIN
     */
    'tags_automapper'?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof ComponentEditIN
     */
    'ifc_is_external'?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof ComponentEditIN
     */
    'ifc_is_loadbearing'?: boolean | null;
    /**
     *
     * @type {ComponentAvailabilityEnum}
     * @memberof ComponentEditIN
     */
    'availability'?: ComponentAvailabilityEnum | null;
    /**
     *
     * @type {ComponentReferenceUnitEnum}
     * @memberof ComponentEditIN
     */
    'reference_unit': ComponentReferenceUnitEnum;
    /**
     *
     * @type {Array<ComponentChildIN>}
     * @memberof ComponentEditIN
     */
    'children'?: Array<ComponentChildIN>;
    /**
     *
     * @type {string}
     * @memberof ComponentEditIN
     */
    'building'?: string | null;
}


/**
 *
 * @export
 * @interface ComponentEditOUT
 */
export interface ComponentEditOUT {
    /**
     *
     * @type {ComponentCreateOUT}
     * @memberof ComponentEditOUT
     */
    'component': ComponentCreateOUT;
    /**
     *
     * @type {boolean}
     * @memberof ComponentEditOUT
     */
    'is_recalculation_triggered': boolean;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ComponentReferenceUnitEnum = {
    M: 'M',
    M2: 'M2',
    M3: 'M3',
    Kg: 'KG',
    Pcs: 'PCS'
} as const;

export type ComponentReferenceUnitEnum = typeof ComponentReferenceUnitEnum[keyof typeof ComponentReferenceUnitEnum];


/**
 *
 * @export
 * @interface ConcularProperty
 */
export interface ConcularProperty {
    /**
     *
     * @type {string}
     * @memberof ConcularProperty
     */
    'field_name': string;
    /**
     *
     * @type {string}
     * @memberof ConcularProperty
     */
    'value'?: string | null;
}
/**
 *
 * @export
 * @interface CustomBuildingProductEPDGroupEditOUT
 */
export interface CustomBuildingProductEPDGroupEditOUT {
    /**
     *
     * @type {string}
     * @memberof CustomBuildingProductEPDGroupEditOUT
     */
    'id': string;
    /**
     *
     * @type {Array<string>}
     * @memberof CustomBuildingProductEPDGroupEditOUT
     */
    'product_epds': Array<string>;
}
/**
 *
 * @export
 * @interface CustomBuildingProductEditIN
 */
export interface CustomBuildingProductEditIN {
    /**
     *
     * @type {string}
     * @memberof CustomBuildingProductEditIN
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof CustomBuildingProductEditIN
     */
    'din_category': string;
    /**
     *
     * @type {number}
     * @memberof CustomBuildingProductEditIN
     */
    'life_span': number;
    /**
     *
     * @type {boolean}
     * @memberof CustomBuildingProductEditIN
     */
    'ifc_is_external'?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof CustomBuildingProductEditIN
     */
    'ifc_is_loadbearing'?: boolean | null;
    /**
     *
     * @type {Array<string>}
     * @memberof CustomBuildingProductEditIN
     */
    'automapping_tags'?: Array<string>;
    /**
     *
     * @type {ProductCreationCPXValues}
     * @memberof CustomBuildingProductEditIN
     */
    'cpx_values': ProductCreationCPXValues;
    /**
     *
     * @type {Array<ProductCreationEPDGroup>}
     * @memberof CustomBuildingProductEditIN
     */
    'epd_groups': Array<ProductCreationEPDGroup>;
    /**
     *
     * @type {string}
     * @memberof CustomBuildingProductEditIN
     */
    'building': string;
    /**
     *
     * @type {CustomBuildingProductReferenceUnitEnum}
     * @memberof CustomBuildingProductEditIN
     */
    'reference_unit': CustomBuildingProductReferenceUnitEnum;
}


/**
 *
 * @export
 * @interface CustomBuildingProductEditOUT
 */
export interface CustomBuildingProductEditOUT {
    /**
     *
     * @type {string}
     * @memberof CustomBuildingProductEditOUT
     */
    'product': string;
    /**
     *
     * @type {Array<CustomBuildingProductEPDGroupEditOUT>}
     * @memberof CustomBuildingProductEditOUT
     */
    'epd_groups': Array<CustomBuildingProductEPDGroupEditOUT>;
    /**
     *
     * @type {boolean}
     * @memberof CustomBuildingProductEditOUT
     */
    'is_recalculation_triggered': boolean;
}
/**
 *
 * @export
 * @interface CustomBuildingProductIN
 */
export interface CustomBuildingProductIN {
    /**
     *
     * @type {string}
     * @memberof CustomBuildingProductIN
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof CustomBuildingProductIN
     */
    'din_category': string;
    /**
     *
     * @type {number}
     * @memberof CustomBuildingProductIN
     */
    'life_span': number;
    /**
     *
     * @type {boolean}
     * @memberof CustomBuildingProductIN
     */
    'ifc_is_external'?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof CustomBuildingProductIN
     */
    'ifc_is_loadbearing'?: boolean | null;
    /**
     *
     * @type {Array<string>}
     * @memberof CustomBuildingProductIN
     */
    'automapping_tags'?: Array<string>;
    /**
     *
     * @type {ProductCreationCPXValues}
     * @memberof CustomBuildingProductIN
     */
    'cpx_values': ProductCreationCPXValues;
    /**
     *
     * @type {Array<ProductCreationEPDGroup>}
     * @memberof CustomBuildingProductIN
     */
    'epd_groups': Array<ProductCreationEPDGroup>;
    /**
     *
     * @type {string}
     * @memberof CustomBuildingProductIN
     */
    'building': string;
    /**
     *
     * @type {CustomBuildingProductReferenceUnitEnum}
     * @memberof CustomBuildingProductIN
     */
    'reference_unit': CustomBuildingProductReferenceUnitEnum;
}


/**
 *
 * @export
 * @interface CustomBuildingProductOUT
 */
export interface CustomBuildingProductOUT {
    /**
     *
     * @type {string}
     * @memberof CustomBuildingProductOUT
     */
    'id'?: string | null;
    /**
     *
     * @type {string}
     * @memberof CustomBuildingProductOUT
     */
    'name'?: string | null;
    /**
     *
     * @type {Array<EPDGroupOUT>}
     * @memberof CustomBuildingProductOUT
     */
    'groups'?: Array<EPDGroupOUT>;
    /**
     *
     * @type {string}
     * @memberof CustomBuildingProductOUT
     */
    'din_category'?: string | null;
    /**
     *
     * @type {string}
     * @memberof CustomBuildingProductOUT
     */
    'din_category_number'?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof CustomBuildingProductOUT
     */
    'is_external'?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof CustomBuildingProductOUT
     */
    'is_load_bearing'?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof CustomBuildingProductOUT
     */
    'separability_score'?: number | null;
    /**
     *
     * @type {string}
     * @memberof CustomBuildingProductOUT
     */
    'separability_qualification'?: string | null;
    /**
     *
     * @type {number}
     * @memberof CustomBuildingProductOUT
     */
    'deconstructability_score'?: number | null;
    /**
     *
     * @type {string}
     * @memberof CustomBuildingProductOUT
     */
    'deconstructability_qualification'?: string | null;
    /**
     *
     * @type {number}
     * @memberof CustomBuildingProductOUT
     */
    'reusability_score'?: number | null;
    /**
     *
     * @type {string}
     * @memberof CustomBuildingProductOUT
     */
    'reusability_qualification'?: string | null;
    /**
     *
     * @type {number}
     * @memberof CustomBuildingProductOUT
     */
    'recyclability_score'?: number | null;
    /**
     *
     * @type {string}
     * @memberof CustomBuildingProductOUT
     */
    'recyclability_qualification'?: string | null;
    /**
     *
     * @type {number}
     * @memberof CustomBuildingProductOUT
     */
    'preuse_score'?: number | null;
    /**
     *
     * @type {string}
     * @memberof CustomBuildingProductOUT
     */
    'preuse_qualification'?: string | null;
    /**
     *
     * @type {string}
     * @memberof CustomBuildingProductOUT
     */
    'dataset_source'?: string | null;
    /**
     *
     * @type {string}
     * @memberof CustomBuildingProductOUT
     */
    'conformity'?: string | null;
    /**
     *
     * @type {string}
     * @memberof CustomBuildingProductOUT
     */
    'building'?: string | null;
    /**
     *
     * @type {number}
     * @memberof CustomBuildingProductOUT
     */
    'life_span'?: number;
    /**
     *
     * @type {Array<InventoryAutomapperTagOUT>}
     * @memberof CustomBuildingProductOUT
     */
    'tags_automapper'?: Array<InventoryAutomapperTagOUT>;
    /**
     *
     * @type {string}
     * @memberof CustomBuildingProductOUT
     */
    'reference_unit': string;
    /**
     *
     * @type {ComplianceOUT}
     * @memberof CustomBuildingProductOUT
     */
    'compliance'?: ComplianceOUT | null;
    /**
     *
     * @type {string}
     * @memberof CustomBuildingProductOUT
     */
    'type': string;
    /**
     *
     * @type {boolean}
     * @memberof CustomBuildingProductOUT
     */
    'is_part_of_a_component'?: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */

export const CustomBuildingProductReferenceUnitEnum = {
    M: 'M',
    M2: 'M2',
    M3: 'M3',
    Kg: 'KG',
    Pcs: 'PCS'
} as const;

export type CustomBuildingProductReferenceUnitEnum = typeof CustomBuildingProductReferenceUnitEnum[keyof typeof CustomBuildingProductReferenceUnitEnum];


/**
 *
 * @export
 * @interface DGNBPassportFileOUT
 */
export interface DGNBPassportFileOUT {
    /**
     *
     * @type {string}
     * @memberof DGNBPassportFileOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportFileOUT
     */
    'download_url'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportFileOUT
     */
    'created': string;
    /**
     *
     * @type {object}
     * @memberof DGNBPassportFileOUT
     */
    'input_data': object;
}
/**
 *
 * @export
 * @interface DGNBPassportIN
 */
export interface DGNBPassportIN {
    /**
     *
     * @type {boolean}
     * @memberof DGNBPassportIN
     */
    'project_certification_bool'?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'project_certification_description'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'address'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'date_of_construction'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'permit_of_construction'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'type_of_construction'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'type_of_operation'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'building_category'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'description'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'special_features'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'gross_floor_area'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'net_room_area'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'utility_unit'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'deconstruction_type'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'deconstruction_type_method'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'building_type_pollution_measure'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'building_type_pollution_measure_explanation'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'building_type_pollution'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'building_type_pollution_explanation'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'building_type_pollution_quality'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'lvl_pollution'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'lvl_pollution_quality'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'pollution_usage'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'report_pollution'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'date_report_pollution'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'result_report_pollution'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'penrt_b6'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'gwp_b6'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'multiple_use_areas'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'repurposing_capability_area'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'area_division_possible'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'area_division_possible_source'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'area_utilization_ratio'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'area_utility_need'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'area_expandable'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'area_expandable_source'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'area_concept_description'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'area_concept_description_explanation'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'state_of_building'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'date_of_issue'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'operation_result'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DGNBPassportIN
     */
    'energy_consumption'?: string | null;
}
/**
 *
 * @export
 * @interface DINCategoryFilter
 */
export interface DINCategoryFilter {
    /**
     *
     * @type {boolean}
     * @memberof DINCategoryFilter
     */
    'use_simplified_version_lca'?: boolean | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export const DINCategoryLevelEnum = {
    _1: 'level_1',
    _2: 'level_2',
    _3: 'level_3'
} as const;

export type DINCategoryLevelEnum = typeof DINCategoryLevelEnum[keyof typeof DINCategoryLevelEnum];


/**
 *
 * @export
 * @interface DatasetCheckerIN
 */
export interface DatasetCheckerIN {
    /**
     *
     * @type {string}
     * @memberof DatasetCheckerIN
     */
    'building_id': string;
    /**
     *
     * @type {string}
     * @memberof DatasetCheckerIN
     */
    'certification_id': string;
    /**
     *
     * @type {string}
     * @memberof DatasetCheckerIN
     */
    'conformity_id': string;
    /**
     *
     * @type {DINCategoryFilter}
     * @memberof DatasetCheckerIN
     */
    'din_category_filter'?: DINCategoryFilter | null;
}
/**
 *
 * @export
 * @interface DatasetCheckerOUT
 */
export interface DatasetCheckerOUT {
    /**
     *
     * @type {number}
     * @memberof DatasetCheckerOUT
     */
    'total_objects': number;
    /**
     *
     * @type {number}
     * @memberof DatasetCheckerOUT
     */
    'compliant_objects': number;
}
/**
 *
 * @export
 * @interface DefaultMaterial
 */
export interface DefaultMaterial {
    /**
     *
     * @type {string}
     * @memberof DefaultMaterial
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof DefaultMaterial
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof DefaultMaterial
     */
    'codename': string;
    /**
     *
     * @type {number}
     * @memberof DefaultMaterial
     */
    'recyclability_score'?: number | null;
    /**
     *
     * @type {string}
     * @memberof DefaultMaterial
     */
    'recyclability_qualification'?: string | null;
}
/**
 *
 * @export
 * @interface Dimensions
 */
export interface Dimensions {
    /**
     *
     * @type {string}
     * @memberof Dimensions
     */
    'field_name'?: string | null;
    /**
     *
     * @type {string}
     * @memberof Dimensions
     */
    'field_name_de'?: string | null;
    /**
     *
     * @type {number}
     * @memberof Dimensions
     */
    'value'?: number | null;
    /**
     *
     * @type {string}
     * @memberof Dimensions
     */
    'unit': string;
}
/**
 *
 * @export
 * @interface DynamicColumnOUT
 */
export interface DynamicColumnOUT {
    /**
     *
     * @type {string}
     * @memberof DynamicColumnOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof DynamicColumnOUT
     */
    'key_name': string;
    /**
     *
     * @type {string}
     * @memberof DynamicColumnOUT
     */
    'friendly_name': string;
    /**
     *
     * @type {string}
     * @memberof DynamicColumnOUT
     */
    'friendly_name_de': string;
    /**
     *
     * @type {string}
     * @memberof DynamicColumnOUT
     */
    'group_name': string;
    /**
     *
     * @type {string}
     * @memberof DynamicColumnOUT
     */
    'group_name_de': string;
    /**
     *
     * @type {string}
     * @memberof DynamicColumnOUT
     */
    'type': string;
    /**
     *
     * @type {string}
     * @memberof DynamicColumnOUT
     */
    'subtitle': string;
    /**
     *
     * @type {string}
     * @memberof DynamicColumnOUT
     */
    'subtitle_de': string;
    /**
     *
     * @type {string}
     * @memberof DynamicColumnOUT
     */
    'tooltip': string;
    /**
     *
     * @type {string}
     * @memberof DynamicColumnOUT
     */
    'tooltip_de': string;
    /**
     *
     * @type {string}
     * @memberof DynamicColumnOUT
     */
    'source': string;
}
/**
 *
 * @export
 * @interface EPDConformityOut
 */
export interface EPDConformityOut {
    /**
     *
     * @type {string}
     * @memberof EPDConformityOut
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof EPDConformityOut
     */
    'name': string;
}
/**
 *
 * @export
 * @interface EPDGroupOUT
 */
export interface EPDGroupOUT {
    /**
     *
     * @type {Array<EPDSOUT>}
     * @memberof EPDGroupOUT
     */
    'epds'?: Array<EPDSOUT>;
    /**
     *
     * @type {string}
     * @memberof EPDGroupOUT
     */
    'conformity'?: string | null;
    /**
     *
     * @type {Array<CertificationOUT>}
     * @memberof EPDGroupOUT
     */
    'certifications'?: Array<CertificationOUT>;
}
/**
 *
 * @export
 * @interface EPDGroupsCheckIN
 */
export interface EPDGroupsCheckIN {
    /**
     *
     * @type {Array<Array<string>>}
     * @memberof EPDGroupsCheckIN
     */
    'epd_groups': Array<Array<string>>;
}
/**
 *
 * @export
 * @interface EPDGroupsCheckOUT
 */
export interface EPDGroupsCheckOUT {
    /**
     *
     * @type {Array<string>}
     * @memberof EPDGroupsCheckOUT
     */
    'dataset_sources'?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof EPDGroupsCheckOUT
     */
    'conformities'?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof EPDGroupsCheckOUT
     */
    'compliant_certifications'?: Array<string>;
}
/**
 *
 * @export
 * @interface EPDOUT
 */
export interface EPDOUT {
    /**
     *
     * @type {string}
     * @memberof EPDOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof EPDOUT
     */
    'name_de'?: string | null;
    /**
     *
     * @type {string}
     * @memberof EPDOUT
     */
    'name_en'?: string | null;
    /**
     *
     * @type {string}
     * @memberof EPDOUT
     */
    'reference_unit'?: string | null;
    /**
     *
     * @type {number}
     * @memberof EPDOUT
     */
    'reference_size': number;
    /**
     *
     * @type {string}
     * @memberof EPDOUT
     */
    'version'?: string | null;
    /**
     *
     * @type {string}
     * @memberof EPDOUT
     */
    'url'?: string | null;
    /**
     *
     * @type {string}
     * @memberof EPDOUT
     */
    'manufacturer'?: string | null;
    /**
     *
     * @type {SearchEngineCategoryOUT}
     * @memberof EPDOUT
     */
    'category'?: SearchEngineCategoryOUT | null;
    /**
     *
     * @type {string}
     * @memberof EPDOUT
     */
    'dataset_source'?: string | null;
    /**
     *
     * @type {string}
     * @memberof EPDOUT
     */
    'conformity'?: string | null;
    /**
     *
     * @type {number}
     * @memberof EPDOUT
     */
    'mass_value_m'?: number | null;
    /**
     *
     * @type {number}
     * @memberof EPDOUT
     */
    'mass_value_m2'?: number | null;
    /**
     *
     * @type {number}
     * @memberof EPDOUT
     */
    'mass_value_m3'?: number | null;
}
/**
 *
 * @export
 * @interface EPDSOUT
 */
export interface EPDSOUT {
    /**
     *
     * @type {EPDOUT}
     * @memberof EPDSOUT
     */
    'epd': EPDOUT;
    /**
     *
     * @type {MaterialOUT}
     * @memberof EPDSOUT
     */
    'material': MaterialOUT | null;
    /**
     *
     * @type {number}
     * @memberof EPDSOUT
     */
    'factor'?: number | null;
}
/**
 *
 * @export
 * @interface EPDSearchIN
 */
export interface EPDSearchIN {
    /**
     *
     * @type {string}
     * @memberof EPDSearchIN
     */
    'term': string;
    /**
     *
     * @type {Array<string>}
     * @memberof EPDSearchIN
     */
    'conformity'?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof EPDSearchIN
     */
    'manufacturer'?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof EPDSearchIN
     */
    'dataset_source'?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof EPDSearchIN
     */
    'data_type'?: Array<string>;
}
/**
 *
 * @export
 * @interface EPDSearchOUT
 */
export interface EPDSearchOUT {
    /**
     *
     * @type {string}
     * @memberof EPDSearchOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof EPDSearchOUT
     */
    'source': string;
    /**
     *
     * @type {string}
     * @memberof EPDSearchOUT
     */
    'source_uuid': string;
    /**
     *
     * @type {string}
     * @memberof EPDSearchOUT
     */
    'version': string;
    /**
     *
     * @type {string}
     * @memberof EPDSearchOUT
     */
    'name_de'?: string | null;
    /**
     *
     * @type {string}
     * @memberof EPDSearchOUT
     */
    'name_en'?: string | null;
    /**
     *
     * @type {string}
     * @memberof EPDSearchOUT
     */
    'url'?: string | null;
    /**
     *
     * @type {string}
     * @memberof EPDSearchOUT
     */
    'category'?: string | null;
    /**
     *
     * @type {string}
     * @memberof EPDSearchOUT
     */
    'manufacturer'?: string | null;
    /**
     *
     * @type {number}
     * @memberof EPDSearchOUT
     */
    'reference_size': number;
    /**
     *
     * @type {string}
     * @memberof EPDSearchOUT
     */
    'reference_unit': string;
    /**
     *
     * @type {number}
     * @memberof EPDSearchOUT
     */
    'mass_conversion_factor'?: number | null;
    /**
     *
     * @type {number}
     * @memberof EPDSearchOUT
     */
    'mass_value_m3'?: number | null;
    /**
     *
     * @type {number}
     * @memberof EPDSearchOUT
     */
    'mass_value_m2'?: number | null;
    /**
     *
     * @type {number}
     * @memberof EPDSearchOUT
     */
    'mass_value_m'?: number | null;
    /**
     *
     * @type {string}
     * @memberof EPDSearchOUT
     */
    'conformity__name'?: string | null;
    /**
     *
     * @type {string}
     * @memberof EPDSearchOUT
     */
    'dataset_source__name'?: string | null;
    /**
     *
     * @type {DefaultMaterial}
     * @memberof EPDSearchOUT
     */
    'default_material'?: DefaultMaterial | null;
    /**
     *
     * @type {string}
     * @memberof EPDSearchOUT
     */
    'data_type'?: string | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export const EPDSearchSortBy = {
    NameEn: 'name_en',
    NameDe: 'name_de',
    DatasetSourceName: 'dataset_source__name',
    ReferenceUnit: 'reference_unit',
    ConformityName: 'conformity__name',
    DataType: 'data_type'
} as const;

export type EPDSearchSortBy = typeof EPDSearchSortBy[keyof typeof EPDSearchSortBy];


/**
 *
 * @export
 * @interface EPDSpecificationCheckIN
 */
export interface EPDSpecificationCheckIN {
    /**
     *
     * @type {Array<string>}
     * @memberof EPDSpecificationCheckIN
     */
    'epds': Array<string>;
}
/**
 *
 * @export
 * @interface EmbedConfig
 */
export interface EmbedConfig {
    /**
     *
     * @type {string}
     * @memberof EmbedConfig
     */
    'tokenId'?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmbedConfig
     */
    'token'?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmbedConfig
     */
    'tokenExpiry'?: string | null;
    /**
     *
     * @type {Array<ReportConfig>}
     * @memberof EmbedConfig
     */
    'reportConfig'?: Array<ReportConfig>;
}
/**
 *
 * @export
 * @interface EpdSpecificationCheckOUT
 */
export interface EpdSpecificationCheckOUT {
    /**
     *
     * @type {string}
     * @memberof EpdSpecificationCheckOUT
     */
    'dataset_source'?: string | null;
    /**
     *
     * @type {string}
     * @memberof EpdSpecificationCheckOUT
     */
    'conformity'?: string | null;
    /**
     *
     * @type {Array<CompliantCertification>}
     * @memberof EpdSpecificationCheckOUT
     */
    'compliant_certifications'?: Array<CompliantCertification>;
}
/**
 *
 * @export
 * @interface ErrorOUT
 */
export interface ErrorOUT {
    /**
     *
     * @type {boolean}
     * @memberof ErrorOUT
     */
    'success': boolean;
    /**
     *
     * @type {number}
     * @memberof ErrorOUT
     */
    'status_code': number;
    /**
     *
     * @type {string}
     * @memberof ErrorOUT
     */
    'message': string;
    /**
     *
     * @type {string}
     * @memberof ErrorOUT
     */
    'error_code': string;
    /**
     *
     * @type {object}
     * @memberof ErrorOUT
     */
    'details': object;
}
/**
 *
 * @export
 * @interface ExceptionSchemaIN
 */
export interface ExceptionSchemaIN {
    /**
     *
     * @type {string}
     * @memberof ExceptionSchemaIN
     */
    'msg': string;
    /**
     *
     * @type {number}
     * @memberof ExceptionSchemaIN
     */
    'status_code'?: number | null;
}
/**
 *
 * @export
 * @interface ExportCertificationIN
 */
export interface ExportCertificationIN {
    /**
     *
     * @type {string}
     * @memberof ExportCertificationIN
     */
    'certification_id': string;
    /**
     *
     * @type {string}
     * @memberof ExportCertificationIN
     */
    'conformity_id': string;
    /**
     *
     * @type {ExportCertificationOptions}
     * @memberof ExportCertificationIN
     */
    'options': ExportCertificationOptions;
}
/**
 *
 * @export
 * @interface ExportCertificationOptions
 */
export interface ExportCertificationOptions {
    /**
     *
     * @type {boolean}
     * @memberof ExportCertificationOptions
     */
    'use_simplified_version_lca': boolean;
    /**
     *
     * @type {boolean}
     * @memberof ExportCertificationOptions
     */
    'reduce_tga_factor_to_1_1': boolean;
    /**
     *
     * @type {string}
     * @memberof ExportCertificationOptions
     */
    'base_amounts_matrix_key': string;
}
/**
 *
 * @export
 * @interface FetchReportTileIN
 */
export interface FetchReportTileIN {
    /**
     *
     * @type {string}
     * @memberof FetchReportTileIN
     */
    'object_id': string;
    /**
     *
     * @type {FetchReportTileReportKeys}
     * @memberof FetchReportTileIN
     */
    'report_keys': FetchReportTileReportKeys;
}
/**
 *
 * @export
 * @interface FetchReportTileOUT
 */
export interface FetchReportTileOUT {
    /**
     *
     * @type {ReportTileInfo}
     * @memberof FetchReportTileOUT
     */
    'info'?: ReportTileInfo | null;
    /**
     *
     * @type {ReportTileReport}
     * @memberof FetchReportTileOUT
     */
    'reports'?: ReportTileReport | null;
    /**
     *
     * @type {IFCProperties}
     * @memberof FetchReportTileOUT
     */
    'ifc_properties'?: IFCProperties | null;
}
/**
 *
 * @export
 * @interface FetchReportTileReportKeys
 */
export interface FetchReportTileReportKeys {
    /**
     *
     * @type {boolean}
     * @memberof FetchReportTileReportKeys
     */
    'info'?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof FetchReportTileReportKeys
     */
    'reports'?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof FetchReportTileReportKeys
     */
    'ifc_properties'?: boolean | null;
}
/**
 *
 * @export
 * @interface FieldIN
 */
export interface FieldIN {
    /**
     *
     * @type {string}
     * @memberof FieldIN
     */
    'name'?: string | null;
    /**
     *
     * @type {string}
     * @memberof FieldIN
     */
    'name_de'?: string | null;
    /**
     *
     * @type {string}
     * @memberof FieldIN
     */
    'field_type'?: string | null;
    /**
     *
     * @type {number}
     * @memberof FieldIN
     */
    'order_of_appearance': number;
}
/**
 *
 * @export
 * @interface FieldOUT
 */
export interface FieldOUT {
    /**
     *
     * @type {string}
     * @memberof FieldOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof FieldOUT
     */
    'name'?: string | null;
    /**
     *
     * @type {string}
     * @memberof FieldOUT
     */
    'name_de'?: string | null;
    /**
     *
     * @type {string}
     * @memberof FieldOUT
     */
    'field_type'?: string | null;
    /**
     *
     * @type {Array<FieldOptionOUT>}
     * @memberof FieldOUT
     */
    'field_options': Array<FieldOptionOUT>;
    /**
     *
     * @type {number}
     * @memberof FieldOUT
     */
    'order_of_appearance': number;
}
/**
 *
 * @export
 * @interface FieldOptionOUT
 */
export interface FieldOptionOUT {
    /**
     *
     * @type {string}
     * @memberof FieldOptionOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof FieldOptionOUT
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof FieldOptionOUT
     */
    'name_de'?: string | null;
    /**
     *
     * @type {string}
     * @memberof FieldOptionOUT
     */
    'value': string;
}
/**
 *
 * @export
 * @interface FileOperationIN
 */
export interface FileOperationIN {
    /**
     *
     * @type {Filtering}
     * @memberof FileOperationIN
     */
    'filtering'?: Filtering | null;
    /**
     *
     * @type {OperationType}
     * @memberof FileOperationIN
     */
    'operation_type': OperationType;
    /**
     *
     * @type {string}
     * @memberof FileOperationIN
     */
    'product_uuid'?: string | null;
    /**
     *
     * @type {Array<ColumnToDisplay>}
     * @memberof FileOperationIN
     */
    'columns_to_display': Array<ColumnToDisplay>;
}


/**
 *
 * @export
 * @interface Filtering
 */
export interface Filtering {
    /**
     *
     * @type {LogicalOperatorENUM}
     * @memberof Filtering
     */
    'logical_operator': LogicalOperatorENUM;
    /**
     *
     * @type {Array<FilteringRule>}
     * @memberof Filtering
     */
    'rules': Array<FilteringRule>;
}


/**
 *
 * @export
 * @interface FilteringRule
 */
export interface FilteringRule {
    /**
     *
     * @type {string}
     * @memberof FilteringRule
     */
    'column_key': string;
    /**
     *
     * @type {ColumnSourceEnum}
     * @memberof FilteringRule
     */
    'column_source': ColumnSourceEnum;
    /**
     *
     * @type {FilteringRuleENUM}
     * @memberof FilteringRule
     */
    'operator': FilteringRuleENUM;
    /**
     *
     * @type {string}
     * @memberof FilteringRule
     */
    'value': string;
}


/**
 *
 * @export
 * @enum {string}
 */

export const FilteringRuleENUM = {
    Is: 'IS',
    IsNot: 'IS_NOT',
    Contains: 'CONTAINS',
    DoesNotContain: 'DOES_NOT_CONTAIN',
    EndsWith: 'ENDS_WITH',
    IsEmpty: 'IS_EMPTY',
    IsNotEmpty: 'IS_NOT_EMPTY'
} as const;

export type FilteringRuleENUM = typeof FilteringRuleENUM[keyof typeof FilteringRuleENUM];


/**
 *
 * @export
 * @interface Financial
 */
export interface Financial {
    /**
     *
     * @type {string}
     * @memberof Financial
     */
    'field_name': string;
    /**
     *
     * @type {string}
     * @memberof Financial
     */
    'field_name_de': string;
    /**
     *
     * @type {number}
     * @memberof Financial
     */
    'value'?: number | null;
}
/**
 *
 * @export
 * @interface FloorCreateIN
 */
export interface FloorCreateIN {
    /**
     *
     * @type {string}
     * @memberof FloorCreateIN
     */
    'name': string;
    /**
     *
     * @type {number}
     * @memberof FloorCreateIN
     */
    'level': number;
    /**
     *
     * @type {string}
     * @memberof FloorCreateIN
     */
    'audit_id': string;
}
/**
 *
 * @export
 * @interface FloorOUT
 */
export interface FloorOUT {
    /**
     *
     * @type {string}
     * @memberof FloorOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof FloorOUT
     */
    'name': string;
    /**
     *
     * @type {number}
     * @memberof FloorOUT
     */
    'level': number;
    /**
     *
     * @type {string}
     * @memberof FloorOUT
     */
    'audit_id': string;
}
/**
 *
 * @export
 * @interface FloorUpdateIN
 */
export interface FloorUpdateIN {
    /**
     *
     * @type {string}
     * @memberof FloorUpdateIN
     */
    'name'?: string | null;
    /**
     *
     * @type {number}
     * @memberof FloorUpdateIN
     */
    'level'?: number | null;
    /**
     *
     * @type {string}
     * @memberof FloorUpdateIN
     */
    'audit_id'?: string | null;
}
/**
 *
 * @export
 * @interface FriendlyNameGroupOUT
 */
export interface FriendlyNameGroupOUT {
    /**
     *
     * @type {string}
     * @memberof FriendlyNameGroupOUT
     */
    'key': string;
    /**
     *
     * @type {string}
     * @memberof FriendlyNameGroupOUT
     */
    'name'?: string | null;
    /**
     *
     * @type {string}
     * @memberof FriendlyNameGroupOUT
     */
    'name_de'?: string | null;
    /**
     *
     * @type {Array<AvailableColumnsOUT>}
     * @memberof FriendlyNameGroupOUT
     */
    'columns': Array<AvailableColumnsOUT>;
}
/**
 *
 * @export
 * @interface GeneralInfo
 */
export interface GeneralInfo {
    /**
     *
     * @type {string}
     * @memberof GeneralInfo
     */
    'field_name'?: string | null;
    /**
     *
     * @type {string}
     * @memberof GeneralInfo
     */
    'field_name_de'?: string | null;
    /**
     *
     * @type {string}
     * @memberof GeneralInfo
     */
    'value'?: string | null;
}
/**
 *
 * @export
 * @interface GenerateAPIKeyOut
 */
export interface GenerateAPIKeyOut {
    /**
     *
     * @type {string}
     * @memberof GenerateAPIKeyOut
     */
    'token': string;
}
/**
 *
 * @export
 * @interface IFCFileOUT
 */
export interface IFCFileOUT {
    /**
     *
     * @type {string}
     * @memberof IFCFileOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof IFCFileOUT
     */
    'building_id': string;
    /**
     *
     * @type {string}
     * @memberof IFCFileOUT
     */
    'file'?: string | null;
    /**
     *
     * @type {string}
     * @memberof IFCFileOUT
     */
    'file_name'?: string;
    /**
     *
     * @type {string}
     * @memberof IFCFileOUT
     */
    'state': string;
    /**
     *
     * @type {string}
     * @memberof IFCFileOUT
     */
    'created': string;
    /**
     *
     * @type {UserProfileOUT}
     * @memberof IFCFileOUT
     */
    'uploaded_by'?: UserProfileOUT | null;
    /**
     *
     * @type {string}
     * @memberof IFCFileOUT
     */
    'file_type'?: string | null;
    /**
     *
     * @type {string}
     * @memberof IFCFileOUT
     */
    'reporting_status'?: string | null;
}
/**
 *
 * @export
 * @interface IFCProperties
 */
export interface IFCProperties {
    /**
     *
     * @type {Array<PropertySet>}
     * @memberof IFCProperties
     */
    'all_properties'?: Array<PropertySet>;
    /**
     *
     * @type {Array<ConcularProperty>}
     * @memberof IFCProperties
     */
    'concular_properties'?: Array<ConcularProperty>;
}
/**
 *
 * @export
 * @interface ImageAlbumOUT
 */
export interface ImageAlbumOUT {
    /**
     *
     * @type {string}
     * @memberof ImageAlbumOUT
     */
    'id': string;
    /**
     *
     * @type {Array<ImageOUT>}
     * @memberof ImageAlbumOUT
     */
    'images': Array<ImageOUT>;
}
/**
 *
 * @export
 * @interface ImageOUT
 */
export interface ImageOUT {
    /**
     *
     * @type {string}
     * @memberof ImageOUT
     */
    'id': string;
    /**
     *
     * @type {number}
     * @memberof ImageOUT
     */
    'order': number;
    /**
     *
     * @type {string}
     * @memberof ImageOUT
     */
    'image'?: string | null;
}
/**
 *
 * @export
 * @interface ImageUploadOUT
 */
export interface ImageUploadOUT {
    /**
     *
     * @type {string}
     * @memberof ImageUploadOUT
     */
    'id': string;
    /**
     *
     * @type {number}
     * @memberof ImageUploadOUT
     */
    'order': number;
    /**
     *
     * @type {string}
     * @memberof ImageUploadOUT
     */
    'image'?: string | null;
    /**
     *
     * @type {S3PresignedPostOUT}
     * @memberof ImageUploadOUT
     */
    'upload_url_config': S3PresignedPostOUT | null;
}
/**
 *
 * @export
 * @interface IngestionReportSchema
 */
export interface IngestionReportSchema {
    /**
     *
     * @type {string}
     * @memberof IngestionReportSchema
     */
    'source_type'?: string | null;
    /**
     *
     * @type {number}
     * @memberof IngestionReportSchema
     */
    'total_objects': number;
    /**
     *
     * @type {number}
     * @memberof IngestionReportSchema
     */
    'discarded_objects': number;
    /**
     *
     * @type {object}
     * @memberof IngestionReportSchema
     */
    'details'?: object | null;
}
/**
 *
 * @export
 * @interface Input
 */
export interface Input {
    /**
     *
     * @type {number}
     * @memberof Input
     */
    'limit'?: number;
    /**
     *
     * @type {number}
     * @memberof Input
     */
    'offset'?: number;
}
/**
 *
 * @export
 * @interface InstanceIN
 */
export interface InstanceIN {
    /**
     *
     * @type {string}
     * @memberof InstanceIN
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof InstanceIN
     */
    'name_de'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InstanceIN
     */
    'extra_info'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InstanceIN
     */
    'blueprint_id': string;
    /**
     *
     * @type {Array<PropertyFieldsSchema>}
     * @memberof InstanceIN
     */
    'instanceproperty_set': Array<PropertyFieldsSchema>;
    /**
     *
     * @type {number}
     * @memberof InstanceIN
     */
    'instance_amount': number;
    /**
     *
     * @type {string}
     * @memberof InstanceIN
     */
    'room_id'?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof InstanceIN
     */
    'floor_ids'?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof InstanceIN
     */
    'album_ids'?: Array<string>;
}
/**
 *
 * @export
 * @interface InstanceOUT
 */
export interface InstanceOUT {
    /**
     *
     * @type {string}
     * @memberof InstanceOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof InstanceOUT
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof InstanceOUT
     */
    'name_de'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InstanceOUT
     */
    'extra_info'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InstanceOUT
     */
    'blueprint_id': string;
    /**
     *
     * @type {string}
     * @memberof InstanceOUT
     */
    'created': string;
    /**
     *
     * @type {string}
     * @memberof InstanceOUT
     */
    'modified': string;
    /**
     *
     * @type {UserProfileOUT}
     * @memberof InstanceOUT
     */
    'created_by': UserProfileOUT | null;
    /**
     *
     * @type {Array<InstancePropertyOUT>}
     * @memberof InstanceOUT
     */
    'instanceproperty_set': Array<InstancePropertyOUT>;
    /**
     *
     * @type {number}
     * @memberof InstanceOUT
     */
    'instance_amount': number;
    /**
     *
     * @type {string}
     * @memberof InstanceOUT
     */
    'room_name'?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof InstanceOUT
     */
    'floor_ids'?: Array<string>;
}
/**
 *
 * @export
 * @interface InstancePropertyIN
 */
export interface InstancePropertyIN {
    /**
     *
     * @type {string}
     * @memberof InstancePropertyIN
     */
    'field_id': string;
    /**
     *
     * @type {string}
     * @memberof InstancePropertyIN
     */
    'value_string'?: string | null;
    /**
     *
     * @type {number}
     * @memberof InstancePropertyIN
     */
    'value_numeric'?: number | null;
    /**
     *
     * @type {string}
     * @memberof InstancePropertyIN
     */
    'value_datetime'?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof InstancePropertyIN
     */
    'value_field_option_ids'?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof InstancePropertyIN
     */
    'instance_record_id': string;
}
/**
 *
 * @export
 * @interface InstancePropertyOUT
 */
export interface InstancePropertyOUT {
    /**
     *
     * @type {string}
     * @memberof InstancePropertyOUT
     */
    'field_id': string;
    /**
     *
     * @type {string}
     * @memberof InstancePropertyOUT
     */
    'value_string'?: string | null;
    /**
     *
     * @type {number}
     * @memberof InstancePropertyOUT
     */
    'value_numeric'?: number | null;
    /**
     *
     * @type {string}
     * @memberof InstancePropertyOUT
     */
    'value_datetime'?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof InstancePropertyOUT
     */
    'value_field_option_ids'?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof InstancePropertyOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof InstancePropertyOUT
     */
    'instance_record_id': string;
    /**
     *
     * @type {string}
     * @memberof InstancePropertyOUT
     */
    'created': string;
    /**
     *
     * @type {string}
     * @memberof InstancePropertyOUT
     */
    'modified': string;
}
/**
 *
 * @export
 * @interface InstancePropertyUpdateIN
 */
export interface InstancePropertyUpdateIN {
    /**
     *
     * @type {string}
     * @memberof InstancePropertyUpdateIN
     */
    'instance_record_id'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InstancePropertyUpdateIN
     */
    'field_id'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InstancePropertyUpdateIN
     */
    'value_string'?: string | null;
    /**
     *
     * @type {number}
     * @memberof InstancePropertyUpdateIN
     */
    'value_numeric'?: number | null;
    /**
     *
     * @type {string}
     * @memberof InstancePropertyUpdateIN
     */
    'value_datetime'?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof InstancePropertyUpdateIN
     */
    'value_field_option_ids'?: Array<string>;
}
/**
 *
 * @export
 * @interface InstanceUpdateIN
 */
export interface InstanceUpdateIN {
    /**
     *
     * @type {string}
     * @memberof InstanceUpdateIN
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof InstanceUpdateIN
     */
    'name_de': string;
    /**
     *
     * @type {string}
     * @memberof InstanceUpdateIN
     */
    'extra_info': string;
    /**
     *
     * @type {string}
     * @memberof InstanceUpdateIN
     */
    'blueprint_id': string;
    /**
     *
     * @type {Array<PropertyFieldsSchema>}
     * @memberof InstanceUpdateIN
     */
    'instanceproperty_set': Array<PropertyFieldsSchema>;
    /**
     *
     * @type {number}
     * @memberof InstanceUpdateIN
     */
    'instance_amount': number;
    /**
     *
     * @type {string}
     * @memberof InstanceUpdateIN
     */
    'room_id': string;
    /**
     *
     * @type {Array<string>}
     * @memberof InstanceUpdateIN
     */
    'floor_ids': Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof InstanceUpdateIN
     */
    'album_ids': Array<string>;
}
/**
 *
 * @export
 * @interface InventoryAutomapperTagOUT
 */
export interface InventoryAutomapperTagOUT {
    /**
     *
     * @type {string}
     * @memberof InventoryAutomapperTagOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof InventoryAutomapperTagOUT
     */
    'name': string;
}
/**
 *
 * @export
 * @interface InventoryCategoryAggregateOut
 */
export interface InventoryCategoryAggregateOut {
    /**
     *
     * @type {string}
     * @memberof InventoryCategoryAggregateOut
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof InventoryCategoryAggregateOut
     */
    'material'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryCategoryAggregateOut
     */
    'component'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryCategoryAggregateOut
     */
    'ifc_entity'?: string | null;
    /**
     *
     * @type {Array<InventoryProductOut>}
     * @memberof InventoryCategoryAggregateOut
     */
    'products'?: Array<InventoryProductOut>;
    /**
     *
     * @type {string}
     * @memberof InventoryCategoryAggregateOut
     */
    'product_match_type'?: string | null;
    /**
     *
     * @type {number}
     * @memberof InventoryCategoryAggregateOut
     */
    'area'?: number | null;
    /**
     *
     * @type {number}
     * @memberof InventoryCategoryAggregateOut
     */
    'volume'?: number | null;
    /**
     *
     * @type {number}
     * @memberof InventoryCategoryAggregateOut
     */
    'length'?: number | null;
    /**
     *
     * @type {number}
     * @memberof InventoryCategoryAggregateOut
     */
    'width'?: number | null;
    /**
     *
     * @type {number}
     * @memberof InventoryCategoryAggregateOut
     */
    'height'?: number | null;
    /**
     *
     * @type {number}
     * @memberof InventoryCategoryAggregateOut
     */
    'gwp'?: number | null;
    /**
     *
     * @type {number}
     * @memberof InventoryCategoryAggregateOut
     */
    'mass'?: number | null;
    /**
     *
     * @type {number}
     * @memberof InventoryCategoryAggregateOut
     */
    'quantity'?: number | null;
    /**
     *
     * @type {number}
     * @memberof InventoryCategoryAggregateOut
     */
    'total_mapped'?: number | null;
    /**
     *
     * @type {number}
     * @memberof InventoryCategoryAggregateOut
     */
    'total_objects'?: number | null;
}
/**
 *
 * @export
 * @interface InventoryCategoryGroupIn
 */
export interface InventoryCategoryGroupIn {
    /**
     *
     * @type {string}
     * @memberof InventoryCategoryGroupIn
     */
    'component'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryCategoryGroupIn
     */
    'material'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryCategoryGroupIn
     */
    'ifc_entity'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryCategoryGroupIn
     */
    'mapped_status'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryCategoryGroupIn
     */
    'ifc_entity_filter'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryCategoryGroupIn
     */
    'material_filter'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryCategoryGroupIn
     */
    'component_filter'?: string | null;
}
/**
 *
 * @export
 * @interface InventoryCategoryMatchGroupIn
 */
export interface InventoryCategoryMatchGroupIn {
    /**
     *
     * @type {string}
     * @memberof InventoryCategoryMatchGroupIn
     */
    'component'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryCategoryMatchGroupIn
     */
    'material'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryCategoryMatchGroupIn
     */
    'ifc_entity'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryCategoryMatchGroupIn
     */
    'product_uuid'?: string | null;
}
/**
 *
 * @export
 * @interface InventoryCertificationOUT
 */
export interface InventoryCertificationOUT {
    /**
     *
     * @type {string}
     * @memberof InventoryCertificationOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof InventoryCertificationOUT
     */
    'name': string;
}
/**
 *
 * @export
 * @interface InventoryEPDForProductDetailsByFileOUT
 */
export interface InventoryEPDForProductDetailsByFileOUT {
    /**
     *
     * @type {string}
     * @memberof InventoryEPDForProductDetailsByFileOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof InventoryEPDForProductDetailsByFileOUT
     */
    'name_de'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryEPDForProductDetailsByFileOUT
     */
    'name_en'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryEPDForProductDetailsByFileOUT
     */
    'url'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryEPDForProductDetailsByFileOUT
     */
    'reference_unit': string;
    /**
     *
     * @type {number}
     * @memberof InventoryEPDForProductDetailsByFileOUT
     */
    'reference_size': number;
    /**
     *
     * @type {string}
     * @memberof InventoryEPDForProductDetailsByFileOUT
     */
    'default_material'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryEPDForProductDetailsByFileOUT
     */
    'dataset_source'?: string | null;
    /**
     *
     * @type {number}
     * @memberof InventoryEPDForProductDetailsByFileOUT
     */
    'mass_value_m'?: number | null;
    /**
     *
     * @type {number}
     * @memberof InventoryEPDForProductDetailsByFileOUT
     */
    'mass_value_m2'?: number | null;
    /**
     *
     * @type {number}
     * @memberof InventoryEPDForProductDetailsByFileOUT
     */
    'mass_value_m3'?: number | null;
}
/**
 *
 * @export
 * @interface InventoryEPDGroupOUT
 */
export interface InventoryEPDGroupOUT {
    /**
     *
     * @type {string}
     * @memberof InventoryEPDGroupOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof InventoryEPDGroupOUT
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof InventoryEPDGroupOUT
     */
    'conformity'?: string | null;
    /**
     *
     * @type {Array<InventoryCertificationOUT>}
     * @memberof InventoryEPDGroupOUT
     */
    'certifications'?: Array<InventoryCertificationOUT>;
    /**
     *
     * @type {Array<InventoryProductEPDForProductDetailsByFileOUT>}
     * @memberof InventoryEPDGroupOUT
     */
    'epds': Array<InventoryProductEPDForProductDetailsByFileOUT>;
}
/**
 *
 * @export
 * @interface InventoryEPDMatchOut
 */
export interface InventoryEPDMatchOut {
    /**
     *
     * @type {string}
     * @memberof InventoryEPDMatchOut
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof InventoryEPDMatchOut
     */
    'name_de'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryEPDMatchOut
     */
    'name_en'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryEPDMatchOut
     */
    'reference_unit': string;
    /**
     *
     * @type {number}
     * @memberof InventoryEPDMatchOut
     */
    'reference_size': number;
    /**
     *
     * @type {string}
     * @memberof InventoryEPDMatchOut
     */
    'version': string;
    /**
     *
     * @type {string}
     * @memberof InventoryEPDMatchOut
     */
    'url': string;
    /**
     *
     * @type {Category}
     * @memberof InventoryEPDMatchOut
     */
    'category'?: Category | null;
    /**
     *
     * @type {string}
     * @memberof InventoryEPDMatchOut
     */
    'dataset_source'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryEPDMatchOut
     */
    'conformity'?: string | null;
}
/**
 *
 * @export
 * @interface InventoryEPDOut
 */
export interface InventoryEPDOut {
    /**
     *
     * @type {string}
     * @memberof InventoryEPDOut
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof InventoryEPDOut
     */
    'name_de'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryEPDOut
     */
    'name_en'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryEPDOut
     */
    'reference_unit': string;
    /**
     *
     * @type {number}
     * @memberof InventoryEPDOut
     */
    'reference_size': number;
    /**
     *
     * @type {string}
     * @memberof InventoryEPDOut
     */
    'version': string;
    /**
     *
     * @type {string}
     * @memberof InventoryEPDOut
     */
    'url'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryEPDOut
     */
    'source': string;
    /**
     *
     * @type {Category}
     * @memberof InventoryEPDOut
     */
    'category': Category | null;
    /**
     *
     * @type {number}
     * @memberof InventoryEPDOut
     */
    'life_span'?: number;
    /**
     *
     * @type {Manufacturer}
     * @memberof InventoryEPDOut
     */
    'manufacturer': Manufacturer | null;
}
/**
 *
 * @export
 * @interface InventoryFileOUT
 */
export interface InventoryFileOUT {
    /**
     *
     * @type {string}
     * @memberof InventoryFileOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof InventoryFileOUT
     */
    'building_id': string;
    /**
     *
     * @type {string}
     * @memberof InventoryFileOUT
     */
    'file'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryFileOUT
     */
    'file_processed_xkt'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryFileOUT
     */
    'file_name'?: string;
    /**
     *
     * @type {number}
     * @memberof InventoryFileOUT
     */
    'total_unique_material_names'?: number;
    /**
     *
     * @type {number}
     * @memberof InventoryFileOUT
     */
    'total_no_of_mapped_material_names'?: number;
    /**
     *
     * @type {string}
     * @memberof InventoryFileOUT
     */
    'state': string;
    /**
     *
     * @type {string}
     * @memberof InventoryFileOUT
     */
    'created': string;
    /**
     *
     * @type {UserProfileOUT}
     * @memberof InventoryFileOUT
     */
    'uploaded_by'?: UserProfileOUT | null;
    /**
     *
     * @type {string}
     * @memberof InventoryFileOUT
     */
    'file_type'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryFileOUT
     */
    'reporting_status'?: string | null;
}
/**
 *
 * @export
 * @interface InventoryListObjectsIdIn
 */
export interface InventoryListObjectsIdIn {
    /**
     *
     * @type {Array<string>}
     * @memberof InventoryListObjectsIdIn
     */
    'objects': Array<string>;
}
/**
 *
 * @export
 * @interface InventoryPhysicalObjectDetailOut
 */
export interface InventoryPhysicalObjectDetailOut {
    /**
     *
     * @type {string}
     * @memberof InventoryPhysicalObjectDetailOut
     */
    'id': string;
    /**
     *
     * @type {number}
     * @memberof InventoryPhysicalObjectDetailOut
     */
    'quantity'?: number | null;
    /**
     *
     * @type {string}
     * @memberof InventoryPhysicalObjectDetailOut
     */
    'material'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryPhysicalObjectDetailOut
     */
    'component'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryPhysicalObjectDetailOut
     */
    'ifc_component_name_original'?: string | null;
    /**
     *
     * @type {number}
     * @memberof InventoryPhysicalObjectDetailOut
     */
    'area'?: number | null;
    /**
     *
     * @type {number}
     * @memberof InventoryPhysicalObjectDetailOut
     */
    'volume'?: number | null;
    /**
     *
     * @type {number}
     * @memberof InventoryPhysicalObjectDetailOut
     */
    'length'?: number | null;
    /**
     *
     * @type {number}
     * @memberof InventoryPhysicalObjectDetailOut
     */
    'width'?: number | null;
    /**
     *
     * @type {number}
     * @memberof InventoryPhysicalObjectDetailOut
     */
    'height'?: number | null;
    /**
     *
     * @type {number}
     * @memberof InventoryPhysicalObjectDetailOut
     */
    'gwp'?: number | null;
    /**
     *
     * @type {number}
     * @memberof InventoryPhysicalObjectDetailOut
     */
    'mass'?: number | null;
    /**
     *
     * @type {string}
     * @memberof InventoryPhysicalObjectDetailOut
     */
    'ifc_entity'?: string | null;
    /**
     *
     * @type {InventoryProductOut}
     * @memberof InventoryPhysicalObjectDetailOut
     */
    'product_match'?: InventoryProductOut | null;
    /**
     *
     * @type {string}
     * @memberof InventoryPhysicalObjectDetailOut
     */
    'product_match_type'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryPhysicalObjectDetailOut
     */
    'layer_position': string;
    /**
     *
     * @type {number}
     * @memberof InventoryPhysicalObjectDetailOut
     */
    'reusability_score'?: number | null;
    /**
     *
     * @type {string}
     * @memberof InventoryPhysicalObjectDetailOut
     */
    'reusability_qualification'?: string | null;
    /**
     *
     * @type {number}
     * @memberof InventoryPhysicalObjectDetailOut
     */
    'separability_score'?: number | null;
    /**
     *
     * @type {string}
     * @memberof InventoryPhysicalObjectDetailOut
     */
    'separability_qualification'?: string | null;
    /**
     *
     * @type {number}
     * @memberof InventoryPhysicalObjectDetailOut
     */
    'deconstructability_score'?: number | null;
    /**
     *
     * @type {string}
     * @memberof InventoryPhysicalObjectDetailOut
     */
    'deconstructability_qualification'?: string | null;
    /**
     *
     * @type {number}
     * @memberof InventoryPhysicalObjectDetailOut
     */
    'preuse_score'?: number | null;
    /**
     *
     * @type {string}
     * @memberof InventoryPhysicalObjectDetailOut
     */
    'preuse_qualification'?: string | null;
    /**
     *
     * @type {object}
     * @memberof InventoryPhysicalObjectDetailOut
     */
    'extra_properties'?: object | null;
}
/**
 *
 * @export
 * @interface InventoryPhysicalObjectOut
 */
export interface InventoryPhysicalObjectOut {
    /**
     *
     * @type {string}
     * @memberof InventoryPhysicalObjectOut
     */
    'id': string;
    /**
     *
     * @type {number}
     * @memberof InventoryPhysicalObjectOut
     */
    'quantity'?: number | null;
    /**
     *
     * @type {string}
     * @memberof InventoryPhysicalObjectOut
     */
    'material'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryPhysicalObjectOut
     */
    'component'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryPhysicalObjectOut
     */
    'ifc_component_name_original'?: string | null;
    /**
     *
     * @type {number}
     * @memberof InventoryPhysicalObjectOut
     */
    'area'?: number | null;
    /**
     *
     * @type {number}
     * @memberof InventoryPhysicalObjectOut
     */
    'volume'?: number | null;
    /**
     *
     * @type {number}
     * @memberof InventoryPhysicalObjectOut
     */
    'length'?: number | null;
    /**
     *
     * @type {number}
     * @memberof InventoryPhysicalObjectOut
     */
    'width'?: number | null;
    /**
     *
     * @type {number}
     * @memberof InventoryPhysicalObjectOut
     */
    'height'?: number | null;
    /**
     *
     * @type {number}
     * @memberof InventoryPhysicalObjectOut
     */
    'gwp'?: number | null;
    /**
     *
     * @type {number}
     * @memberof InventoryPhysicalObjectOut
     */
    'mass'?: number | null;
    /**
     *
     * @type {string}
     * @memberof InventoryPhysicalObjectOut
     */
    'ifc_entity'?: string | null;
    /**
     *
     * @type {InventoryProductOut}
     * @memberof InventoryPhysicalObjectOut
     */
    'product_match'?: InventoryProductOut | null;
    /**
     *
     * @type {string}
     * @memberof InventoryPhysicalObjectOut
     */
    'product_match_type'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryPhysicalObjectOut
     */
    'layer_position': string;
    /**
     *
     * @type {number}
     * @memberof InventoryPhysicalObjectOut
     */
    'reusability_score'?: number | null;
    /**
     *
     * @type {string}
     * @memberof InventoryPhysicalObjectOut
     */
    'reusability_qualification'?: string | null;
    /**
     *
     * @type {number}
     * @memberof InventoryPhysicalObjectOut
     */
    'separability_score'?: number | null;
    /**
     *
     * @type {string}
     * @memberof InventoryPhysicalObjectOut
     */
    'separability_qualification'?: string | null;
    /**
     *
     * @type {number}
     * @memberof InventoryPhysicalObjectOut
     */
    'deconstructability_score'?: number | null;
    /**
     *
     * @type {string}
     * @memberof InventoryPhysicalObjectOut
     */
    'deconstructability_qualification'?: string | null;
    /**
     *
     * @type {number}
     * @memberof InventoryPhysicalObjectOut
     */
    'preuse_score'?: number | null;
    /**
     *
     * @type {string}
     * @memberof InventoryPhysicalObjectOut
     */
    'preuse_qualification'?: string | null;
}
/**
 *
 * @export
 * @interface InventoryProductDetailsByFileOut
 */
export interface InventoryProductDetailsByFileOut {
    /**
     *
     * @type {string}
     * @memberof InventoryProductDetailsByFileOut
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof InventoryProductDetailsByFileOut
     */
    'name'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryProductDetailsByFileOut
     */
    'din_category_level_1'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryProductDetailsByFileOut
     */
    'din_category_level_2'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryProductDetailsByFileOut
     */
    'din_category_level_3'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryProductDetailsByFileOut
     */
    'din_category_level_1_number'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryProductDetailsByFileOut
     */
    'din_category_level_2_number'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryProductDetailsByFileOut
     */
    'din_category_level_3_number'?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof InventoryProductDetailsByFileOut
     */
    'is_compliant_by_file': boolean;
    /**
     *
     * @type {string}
     * @memberof InventoryProductDetailsByFileOut
     */
    'selected_epd_group_id'?: string | null;
    /**
     *
     * @type {number}
     * @memberof InventoryProductDetailsByFileOut
     */
    'life_span'?: number;
    /**
     *
     * @type {boolean}
     * @memberof InventoryProductDetailsByFileOut
     */
    'is_external'?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof InventoryProductDetailsByFileOut
     */
    'is_load_bearing'?: boolean | null;
    /**
     *
     * @type {Array<InventoryEPDGroupOUT>}
     * @memberof InventoryProductDetailsByFileOut
     */
    'epd_groups': Array<InventoryEPDGroupOUT>;
    /**
     *
     * @type {ComplianceOUT}
     * @memberof InventoryProductDetailsByFileOut
     */
    'compliance'?: ComplianceOUT | null;
    /**
     *
     * @type {Array<string>}
     * @memberof InventoryProductDetailsByFileOut
     */
    'automapping_tags'?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof InventoryProductDetailsByFileOut
     */
    'reference_unit': string;
}
/**
 *
 * @export
 * @interface InventoryProductEPDForProductDetailsByFileOUT
 */
export interface InventoryProductEPDForProductDetailsByFileOUT {
    /**
     *
     * @type {string}
     * @memberof InventoryProductEPDForProductDetailsByFileOUT
     */
    'id': string;
    /**
     *
     * @type {number}
     * @memberof InventoryProductEPDForProductDetailsByFileOUT
     */
    'factor'?: number | null;
    /**
     *
     * @type {string}
     * @memberof InventoryProductEPDForProductDetailsByFileOUT
     */
    'material': string;
    /**
     *
     * @type {InventoryEPDForProductDetailsByFileOUT}
     * @memberof InventoryProductEPDForProductDetailsByFileOUT
     */
    'epd': InventoryEPDForProductDetailsByFileOUT;
    /**
     *
     * @type {number}
     * @memberof InventoryProductEPDForProductDetailsByFileOUT
     */
    'material_recyclability_score'?: number | null;
    /**
     *
     * @type {string}
     * @memberof InventoryProductEPDForProductDetailsByFileOUT
     */
    'material_recyclability_qualification'?: string | null;
}
/**
 *
 * @export
 * @interface InventoryProductEPDOut
 */
export interface InventoryProductEPDOut {
    /**
     *
     * @type {number}
     * @memberof InventoryProductEPDOut
     */
    'ratio': number;
    /**
     *
     * @type {InventoryEPDMatchOut}
     * @memberof InventoryProductEPDOut
     */
    'epd': InventoryEPDMatchOut;
    /**
     *
     * @type {string}
     * @memberof InventoryProductEPDOut
     */
    'material'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InventoryProductEPDOut
     */
    'material_id'?: string | null;
}
/**
 *
 * @export
 * @interface InventoryProductOut
 */
export interface InventoryProductOut {
    /**
     *
     * @type {string}
     * @memberof InventoryProductOut
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof InventoryProductOut
     */
    'name': string | null;
    /**
     *
     * @type {Array<InventoryProductEPDOut>}
     * @memberof InventoryProductOut
     */
    'epds': Array<InventoryProductEPDOut>;
    /**
     *
     * @type {string}
     * @memberof InventoryProductOut
     */
    'din_category'?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof InventoryProductOut
     */
    'is_external'?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof InventoryProductOut
     */
    'is_load_bearing'?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof InventoryProductOut
     */
    'separability_qualification'?: string | null;
    /**
     *
     * @type {number}
     * @memberof InventoryProductOut
     */
    'separability_score'?: number | null;
    /**
     *
     * @type {string}
     * @memberof InventoryProductOut
     */
    'deconstructability_qualification'?: string | null;
    /**
     *
     * @type {number}
     * @memberof InventoryProductOut
     */
    'deconstructability_score'?: number | null;
    /**
     *
     * @type {string}
     * @memberof InventoryProductOut
     */
    'recyclability_qualification'?: string | null;
    /**
     *
     * @type {number}
     * @memberof InventoryProductOut
     */
    'recyclability_score'?: number | null;
    /**
     *
     * @type {string}
     * @memberof InventoryProductOut
     */
    'preuse_qualification'?: string | null;
    /**
     *
     * @type {number}
     * @memberof InventoryProductOut
     */
    'preuse_score'?: number | null;
    /**
     *
     * @type {string}
     * @memberof InventoryProductOut
     */
    'reusability_qualification'?: string | null;
    /**
     *
     * @type {number}
     * @memberof InventoryProductOut
     */
    'reusability_score'?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof InventoryProductOut
     */
    'is_compliant_by_file'?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof InventoryProductOut
     */
    'selected_epd_group_id'?: string | null;
    /**
     *
     * @type {Array<InventoryAutomapperTagOUT>}
     * @memberof InventoryProductOut
     */
    'tags_automapper'?: Array<InventoryAutomapperTagOUT>;
    /**
     *
     * @type {string}
     * @memberof InventoryProductOut
     */
    'availability': string;
    /**
     *
     * @type {string}
     * @memberof InventoryProductOut
     */
    'type': string;
    /**
     *
     * @type {string}
     * @memberof InventoryProductOut
     */
    'reference_unit': string;
}
/**
 *
 * @export
 * @interface LCAIndicatorValue
 */
export interface LCAIndicatorValue {
    /**
     *
     * @type {string}
     * @memberof LCAIndicatorValue
     */
    'indicator_name': string;
    /**
     *
     * @type {number}
     * @memberof LCAIndicatorValue
     */
    'value'?: number | null;
    /**
     *
     * @type {string}
     * @memberof LCAIndicatorValue
     */
    'unit'?: string | null;
    /**
     *
     * @type {Array<LCAIndicatorValueModule>}
     * @memberof LCAIndicatorValue
     */
    'modules'?: Array<LCAIndicatorValueModule>;
}
/**
 *
 * @export
 * @interface LCAIndicatorValueModule
 */
export interface LCAIndicatorValueModule {
    /**
     *
     * @type {string}
     * @memberof LCAIndicatorValueModule
     */
    'module_name': string;
    /**
     *
     * @type {number}
     * @memberof LCAIndicatorValueModule
     */
    'value'?: number | null;
    /**
     *
     * @type {string}
     * @memberof LCAIndicatorValueModule
     */
    'unit'?: string | null;
}
/**
 *
 * @export
 * @interface LatestReportOUT
 */
export interface LatestReportOUT {
    /**
     *
     * @type {ReportOUT}
     * @memberof LatestReportOUT
     */
    'report'?: ReportOUT | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export const LogicalOperatorENUM = {
    And: 'AND',
    Or: 'OR'
} as const;

export type LogicalOperatorENUM = typeof LogicalOperatorENUM[keyof typeof LogicalOperatorENUM];


/**
 *
 * @export
 * @interface Manufacturer
 */
export interface Manufacturer {
    /**
     *
     * @type {string}
     * @memberof Manufacturer
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof Manufacturer
     */
    'name': string;
}
/**
 *
 * @export
 * @interface ManufacturerOUT
 */
export interface ManufacturerOUT {
    /**
     *
     * @type {string}
     * @memberof ManufacturerOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof ManufacturerOUT
     */
    'name': string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const MatchCompliance = {
    Ok: 'OK',
    DoesNot: 'DOES_NOT',
    Error: 'ERROR'
} as const;

export type MatchCompliance = typeof MatchCompliance[keyof typeof MatchCompliance];


/**
 *
 * @export
 * @interface MatchObjectsIN
 */
export interface MatchObjectsIN {
    /**
     *
     * @type {string}
     * @memberof MatchObjectsIN
     */
    'product_id'?: string | null;
    /**
     *
     * @type {Array<ColumnToDisplay>}
     * @memberof MatchObjectsIN
     */
    'columns_to_display': Array<ColumnToDisplay>;
    /**
     *
     * @type {Array<string>}
     * @memberof MatchObjectsIN
     */
    'object_ids': Array<string>;
}
/**
 *
 * @export
 * @enum {string}
 */

export const MatchType = {
    Manual: 'manual',
    Auto: 'auto',
    Pre: 'pre'
} as const;

export type MatchType = typeof MatchType[keyof typeof MatchType];


/**
 *
 * @export
 * @interface MaterialOUT
 */
export interface MaterialOUT {
    /**
     *
     * @type {string}
     * @memberof MaterialOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof MaterialOUT
     */
    'name'?: string | null;
    /**
     *
     * @type {string}
     * @memberof MaterialOUT
     */
    'category_dgnb'?: string;
    /**
     *
     * @type {string}
     * @memberof MaterialOUT
     */
    'category_origin'?: string;
    /**
     *
     * @type {string}
     * @memberof MaterialOUT
     */
    'category_general'?: string;
    /**
     *
     * @type {number}
     * @memberof MaterialOUT
     */
    'recyclability_score'?: number | null;
    /**
     *
     * @type {string}
     * @memberof MaterialOUT
     */
    'recyclability_qualification'?: string | null;
}
/**
 *
 * @export
 * @interface MaterialResourceOUT
 */
export interface MaterialResourceOUT {
    /**
     *
     * @type {string}
     * @memberof MaterialResourceOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof MaterialResourceOUT
     */
    'building_id': string;
    /**
     *
     * @type {string}
     * @memberof MaterialResourceOUT
     */
    'file'?: string | null;
    /**
     *
     * @type {string}
     * @memberof MaterialResourceOUT
     */
    'file_processed_xkt'?: string | null;
    /**
     *
     * @type {string}
     * @memberof MaterialResourceOUT
     */
    'name'?: string | null;
    /**
     *
     * @type {string}
     * @memberof MaterialResourceOUT
     */
    'file_name'?: string;
    /**
     *
     * @type {number}
     * @memberof MaterialResourceOUT
     */
    'total_unique_material_names'?: number;
    /**
     *
     * @type {number}
     * @memberof MaterialResourceOUT
     */
    'total_no_of_mapped_material_names'?: number;
    /**
     *
     * @type {IngestionReportSchema}
     * @memberof MaterialResourceOUT
     */
    'report'?: IngestionReportSchema | null;
    /**
     *
     * @type {string}
     * @memberof MaterialResourceOUT
     */
    'state'?: string | null;
    /**
     *
     * @type {string}
     * @memberof MaterialResourceOUT
     */
    'created': string;
    /**
     *
     * @type {UserProfileOUT}
     * @memberof MaterialResourceOUT
     */
    'uploaded_by'?: UserProfileOUT | null;
    /**
     *
     * @type {UserProfileOUT}
     * @memberof MaterialResourceOUT
     */
    'created_by'?: UserProfileOUT | null;
    /**
     *
     * @type {string}
     * @memberof MaterialResourceOUT
     */
    'file_type'?: string | null;
    /**
     *
     * @type {string}
     * @memberof MaterialResourceOUT
     */
    'title'?: string | null;
    /**
     *
     * @type {string}
     * @memberof MaterialResourceOUT
     */
    'reporting_status'?: string | null;
}
/**
 *
 * @export
 * @interface MaterialSearchIN
 */
export interface MaterialSearchIN {
    /**
     *
     * @type {string}
     * @memberof MaterialSearchIN
     */
    'term'?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MaterialSearchIN
     */
    'category_general'?: Array<string>;
}
/**
 *
 * @export
 * @interface MaterialSearchOUT
 */
export interface MaterialSearchOUT {
    /**
     *
     * @type {string}
     * @memberof MaterialSearchOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof MaterialSearchOUT
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof MaterialSearchOUT
     */
    'codename': string;
    /**
     *
     * @type {string}
     * @memberof MaterialSearchOUT
     */
    'category_dgnb'?: string | null;
    /**
     *
     * @type {string}
     * @memberof MaterialSearchOUT
     */
    'category_origin'?: string | null;
    /**
     *
     * @type {string}
     * @memberof MaterialSearchOUT
     */
    'category_general__name'?: string | null;
    /**
     *
     * @type {string}
     * @memberof MaterialSearchOUT
     */
    'category_general__name_de'?: string | null;
    /**
     *
     * @type {number}
     * @memberof MaterialSearchOUT
     */
    'life_span'?: number | null;
    /**
     *
     * @type {number}
     * @memberof MaterialSearchOUT
     */
    'recyclability_score': number;
    /**
     *
     * @type {string}
     * @memberof MaterialSearchOUT
     */
    'recyclability_qualification'?: string | null;
    /**
     *
     * @type {string}
     * @memberof MaterialSearchOUT
     */
    'dataset_source'?: string | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export const MaterialSearchSortBy = {
    Name: 'name',
    CategoryGeneralName: 'category_general__name',
    CategoryGeneralNameDe: 'category_general__name_de',
    RecyclabilityScore: 'recyclability_score',
    RecyclabilityQualification: 'recyclability_qualification'
} as const;

export type MaterialSearchSortBy = typeof MaterialSearchSortBy[keyof typeof MaterialSearchSortBy];


/**
 *
 * @export
 * @interface ObjectListIN
 */
export interface ObjectListIN {
    /**
     *
     * @type {Filtering}
     * @memberof ObjectListIN
     */
    'filtering'?: Filtering | null;
    /**
     *
     * @type {boolean}
     * @memberof ObjectListIN
     */
    'retrieve_ids'?: boolean;
    /**
     *
     * @type {Array<ColumnToDisplay>}
     * @memberof ObjectListIN
     */
    'columns_to_display': Array<ColumnToDisplay>;
    /**
     *
     * @type {Array<Sorting>}
     * @memberof ObjectListIN
     */
    'sorting'?: Array<Sorting>;
}
/**
 *
 * @export
 * @interface ObjectListOUT
 */
export interface ObjectListOUT {
    /**
     *
     * @type {Array<SingleObject>}
     * @memberof ObjectListOUT
     */
    'items': Array<SingleObject>;
    /**
     *
     * @type {number}
     * @memberof ObjectListOUT
     */
    'count': number;
}
/**
 *
 * @export
 * @interface ObjectOperationIN
 */
export interface ObjectOperationIN {
    /**
     *
     * @type {OperationType}
     * @memberof ObjectOperationIN
     */
    'operation_type': OperationType;
    /**
     *
     * @type {string}
     * @memberof ObjectOperationIN
     */
    'product_uuid'?: string | null;
    /**
     *
     * @type {Array<ColumnToDisplay>}
     * @memberof ObjectOperationIN
     */
    'columns_to_display': Array<ColumnToDisplay>;
    /**
     *
     * @type {Array<string>}
     * @memberof ObjectOperationIN
     */
    'object_ids': Array<string>;
}


/**
 *
 * @export
 * @interface ObjectOperationOUT
 */
export interface ObjectOperationOUT {
    /**
     *
     * @type {Array<SingleObject>}
     * @memberof ObjectOperationOUT
     */
    'items': Array<SingleObject>;
}
/**
 *
 * @export
 * @interface ObjectProductMatch
 */
export interface ObjectProductMatch {
    /**
     *
     * @type {string}
     * @memberof ObjectProductMatch
     */
    'id': string | null;
    /**
     *
     * @type {string}
     * @memberof ObjectProductMatch
     */
    'name': string | null;
    /**
     *
     * @type {MatchType}
     * @memberof ObjectProductMatch
     */
    'match_type': MatchType | null;
    /**
     *
     * @type {MatchCompliance}
     * @memberof ObjectProductMatch
     */
    'compliance': MatchCompliance | null;
}


/**
 *
 * @export
 * @interface ObjectsFilterIN
 */
export interface ObjectsFilterIN {
    /**
     *
     * @type {Filtering}
     * @memberof ObjectsFilterIN
     */
    'filtering'?: Filtering | null;
    /**
     *
     * @type {boolean}
     * @memberof ObjectsFilterIN
     */
    'retrieve_ids'?: boolean;
}
/**
 *
 * @export
 * @interface ObjectsFilterOUT
 */
export interface ObjectsFilterOUT {
    /**
     *
     * @type {number}
     * @memberof ObjectsFilterOUT
     */
    'result_count': number;
    /**
     *
     * @type {Array<number>}
     * @memberof ObjectsFilterOUT
     */
    'serial_ids'?: Array<number>;
}
/**
 *
 * @export
 * @enum {string}
 */

export const OperationType = {
    ManualMatch: 'manual_match',
    AutoMapMatch: 'auto_map_match',
    UndoMatch: 'undo_match'
} as const;

export type OperationType = typeof OperationType[keyof typeof OperationType];


/**
 *
 * @export
 * @interface OperationalEnergyReportSubmitOUT
 */
export interface OperationalEnergyReportSubmitOUT {
    /**
     *
     * @type {string}
     * @memberof OperationalEnergyReportSubmitOUT
     */
    'report_id': string;
}
/**
 *
 * @export
 * @interface OrganisationAccessRequestOUT
 */
export interface OrganisationAccessRequestOUT {
    /**
     *
     * @type {number}
     * @memberof OrganisationAccessRequestOUT
     */
    'status'?: number | null;
    /**
     *
     * @type {string}
     * @memberof OrganisationAccessRequestOUT
     */
    'organisation_id': string;
    /**
     *
     * @type {string}
     * @memberof OrganisationAccessRequestOUT
     */
    'modified': string;
}
/**
 *
 * @export
 * @interface OrganisationDomainIN
 */
export interface OrganisationDomainIN {
    /**
     *
     * @type {string}
     * @memberof OrganisationDomainIN
     */
    'organisation_id': string;
    /**
     *
     * @type {string}
     * @memberof OrganisationDomainIN
     */
    'domain': string;
}
/**
 *
 * @export
 * @interface OrganisationDomainOUT
 */
export interface OrganisationDomainOUT {
    /**
     *
     * @type {string}
     * @memberof OrganisationDomainOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof OrganisationDomainOUT
     */
    'domain': string;
    /**
     *
     * @type {OrganisationOUT}
     * @memberof OrganisationDomainOUT
     */
    'organisation': OrganisationOUT;
}
/**
 *
 * @export
 * @interface OrganisationIN
 */
export interface OrganisationIN {
    /**
     *
     * @type {string}
     * @memberof OrganisationIN
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof OrganisationIN
     */
    'organisation_type_id': string;
}
/**
 *
 * @export
 * @interface OrganisationInviteeIN
 */
export interface OrganisationInviteeIN {
    /**
     *
     * @type {string}
     * @memberof OrganisationInviteeIN
     */
    'email': string;
    /**
     *
     * @type {number}
     * @memberof OrganisationInviteeIN
     */
    'role'?: number;
}
/**
 *
 * @export
 * @interface OrganisationInviteeOUT
 */
export interface OrganisationInviteeOUT {
    /**
     *
     * @type {string}
     * @memberof OrganisationInviteeOUT
     */
    'id': string;
    /**
     *
     * @type {number}
     * @memberof OrganisationInviteeOUT
     */
    'role': number;
    /**
     *
     * @type {string}
     * @memberof OrganisationInviteeOUT
     */
    'organisation_id': string;
}
/**
 *
 * @export
 * @interface OrganisationOUT
 */
export interface OrganisationOUT {
    /**
     *
     * @type {string}
     * @memberof OrganisationOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof OrganisationOUT
     */
    'name': string;
    /**
     *
     * @type {OrganisationTypeOUT}
     * @memberof OrganisationOUT
     */
    'organisation_type': OrganisationTypeOUT;
    /**
     *
     * @type {string}
     * @memberof OrganisationOUT
     */
    'legal_name'?: string | null;
    /**
     *
     * @type {string}
     * @memberof OrganisationOUT
     */
    'address'?: string | null;
    /**
     *
     * @type {string}
     * @memberof OrganisationOUT
     */
    'phone'?: string | null;
    /**
     *
     * @type {number}
     * @memberof OrganisationOUT
     */
    'zip_code'?: number | null;
    /**
     *
     * @type {string}
     * @memberof OrganisationOUT
     */
    'city'?: string | null;
    /**
     *
     * @type {string}
     * @memberof OrganisationOUT
     */
    'logo'?: string | null;
    /**
     *
     * @type {number}
     * @memberof OrganisationOUT
     */
    'role'?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof OrganisationOUT
     */
    'can_create_buildings'?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof OrganisationOUT
     */
    'max_building_entry'?: number | null;
}
/**
 *
 * @export
 * @interface OrganisationRequestAcceptIN
 */
export interface OrganisationRequestAcceptIN {
    /**
     *
     * @type {string}
     * @memberof OrganisationRequestAcceptIN
     */
    'id': string;
}
/**
 *
 * @export
 * @interface OrganisationRequestAllOUT
 */
export interface OrganisationRequestAllOUT {
    /**
     *
     * @type {string}
     * @memberof OrganisationRequestAllOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof OrganisationRequestAllOUT
     */
    'email'?: string | null;
    /**
     *
     * @type {number}
     * @memberof OrganisationRequestAllOUT
     */
    'role': number;
    /**
     *
     * @type {number}
     * @memberof OrganisationRequestAllOUT
     */
    'status'?: number | null;
    /**
     *
     * @type {string}
     * @memberof OrganisationRequestAllOUT
     */
    'modified': string;
    /**
     *
     * @type {string}
     * @memberof OrganisationRequestAllOUT
     */
    'organisation_id': string;
    /**
     *
     * @type {UserProfileOUT}
     * @memberof OrganisationRequestAllOUT
     */
    'user'?: UserProfileOUT | null;
    /**
     *
     * @type {UserProfileOUT}
     * @memberof OrganisationRequestAllOUT
     */
    'invited_by'?: UserProfileOUT | null;
}
/**
 *
 * @export
 * @interface OrganisationTypeOUT
 */
export interface OrganisationTypeOUT {
    /**
     *
     * @type {string}
     * @memberof OrganisationTypeOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof OrganisationTypeOUT
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof OrganisationTypeOUT
     */
    'name_de': string;
}
/**
 *
 * @export
 * @interface OrganisationUpdateIN
 */
export interface OrganisationUpdateIN {
    /**
     *
     * @type {string}
     * @memberof OrganisationUpdateIN
     */
    'name'?: string | null;
    /**
     *
     * @type {string}
     * @memberof OrganisationUpdateIN
     */
    'organisation_type_id'?: string | null;
    /**
     *
     * @type {string}
     * @memberof OrganisationUpdateIN
     */
    'legal_name'?: string | null;
    /**
     *
     * @type {string}
     * @memberof OrganisationUpdateIN
     */
    'phone'?: string | null;
    /**
     *
     * @type {string}
     * @memberof OrganisationUpdateIN
     */
    'address'?: string | null;
    /**
     *
     * @type {string}
     * @memberof OrganisationUpdateIN
     */
    'zip_code'?: string | null;
    /**
     *
     * @type {string}
     * @memberof OrganisationUpdateIN
     */
    'city'?: string | null;
}
/**
 *
 * @export
 * @interface PagedAWSLocationOUT
 */
export interface PagedAWSLocationOUT {
    /**
     *
     * @type {Array<AWSLocationOUT>}
     * @memberof PagedAWSLocationOUT
     */
    'items': Array<AWSLocationOUT>;
    /**
     *
     * @type {number}
     * @memberof PagedAWSLocationOUT
     */
    'count': number;
}
/**
 *
 * @export
 * @interface PagedBlueprintPropertyOUT
 */
export interface PagedBlueprintPropertyOUT {
    /**
     *
     * @type {Array<BlueprintPropertyOUT>}
     * @memberof PagedBlueprintPropertyOUT
     */
    'items': Array<BlueprintPropertyOUT>;
    /**
     *
     * @type {number}
     * @memberof PagedBlueprintPropertyOUT
     */
    'count': number;
}
/**
 *
 * @export
 * @interface PagedBuildingMemberOUT
 */
export interface PagedBuildingMemberOUT {
    /**
     *
     * @type {Array<BuildingMemberOUT>}
     * @memberof PagedBuildingMemberOUT
     */
    'items': Array<BuildingMemberOUT>;
    /**
     *
     * @type {number}
     * @memberof PagedBuildingMemberOUT
     */
    'count': number;
}
/**
 *
 * @export
 * @interface PagedBuildingOUT
 */
export interface PagedBuildingOUT {
    /**
     *
     * @type {Array<BuildingOUT>}
     * @memberof PagedBuildingOUT
     */
    'items': Array<BuildingOUT>;
    /**
     *
     * @type {number}
     * @memberof PagedBuildingOUT
     */
    'count': number;
}
/**
 *
 * @export
 * @interface PagedBuildingTypeOUT
 */
export interface PagedBuildingTypeOUT {
    /**
     *
     * @type {Array<BuildingTypeOUT>}
     * @memberof PagedBuildingTypeOUT
     */
    'items': Array<BuildingTypeOUT>;
    /**
     *
     * @type {number}
     * @memberof PagedBuildingTypeOUT
     */
    'count': number;
}
/**
 *
 * @export
 * @interface PagedEPDSearchOUT
 */
export interface PagedEPDSearchOUT {
    /**
     *
     * @type {Array<EPDSearchOUT>}
     * @memberof PagedEPDSearchOUT
     */
    'items': Array<EPDSearchOUT>;
    /**
     *
     * @type {number}
     * @memberof PagedEPDSearchOUT
     */
    'count': number;
}
/**
 *
 * @export
 * @interface PagedFieldOUT
 */
export interface PagedFieldOUT {
    /**
     *
     * @type {Array<FieldOUT>}
     * @memberof PagedFieldOUT
     */
    'items': Array<FieldOUT>;
    /**
     *
     * @type {number}
     * @memberof PagedFieldOUT
     */
    'count': number;
}
/**
 *
 * @export
 * @interface PagedFloorOUT
 */
export interface PagedFloorOUT {
    /**
     *
     * @type {Array<FloorOUT>}
     * @memberof PagedFloorOUT
     */
    'items': Array<FloorOUT>;
    /**
     *
     * @type {number}
     * @memberof PagedFloorOUT
     */
    'count': number;
}
/**
 *
 * @export
 * @interface PagedIFCFileOUT
 */
export interface PagedIFCFileOUT {
    /**
     *
     * @type {Array<IFCFileOUT>}
     * @memberof PagedIFCFileOUT
     */
    'items': Array<IFCFileOUT>;
    /**
     *
     * @type {number}
     * @memberof PagedIFCFileOUT
     */
    'count': number;
}
/**
 *
 * @export
 * @interface PagedInstanceOUT
 */
export interface PagedInstanceOUT {
    /**
     *
     * @type {Array<InstanceOUT>}
     * @memberof PagedInstanceOUT
     */
    'items': Array<InstanceOUT>;
    /**
     *
     * @type {number}
     * @memberof PagedInstanceOUT
     */
    'count': number;
}
/**
 *
 * @export
 * @interface PagedInstancePropertyOUT
 */
export interface PagedInstancePropertyOUT {
    /**
     *
     * @type {Array<InstancePropertyOUT>}
     * @memberof PagedInstancePropertyOUT
     */
    'items': Array<InstancePropertyOUT>;
    /**
     *
     * @type {number}
     * @memberof PagedInstancePropertyOUT
     */
    'count': number;
}
/**
 *
 * @export
 * @interface PagedInventoryCategoryAggregateOut
 */
export interface PagedInventoryCategoryAggregateOut {
    /**
     *
     * @type {Array<InventoryCategoryAggregateOut>}
     * @memberof PagedInventoryCategoryAggregateOut
     */
    'items': Array<InventoryCategoryAggregateOut>;
    /**
     *
     * @type {number}
     * @memberof PagedInventoryCategoryAggregateOut
     */
    'count': number;
}
/**
 *
 * @export
 * @interface PagedInventoryEPDOut
 */
export interface PagedInventoryEPDOut {
    /**
     *
     * @type {Array<InventoryEPDOut>}
     * @memberof PagedInventoryEPDOut
     */
    'items': Array<InventoryEPDOut>;
    /**
     *
     * @type {number}
     * @memberof PagedInventoryEPDOut
     */
    'count': number;
}
/**
 *
 * @export
 * @interface PagedInventoryPhysicalObjectOut
 */
export interface PagedInventoryPhysicalObjectOut {
    /**
     *
     * @type {Array<InventoryPhysicalObjectOut>}
     * @memberof PagedInventoryPhysicalObjectOut
     */
    'items': Array<InventoryPhysicalObjectOut>;
    /**
     *
     * @type {number}
     * @memberof PagedInventoryPhysicalObjectOut
     */
    'count': number;
}
/**
 *
 * @export
 * @interface PagedInventoryProductOut
 */
export interface PagedInventoryProductOut {
    /**
     *
     * @type {Array<InventoryProductOut>}
     * @memberof PagedInventoryProductOut
     */
    'items': Array<InventoryProductOut>;
    /**
     *
     * @type {number}
     * @memberof PagedInventoryProductOut
     */
    'count': number;
}
/**
 *
 * @export
 * @interface PagedManufacturerOUT
 */
export interface PagedManufacturerOUT {
    /**
     *
     * @type {Array<ManufacturerOUT>}
     * @memberof PagedManufacturerOUT
     */
    'items': Array<ManufacturerOUT>;
    /**
     *
     * @type {number}
     * @memberof PagedManufacturerOUT
     */
    'count': number;
}
/**
 *
 * @export
 * @interface PagedMaterialSearchOUT
 */
export interface PagedMaterialSearchOUT {
    /**
     *
     * @type {Array<MaterialSearchOUT>}
     * @memberof PagedMaterialSearchOUT
     */
    'items': Array<MaterialSearchOUT>;
    /**
     *
     * @type {number}
     * @memberof PagedMaterialSearchOUT
     */
    'count': number;
}
/**
 *
 * @export
 * @interface PagedOrganisationDomainOUT
 */
export interface PagedOrganisationDomainOUT {
    /**
     *
     * @type {Array<OrganisationDomainOUT>}
     * @memberof PagedOrganisationDomainOUT
     */
    'items': Array<OrganisationDomainOUT>;
    /**
     *
     * @type {number}
     * @memberof PagedOrganisationDomainOUT
     */
    'count': number;
}
/**
 *
 * @export
 * @interface PagedOrganisationOUT
 */
export interface PagedOrganisationOUT {
    /**
     *
     * @type {Array<OrganisationOUT>}
     * @memberof PagedOrganisationOUT
     */
    'items': Array<OrganisationOUT>;
    /**
     *
     * @type {number}
     * @memberof PagedOrganisationOUT
     */
    'count': number;
}
/**
 *
 * @export
 * @interface PagedOrganisationRequestAllOUT
 */
export interface PagedOrganisationRequestAllOUT {
    /**
     *
     * @type {Array<OrganisationRequestAllOUT>}
     * @memberof PagedOrganisationRequestAllOUT
     */
    'items': Array<OrganisationRequestAllOUT>;
    /**
     *
     * @type {number}
     * @memberof PagedOrganisationRequestAllOUT
     */
    'count': number;
}
/**
 *
 * @export
 * @interface PagedOrganisationTypeOUT
 */
export interface PagedOrganisationTypeOUT {
    /**
     *
     * @type {Array<OrganisationTypeOUT>}
     * @memberof PagedOrganisationTypeOUT
     */
    'items': Array<OrganisationTypeOUT>;
    /**
     *
     * @type {number}
     * @memberof PagedOrganisationTypeOUT
     */
    'count': number;
}
/**
 *
 * @export
 * @interface PagedProductGroupOUT
 */
export interface PagedProductGroupOUT {
    /**
     *
     * @type {Array<ProductGroupOUT>}
     * @memberof PagedProductGroupOUT
     */
    'items': Array<ProductGroupOUT>;
    /**
     *
     * @type {number}
     * @memberof PagedProductGroupOUT
     */
    'count': number;
}
/**
 *
 * @export
 * @interface PagedRoomOUT
 */
export interface PagedRoomOUT {
    /**
     *
     * @type {Array<RoomOUT>}
     * @memberof PagedRoomOUT
     */
    'items': Array<RoomOUT>;
    /**
     *
     * @type {number}
     * @memberof PagedRoomOUT
     */
    'count': number;
}
/**
 *
 * @export
 * @interface PagedSlimAuditOut
 */
export interface PagedSlimAuditOut {
    /**
     *
     * @type {Array<SlimAuditOut>}
     * @memberof PagedSlimAuditOut
     */
    'items': Array<SlimAuditOut>;
    /**
     *
     * @type {number}
     * @memberof PagedSlimAuditOut
     */
    'count': number;
}
/**
 *
 * @export
 * @interface PagedSlimBlueprintOUT
 */
export interface PagedSlimBlueprintOUT {
    /**
     *
     * @type {Array<SlimBlueprintOUT>}
     * @memberof PagedSlimBlueprintOUT
     */
    'items': Array<SlimBlueprintOUT>;
    /**
     *
     * @type {number}
     * @memberof PagedSlimBlueprintOUT
     */
    'count': number;
}
/**
 *
 * @export
 * @interface PagedSlimUserAuditOUT
 */
export interface PagedSlimUserAuditOUT {
    /**
     *
     * @type {Array<SlimUserAuditOUT>}
     * @memberof PagedSlimUserAuditOUT
     */
    'items': Array<SlimUserAuditOUT>;
    /**
     *
     * @type {number}
     * @memberof PagedSlimUserAuditOUT
     */
    'count': number;
}
/**
 *
 * @export
 * @interface PagedSuggestOUT
 */
export interface PagedSuggestOUT {
    /**
     *
     * @type {Array<SuggestOUT>}
     * @memberof PagedSuggestOUT
     */
    'items': Array<SuggestOUT>;
    /**
     *
     * @type {number}
     * @memberof PagedSuggestOUT
     */
    'count': number;
}
/**
 *
 * @export
 * @interface PagedUserOrganisationMemberOUT
 */
export interface PagedUserOrganisationMemberOUT {
    /**
     *
     * @type {Array<UserOrganisationMemberOUT>}
     * @memberof PagedUserOrganisationMemberOUT
     */
    'items': Array<UserOrganisationMemberOUT>;
    /**
     *
     * @type {number}
     * @memberof PagedUserOrganisationMemberOUT
     */
    'count': number;
}
/**
 *
 * @export
 * @interface Pageddict
 */
export interface Pageddict {
    /**
     *
     * @type {Array<object>}
     * @memberof Pageddict
     */
    'items': Array<object>;
    /**
     *
     * @type {number}
     * @memberof Pageddict
     */
    'count': number;
}
/**
 *
 * @export
 * @interface PaginatedProductSearchOutput
 */
export interface PaginatedProductSearchOutput {
    /**
     *
     * @type {Array<ProductSearchOutput>}
     * @memberof PaginatedProductSearchOutput
     */
    'items': Array<ProductSearchOutput>;
    /**
     *
     * @type {number}
     * @memberof PaginatedProductSearchOutput
     */
    'count': number;
    /**
     *
     * @type {number}
     * @memberof PaginatedProductSearchOutput
     */
    'total_count_without_compliance_filter': number;
}
/**
 *
 * @export
 * @interface PlaceDetailsOUT
 */
export interface PlaceDetailsOUT {
    /**
     *
     * @type {string}
     * @memberof PlaceDetailsOUT
     */
    'street': string;
    /**
     *
     * @type {number}
     * @memberof PlaceDetailsOUT
     */
    'latitude': number;
    /**
     *
     * @type {number}
     * @memberof PlaceDetailsOUT
     */
    'longitude': number;
    /**
     *
     * @type {string}
     * @memberof PlaceDetailsOUT
     */
    'country_code': string;
    /**
     *
     * @type {string}
     * @memberof PlaceDetailsOUT
     */
    'house_number': string;
    /**
     *
     * @type {string}
     * @memberof PlaceDetailsOUT
     */
    'postal_code': string;
    /**
     *
     * @type {string}
     * @memberof PlaceDetailsOUT
     */
    'region': string;
    /**
     *
     * @type {string}
     * @memberof PlaceDetailsOUT
     */
    'place_id': string;
}
/**
 *
 * @export
 * @interface PresignedUrlIn
 */
export interface PresignedUrlIn {
    /**
     *
     * @type {string}
     * @memberof PresignedUrlIn
     */
    'file_name': string;
    /**
     *
     * @type {string}
     * @memberof PresignedUrlIn
     */
    'category': string;
    /**
     *
     * @type {object}
     * @memberof PresignedUrlIn
     */
    'metadata'?: object | null;
}
/**
 *
 * @export
 * @interface ProductComplianceOUT
 */
export interface ProductComplianceOUT {
    /**
     *
     * @type {Array<string>}
     * @memberof ProductComplianceOUT
     */
    'dataset_sources'?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof ProductComplianceOUT
     */
    'conformities'?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof ProductComplianceOUT
     */
    'compliant_certifications'?: Array<string>;
}
/**
 *
 * @export
 * @interface ProductCreationCPXValues
 */
export interface ProductCreationCPXValues {
    /**
     *
     * @type {string}
     * @memberof ProductCreationCPXValues
     */
    'separability_qualification': string;
    /**
     *
     * @type {number}
     * @memberof ProductCreationCPXValues
     */
    'separability_score': number;
    /**
     *
     * @type {string}
     * @memberof ProductCreationCPXValues
     */
    'deconstructability_qualification': string;
    /**
     *
     * @type {number}
     * @memberof ProductCreationCPXValues
     */
    'deconstructability_score': number;
    /**
     *
     * @type {string}
     * @memberof ProductCreationCPXValues
     */
    'reusability_qualification': string;
    /**
     *
     * @type {number}
     * @memberof ProductCreationCPXValues
     */
    'reusability_score': number;
    /**
     *
     * @type {string}
     * @memberof ProductCreationCPXValues
     */
    'preuse_qualification': string;
    /**
     *
     * @type {number}
     * @memberof ProductCreationCPXValues
     */
    'preuse_score': number;
}
/**
 *
 * @export
 * @interface ProductCreationEPD
 */
export interface ProductCreationEPD {
    /**
     *
     * @type {string}
     * @memberof ProductCreationEPD
     */
    'material': string;
    /**
     *
     * @type {string}
     * @memberof ProductCreationEPD
     */
    'epd': string;
    /**
     *
     * @type {number}
     * @memberof ProductCreationEPD
     */
    'factor': number;
}
/**
 *
 * @export
 * @interface ProductCreationEPDGroup
 */
export interface ProductCreationEPDGroup {
    /**
     *
     * @type {Array<ProductCreationEPD>}
     * @memberof ProductCreationEPDGroup
     */
    'epds': Array<ProductCreationEPD>;
}
/**
 * Schema for a Product Group
 * @export
 * @interface ProductGroupOUT
 */
export interface ProductGroupOUT {
    /**
     *
     * @type {string}
     * @memberof ProductGroupOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof ProductGroupOUT
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof ProductGroupOUT
     */
    'name_de': string;
    /**
     *
     * @type {Array<CategoryOUT>}
     * @memberof ProductGroupOUT
     */
    'categories': Array<CategoryOUT>;
    /**
     *
     * @type {Array<SlimProductGroupFieldSetOUT>}
     * @memberof ProductGroupOUT
     */
    'blueprint_field_set'?: Array<SlimProductGroupFieldSetOUT>;
    /**
     *
     * @type {Array<FieldOUT>}
     * @memberof ProductGroupOUT
     */
    'required_fields': Array<FieldOUT>;
    /**
     *
     * @type {Array<FieldOUT>}
     * @memberof ProductGroupOUT
     */
    'optional_fields': Array<FieldOUT>;
    /**
     *
     * @type {Array<ProductGroupOUT>}
     * @memberof ProductGroupOUT
     */
    'required_components'?: Array<ProductGroupOUT>;
    /**
     *
     * @type {Array<ProductGroupOUT>}
     * @memberof ProductGroupOUT
     */
    'optional_components'?: Array<ProductGroupOUT>;
    /**
     *
     * @type {string}
     * @memberof ProductGroupOUT
     */
    'unit': string;
}
/**
 *
 * @export
 * @interface ProductSearchInput
 */
export interface ProductSearchInput {
    /**
     *
     * @type {string}
     * @memberof ProductSearchInput
     */
    'building_id'?: string | null;
    /**
     *
     * @type {string}
     * @memberof ProductSearchInput
     */
    'query'?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof ProductSearchInput
     */
    'source'?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof ProductSearchInput
     */
    'din_category'?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof ProductSearchInput
     */
    'material_name'?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof ProductSearchInput
     */
    'is_external'?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductSearchInput
     */
    'is_load_bearing'?: boolean | null;
    /**
     *
     * @type {Array<string>}
     * @memberof ProductSearchInput
     */
    'epd_conformity'?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof ProductSearchInput
     */
    'certifications'?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof ProductSearchInput
     */
    'product_source'?: string;
    /**
     *
     * @type {boolean}
     * @memberof ProductSearchInput
     */
    'building_compliance'?: boolean | null;
    /**
     *
     * @type {ProductTypeChoicesEnum}
     * @memberof ProductSearchInput
     */
    'type'?: ProductTypeChoicesEnum | null;
}


/**
 *
 * @export
 * @interface ProductSearchOutput
 */
export interface ProductSearchOutput {
    /**
     *
     * @type {string}
     * @memberof ProductSearchOutput
     */
    'id'?: string | null;
    /**
     *
     * @type {string}
     * @memberof ProductSearchOutput
     */
    'name'?: string | null;
    /**
     *
     * @type {Array<EPDGroupOUT>}
     * @memberof ProductSearchOutput
     */
    'groups'?: Array<EPDGroupOUT>;
    /**
     *
     * @type {string}
     * @memberof ProductSearchOutput
     */
    'din_category'?: string | null;
    /**
     *
     * @type {string}
     * @memberof ProductSearchOutput
     */
    'din_category_number'?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductSearchOutput
     */
    'is_external'?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductSearchOutput
     */
    'is_load_bearing'?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof ProductSearchOutput
     */
    'separability_score'?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductSearchOutput
     */
    'separability_qualification'?: string | null;
    /**
     *
     * @type {number}
     * @memberof ProductSearchOutput
     */
    'deconstructability_score'?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductSearchOutput
     */
    'deconstructability_qualification'?: string | null;
    /**
     *
     * @type {number}
     * @memberof ProductSearchOutput
     */
    'reusability_score'?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductSearchOutput
     */
    'reusability_qualification'?: string | null;
    /**
     *
     * @type {number}
     * @memberof ProductSearchOutput
     */
    'recyclability_score'?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductSearchOutput
     */
    'recyclability_qualification'?: string | null;
    /**
     *
     * @type {number}
     * @memberof ProductSearchOutput
     */
    'preuse_score'?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductSearchOutput
     */
    'preuse_qualification'?: string | null;
    /**
     *
     * @type {string}
     * @memberof ProductSearchOutput
     */
    'dataset_source'?: string | null;
    /**
     *
     * @type {string}
     * @memberof ProductSearchOutput
     */
    'conformity'?: string | null;
    /**
     *
     * @type {string}
     * @memberof ProductSearchOutput
     */
    'building'?: string | null;
    /**
     *
     * @type {number}
     * @memberof ProductSearchOutput
     */
    'life_span'?: number;
    /**
     *
     * @type {Array<InventoryAutomapperTagOUT>}
     * @memberof ProductSearchOutput
     */
    'tags_automapper'?: Array<InventoryAutomapperTagOUT>;
    /**
     *
     * @type {string}
     * @memberof ProductSearchOutput
     */
    'reference_unit': string;
    /**
     *
     * @type {ComplianceOUT}
     * @memberof ProductSearchOutput
     */
    'compliance'?: ComplianceOUT | null;
    /**
     *
     * @type {string}
     * @memberof ProductSearchOutput
     */
    'type': string;
    /**
     *
     * @type {boolean}
     * @memberof ProductSearchOutput
     */
    'is_part_of_a_component'?: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ProductTypeChoicesEnum = {
    Product: 'PRODUCT',
    Component: 'COMPONENT'
} as const;

export type ProductTypeChoicesEnum = typeof ProductTypeChoicesEnum[keyof typeof ProductTypeChoicesEnum];


/**
 *
 * @export
 * @interface PropertyBrowserTreeNode
 */
export interface PropertyBrowserTreeNode {
    /**
     *
     * @type {string}
     * @memberof PropertyBrowserTreeNode
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof PropertyBrowserTreeNode
     */
    'column_key': string;
    /**
     *
     * @type {string}
     * @memberof PropertyBrowserTreeNode
     */
    'column_source': string;
    /**
     *
     * @type {string}
     * @memberof PropertyBrowserTreeNode
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof PropertyBrowserTreeNode
     */
    'name_de': string;
    /**
     *
     * @type {Array<PropertyBrowserTreeNode>}
     * @memberof PropertyBrowserTreeNode
     */
    'children'?: Array<PropertyBrowserTreeNode>;
}
/**
 *
 * @export
 * @interface PropertyFieldsSchema
 */
export interface PropertyFieldsSchema {
    /**
     *
     * @type {string}
     * @memberof PropertyFieldsSchema
     */
    'field_id': string;
    /**
     *
     * @type {string}
     * @memberof PropertyFieldsSchema
     */
    'value_string'?: string | null;
    /**
     *
     * @type {number}
     * @memberof PropertyFieldsSchema
     */
    'value_numeric'?: number | null;
    /**
     *
     * @type {string}
     * @memberof PropertyFieldsSchema
     */
    'value_datetime'?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof PropertyFieldsSchema
     */
    'value_field_option_ids'?: Array<string>;
}
/**
 *
 * @export
 * @interface PropertySet
 */
export interface PropertySet {
    /**
     *
     * @type {string}
     * @memberof PropertySet
     */
    'property_set_name': string;
    /**
     *
     * @type {Array<PropertySetProperty>}
     * @memberof PropertySet
     */
    'properties'?: Array<PropertySetProperty>;
}
/**
 *
 * @export
 * @interface PropertySetProperty
 */
export interface PropertySetProperty {
    /**
     *
     * @type {string}
     * @memberof PropertySetProperty
     */
    'property_name': string;
    /**
     *
     * @type {string}
     * @memberof PropertySetProperty
     */
    'property_value'?: string | null;
}
/**
 *
 * @export
 * @interface RecyclabilityDescription
 */
export interface RecyclabilityDescription {
    /**
     *
     * @type {string}
     * @memberof RecyclabilityDescription
     */
    'field_name': string;
    /**
     *
     * @type {string}
     * @memberof RecyclabilityDescription
     */
    'field_name_de': string;
    /**
     *
     * @type {Array<RecyclabilityDescriptionValue>}
     * @memberof RecyclabilityDescription
     */
    'values'?: Array<RecyclabilityDescriptionValue>;
}
/**
 *
 * @export
 * @interface RecyclabilityDescriptionValue
 */
export interface RecyclabilityDescriptionValue {
    /**
     *
     * @type {string}
     * @memberof RecyclabilityDescriptionValue
     */
    'material_name'?: string | null;
    /**
     *
     * @type {string}
     * @memberof RecyclabilityDescriptionValue
     */
    'value'?: string | null;
}
/**
 *
 * @export
 * @interface RecyclabilityScore
 */
export interface RecyclabilityScore {
    /**
     *
     * @type {string}
     * @memberof RecyclabilityScore
     */
    'field_name': string;
    /**
     *
     * @type {string}
     * @memberof RecyclabilityScore
     */
    'field_name_de': string;
    /**
     *
     * @type {Array<RecyclabilityScoreValue>}
     * @memberof RecyclabilityScore
     */
    'values'?: Array<RecyclabilityScoreValue>;
}
/**
 *
 * @export
 * @interface RecyclabilityScoreValue
 */
export interface RecyclabilityScoreValue {
    /**
     *
     * @type {string}
     * @memberof RecyclabilityScoreValue
     */
    'material_name'?: string | null;
    /**
     *
     * @type {string}
     * @memberof RecyclabilityScoreValue
     */
    'value'?: string | null;
}
/**
 *
 * @export
 * @interface ReportConfig
 */
export interface ReportConfig {
    /**
     *
     * @type {string}
     * @memberof ReportConfig
     */
    'reportId'?: string | null;
    /**
     *
     * @type {string}
     * @memberof ReportConfig
     */
    'reportName'?: string | null;
    /**
     *
     * @type {string}
     * @memberof ReportConfig
     */
    'embedUrl'?: string | null;
    /**
     *
     * @type {string}
     * @memberof ReportConfig
     */
    'datasetId'?: string | null;
}
/**
 *
 * @export
 * @interface ReportOUT
 */
export interface ReportOUT {
    /**
     *
     * @type {string}
     * @memberof ReportOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof ReportOUT
     */
    'building_id': string;
    /**
     *
     * @type {string}
     * @memberof ReportOUT
     */
    'input_file'?: string | null;
    /**
     *
     * @type {string}
     * @memberof ReportOUT
     */
    'download_url'?: string | null;
    /**
     *
     * @type {string}
     * @memberof ReportOUT
     */
    'status': string;
    /**
     *
     * @type {}
     * @memberof ReportOUT
     */
    'raw_values'?:  | null;
    /**
     *
     * @type {}
     * @memberof ReportOUT
     */
    'multiplied_values'?:  | null;
    /**
     *
     * @type {string}
     * @memberof ReportOUT
     */
    'calculation_reason'?: string | null;
    /**
     *
     * @type {string}
     * @memberof ReportOUT
     */
    'calculation_date'?: string | null;
    /**
     *
     * @type {string}
     * @memberof ReportOUT
     */
    'created': string;
    /**
     *
     * @type {string}
     * @memberof ReportOUT
     */
    'modified': string;
}
/**
 *
 * @export
 * @interface ReportTileInfo
 */
export interface ReportTileInfo {
    /**
     *
     * @type {Array<GeneralInfo>}
     * @memberof ReportTileInfo
     */
    'general_information'?: Array<GeneralInfo>;
    /**
     *
     * @type {Array<Dimensions>}
     * @memberof ReportTileInfo
     */
    'dimensions'?: Array<Dimensions>;
}
/**
 *
 * @export
 * @interface ReportTileReport
 */
export interface ReportTileReport {
    /**
     *
     * @type {ReportTileReportMass}
     * @memberof ReportTileReport
     */
    'mass'?: ReportTileReportMass | null;
    /**
     *
     * @type {Array<LCAIndicatorValue>}
     * @memberof ReportTileReport
     */
    'lca_indicator_values'?: Array<LCAIndicatorValue>;
    /**
     *
     * @type {Circularity}
     * @memberof ReportTileReport
     */
    'circularity'?: Circularity | null;
    /**
     *
     * @type {Financial}
     * @memberof ReportTileReport
     */
    'financial'?: Financial | null;
}
/**
 *
 * @export
 * @interface ReportTileReportMass
 */
export interface ReportTileReportMass {
    /**
     *
     * @type {number}
     * @memberof ReportTileReportMass
     */
    'value'?: number | null;
    /**
     *
     * @type {string}
     * @memberof ReportTileReportMass
     */
    'unit'?: string | null;
}
/**
 * Output types
 * @export
 * @enum {string}
 */

export const ResourcePassportOutputType = {
    Excel: 'EXCEL',
    Pdf: 'PDF',
    Txt: 'TXT',
    None: 'NONE'
} as const;

export type ResourcePassportOutputType = typeof ResourcePassportOutputType[keyof typeof ResourcePassportOutputType];


/**
 *
 * @export
 * @interface ResultOUT
 */
export interface ResultOUT {
    /**
     *
     * @type {boolean}
     * @memberof ResultOUT
     */
    'success': boolean;
    /**
     *
     * @type {object}
     * @memberof ResultOUT
     */
    'message': object;
}
/**
 *
 * @export
 * @interface RetoolEmbedOUT
 */
export interface RetoolEmbedOUT {
    /**
     *
     * @type {string}
     * @memberof RetoolEmbedOUT
     */
    'embedUrl': string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const RoleEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type RoleEnum = typeof RoleEnum[keyof typeof RoleEnum];


/**
 *
 * @export
 * @interface RoomCreateIN
 */
export interface RoomCreateIN {
    /**
     *
     * @type {string}
     * @memberof RoomCreateIN
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof RoomCreateIN
     */
    'audit_id': string;
}
/**
 *
 * @export
 * @interface RoomOUT
 */
export interface RoomOUT {
    /**
     *
     * @type {string}
     * @memberof RoomOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof RoomOUT
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof RoomOUT
     */
    'audit_id': string;
}
/**
 *
 * @export
 * @interface RoomUpdateIN
 */
export interface RoomUpdateIN {
    /**
     *
     * @type {string}
     * @memberof RoomUpdateIN
     */
    'name'?: string | null;
    /**
     *
     * @type {string}
     * @memberof RoomUpdateIN
     */
    'audit_id'?: string | null;
}
/**
 *
 * @export
 * @interface S3PresignedPostOUT
 */
export interface S3PresignedPostOUT {
    /**
     *
     * @type {string}
     * @memberof S3PresignedPostOUT
     */
    'url': string;
    /**
     *
     * @type {object}
     * @memberof S3PresignedPostOUT
     */
    'fields': object;
}
/**
 *
 * @export
 * @interface SearchEngineCategoryOUT
 */
export interface SearchEngineCategoryOUT {
    /**
     *
     * @type {string}
     * @memberof SearchEngineCategoryOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof SearchEngineCategoryOUT
     */
    'name'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SearchEngineCategoryOUT
     */
    'name_de'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SearchEngineCategoryOUT
     */
    'category_number'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SearchEngineCategoryOUT
     */
    'category_system'?: string | null;
    /**
     *
     * @type {Array<SearchEngineCategoryOUT>}
     * @memberof SearchEngineCategoryOUT
     */
    'children'?: Array<SearchEngineCategoryOUT>;
}
/**
 *
 * @export
 * @interface SimpleMaterialOut
 */
export interface SimpleMaterialOut {
    /**
     *
     * @type {string}
     * @memberof SimpleMaterialOut
     */
    'id': string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleMaterialOut
     */
    'name'?: string | null;
}
/**
 *
 * @export
 * @interface SingleObject
 */
export interface SingleObject {
    /**
     *
     * @type {string}
     * @memberof SingleObject
     */
    'id': string;
    /**
     *
     * @type {ObjectProductMatch}
     * @memberof SingleObject
     */
    'product_match': ObjectProductMatch;
    /**
     *
     * @type {object}
     * @memberof SingleObject
     */
    'values': object;
}
/**
 *
 * @export
 * @interface SingularCircularityValue
 */
export interface SingularCircularityValue {
    /**
     *
     * @type {string}
     * @memberof SingularCircularityValue
     */
    'field_name'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SingularCircularityValue
     */
    'field_name_de'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SingularCircularityValue
     */
    'value'?: string | null;
}
/**
 *
 * @export
 * @interface SlimAuditOut
 */
export interface SlimAuditOut {
    /**
     *
     * @type {string}
     * @memberof SlimAuditOut
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof SlimAuditOut
     */
    'title': string;
    /**
     *
     * @type {string}
     * @memberof SlimAuditOut
     */
    'building_id': string;
    /**
     *
     * @type {string}
     * @memberof SlimAuditOut
     */
    'created': string;
    /**
     *
     * @type {string}
     * @memberof SlimAuditOut
     */
    'modified': string;
    /**
     *
     * @type {UserProfileOUT}
     * @memberof SlimAuditOut
     */
    'created_by': UserProfileOUT;
}
/**
 *
 * @export
 * @interface SlimBlueprintOUT
 */
export interface SlimBlueprintOUT {
    /**
     *
     * @type {string}
     * @memberof SlimBlueprintOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof SlimBlueprintOUT
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof SlimBlueprintOUT
     */
    'name_de': string | null;
    /**
     *
     * @type {string}
     * @memberof SlimBlueprintOUT
     */
    'extra_info'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SlimBlueprintOUT
     */
    'audit_id': string;
    /**
     *
     * @type {string}
     * @memberof SlimBlueprintOUT
     */
    'product_group_id': string;
    /**
     *
     * @type {string}
     * @memberof SlimBlueprintOUT
     */
    'created': string;
    /**
     *
     * @type {string}
     * @memberof SlimBlueprintOUT
     */
    'modified': string;
    /**
     *
     * @type {UserProfileOUT}
     * @memberof SlimBlueprintOUT
     */
    'created_by': UserProfileOUT | null;
    /**
     *
     * @type {Array<BlueprintPropertyOUT>}
     * @memberof SlimBlueprintOUT
     */
    'blueprintproperty_set'?: Array<BlueprintPropertyOUT>;
    /**
     *
     * @type {Array<InstanceOUT>}
     * @memberof SlimBlueprintOUT
     */
    'instance_set': Array<InstanceOUT>;
    /**
     *
     * @type {Array<SlimBlueprintOUT>}
     * @memberof SlimBlueprintOUT
     */
    'components': Array<SlimBlueprintOUT>;
    /**
     *
     * @type {number}
     * @memberof SlimBlueprintOUT
     */
    'as_component_amount': number | null;
    /**
     *
     * @type {ManufacturerOUT}
     * @memberof SlimBlueprintOUT
     */
    'manufacturer': ManufacturerOUT | null;
    /**
     *
     * @type {Array<ImageAlbumOUT>}
     * @memberof SlimBlueprintOUT
     */
    'albums': Array<ImageAlbumOUT>;
    /**
     *
     * @type {number}
     * @memberof SlimBlueprintOUT
     */
    'total_instance_amount'?: number | null;
}
/**
 *
 * @export
 * @interface SlimBuildingOUT
 */
export interface SlimBuildingOUT {
    /**
     *
     * @type {string}
     * @memberof SlimBuildingOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof SlimBuildingOUT
     */
    'name': string;
    /**
     *
     * @type {object}
     * @memberof SlimBuildingOUT
     */
    'address'?: object | null;
    /**
     *
     * @type {string}
     * @memberof SlimBuildingOUT
     */
    'postal_code'?: string | null;
}
/**
 *
 * @export
 * @interface SlimFieldOUT
 */
export interface SlimFieldOUT {
    /**
     *
     * @type {string}
     * @memberof SlimFieldOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof SlimFieldOUT
     */
    'name'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SlimFieldOUT
     */
    'name_de'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SlimFieldOUT
     */
    'field_type'?: string | null;
    /**
     *
     * @type {number}
     * @memberof SlimFieldOUT
     */
    'order_of_appearance': number;
}
/**
 * Fieldset without the field options
 * @export
 * @interface SlimFieldSetOUT
 */
export interface SlimFieldSetOUT {
    /**
     *
     * @type {string}
     * @memberof SlimFieldSetOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof SlimFieldSetOUT
     */
    'name'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SlimFieldSetOUT
     */
    'name_de'?: string | null;
    /**
     *
     * @type {number}
     * @memberof SlimFieldSetOUT
     */
    'order_of_appearance': number;
    /**
     *
     * @type {Array<SlimFieldOUT>}
     * @memberof SlimFieldSetOUT
     */
    'required_fields'?: Array<SlimFieldOUT>;
    /**
     *
     * @type {Array<SlimFieldOUT>}
     * @memberof SlimFieldSetOUT
     */
    'optional_fields'?: Array<SlimFieldOUT>;
}
/**
 *
 * @export
 * @interface SlimProductGroupFieldSetOUT
 */
export interface SlimProductGroupFieldSetOUT {
    /**
     *
     * @type {string}
     * @memberof SlimProductGroupFieldSetOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof SlimProductGroupFieldSetOUT
     */
    'name': string | null;
    /**
     *
     * @type {string}
     * @memberof SlimProductGroupFieldSetOUT
     */
    'name_de': string | null;
    /**
     *
     * @type {number}
     * @memberof SlimProductGroupFieldSetOUT
     */
    'order_of_appearance': number;
    /**
     *
     * @type {Array<SlimFieldOUT>}
     * @memberof SlimProductGroupFieldSetOUT
     */
    'required_fields'?: Array<SlimFieldOUT>;
    /**
     *
     * @type {Array<SlimFieldOUT>}
     * @memberof SlimProductGroupFieldSetOUT
     */
    'optional_fields'?: Array<SlimFieldOUT>;
}
/**
 * Schema for a Product Group
 * @export
 * @interface SlimProductGroupOUT
 */
export interface SlimProductGroupOUT {
    /**
     *
     * @type {string}
     * @memberof SlimProductGroupOUT
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof SlimProductGroupOUT
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof SlimProductGroupOUT
     */
    'name_de': string;
    /**
     *
     * @type {Array<CategoryOUT>}
     * @memberof SlimProductGroupOUT
     */
    'categories': Array<CategoryOUT>;
    /**
     *
     * @type {Array<SlimProductGroupFieldSetOUT>}
     * @memberof SlimProductGroupOUT
     */
    'blueprint_field_set'?: Array<SlimProductGroupFieldSetOUT>;
    /**
     *
     * @type {Array<FieldOUT>}
     * @memberof SlimProductGroupOUT
     */
    'required_fields': Array<FieldOUT>;
    /**
     *
     * @type {Array<FieldOUT>}
     * @memberof SlimProductGroupOUT
     */
    'optional_fields': Array<FieldOUT>;
    /**
     *
     * @type {Array<SlimProductGroupOUT>}
     * @memberof SlimProductGroupOUT
     */
    'required_components'?: Array<SlimProductGroupOUT>;
    /**
     *
     * @type {Array<SlimProductGroupOUT>}
     * @memberof SlimProductGroupOUT
     */
    'optional_components'?: Array<SlimProductGroupOUT>;
    /**
     *
     * @type {string}
     * @memberof SlimProductGroupOUT
     */
    'unit': string;
}
/**
 *
 * @export
 * @interface SlimUserAuditOUT
 */
export interface SlimUserAuditOUT {
    /**
     *
     * @type {string}
     * @memberof SlimUserAuditOUT
     */
    'audit_id': string;
    /**
     *
     * @type {string}
     * @memberof SlimUserAuditOUT
     */
    'audit_title'?: string | null;
    /**
     *
     * @type {number}
     * @memberof SlimUserAuditOUT
     */
    'member_count': number;
    /**
     *
     * @type {number}
     * @memberof SlimUserAuditOUT
     */
    'role': number;
    /**
     *
     * @type {SlimBuildingOUT}
     * @memberof SlimUserAuditOUT
     */
    'building': SlimBuildingOUT;
    /**
     *
     * @type {string}
     * @memberof SlimUserAuditOUT
     */
    'created': string;
    /**
     *
     * @type {string}
     * @memberof SlimUserAuditOUT
     */
    'modified': string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const SortDirectionENUM = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type SortDirectionENUM = typeof SortDirectionENUM[keyof typeof SortDirectionENUM];


/**
 *
 * @export
 * @enum {string}
 */

export const SortOrder = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type SortOrder = typeof SortOrder[keyof typeof SortOrder];


/**
 *
 * @export
 * @interface Sorting
 */
export interface Sorting {
    /**
     *
     * @type {string}
     * @memberof Sorting
     */
    'column_key': string;
    /**
     *
     * @type {ColumnSourceEnum}
     * @memberof Sorting
     */
    'column_source': ColumnSourceEnum;
    /**
     *
     * @type {SortDirectionENUM}
     * @memberof Sorting
     */
    'direction': SortDirectionENUM;
}


/**
 *
 * @export
 * @interface SuggestOUT
 */
export interface SuggestOUT {
    /**
     *
     * @type {string}
     * @memberof SuggestOUT
     */
    'place_id': string;
    /**
     *
     * @type {object}
     * @memberof SuggestOUT
     */
    'structured_formatting': object;
    /**
     *
     * @type {string}
     * @memberof SuggestOUT
     */
    'description': string;
}
/**
 *
 * @export
 * @interface SummaryTableDataFilterSchema
 */
export interface SummaryTableDataFilterSchema {
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'input_file_id'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'input_id'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'match_id'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'building_id'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'object_id'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'object_name'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'object_material'?: string | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'object_area__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'object_area__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'object_volume__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'object_volume__until'?: number | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'component_name'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'product_name'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'product_lca_data_obd_name_de'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'product_din_category_name'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'product_material_category_general_name'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'product_material'?: string | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'product_reusability_score__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'product_reusability_score__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'product_separability_score__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'product_separability_score__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'product_recyclability_score__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'product_recyclability_score__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'product_deconstructability_score__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'product_deconstructability_score__until'?: number | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'product_reusability_qualification'?: string | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'gwp_total__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'gwp_total__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'mass__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'mass__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'object_height__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'object_height__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'object_width__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'object_width__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'object_length__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'object_length__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'mass'?: number | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'epd_name_de'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'epd_name_en'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'epd_url'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'epd_group_id'?: string | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'epd_ratio__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'epd_ratio__until'?: number | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'epd_reference_size'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'epd_reference_unit'?: string | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'epd_mass_value_m__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'epd_mass_value_m__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'epd_mass_value_m2__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'epd_mass_value_m2__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'epd_mass_value_m3__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'epd_mass_value_m3__until'?: number | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'material'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'material_category_origin_id'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'material_category_origin_name'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'material_category_general_name'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'material_category_general_id'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'material_category_dgnb_id'?: string | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'material_life_span__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'material_life_span__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'material_recyclability_score__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'material_recyclability_score__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'cpx_preuse_score__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'cpx_preuse_score__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'cpx_recyclability_score__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'cpx_recyclability_score__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'cpx_score__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'cpx_score__until'?: number | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'cpx_separability_qualification'?: string | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'cpx_separability_score__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'cpx_separability_score__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'cpx_deconstructability_score__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'cpx_deconstructability_score__until'?: number | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'cpx_preuse_qualification'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'cpx_recyclability_qualification'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'cpx_deconstructability_qualification'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'cpx_reusability_qualification'?: string | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'cpx_reuse_potential_score__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'cpx_reuse_potential_score__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'mass_material_value__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'mass_material_value__until'?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof SummaryTableDataFilterSchema
     */
    'is_compliant'?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'object_ifc_entity'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'product_din_category_id'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'product_din_category_name_level_2'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'product_din_category_name_level_3'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'product_match_type'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'product_epd_id'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'product_id'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'material_recyclability_qualification'?: string | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'product_match_confidence__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'product_match_confidence__until'?: number | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'product_deconstructability_qualification'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'object_deconstructability_qualification'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'product_deconstructability_dgnb_wording'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataFilterSchema
     */
    'object_recyclability_qualification'?: string | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'penrt_total__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'penrt_total__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'pert_total__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'pert_total__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'pe_total__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'pe_total__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'pocp_total__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'pocp_total__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'ap_total__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'ap_total__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'ep_total__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'ep_total__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'fw_total__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'fw_total__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'hwd_total__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'hwd_total__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'mer_total__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'mer_total__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'mfr_total__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'mfr_total__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'nhwd_total__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'nhwd_total__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'adpe_total__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'adpe_total__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'adpf_total__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'adpf_total__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'gwpfossil_total__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'gwpfossil_total__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'gwp_a1_a3__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'gwp_a1_a3__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'gwp_a4__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'gwp_a4__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'gwp_a5__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'gwp_a5__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'gwp_b4__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'gwp_b4__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'gwp_c1__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'gwp_c1__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'gwp_c2__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'gwp_c2__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'gwp_c3__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'gwp_c3__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'gwp_c4__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'gwp_c4__until'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'gwp_d__from'?: number | null;
    /**
     *
     * @type {number}
     * @memberof SummaryTableDataFilterSchema
     */
    'gwp_d__until'?: number | null;
}
/**
 *
 * @export
 * @interface SummaryTableDataSchemaIn
 */
export interface SummaryTableDataSchemaIn {
    /**
     *
     * @type {Array<string>}
     * @memberof SummaryTableDataSchemaIn
     */
    'columns'?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataSchemaIn
     */
    'sort_by'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SummaryTableDataSchemaIn
     */
    'sort_direction'?: string | null;
    /**
     *
     * @type {SummaryTableDataFilterSchema}
     * @memberof SummaryTableDataSchemaIn
     */
    'filters': SummaryTableDataFilterSchema;
}
/**
 *
 * @export
 * @interface UserAuditRoleOUT
 */
export interface UserAuditRoleOUT {
    /**
     *
     * @type {string}
     * @memberof UserAuditRoleOUT
     */
    'role_type': string;
    /**
     *
     * @type {string}
     * @memberof UserAuditRoleOUT
     */
    'role_name': string;
}
/**
 *
 * @export
 * @interface UserAuditUpdateIN
 */
export interface UserAuditUpdateIN {
    /**
     *
     * @type {RoleEnum}
     * @memberof UserAuditUpdateIN
     */
    'role': RoleEnum;
}


/**
 *
 * @export
 * @interface UserBackTokenOUT
 */
export interface UserBackTokenOUT {
    /**
     *
     * @type {string}
     * @memberof UserBackTokenOUT
     */
    'sso_jwt': string;
}
/**
 *
 * @export
 * @interface UserBuildingOUT
 */
export interface UserBuildingOUT {
    /**
     *
     * @type {string}
     * @memberof UserBuildingOUT
     */
    'id': string;
    /**
     *
     * @type {BuildingOUT}
     * @memberof UserBuildingOUT
     */
    'building': BuildingOUT;
    /**
     *
     * @type {string}
     * @memberof UserBuildingOUT
     */
    'organisation_name': string;
    /**
     *
     * @type {UserProfileOUT}
     * @memberof UserBuildingOUT
     */
    'user': UserProfileOUT;
    /**
     *
     * @type {number}
     * @memberof UserBuildingOUT
     */
    'role': number;
}
/**
 *
 * @export
 * @interface UserOrganisationMemberOUT
 */
export interface UserOrganisationMemberOUT {
    /**
     *
     * @type {UserProfileOUT}
     * @memberof UserOrganisationMemberOUT
     */
    'user': UserProfileOUT;
    /**
     *
     * @type {number}
     * @memberof UserOrganisationMemberOUT
     */
    'role': number;
}
/**
 *
 * @export
 * @interface UserOrganisationOUT
 */
export interface UserOrganisationOUT {
    /**
     *
     * @type {string}
     * @memberof UserOrganisationOUT
     */
    'id': string;
    /**
     *
     * @type {OrganisationOUT}
     * @memberof UserOrganisationOUT
     */
    'organisation': OrganisationOUT;
    /**
     *
     * @type {number}
     * @memberof UserOrganisationOUT
     */
    'role': number;
}
/**
 *
 * @export
 * @interface UserOrganisationRoleIN
 */
export interface UserOrganisationRoleIN {
    /**
     *
     * @type {string}
     * @memberof UserOrganisationRoleIN
     */
    'username': string;
    /**
     *
     * @type {number}
     * @memberof UserOrganisationRoleIN
     */
    'role': number;
}
/**
 *
 * @export
 * @interface UserProfileIN
 */
export interface UserProfileIN {
    /**
     *
     * @type {string}
     * @memberof UserProfileIN
     */
    'first_name': string;
    /**
     *
     * @type {string}
     * @memberof UserProfileIN
     */
    'last_name': string;
    /**
     *
     * @type {boolean}
     * @memberof UserProfileIN
     */
    'terms_accepted'?: boolean | null;
}
/**
 *
 * @export
 * @interface UserProfileOUT
 */
export interface UserProfileOUT {
    /**
     *
     * @type {string}
     * @memberof UserProfileOUT
     */
    'username': string;
    /**
     *
     * @type {string}
     * @memberof UserProfileOUT
     */
    'email': string;
    /**
     *
     * @type {string}
     * @memberof UserProfileOUT
     */
    'first_name': string;
    /**
     *
     * @type {string}
     * @memberof UserProfileOUT
     */
    'last_name': string;
}
/**
 *
 * @export
 * @interface UserRoleOUT
 */
export interface UserRoleOUT {
    /**
     *
     * @type {string}
     * @memberof UserRoleOUT
     */
    'role_type': string;
    /**
     *
     * @type {string}
     * @memberof UserRoleOUT
     */
    'role_name': string;
}

/**
 * APIKeyApi - axios parameter creator
 * @export
 */
export const APIKeyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Generate API key for user
         * @summary Generate Api Key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersApiKeyGenerateApiKey: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/concular/api_key/generate_api_key`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Validate if the API key belongs to the user
         * @summary Validate Api Key
         * @param {string} apiKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersApiKeyValidateApiKey: async (apiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiKey' is not null or undefined
            assertParamExists('concularApiV1RoutersApiKeyValidateApiKey', 'apiKey', apiKey)
            const localVarPath = `/api/v1/concular/api_key/validate_api_key`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (apiKey !== undefined) {
                localVarQueryParameter['api_key'] = apiKey;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * APIKeyApi - functional programming interface
 * @export
 */
export const APIKeyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = APIKeyApiAxiosParamCreator(configuration)
    return {
        /**
         * Generate API key for user
         * @summary Generate Api Key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersApiKeyGenerateApiKey(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateAPIKeyOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersApiKeyGenerateApiKey(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Validate if the API key belongs to the user
         * @summary Validate Api Key
         * @param {string} apiKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersApiKeyValidateApiKey(apiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersApiKeyValidateApiKey(apiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * APIKeyApi - factory interface
 * @export
 */
export const APIKeyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = APIKeyApiFp(configuration)
    return {
        /**
         * Generate API key for user
         * @summary Generate Api Key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersApiKeyGenerateApiKey(options?: any): AxiosPromise<GenerateAPIKeyOut> {
            return localVarFp.concularApiV1RoutersApiKeyGenerateApiKey(options).then((request) => request(axios, basePath));
        },
        /**
         * Validate if the API key belongs to the user
         * @summary Validate Api Key
         * @param {string} apiKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersApiKeyValidateApiKey(apiKey: string, options?: any): AxiosPromise<ResultOUT> {
            return localVarFp.concularApiV1RoutersApiKeyValidateApiKey(apiKey, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * APIKeyApi - object-oriented interface
 * @export
 * @class APIKeyApi
 * @extends {BaseAPI}
 */
export class APIKeyApi extends BaseAPI {
    /**
     * Generate API key for user
     * @summary Generate Api Key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIKeyApi
     */
    public concularApiV1RoutersApiKeyGenerateApiKey(options?: AxiosRequestConfig) {
        return APIKeyApiFp(this.configuration).concularApiV1RoutersApiKeyGenerateApiKey(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Validate if the API key belongs to the user
     * @summary Validate Api Key
     * @param {string} apiKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIKeyApi
     */
    public concularApiV1RoutersApiKeyValidateApiKey(apiKey: string, options?: AxiosRequestConfig) {
        return APIKeyApiFp(this.configuration).concularApiV1RoutersApiKeyValidateApiKey(apiKey, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AuditInvitationsApi - axios parameter creator
 * @export
 */
export const AuditInvitationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete an audit invitation.
         * @summary Delete Audit Invitation
         * @param {string} invitationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersAuditInvitationDeleteAuditInvitation: async (invitationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitationId' is not null or undefined
            assertParamExists('caApiV1RoutersAuditInvitationDeleteAuditInvitation', 'invitationId', invitationId)
            const localVarPath = `/api/v1/ca/audit_invitations/{invitation_id}`
                .replace(`{${"invitation_id"}}`, encodeURIComponent(String(invitationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Invite a user to join an audit. Audit manager can invite the user to join an audit.
         * @summary Invite User For Audit
         * @param {AuditInvitationIN} auditInvitationIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersAuditInvitationInviteUserForAudit: async (auditInvitationIN: AuditInvitationIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditInvitationIN' is not null or undefined
            assertParamExists('caApiV1RoutersAuditInvitationInviteUserForAudit', 'auditInvitationIN', auditInvitationIN)
            const localVarPath = `/api/v1/ca/audit_invitations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(auditInvitationIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all the audit invitations for a specific audit.
         * @summary List Audit Invitations
         * @param {string} auditId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersAuditInvitationListAuditInvitations: async (auditId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('caApiV1RoutersAuditInvitationListAuditInvitations', 'auditId', auditId)
            const localVarPath = `/api/v1/ca/audit_invitations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (auditId !== undefined) {
                localVarQueryParameter['audit_id'] = auditId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuditInvitationsApi - functional programming interface
 * @export
 */
export const AuditInvitationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuditInvitationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete an audit invitation.
         * @summary Delete Audit Invitation
         * @param {string} invitationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersAuditInvitationDeleteAuditInvitation(invitationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersAuditInvitationDeleteAuditInvitation(invitationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Invite a user to join an audit. Audit manager can invite the user to join an audit.
         * @summary Invite User For Audit
         * @param {AuditInvitationIN} auditInvitationIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersAuditInvitationInviteUserForAudit(auditInvitationIN: AuditInvitationIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuditInvitationOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersAuditInvitationInviteUserForAudit(auditInvitationIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List all the audit invitations for a specific audit.
         * @summary List Audit Invitations
         * @param {string} auditId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersAuditInvitationListAuditInvitations(auditId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AuditInvitationDetailsOUT>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersAuditInvitationListAuditInvitations(auditId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuditInvitationsApi - factory interface
 * @export
 */
export const AuditInvitationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuditInvitationsApiFp(configuration)
    return {
        /**
         * Delete an audit invitation.
         * @summary Delete Audit Invitation
         * @param {string} invitationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersAuditInvitationDeleteAuditInvitation(invitationId: string, options?: any): AxiosPromise<ResultOUT> {
            return localVarFp.caApiV1RoutersAuditInvitationDeleteAuditInvitation(invitationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Invite a user to join an audit. Audit manager can invite the user to join an audit.
         * @summary Invite User For Audit
         * @param {AuditInvitationIN} auditInvitationIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersAuditInvitationInviteUserForAudit(auditInvitationIN: AuditInvitationIN, options?: any): AxiosPromise<AuditInvitationOUT> {
            return localVarFp.caApiV1RoutersAuditInvitationInviteUserForAudit(auditInvitationIN, options).then((request) => request(axios, basePath));
        },
        /**
         * List all the audit invitations for a specific audit.
         * @summary List Audit Invitations
         * @param {string} auditId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersAuditInvitationListAuditInvitations(auditId: string, options?: any): AxiosPromise<Array<AuditInvitationDetailsOUT>> {
            return localVarFp.caApiV1RoutersAuditInvitationListAuditInvitations(auditId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuditInvitationsApi - object-oriented interface
 * @export
 * @class AuditInvitationsApi
 * @extends {BaseAPI}
 */
export class AuditInvitationsApi extends BaseAPI {
    /**
     * Delete an audit invitation.
     * @summary Delete Audit Invitation
     * @param {string} invitationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditInvitationsApi
     */
    public caApiV1RoutersAuditInvitationDeleteAuditInvitation(invitationId: string, options?: AxiosRequestConfig) {
        return AuditInvitationsApiFp(this.configuration).caApiV1RoutersAuditInvitationDeleteAuditInvitation(invitationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Invite a user to join an audit. Audit manager can invite the user to join an audit.
     * @summary Invite User For Audit
     * @param {AuditInvitationIN} auditInvitationIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditInvitationsApi
     */
    public caApiV1RoutersAuditInvitationInviteUserForAudit(auditInvitationIN: AuditInvitationIN, options?: AxiosRequestConfig) {
        return AuditInvitationsApiFp(this.configuration).caApiV1RoutersAuditInvitationInviteUserForAudit(auditInvitationIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all the audit invitations for a specific audit.
     * @summary List Audit Invitations
     * @param {string} auditId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditInvitationsApi
     */
    public caApiV1RoutersAuditInvitationListAuditInvitations(auditId: string, options?: AxiosRequestConfig) {
        return AuditInvitationsApiFp(this.configuration).caApiV1RoutersAuditInvitationListAuditInvitations(auditId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AuditsApi - axios parameter creator
 * @export
 */
export const AuditsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete blueprint by id.
         * @summary Delete Blueprint
         * @param {string} blueprintId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersAuditDeleteBlueprint: async (blueprintId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'blueprintId' is not null or undefined
            assertParamExists('caApiV1RoutersAuditDeleteBlueprint', 'blueprintId', blueprintId)
            const localVarPath = `/api/v1/ca/audits/blueprint/{blueprint_id}`
                .replace(`{${"blueprint_id"}}`, encodeURIComponent(String(blueprintId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete instance by id.
         * @summary Delete Instance
         * @param {string} instanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersAuditDeleteInstance: async (instanceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('caApiV1RoutersAuditDeleteInstance', 'instanceId', instanceId)
            const localVarPath = `/api/v1/ca/audits/instance/{instance_id}`
                .replace(`{${"instance_id"}}`, encodeURIComponent(String(instanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get audit by id.
         * @summary Get Audit
         * @param {string} auditId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersAuditGetAudit: async (auditId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('caApiV1RoutersAuditGetAudit', 'auditId', auditId)
            const localVarPath = `/api/v1/ca/audits/{audit_id}`
                .replace(`{${"audit_id"}}`, encodeURIComponent(String(auditId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update Audit
         * @param {AuditIN} auditIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersAuditUpdateAudit: async (auditIN: AuditIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditIN' is not null or undefined
            assertParamExists('caApiV1RoutersAuditUpdateAudit', 'auditIN', auditIN)
            const localVarPath = `/api/v1/ca/audits/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(auditIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuditsApi - functional programming interface
 * @export
 */
export const AuditsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuditsApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete blueprint by id.
         * @summary Delete Blueprint
         * @param {string} blueprintId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersAuditDeleteBlueprint(blueprintId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersAuditDeleteBlueprint(blueprintId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete instance by id.
         * @summary Delete Instance
         * @param {string} instanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersAuditDeleteInstance(instanceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersAuditDeleteInstance(instanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get audit by id.
         * @summary Get Audit
         * @param {string} auditId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersAuditGetAudit(auditId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuditOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersAuditGetAudit(auditId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Update Audit
         * @param {AuditIN} auditIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersAuditUpdateAudit(auditIN: AuditIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersAuditUpdateAudit(auditIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuditsApi - factory interface
 * @export
 */
export const AuditsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuditsApiFp(configuration)
    return {
        /**
         * Delete blueprint by id.
         * @summary Delete Blueprint
         * @param {string} blueprintId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersAuditDeleteBlueprint(blueprintId: string, options?: any): AxiosPromise<void> {
            return localVarFp.caApiV1RoutersAuditDeleteBlueprint(blueprintId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete instance by id.
         * @summary Delete Instance
         * @param {string} instanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersAuditDeleteInstance(instanceId: string, options?: any): AxiosPromise<void> {
            return localVarFp.caApiV1RoutersAuditDeleteInstance(instanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get audit by id.
         * @summary Get Audit
         * @param {string} auditId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersAuditGetAudit(auditId: string, options?: any): AxiosPromise<AuditOUT> {
            return localVarFp.caApiV1RoutersAuditGetAudit(auditId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Update Audit
         * @param {AuditIN} auditIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersAuditUpdateAudit(auditIN: AuditIN, options?: any): AxiosPromise<object> {
            return localVarFp.caApiV1RoutersAuditUpdateAudit(auditIN, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuditsApi - object-oriented interface
 * @export
 * @class AuditsApi
 * @extends {BaseAPI}
 */
export class AuditsApi extends BaseAPI {
    /**
     * Delete blueprint by id.
     * @summary Delete Blueprint
     * @param {string} blueprintId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditsApi
     */
    public caApiV1RoutersAuditDeleteBlueprint(blueprintId: string, options?: AxiosRequestConfig) {
        return AuditsApiFp(this.configuration).caApiV1RoutersAuditDeleteBlueprint(blueprintId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete instance by id.
     * @summary Delete Instance
     * @param {string} instanceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditsApi
     */
    public caApiV1RoutersAuditDeleteInstance(instanceId: string, options?: AxiosRequestConfig) {
        return AuditsApiFp(this.configuration).caApiV1RoutersAuditDeleteInstance(instanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get audit by id.
     * @summary Get Audit
     * @param {string} auditId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditsApi
     */
    public caApiV1RoutersAuditGetAudit(auditId: string, options?: AxiosRequestConfig) {
        return AuditsApiFp(this.configuration).caApiV1RoutersAuditGetAudit(auditId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Update Audit
     * @param {AuditIN} auditIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditsApi
     */
    public caApiV1RoutersAuditUpdateAudit(auditIN: AuditIN, options?: AxiosRequestConfig) {
        return AuditsApiFp(this.configuration).caApiV1RoutersAuditUpdateAudit(auditIN, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BuildingInviteesApi - axios parameter creator
 * @export
 */
export const BuildingInviteesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Once the organisation admin sent the invites to user for joining building. User can accept invite to join a building
         * @summary Accept Invite
         * @param {string} buildingInviteeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingInviteesAcceptInvite: async (buildingInviteeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingInviteeId' is not null or undefined
            assertParamExists('concularApiV1RoutersBuildingInviteesAcceptInvite', 'buildingInviteeId', buildingInviteeId)
            const localVarPath = `/api/v1/concular/building_invitees/{building_invitee_id}`
                .replace(`{${"building_invitee_id"}}`, encodeURIComponent(String(buildingInviteeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the latest status of user building request. If it\'s invited then user hasn\'t join building yet.
         * @summary Check Invite Status
         * @param {string} buildingInviteeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingInviteesCheckInviteStatus: async (buildingInviteeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingInviteeId' is not null or undefined
            assertParamExists('concularApiV1RoutersBuildingInviteesCheckInviteStatus', 'buildingInviteeId', buildingInviteeId)
            const localVarPath = `/api/v1/concular/building_invitees/{building_invitee_id}/status`
                .replace(`{${"building_invitee_id"}}`, encodeURIComponent(String(buildingInviteeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Organisation member can see all the pending invites (user who didn\'t sign up yet) for the building.
         * @summary Get All Invites For Building
         * @param {string} buildingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingInviteesGetAllInvitesForBuilding: async (buildingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('concularApiV1RoutersBuildingInviteesGetAllInvitesForBuilding', 'buildingId', buildingId)
            const localVarPath = `/api/v1/concular/building_invitees/all/invites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (buildingId !== undefined) {
                localVarQueryParameter['building_id'] = buildingId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Invite a user to join a building. Organisation admin can invite to the user to join a building. Building will be shared with that user even if user is not part for organisation
         * @summary Invite User For Building
         * @param {BuildingInviteeIN} buildingInviteeIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingInviteesInviteUserForBuilding: async (buildingInviteeIN: BuildingInviteeIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingInviteeIN' is not null or undefined
            assertParamExists('concularApiV1RoutersBuildingInviteesInviteUserForBuilding', 'buildingInviteeIN', buildingInviteeIN)
            const localVarPath = `/api/v1/concular/building_invitees/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buildingInviteeIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove user from invitee list. In future if we implement decline request by user that will also work.
         * @summary Remove User From Invitee List
         * @param {string} buildingInviteeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingInviteesRemoveUserFromInviteeList: async (buildingInviteeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingInviteeId' is not null or undefined
            assertParamExists('concularApiV1RoutersBuildingInviteesRemoveUserFromInviteeList', 'buildingInviteeId', buildingInviteeId)
            const localVarPath = `/api/v1/concular/building_invitees/{building_invitee_id}`
                .replace(`{${"building_invitee_id"}}`, encodeURIComponent(String(buildingInviteeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Organisation member can resend the invites to the user
         * @summary Resend Invitation Email
         * @param {string} buildingInviteeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingInviteesResendInvitationEmail: async (buildingInviteeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingInviteeId' is not null or undefined
            assertParamExists('concularApiV1RoutersBuildingInviteesResendInvitationEmail', 'buildingInviteeId', buildingInviteeId)
            const localVarPath = `/api/v1/concular/building_invitees/{building_invitee_id}/resend`
                .replace(`{${"building_invitee_id"}}`, encodeURIComponent(String(buildingInviteeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BuildingInviteesApi - functional programming interface
 * @export
 */
export const BuildingInviteesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BuildingInviteesApiAxiosParamCreator(configuration)
    return {
        /**
         * Once the organisation admin sent the invites to user for joining building. User can accept invite to join a building
         * @summary Accept Invite
         * @param {string} buildingInviteeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersBuildingInviteesAcceptInvite(buildingInviteeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserBuildingOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersBuildingInviteesAcceptInvite(buildingInviteeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the latest status of user building request. If it\'s invited then user hasn\'t join building yet.
         * @summary Check Invite Status
         * @param {string} buildingInviteeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersBuildingInviteesCheckInviteStatus(buildingInviteeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildingInviteeOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersBuildingInviteesCheckInviteStatus(buildingInviteeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Organisation member can see all the pending invites (user who didn\'t sign up yet) for the building.
         * @summary Get All Invites For Building
         * @param {string} buildingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersBuildingInviteesGetAllInvitesForBuilding(buildingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BuildingInviteeOUT>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersBuildingInviteesGetAllInvitesForBuilding(buildingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Invite a user to join a building. Organisation admin can invite to the user to join a building. Building will be shared with that user even if user is not part for organisation
         * @summary Invite User For Building
         * @param {BuildingInviteeIN} buildingInviteeIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersBuildingInviteesInviteUserForBuilding(buildingInviteeIN: BuildingInviteeIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildingInviteeOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersBuildingInviteesInviteUserForBuilding(buildingInviteeIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Remove user from invitee list. In future if we implement decline request by user that will also work.
         * @summary Remove User From Invitee List
         * @param {string} buildingInviteeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersBuildingInviteesRemoveUserFromInviteeList(buildingInviteeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersBuildingInviteesRemoveUserFromInviteeList(buildingInviteeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Organisation member can resend the invites to the user
         * @summary Resend Invitation Email
         * @param {string} buildingInviteeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersBuildingInviteesResendInvitationEmail(buildingInviteeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildingInviteeOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersBuildingInviteesResendInvitationEmail(buildingInviteeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BuildingInviteesApi - factory interface
 * @export
 */
export const BuildingInviteesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BuildingInviteesApiFp(configuration)
    return {
        /**
         * Once the organisation admin sent the invites to user for joining building. User can accept invite to join a building
         * @summary Accept Invite
         * @param {string} buildingInviteeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingInviteesAcceptInvite(buildingInviteeId: string, options?: any): AxiosPromise<UserBuildingOUT> {
            return localVarFp.concularApiV1RoutersBuildingInviteesAcceptInvite(buildingInviteeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the latest status of user building request. If it\'s invited then user hasn\'t join building yet.
         * @summary Check Invite Status
         * @param {string} buildingInviteeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingInviteesCheckInviteStatus(buildingInviteeId: string, options?: any): AxiosPromise<BuildingInviteeOUT> {
            return localVarFp.concularApiV1RoutersBuildingInviteesCheckInviteStatus(buildingInviteeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Organisation member can see all the pending invites (user who didn\'t sign up yet) for the building.
         * @summary Get All Invites For Building
         * @param {string} buildingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingInviteesGetAllInvitesForBuilding(buildingId: string, options?: any): AxiosPromise<Array<BuildingInviteeOUT>> {
            return localVarFp.concularApiV1RoutersBuildingInviteesGetAllInvitesForBuilding(buildingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Invite a user to join a building. Organisation admin can invite to the user to join a building. Building will be shared with that user even if user is not part for organisation
         * @summary Invite User For Building
         * @param {BuildingInviteeIN} buildingInviteeIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingInviteesInviteUserForBuilding(buildingInviteeIN: BuildingInviteeIN, options?: any): AxiosPromise<BuildingInviteeOUT> {
            return localVarFp.concularApiV1RoutersBuildingInviteesInviteUserForBuilding(buildingInviteeIN, options).then((request) => request(axios, basePath));
        },
        /**
         * Remove user from invitee list. In future if we implement decline request by user that will also work.
         * @summary Remove User From Invitee List
         * @param {string} buildingInviteeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingInviteesRemoveUserFromInviteeList(buildingInviteeId: string, options?: any): AxiosPromise<ResultOUT> {
            return localVarFp.concularApiV1RoutersBuildingInviteesRemoveUserFromInviteeList(buildingInviteeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Organisation member can resend the invites to the user
         * @summary Resend Invitation Email
         * @param {string} buildingInviteeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingInviteesResendInvitationEmail(buildingInviteeId: string, options?: any): AxiosPromise<BuildingInviteeOUT> {
            return localVarFp.concularApiV1RoutersBuildingInviteesResendInvitationEmail(buildingInviteeId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BuildingInviteesApi - object-oriented interface
 * @export
 * @class BuildingInviteesApi
 * @extends {BaseAPI}
 */
export class BuildingInviteesApi extends BaseAPI {
    /**
     * Once the organisation admin sent the invites to user for joining building. User can accept invite to join a building
     * @summary Accept Invite
     * @param {string} buildingInviteeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingInviteesApi
     */
    public concularApiV1RoutersBuildingInviteesAcceptInvite(buildingInviteeId: string, options?: AxiosRequestConfig) {
        return BuildingInviteesApiFp(this.configuration).concularApiV1RoutersBuildingInviteesAcceptInvite(buildingInviteeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the latest status of user building request. If it\'s invited then user hasn\'t join building yet.
     * @summary Check Invite Status
     * @param {string} buildingInviteeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingInviteesApi
     */
    public concularApiV1RoutersBuildingInviteesCheckInviteStatus(buildingInviteeId: string, options?: AxiosRequestConfig) {
        return BuildingInviteesApiFp(this.configuration).concularApiV1RoutersBuildingInviteesCheckInviteStatus(buildingInviteeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Organisation member can see all the pending invites (user who didn\'t sign up yet) for the building.
     * @summary Get All Invites For Building
     * @param {string} buildingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingInviteesApi
     */
    public concularApiV1RoutersBuildingInviteesGetAllInvitesForBuilding(buildingId: string, options?: AxiosRequestConfig) {
        return BuildingInviteesApiFp(this.configuration).concularApiV1RoutersBuildingInviteesGetAllInvitesForBuilding(buildingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Invite a user to join a building. Organisation admin can invite to the user to join a building. Building will be shared with that user even if user is not part for organisation
     * @summary Invite User For Building
     * @param {BuildingInviteeIN} buildingInviteeIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingInviteesApi
     */
    public concularApiV1RoutersBuildingInviteesInviteUserForBuilding(buildingInviteeIN: BuildingInviteeIN, options?: AxiosRequestConfig) {
        return BuildingInviteesApiFp(this.configuration).concularApiV1RoutersBuildingInviteesInviteUserForBuilding(buildingInviteeIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Remove user from invitee list. In future if we implement decline request by user that will also work.
     * @summary Remove User From Invitee List
     * @param {string} buildingInviteeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingInviteesApi
     */
    public concularApiV1RoutersBuildingInviteesRemoveUserFromInviteeList(buildingInviteeId: string, options?: AxiosRequestConfig) {
        return BuildingInviteesApiFp(this.configuration).concularApiV1RoutersBuildingInviteesRemoveUserFromInviteeList(buildingInviteeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Organisation member can resend the invites to the user
     * @summary Resend Invitation Email
     * @param {string} buildingInviteeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingInviteesApi
     */
    public concularApiV1RoutersBuildingInviteesResendInvitationEmail(buildingInviteeId: string, options?: AxiosRequestConfig) {
        return BuildingInviteesApiFp(this.configuration).concularApiV1RoutersBuildingInviteesResendInvitationEmail(buildingInviteeId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BuildingsApi - axios parameter creator
 * @export
 */
export const BuildingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * As an admin or member user can create a new building within the organisation.
         * @summary Create Org Building
         * @param {BuildingIN} buildingIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingsCreateOrgBuilding: async (buildingIN: BuildingIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingIN' is not null or undefined
            assertParamExists('concularApiV1RoutersBuildingsCreateOrgBuilding', 'buildingIN', buildingIN)
            const localVarPath = `/api/v1/concular/buildings/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buildingIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a building.
         * @summary Delete Building
         * @param {string} buildingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingsDeleteBuilding: async (buildingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('concularApiV1RoutersBuildingsDeleteBuilding', 'buildingId', buildingId)
            const localVarPath = `/api/v1/concular/buildings/{building_id}/`
                .replace(`{${"building_id"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove images from the building.
         * @summary Delete Img Building
         * @param {string} buildingId
         * @param {BuildingImageAddRemoveIN} buildingImageAddRemoveIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingsDeleteImgBuilding: async (buildingId: string, buildingImageAddRemoveIN: BuildingImageAddRemoveIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('concularApiV1RoutersBuildingsDeleteImgBuilding', 'buildingId', buildingId)
            // verify required parameter 'buildingImageAddRemoveIN' is not null or undefined
            assertParamExists('concularApiV1RoutersBuildingsDeleteImgBuilding', 'buildingImageAddRemoveIN', buildingImageAddRemoveIN)
            const localVarPath = `/api/v1/concular/buildings/{building_id}/image`
                .replace(`{${"building_id"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buildingImageAddRemoveIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Export Certification
         * @param {string} buildingId
         * @param {ExportCertificationIN} exportCertificationIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingsExportCertification: async (buildingId: string, exportCertificationIN: ExportCertificationIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('concularApiV1RoutersBuildingsExportCertification', 'buildingId', buildingId)
            // verify required parameter 'exportCertificationIN' is not null or undefined
            assertParamExists('concularApiV1RoutersBuildingsExportCertification', 'exportCertificationIN', exportCertificationIN)
            const localVarPath = `/api/v1/concular/buildings/{building_id}/export_certification`
                .replace(`{${"building_id"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exportCertificationIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all the building types. Building type will requre to create a building.
         * @summary Get All Building Types
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingsGetAllBuildingTypes: async (limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/concular/buildings/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all available state for the building. For example Planned or Existing building.
         * @summary Get All Current State
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingsGetAllCurrentState: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/concular/buildings/current_state`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of IFC, CSV and Audit files of the building.
         * @summary Get All Material Resources
         * @param {string} buildingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingsGetAllMaterialResources: async (buildingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('concularApiV1RoutersBuildingsGetAllMaterialResources', 'buildingId', buildingId)
            const localVarPath = `/api/v1/concular/buildings/{building_id}/material_resources`
                .replace(`{${"building_id"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all buildings for the organisation. It will return list of all the buildings that belongs to organisation.
         * @summary Get All Org Buildings
         * @param {string} organisationId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingsGetAllOrgBuildings: async (organisationId: string, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('concularApiV1RoutersBuildingsGetAllOrgBuildings', 'organisationId', organisationId)
            const localVarPath = `/api/v1/concular/buildings/{organisation_id}/all`
                .replace(`{${"organisation_id"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication AuthAPIKey required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the all the details for a specific building.
         * @summary Get Org Building
         * @param {string} buildingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingsGetOrgBuilding: async (buildingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('concularApiV1RoutersBuildingsGetOrgBuilding', 'buildingId', buildingId)
            const localVarPath = `/api/v1/concular/buildings/{building_id}`
                .replace(`{${"building_id"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return the status of the building
         * @summary Mapping Status
         * @param {string} buildingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingsMappingStatus: async (buildingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('concularApiV1RoutersBuildingsMappingStatus', 'buildingId', buildingId)
            const localVarPath = `/api/v1/concular/buildings/{building_id}/mapping_status`
                .replace(`{${"building_id"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Recalculates the summary table for a building\'s active IFC file.
         * @summary Recalculate Summary Table For Building
         * @param {string} buildingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingsRecalculateSummaryTableForBuilding: async (buildingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('concularApiV1RoutersBuildingsRecalculateSummaryTableForBuilding', 'buildingId', buildingId)
            const localVarPath = `/api/v1/concular/buildings/{building_id}/recalculate`
                .replace(`{${"building_id"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add/attach images with building.
         * @summary Update Img Building
         * @param {string} buildingId
         * @param {BuildingImageAddRemoveIN} buildingImageAddRemoveIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingsUpdateImgBuilding: async (buildingId: string, buildingImageAddRemoveIN: BuildingImageAddRemoveIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('concularApiV1RoutersBuildingsUpdateImgBuilding', 'buildingId', buildingId)
            // verify required parameter 'buildingImageAddRemoveIN' is not null or undefined
            assertParamExists('concularApiV1RoutersBuildingsUpdateImgBuilding', 'buildingImageAddRemoveIN', buildingImageAddRemoveIN)
            const localVarPath = `/api/v1/concular/buildings/{building_id}/image`
                .replace(`{${"building_id"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buildingImageAddRemoveIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * User or admin can update the building details.
         * @summary Update Org Building
         * @param {string} buildingId
         * @param {BuildingUpdateIN} buildingUpdateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingsUpdateOrgBuilding: async (buildingId: string, buildingUpdateIN: BuildingUpdateIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('concularApiV1RoutersBuildingsUpdateOrgBuilding', 'buildingId', buildingId)
            // verify required parameter 'buildingUpdateIN' is not null or undefined
            assertParamExists('concularApiV1RoutersBuildingsUpdateOrgBuilding', 'buildingUpdateIN', buildingUpdateIN)
            const localVarPath = `/api/v1/concular/buildings/{building_id}`
                .replace(`{${"building_id"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buildingUpdateIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Building image uploader. Single or multiple images can be uploaded.
         * @summary Upload Building Images
         * @param {Array<File>} images
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingsUploadBuildingImages: async (images: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'images' is not null or undefined
            assertParamExists('concularApiV1RoutersBuildingsUploadBuildingImages', 'images', images)
            const localVarPath = `/api/v1/concular/buildings/upload/images`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (images) {
                images.forEach((element) => {
                    localVarFormParams.append('images', element as any);
                })
            }



            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BuildingsApi - functional programming interface
 * @export
 */
export const BuildingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BuildingsApiAxiosParamCreator(configuration)
    return {
        /**
         * As an admin or member user can create a new building within the organisation.
         * @summary Create Org Building
         * @param {BuildingIN} buildingIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersBuildingsCreateOrgBuilding(buildingIN: BuildingIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildingOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersBuildingsCreateOrgBuilding(buildingIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a building.
         * @summary Delete Building
         * @param {string} buildingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersBuildingsDeleteBuilding(buildingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersBuildingsDeleteBuilding(buildingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Remove images from the building.
         * @summary Delete Img Building
         * @param {string} buildingId
         * @param {BuildingImageAddRemoveIN} buildingImageAddRemoveIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersBuildingsDeleteImgBuilding(buildingId: string, buildingImageAddRemoveIN: BuildingImageAddRemoveIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildingOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersBuildingsDeleteImgBuilding(buildingId, buildingImageAddRemoveIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Export Certification
         * @param {string} buildingId
         * @param {ExportCertificationIN} exportCertificationIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersBuildingsExportCertification(buildingId: string, exportCertificationIN: ExportCertificationIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersBuildingsExportCertification(buildingId, exportCertificationIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all the building types. Building type will requre to create a building.
         * @summary Get All Building Types
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersBuildingsGetAllBuildingTypes(limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedBuildingTypeOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersBuildingsGetAllBuildingTypes(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all available state for the building. For example Planned or Existing building.
         * @summary Get All Current State
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersBuildingsGetAllCurrentState(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersBuildingsGetAllCurrentState(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get list of IFC, CSV and Audit files of the building.
         * @summary Get All Material Resources
         * @param {string} buildingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersBuildingsGetAllMaterialResources(buildingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MaterialResourceOUT>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersBuildingsGetAllMaterialResources(buildingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all buildings for the organisation. It will return list of all the buildings that belongs to organisation.
         * @summary Get All Org Buildings
         * @param {string} organisationId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersBuildingsGetAllOrgBuildings(organisationId: string, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedBuildingOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersBuildingsGetAllOrgBuildings(organisationId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the all the details for a specific building.
         * @summary Get Org Building
         * @param {string} buildingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersBuildingsGetOrgBuilding(buildingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildingOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersBuildingsGetOrgBuilding(buildingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return the status of the building
         * @summary Mapping Status
         * @param {string} buildingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersBuildingsMappingStatus(buildingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildingMappingStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersBuildingsMappingStatus(buildingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Recalculates the summary table for a building\'s active IFC file.
         * @summary Recalculate Summary Table For Building
         * @param {string} buildingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersBuildingsRecalculateSummaryTableForBuilding(buildingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersBuildingsRecalculateSummaryTableForBuilding(buildingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add/attach images with building.
         * @summary Update Img Building
         * @param {string} buildingId
         * @param {BuildingImageAddRemoveIN} buildingImageAddRemoveIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersBuildingsUpdateImgBuilding(buildingId: string, buildingImageAddRemoveIN: BuildingImageAddRemoveIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildingOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersBuildingsUpdateImgBuilding(buildingId, buildingImageAddRemoveIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * User or admin can update the building details.
         * @summary Update Org Building
         * @param {string} buildingId
         * @param {BuildingUpdateIN} buildingUpdateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersBuildingsUpdateOrgBuilding(buildingId: string, buildingUpdateIN: BuildingUpdateIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildingOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersBuildingsUpdateOrgBuilding(buildingId, buildingUpdateIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Building image uploader. Single or multiple images can be uploaded.
         * @summary Upload Building Images
         * @param {Array<File>} images
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersBuildingsUploadBuildingImages(images: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BuildingImageOUT>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersBuildingsUploadBuildingImages(images, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BuildingsApi - factory interface
 * @export
 */
export const BuildingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BuildingsApiFp(configuration)
    return {
        /**
         * As an admin or member user can create a new building within the organisation.
         * @summary Create Org Building
         * @param {BuildingIN} buildingIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingsCreateOrgBuilding(buildingIN: BuildingIN, options?: any): AxiosPromise<BuildingOUT> {
            return localVarFp.concularApiV1RoutersBuildingsCreateOrgBuilding(buildingIN, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a building.
         * @summary Delete Building
         * @param {string} buildingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingsDeleteBuilding(buildingId: string, options?: any): AxiosPromise<ResultOUT> {
            return localVarFp.concularApiV1RoutersBuildingsDeleteBuilding(buildingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Remove images from the building.
         * @summary Delete Img Building
         * @param {string} buildingId
         * @param {BuildingImageAddRemoveIN} buildingImageAddRemoveIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingsDeleteImgBuilding(buildingId: string, buildingImageAddRemoveIN: BuildingImageAddRemoveIN, options?: any): AxiosPromise<BuildingOUT> {
            return localVarFp.concularApiV1RoutersBuildingsDeleteImgBuilding(buildingId, buildingImageAddRemoveIN, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Export Certification
         * @param {string} buildingId
         * @param {ExportCertificationIN} exportCertificationIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingsExportCertification(buildingId: string, exportCertificationIN: ExportCertificationIN, options?: any): AxiosPromise<ResultOUT> {
            return localVarFp.concularApiV1RoutersBuildingsExportCertification(buildingId, exportCertificationIN, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all the building types. Building type will requre to create a building.
         * @summary Get All Building Types
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingsGetAllBuildingTypes(limit?: number, offset?: number, options?: any): AxiosPromise<PagedBuildingTypeOUT> {
            return localVarFp.concularApiV1RoutersBuildingsGetAllBuildingTypes(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all available state for the building. For example Planned or Existing building.
         * @summary Get All Current State
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingsGetAllCurrentState(options?: any): AxiosPromise<void> {
            return localVarFp.concularApiV1RoutersBuildingsGetAllCurrentState(options).then((request) => request(axios, basePath));
        },
        /**
         * Get list of IFC, CSV and Audit files of the building.
         * @summary Get All Material Resources
         * @param {string} buildingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingsGetAllMaterialResources(buildingId: string, options?: any): AxiosPromise<Array<MaterialResourceOUT>> {
            return localVarFp.concularApiV1RoutersBuildingsGetAllMaterialResources(buildingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all buildings for the organisation. It will return list of all the buildings that belongs to organisation.
         * @summary Get All Org Buildings
         * @param {string} organisationId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingsGetAllOrgBuildings(organisationId: string, limit?: number, offset?: number, options?: any): AxiosPromise<PagedBuildingOUT> {
            return localVarFp.concularApiV1RoutersBuildingsGetAllOrgBuildings(organisationId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the all the details for a specific building.
         * @summary Get Org Building
         * @param {string} buildingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingsGetOrgBuilding(buildingId: string, options?: any): AxiosPromise<BuildingOUT> {
            return localVarFp.concularApiV1RoutersBuildingsGetOrgBuilding(buildingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return the status of the building
         * @summary Mapping Status
         * @param {string} buildingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingsMappingStatus(buildingId: string, options?: any): AxiosPromise<BuildingMappingStatus> {
            return localVarFp.concularApiV1RoutersBuildingsMappingStatus(buildingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Recalculates the summary table for a building\'s active IFC file.
         * @summary Recalculate Summary Table For Building
         * @param {string} buildingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingsRecalculateSummaryTableForBuilding(buildingId: string, options?: any): AxiosPromise<ResultOUT> {
            return localVarFp.concularApiV1RoutersBuildingsRecalculateSummaryTableForBuilding(buildingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add/attach images with building.
         * @summary Update Img Building
         * @param {string} buildingId
         * @param {BuildingImageAddRemoveIN} buildingImageAddRemoveIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingsUpdateImgBuilding(buildingId: string, buildingImageAddRemoveIN: BuildingImageAddRemoveIN, options?: any): AxiosPromise<BuildingOUT> {
            return localVarFp.concularApiV1RoutersBuildingsUpdateImgBuilding(buildingId, buildingImageAddRemoveIN, options).then((request) => request(axios, basePath));
        },
        /**
         * User or admin can update the building details.
         * @summary Update Org Building
         * @param {string} buildingId
         * @param {BuildingUpdateIN} buildingUpdateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingsUpdateOrgBuilding(buildingId: string, buildingUpdateIN: BuildingUpdateIN, options?: any): AxiosPromise<BuildingOUT> {
            return localVarFp.concularApiV1RoutersBuildingsUpdateOrgBuilding(buildingId, buildingUpdateIN, options).then((request) => request(axios, basePath));
        },
        /**
         * Building image uploader. Single or multiple images can be uploaded.
         * @summary Upload Building Images
         * @param {Array<File>} images
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersBuildingsUploadBuildingImages(images: Array<File>, options?: any): AxiosPromise<Array<BuildingImageOUT>> {
            return localVarFp.concularApiV1RoutersBuildingsUploadBuildingImages(images, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BuildingsApi - object-oriented interface
 * @export
 * @class BuildingsApi
 * @extends {BaseAPI}
 */
export class BuildingsApi extends BaseAPI {
    /**
     * As an admin or member user can create a new building within the organisation.
     * @summary Create Org Building
     * @param {BuildingIN} buildingIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingsApi
     */
    public concularApiV1RoutersBuildingsCreateOrgBuilding(buildingIN: BuildingIN, options?: AxiosRequestConfig) {
        return BuildingsApiFp(this.configuration).concularApiV1RoutersBuildingsCreateOrgBuilding(buildingIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a building.
     * @summary Delete Building
     * @param {string} buildingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingsApi
     */
    public concularApiV1RoutersBuildingsDeleteBuilding(buildingId: string, options?: AxiosRequestConfig) {
        return BuildingsApiFp(this.configuration).concularApiV1RoutersBuildingsDeleteBuilding(buildingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Remove images from the building.
     * @summary Delete Img Building
     * @param {string} buildingId
     * @param {BuildingImageAddRemoveIN} buildingImageAddRemoveIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingsApi
     */
    public concularApiV1RoutersBuildingsDeleteImgBuilding(buildingId: string, buildingImageAddRemoveIN: BuildingImageAddRemoveIN, options?: AxiosRequestConfig) {
        return BuildingsApiFp(this.configuration).concularApiV1RoutersBuildingsDeleteImgBuilding(buildingId, buildingImageAddRemoveIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Export Certification
     * @param {string} buildingId
     * @param {ExportCertificationIN} exportCertificationIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingsApi
     */
    public concularApiV1RoutersBuildingsExportCertification(buildingId: string, exportCertificationIN: ExportCertificationIN, options?: AxiosRequestConfig) {
        return BuildingsApiFp(this.configuration).concularApiV1RoutersBuildingsExportCertification(buildingId, exportCertificationIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all the building types. Building type will requre to create a building.
     * @summary Get All Building Types
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingsApi
     */
    public concularApiV1RoutersBuildingsGetAllBuildingTypes(limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return BuildingsApiFp(this.configuration).concularApiV1RoutersBuildingsGetAllBuildingTypes(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all available state for the building. For example Planned or Existing building.
     * @summary Get All Current State
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingsApi
     */
    public concularApiV1RoutersBuildingsGetAllCurrentState(options?: AxiosRequestConfig) {
        return BuildingsApiFp(this.configuration).concularApiV1RoutersBuildingsGetAllCurrentState(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list of IFC, CSV and Audit files of the building.
     * @summary Get All Material Resources
     * @param {string} buildingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingsApi
     */
    public concularApiV1RoutersBuildingsGetAllMaterialResources(buildingId: string, options?: AxiosRequestConfig) {
        return BuildingsApiFp(this.configuration).concularApiV1RoutersBuildingsGetAllMaterialResources(buildingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all buildings for the organisation. It will return list of all the buildings that belongs to organisation.
     * @summary Get All Org Buildings
     * @param {string} organisationId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingsApi
     */
    public concularApiV1RoutersBuildingsGetAllOrgBuildings(organisationId: string, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return BuildingsApiFp(this.configuration).concularApiV1RoutersBuildingsGetAllOrgBuildings(organisationId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the all the details for a specific building.
     * @summary Get Org Building
     * @param {string} buildingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingsApi
     */
    public concularApiV1RoutersBuildingsGetOrgBuilding(buildingId: string, options?: AxiosRequestConfig) {
        return BuildingsApiFp(this.configuration).concularApiV1RoutersBuildingsGetOrgBuilding(buildingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return the status of the building
     * @summary Mapping Status
     * @param {string} buildingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingsApi
     */
    public concularApiV1RoutersBuildingsMappingStatus(buildingId: string, options?: AxiosRequestConfig) {
        return BuildingsApiFp(this.configuration).concularApiV1RoutersBuildingsMappingStatus(buildingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Recalculates the summary table for a building\'s active IFC file.
     * @summary Recalculate Summary Table For Building
     * @param {string} buildingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingsApi
     */
    public concularApiV1RoutersBuildingsRecalculateSummaryTableForBuilding(buildingId: string, options?: AxiosRequestConfig) {
        return BuildingsApiFp(this.configuration).concularApiV1RoutersBuildingsRecalculateSummaryTableForBuilding(buildingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add/attach images with building.
     * @summary Update Img Building
     * @param {string} buildingId
     * @param {BuildingImageAddRemoveIN} buildingImageAddRemoveIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingsApi
     */
    public concularApiV1RoutersBuildingsUpdateImgBuilding(buildingId: string, buildingImageAddRemoveIN: BuildingImageAddRemoveIN, options?: AxiosRequestConfig) {
        return BuildingsApiFp(this.configuration).concularApiV1RoutersBuildingsUpdateImgBuilding(buildingId, buildingImageAddRemoveIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * User or admin can update the building details.
     * @summary Update Org Building
     * @param {string} buildingId
     * @param {BuildingUpdateIN} buildingUpdateIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingsApi
     */
    public concularApiV1RoutersBuildingsUpdateOrgBuilding(buildingId: string, buildingUpdateIN: BuildingUpdateIN, options?: AxiosRequestConfig) {
        return BuildingsApiFp(this.configuration).concularApiV1RoutersBuildingsUpdateOrgBuilding(buildingId, buildingUpdateIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Building image uploader. Single or multiple images can be uploaded.
     * @summary Upload Building Images
     * @param {Array<File>} images
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingsApi
     */
    public concularApiV1RoutersBuildingsUploadBuildingImages(images: Array<File>, options?: AxiosRequestConfig) {
        return BuildingsApiFp(this.configuration).concularApiV1RoutersBuildingsUploadBuildingImages(images, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CategoriesApi - axios parameter creator
 * @export
 */
export const CategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a list of categories
         * @summary Categories
         * @param {string} categorySystem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCategoryCategories: async (categorySystem: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categorySystem' is not null or undefined
            assertParamExists('caApiV1RoutersCategoryCategories', 'categorySystem', categorySystem)
            const localVarPath = `/api/v1/ca/categories/categories/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (categorySystem !== undefined) {
                localVarQueryParameter['category_system'] = categorySystem;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoriesApi - functional programming interface
 * @export
 */
export const CategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a list of categories
         * @summary Categories
         * @param {string} categorySystem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCategoryCategories(categorySystem: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoryFullOut>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCategoryCategories(categorySystem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CategoriesApi - factory interface
 * @export
 */
export const CategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoriesApiFp(configuration)
    return {
        /**
         * Returns a list of categories
         * @summary Categories
         * @param {string} categorySystem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCategoryCategories(categorySystem: string, options?: any): AxiosPromise<Array<CategoryFullOut>> {
            return localVarFp.caApiV1RoutersCategoryCategories(categorySystem, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CategoriesApi - object-oriented interface
 * @export
 * @class CategoriesApi
 * @extends {BaseAPI}
 */
export class CategoriesApi extends BaseAPI {
    /**
     * Returns a list of categories
     * @summary Categories
     * @param {string} categorySystem
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public caApiV1RoutersCategoryCategories(categorySystem: string, options?: AxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).caApiV1RoutersCategoryCategories(categorySystem, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CommonURLsForApplicationsApi - axios parameter creator
 * @export
 */
export const CommonURLsForApplicationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Generate the POST URL for s3 upload.
         * @summary Generate Presigned Url
         * @param {PresignedUrlIn} presignedUrlIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersCommonGeneratePresignedUrl: async (presignedUrlIn: PresignedUrlIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'presignedUrlIn' is not null or undefined
            assertParamExists('concularApiV1RoutersCommonGeneratePresignedUrl', 'presignedUrlIn', presignedUrlIn)
            const localVarPath = `/api/v1/concular/common/get-presigned-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(presignedUrlIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommonURLsForApplicationsApi - functional programming interface
 * @export
 */
export const CommonURLsForApplicationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommonURLsForApplicationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Generate the POST URL for s3 upload.
         * @summary Generate Presigned Url
         * @param {PresignedUrlIn} presignedUrlIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersCommonGeneratePresignedUrl(presignedUrlIn: PresignedUrlIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersCommonGeneratePresignedUrl(presignedUrlIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommonURLsForApplicationsApi - factory interface
 * @export
 */
export const CommonURLsForApplicationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommonURLsForApplicationsApiFp(configuration)
    return {
        /**
         * Generate the POST URL for s3 upload.
         * @summary Generate Presigned Url
         * @param {PresignedUrlIn} presignedUrlIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersCommonGeneratePresignedUrl(presignedUrlIn: PresignedUrlIn, options?: any): AxiosPromise<void> {
            return localVarFp.concularApiV1RoutersCommonGeneratePresignedUrl(presignedUrlIn, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommonURLsForApplicationsApi - object-oriented interface
 * @export
 * @class CommonURLsForApplicationsApi
 * @extends {BaseAPI}
 */
export class CommonURLsForApplicationsApi extends BaseAPI {
    /**
     * Generate the POST URL for s3 upload.
     * @summary Generate Presigned Url
     * @param {PresignedUrlIn} presignedUrlIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonURLsForApplicationsApi
     */
    public concularApiV1RoutersCommonGeneratePresignedUrl(presignedUrlIn: PresignedUrlIn, options?: AxiosRequestConfig) {
        return CommonURLsForApplicationsApiFp(this.configuration).concularApiV1RoutersCommonGeneratePresignedUrl(presignedUrlIn, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ComponentsApi - axios parameter creator
 * @export
 */
export const ComponentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Check Component Compliance
         * @param {ComponentComplianceIN} componentComplianceIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersComponentsCheckComponentCompliance: async (componentComplianceIN: ComponentComplianceIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'componentComplianceIN' is not null or undefined
            assertParamExists('inventoryApiV1RoutersComponentsCheckComponentCompliance', 'componentComplianceIN', componentComplianceIN)
            const localVarPath = `/api/v1/inventory/components/compliance/component_compliance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(componentComplianceIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Check Component Din Category
         * @param {ComponentDINCategoryIN} componentDINCategoryIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersComponentsCheckComponentDinCategory: async (componentDINCategoryIN: ComponentDINCategoryIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'componentDINCategoryIN' is not null or undefined
            assertParamExists('inventoryApiV1RoutersComponentsCheckComponentDinCategory', 'componentDINCategoryIN', componentDINCategoryIN)
            const localVarPath = `/api/v1/inventory/components/categories/din_category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(componentDINCategoryIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Check Product Compliance
         * @param {string} productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersComponentsCheckProductCompliance: async (productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('inventoryApiV1RoutersComponentsCheckProductCompliance', 'productId', productId)
            const localVarPath = `/api/v1/inventory/components/compliance/product_compliance/{product_id}`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create Component
         * @param {ComponentCreateIN} componentCreateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersComponentsCreateComponent: async (componentCreateIN: ComponentCreateIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'componentCreateIN' is not null or undefined
            assertParamExists('inventoryApiV1RoutersComponentsCreateComponent', 'componentCreateIN', componentCreateIN)
            const localVarPath = `/api/v1/inventory/components/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(componentCreateIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete Component
         * @param {string} componentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersComponentsDeleteComponent: async (componentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'componentId' is not null or undefined
            assertParamExists('inventoryApiV1RoutersComponentsDeleteComponent', 'componentId', componentId)
            const localVarPath = `/api/v1/inventory/components/{component_id}`
                .replace(`{${"component_id"}}`, encodeURIComponent(String(componentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Details By File
         * @param {string} componentId
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersComponentsDetailsByFile: async (componentId: string, fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'componentId' is not null or undefined
            assertParamExists('inventoryApiV1RoutersComponentsDetailsByFile', 'componentId', componentId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('inventoryApiV1RoutersComponentsDetailsByFile', 'fileId', fileId)
            const localVarPath = `/api/v1/inventory/components/details_by_file/{component_id}/{file_id}`
                .replace(`{${"component_id"}}`, encodeURIComponent(String(componentId)))
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Edit Component
         * @param {string} componentId
         * @param {ComponentEditIN} componentEditIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersComponentsEditComponent: async (componentId: string, componentEditIN: ComponentEditIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'componentId' is not null or undefined
            assertParamExists('inventoryApiV1RoutersComponentsEditComponent', 'componentId', componentId)
            // verify required parameter 'componentEditIN' is not null or undefined
            assertParamExists('inventoryApiV1RoutersComponentsEditComponent', 'componentEditIN', componentEditIN)
            const localVarPath = `/api/v1/inventory/components/{component_id}`
                .replace(`{${"component_id"}}`, encodeURIComponent(String(componentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(componentEditIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ComponentsApi - functional programming interface
 * @export
 */
export const ComponentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ComponentsApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Check Component Compliance
         * @param {ComponentComplianceIN} componentComplianceIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersComponentsCheckComponentCompliance(componentComplianceIN: ComponentComplianceIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComponentComplianceOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersComponentsCheckComponentCompliance(componentComplianceIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Check Component Din Category
         * @param {ComponentDINCategoryIN} componentDINCategoryIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersComponentsCheckComponentDinCategory(componentDINCategoryIN: ComponentDINCategoryIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComponentDINCategoryOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersComponentsCheckComponentDinCategory(componentDINCategoryIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Check Product Compliance
         * @param {string} productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersComponentsCheckProductCompliance(productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductComplianceOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersComponentsCheckProductCompliance(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Create Component
         * @param {ComponentCreateIN} componentCreateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersComponentsCreateComponent(componentCreateIN: ComponentCreateIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComponentCreateOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersComponentsCreateComponent(componentCreateIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Delete Component
         * @param {string} componentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersComponentsDeleteComponent(componentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersComponentsDeleteComponent(componentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Details By File
         * @param {string} componentId
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersComponentsDetailsByFile(componentId: string, fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComponentDetailsByFileOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersComponentsDetailsByFile(componentId, fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Edit Component
         * @param {string} componentId
         * @param {ComponentEditIN} componentEditIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersComponentsEditComponent(componentId: string, componentEditIN: ComponentEditIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComponentEditOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersComponentsEditComponent(componentId, componentEditIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ComponentsApi - factory interface
 * @export
 */
export const ComponentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ComponentsApiFp(configuration)
    return {
        /**
         *
         * @summary Check Component Compliance
         * @param {ComponentComplianceIN} componentComplianceIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersComponentsCheckComponentCompliance(componentComplianceIN: ComponentComplianceIN, options?: any): AxiosPromise<ComponentComplianceOUT> {
            return localVarFp.inventoryApiV1RoutersComponentsCheckComponentCompliance(componentComplianceIN, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Check Component Din Category
         * @param {ComponentDINCategoryIN} componentDINCategoryIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersComponentsCheckComponentDinCategory(componentDINCategoryIN: ComponentDINCategoryIN, options?: any): AxiosPromise<ComponentDINCategoryOUT> {
            return localVarFp.inventoryApiV1RoutersComponentsCheckComponentDinCategory(componentDINCategoryIN, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Check Product Compliance
         * @param {string} productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersComponentsCheckProductCompliance(productId: string, options?: any): AxiosPromise<ProductComplianceOUT> {
            return localVarFp.inventoryApiV1RoutersComponentsCheckProductCompliance(productId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Create Component
         * @param {ComponentCreateIN} componentCreateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersComponentsCreateComponent(componentCreateIN: ComponentCreateIN, options?: any): AxiosPromise<ComponentCreateOUT> {
            return localVarFp.inventoryApiV1RoutersComponentsCreateComponent(componentCreateIN, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete Component
         * @param {string} componentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersComponentsDeleteComponent(componentId: string, options?: any): AxiosPromise<ResultOUT> {
            return localVarFp.inventoryApiV1RoutersComponentsDeleteComponent(componentId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Details By File
         * @param {string} componentId
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersComponentsDetailsByFile(componentId: string, fileId: string, options?: any): AxiosPromise<ComponentDetailsByFileOut> {
            return localVarFp.inventoryApiV1RoutersComponentsDetailsByFile(componentId, fileId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit Component
         * @param {string} componentId
         * @param {ComponentEditIN} componentEditIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersComponentsEditComponent(componentId: string, componentEditIN: ComponentEditIN, options?: any): AxiosPromise<ComponentEditOUT> {
            return localVarFp.inventoryApiV1RoutersComponentsEditComponent(componentId, componentEditIN, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ComponentsApi - object-oriented interface
 * @export
 * @class ComponentsApi
 * @extends {BaseAPI}
 */
export class ComponentsApi extends BaseAPI {
    /**
     *
     * @summary Check Component Compliance
     * @param {ComponentComplianceIN} componentComplianceIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentsApi
     */
    public inventoryApiV1RoutersComponentsCheckComponentCompliance(componentComplianceIN: ComponentComplianceIN, options?: AxiosRequestConfig) {
        return ComponentsApiFp(this.configuration).inventoryApiV1RoutersComponentsCheckComponentCompliance(componentComplianceIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Check Component Din Category
     * @param {ComponentDINCategoryIN} componentDINCategoryIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentsApi
     */
    public inventoryApiV1RoutersComponentsCheckComponentDinCategory(componentDINCategoryIN: ComponentDINCategoryIN, options?: AxiosRequestConfig) {
        return ComponentsApiFp(this.configuration).inventoryApiV1RoutersComponentsCheckComponentDinCategory(componentDINCategoryIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Check Product Compliance
     * @param {string} productId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentsApi
     */
    public inventoryApiV1RoutersComponentsCheckProductCompliance(productId: string, options?: AxiosRequestConfig) {
        return ComponentsApiFp(this.configuration).inventoryApiV1RoutersComponentsCheckProductCompliance(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Create Component
     * @param {ComponentCreateIN} componentCreateIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentsApi
     */
    public inventoryApiV1RoutersComponentsCreateComponent(componentCreateIN: ComponentCreateIN, options?: AxiosRequestConfig) {
        return ComponentsApiFp(this.configuration).inventoryApiV1RoutersComponentsCreateComponent(componentCreateIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Delete Component
     * @param {string} componentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentsApi
     */
    public inventoryApiV1RoutersComponentsDeleteComponent(componentId: string, options?: AxiosRequestConfig) {
        return ComponentsApiFp(this.configuration).inventoryApiV1RoutersComponentsDeleteComponent(componentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Details By File
     * @param {string} componentId
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentsApi
     */
    public inventoryApiV1RoutersComponentsDetailsByFile(componentId: string, fileId: string, options?: AxiosRequestConfig) {
        return ComponentsApiFp(this.configuration).inventoryApiV1RoutersComponentsDetailsByFile(componentId, fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Edit Component
     * @param {string} componentId
     * @param {ComponentEditIN} componentEditIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentsApi
     */
    public inventoryApiV1RoutersComponentsEditComponent(componentId: string, componentEditIN: ComponentEditIN, options?: AxiosRequestConfig) {
        return ComponentsApiFp(this.configuration).inventoryApiV1RoutersComponentsEditComponent(componentId, componentEditIN, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DGNBApi - axios parameter creator
 * @export
 */
export const DGNBApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a resource passport and returns the file
         * @summary Passport Create
         * @param {string} buildingUuid
         * @param {ReportApiV1RoutersDgnbPassportCreateOutputTypeEnum} outputType Output types
         * @param {DGNBPassportIN} dGNBPassportIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportApiV1RoutersDgnbPassportCreate: async (buildingUuid: string, outputType: ReportApiV1RoutersDgnbPassportCreateOutputTypeEnum, dGNBPassportIN: DGNBPassportIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingUuid' is not null or undefined
            assertParamExists('reportApiV1RoutersDgnbPassportCreate', 'buildingUuid', buildingUuid)
            // verify required parameter 'outputType' is not null or undefined
            assertParamExists('reportApiV1RoutersDgnbPassportCreate', 'outputType', outputType)
            // verify required parameter 'dGNBPassportIN' is not null or undefined
            assertParamExists('reportApiV1RoutersDgnbPassportCreate', 'dGNBPassportIN', dGNBPassportIN)
            const localVarPath = `/api/v1/report/dgnb/passport/{building_uuid}`
                .replace(`{${"building_uuid"}}`, encodeURIComponent(String(buildingUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (outputType !== undefined) {
                localVarQueryParameter['output_type'] = outputType;
            }



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dGNBPassportIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return the latest dgnb passport for a specific building with all the info
         * @summary Passport Latest
         * @param {string} buildingUuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportApiV1RoutersDgnbPassportLatest: async (buildingUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingUuid' is not null or undefined
            assertParamExists('reportApiV1RoutersDgnbPassportLatest', 'buildingUuid', buildingUuid)
            const localVarPath = `/api/v1/report/dgnb/passport/{building_uuid}/latest`
                .replace(`{${"building_uuid"}}`, encodeURIComponent(String(buildingUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DGNBApi - functional programming interface
 * @export
 */
export const DGNBApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DGNBApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a resource passport and returns the file
         * @summary Passport Create
         * @param {string} buildingUuid
         * @param {ReportApiV1RoutersDgnbPassportCreateOutputTypeEnum} outputType Output types
         * @param {DGNBPassportIN} dGNBPassportIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportApiV1RoutersDgnbPassportCreate(buildingUuid: string, outputType: ReportApiV1RoutersDgnbPassportCreateOutputTypeEnum, dGNBPassportIN: DGNBPassportIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildingReportOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportApiV1RoutersDgnbPassportCreate(buildingUuid, outputType, dGNBPassportIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return the latest dgnb passport for a specific building with all the info
         * @summary Passport Latest
         * @param {string} buildingUuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportApiV1RoutersDgnbPassportLatest(buildingUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DGNBPassportFileOUT>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportApiV1RoutersDgnbPassportLatest(buildingUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DGNBApi - factory interface
 * @export
 */
export const DGNBApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DGNBApiFp(configuration)
    return {
        /**
         * Creates a resource passport and returns the file
         * @summary Passport Create
         * @param {string} buildingUuid
         * @param {ReportApiV1RoutersDgnbPassportCreateOutputTypeEnum} outputType Output types
         * @param {DGNBPassportIN} dGNBPassportIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportApiV1RoutersDgnbPassportCreate(buildingUuid: string, outputType: ReportApiV1RoutersDgnbPassportCreateOutputTypeEnum, dGNBPassportIN: DGNBPassportIN, options?: any): AxiosPromise<BuildingReportOUT> {
            return localVarFp.reportApiV1RoutersDgnbPassportCreate(buildingUuid, outputType, dGNBPassportIN, options).then((request) => request(axios, basePath));
        },
        /**
         * Return the latest dgnb passport for a specific building with all the info
         * @summary Passport Latest
         * @param {string} buildingUuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportApiV1RoutersDgnbPassportLatest(buildingUuid: string, options?: any): AxiosPromise<Array<DGNBPassportFileOUT>> {
            return localVarFp.reportApiV1RoutersDgnbPassportLatest(buildingUuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DGNBApi - object-oriented interface
 * @export
 * @class DGNBApi
 * @extends {BaseAPI}
 */
export class DGNBApi extends BaseAPI {
    /**
     * Creates a resource passport and returns the file
     * @summary Passport Create
     * @param {string} buildingUuid
     * @param {ReportApiV1RoutersDgnbPassportCreateOutputTypeEnum} outputType Output types
     * @param {DGNBPassportIN} dGNBPassportIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DGNBApi
     */
    public reportApiV1RoutersDgnbPassportCreate(buildingUuid: string, outputType: ReportApiV1RoutersDgnbPassportCreateOutputTypeEnum, dGNBPassportIN: DGNBPassportIN, options?: AxiosRequestConfig) {
        return DGNBApiFp(this.configuration).reportApiV1RoutersDgnbPassportCreate(buildingUuid, outputType, dGNBPassportIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return the latest dgnb passport for a specific building with all the info
     * @summary Passport Latest
     * @param {string} buildingUuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DGNBApi
     */
    public reportApiV1RoutersDgnbPassportLatest(buildingUuid: string, options?: AxiosRequestConfig) {
        return DGNBApiFp(this.configuration).reportApiV1RoutersDgnbPassportLatest(buildingUuid, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ReportApiV1RoutersDgnbPassportCreateOutputTypeEnum = {
    Excel: 'EXCEL',
    Pdf: 'PDF',
    Txt: 'TXT',
    None: 'NONE'
} as const;
export type ReportApiV1RoutersDgnbPassportCreateOutputTypeEnum = typeof ReportApiV1RoutersDgnbPassportCreateOutputTypeEnum[keyof typeof ReportApiV1RoutersDgnbPassportCreateOutputTypeEnum];


/**
 * DatasetCheckerApi - axios parameter creator
 * @export
 */
export const DatasetCheckerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns how many physical objects in an IFC file are compliant with the building\'s certification.
         * @summary Check Dataset Compliance
         * @param {DatasetCheckerIN} datasetCheckerIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersDatasetCheckerCheckDatasetCompliance: async (datasetCheckerIN: DatasetCheckerIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetCheckerIN' is not null or undefined
            assertParamExists('inventoryApiV1RoutersDatasetCheckerCheckDatasetCompliance', 'datasetCheckerIN', datasetCheckerIN)
            const localVarPath = `/api/v1/inventory/dataset_checker/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(datasetCheckerIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DatasetCheckerApi - functional programming interface
 * @export
 */
export const DatasetCheckerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DatasetCheckerApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns how many physical objects in an IFC file are compliant with the building\'s certification.
         * @summary Check Dataset Compliance
         * @param {DatasetCheckerIN} datasetCheckerIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersDatasetCheckerCheckDatasetCompliance(datasetCheckerIN: DatasetCheckerIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetCheckerOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersDatasetCheckerCheckDatasetCompliance(datasetCheckerIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DatasetCheckerApi - factory interface
 * @export
 */
export const DatasetCheckerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DatasetCheckerApiFp(configuration)
    return {
        /**
         * Returns how many physical objects in an IFC file are compliant with the building\'s certification.
         * @summary Check Dataset Compliance
         * @param {DatasetCheckerIN} datasetCheckerIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersDatasetCheckerCheckDatasetCompliance(datasetCheckerIN: DatasetCheckerIN, options?: any): AxiosPromise<DatasetCheckerOUT> {
            return localVarFp.inventoryApiV1RoutersDatasetCheckerCheckDatasetCompliance(datasetCheckerIN, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DatasetCheckerApi - object-oriented interface
 * @export
 * @class DatasetCheckerApi
 * @extends {BaseAPI}
 */
export class DatasetCheckerApi extends BaseAPI {
    /**
     * Returns how many physical objects in an IFC file are compliant with the building\'s certification.
     * @summary Check Dataset Compliance
     * @param {DatasetCheckerIN} datasetCheckerIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetCheckerApi
     */
    public inventoryApiV1RoutersDatasetCheckerCheckDatasetCompliance(datasetCheckerIN: DatasetCheckerIN, options?: AxiosRequestConfig) {
        return DatasetCheckerApiFp(this.configuration).inventoryApiV1RoutersDatasetCheckerCheckDatasetCompliance(datasetCheckerIN, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DetailReportTileApi - axios parameter creator
 * @export
 */
export const DetailReportTileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Fetch report tile data by report key.
         * @summary Fetch Report Tile
         * @param {FetchReportTileIN} fetchReportTileIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersDetailReportTileFetchReportTile: async (fetchReportTileIN: FetchReportTileIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fetchReportTileIN' is not null or undefined
            assertParamExists('inventoryApiV1RoutersDetailReportTileFetchReportTile', 'fetchReportTileIN', fetchReportTileIN)
            const localVarPath = `/api/v1/inventory/detail_report_tile/fetch_report_tile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fetchReportTileIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DetailReportTileApi - functional programming interface
 * @export
 */
export const DetailReportTileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DetailReportTileApiAxiosParamCreator(configuration)
    return {
        /**
         * Fetch report tile data by report key.
         * @summary Fetch Report Tile
         * @param {FetchReportTileIN} fetchReportTileIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersDetailReportTileFetchReportTile(fetchReportTileIN: FetchReportTileIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FetchReportTileOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersDetailReportTileFetchReportTile(fetchReportTileIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DetailReportTileApi - factory interface
 * @export
 */
export const DetailReportTileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DetailReportTileApiFp(configuration)
    return {
        /**
         * Fetch report tile data by report key.
         * @summary Fetch Report Tile
         * @param {FetchReportTileIN} fetchReportTileIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersDetailReportTileFetchReportTile(fetchReportTileIN: FetchReportTileIN, options?: any): AxiosPromise<FetchReportTileOUT> {
            return localVarFp.inventoryApiV1RoutersDetailReportTileFetchReportTile(fetchReportTileIN, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DetailReportTileApi - object-oriented interface
 * @export
 * @class DetailReportTileApi
 * @extends {BaseAPI}
 */
export class DetailReportTileApi extends BaseAPI {
    /**
     * Fetch report tile data by report key.
     * @summary Fetch Report Tile
     * @param {FetchReportTileIN} fetchReportTileIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetailReportTileApi
     */
    public inventoryApiV1RoutersDetailReportTileFetchReportTile(fetchReportTileIN: FetchReportTileIN, options?: AxiosRequestConfig) {
        return DetailReportTileApiFp(this.configuration).inventoryApiV1RoutersDetailReportTileFetchReportTile(fetchReportTileIN, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DynamicMappingEditorApi - axios parameter creator
 * @export
 */
export const DynamicMappingEditorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns objects by file_id that matches the filter
         * @summary Filtered Object Ids
         * @param {string} fileId
         * @param {ObjectsFilterIN} objectsFilterIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mappingEditorApiV1RoutersIndexFilterFilteredObjectIds: async (fileId: string, objectsFilterIN: ObjectsFilterIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('mappingEditorApiV1RoutersIndexFilterFilteredObjectIds', 'fileId', fileId)
            // verify required parameter 'objectsFilterIN' is not null or undefined
            assertParamExists('mappingEditorApiV1RoutersIndexFilterFilteredObjectIds', 'objectsFilterIN', objectsFilterIN)
            const localVarPath = `/api/v1/mapping_editor/filtering/{file_id}/index_filter`
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(objectsFilterIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the properties that can be filtered per file
         * @summary Property Browser Full
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mappingEditorApiV1RoutersIndexFilterPropertyBrowserFull: async (fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('mappingEditorApiV1RoutersIndexFilterPropertyBrowserFull', 'fileId', fileId)
            const localVarPath = `/api/v1/mapping_editor/filtering/{file_id}/property_browser_full`
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DynamicMappingEditorApi - functional programming interface
 * @export
 */
export const DynamicMappingEditorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DynamicMappingEditorApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns objects by file_id that matches the filter
         * @summary Filtered Object Ids
         * @param {string} fileId
         * @param {ObjectsFilterIN} objectsFilterIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mappingEditorApiV1RoutersIndexFilterFilteredObjectIds(fileId: string, objectsFilterIN: ObjectsFilterIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectsFilterOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mappingEditorApiV1RoutersIndexFilterFilteredObjectIds(fileId, objectsFilterIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all the properties that can be filtered per file
         * @summary Property Browser Full
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mappingEditorApiV1RoutersIndexFilterPropertyBrowserFull(fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PropertyBrowserTreeNode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mappingEditorApiV1RoutersIndexFilterPropertyBrowserFull(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DynamicMappingEditorApi - factory interface
 * @export
 */
export const DynamicMappingEditorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DynamicMappingEditorApiFp(configuration)
    return {
        /**
         * Returns objects by file_id that matches the filter
         * @summary Filtered Object Ids
         * @param {string} fileId
         * @param {ObjectsFilterIN} objectsFilterIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mappingEditorApiV1RoutersIndexFilterFilteredObjectIds(fileId: string, objectsFilterIN: ObjectsFilterIN, options?: any): AxiosPromise<ObjectsFilterOUT> {
            return localVarFp.mappingEditorApiV1RoutersIndexFilterFilteredObjectIds(fileId, objectsFilterIN, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the properties that can be filtered per file
         * @summary Property Browser Full
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mappingEditorApiV1RoutersIndexFilterPropertyBrowserFull(fileId: string, options?: any): AxiosPromise<Array<PropertyBrowserTreeNode>> {
            return localVarFp.mappingEditorApiV1RoutersIndexFilterPropertyBrowserFull(fileId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DynamicMappingEditorApi - object-oriented interface
 * @export
 * @class DynamicMappingEditorApi
 * @extends {BaseAPI}
 */
export class DynamicMappingEditorApi extends BaseAPI {
    /**
     * Returns objects by file_id that matches the filter
     * @summary Filtered Object Ids
     * @param {string} fileId
     * @param {ObjectsFilterIN} objectsFilterIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicMappingEditorApi
     */
    public mappingEditorApiV1RoutersIndexFilterFilteredObjectIds(fileId: string, objectsFilterIN: ObjectsFilterIN, options?: AxiosRequestConfig) {
        return DynamicMappingEditorApiFp(this.configuration).mappingEditorApiV1RoutersIndexFilterFilteredObjectIds(fileId, objectsFilterIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the properties that can be filtered per file
     * @summary Property Browser Full
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicMappingEditorApi
     */
    public mappingEditorApiV1RoutersIndexFilterPropertyBrowserFull(fileId: string, options?: AxiosRequestConfig) {
        return DynamicMappingEditorApiFp(this.configuration).mappingEditorApiV1RoutersIndexFilterPropertyBrowserFull(fileId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FileOperationsApi - axios parameter creator
 * @export
 */
export const FileOperationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Run File Operations
         * @param {string} fileId
         * @param {FileOperationIN} fileOperationIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mappingEditorApiV1RoutersFileOperationsRunFileOperations: async (fileId: string, fileOperationIN: FileOperationIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('mappingEditorApiV1RoutersFileOperationsRunFileOperations', 'fileId', fileId)
            // verify required parameter 'fileOperationIN' is not null or undefined
            assertParamExists('mappingEditorApiV1RoutersFileOperationsRunFileOperations', 'fileOperationIN', fileOperationIN)
            const localVarPath = `/api/v1/mapping_editor/file_operations/file/{file_id}`
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileOperationIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileOperationsApi - functional programming interface
 * @export
 */
export const FileOperationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileOperationsApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Run File Operations
         * @param {string} fileId
         * @param {FileOperationIN} fileOperationIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mappingEditorApiV1RoutersFileOperationsRunFileOperations(fileId: string, fileOperationIN: FileOperationIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectListOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mappingEditorApiV1RoutersFileOperationsRunFileOperations(fileId, fileOperationIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileOperationsApi - factory interface
 * @export
 */
export const FileOperationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileOperationsApiFp(configuration)
    return {
        /**
         *
         * @summary Run File Operations
         * @param {string} fileId
         * @param {FileOperationIN} fileOperationIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mappingEditorApiV1RoutersFileOperationsRunFileOperations(fileId: string, fileOperationIN: FileOperationIN, options?: any): AxiosPromise<ObjectListOUT> {
            return localVarFp.mappingEditorApiV1RoutersFileOperationsRunFileOperations(fileId, fileOperationIN, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileOperationsApi - object-oriented interface
 * @export
 * @class FileOperationsApi
 * @extends {BaseAPI}
 */
export class FileOperationsApi extends BaseAPI {
    /**
     *
     * @summary Run File Operations
     * @param {string} fileId
     * @param {FileOperationIN} fileOperationIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileOperationsApi
     */
    public mappingEditorApiV1RoutersFileOperationsRunFileOperations(fileId: string, fileOperationIN: FileOperationIN, options?: AxiosRequestConfig) {
        return FileOperationsApiFp(this.configuration).mappingEditorApiV1RoutersFileOperationsRunFileOperations(fileId, fileOperationIN, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * IFCFileApi - axios parameter creator
 * @export
 */
export const IFCFileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete an IFC file. It will take a while to clean up all the stuffs related to file.
         * @summary Delete Ifc File
         * @param {string} ifcFileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ifcApiV1RoutersIfcFileDeleteIfcFile: async (ifcFileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ifcFileId' is not null or undefined
            assertParamExists('ifcApiV1RoutersIfcFileDeleteIfcFile', 'ifcFileId', ifcFileId)
            const localVarPath = `/api/v1/ifc/files/{ifc_file_id}`
                .replace(`{${"ifc_file_id"}}`, encodeURIComponent(String(ifcFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Download ifc file that user uploaded.
         * @summary Download Ifc File
         * @param {string} ifcFileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ifcApiV1RoutersIfcFileDownloadIfcFile: async (ifcFileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ifcFileId' is not null or undefined
            assertParamExists('ifcApiV1RoutersIfcFileDownloadIfcFile', 'ifcFileId', ifcFileId)
            const localVarPath = `/api/v1/ifc/files/{ifc_file_id}/download`
                .replace(`{${"ifc_file_id"}}`, encodeURIComponent(String(ifcFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of IFC files of the building.
         * @summary Get All Ifc Files
         * @param {string} buildingId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ifcApiV1RoutersIfcFileGetAllIfcFiles: async (buildingId: string, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('ifcApiV1RoutersIfcFileGetAllIfcFiles', 'buildingId', buildingId)
            const localVarPath = `/api/v1/ifc/files/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication AuthAPIKey required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            if (buildingId !== undefined) {
                localVarQueryParameter['building_id'] = buildingId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update reporting status of ifc file.
         * @summary Update Reporting Status
         * @param {string} ifcFileId
         * @param {string} reportingStatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ifcApiV1RoutersIfcFileUpdateReportingStatus: async (ifcFileId: string, reportingStatus: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ifcFileId' is not null or undefined
            assertParamExists('ifcApiV1RoutersIfcFileUpdateReportingStatus', 'ifcFileId', ifcFileId)
            // verify required parameter 'reportingStatus' is not null or undefined
            assertParamExists('ifcApiV1RoutersIfcFileUpdateReportingStatus', 'reportingStatus', reportingStatus)
            const localVarPath = `/api/v1/ifc/files/{ifc_file_id}/reporting_status`
                .replace(`{${"ifc_file_id"}}`, encodeURIComponent(String(ifcFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reportingStatus !== undefined) {
                localVarQueryParameter['reporting_status'] = reportingStatus;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IFCFileApi - functional programming interface
 * @export
 */
export const IFCFileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IFCFileApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete an IFC file. It will take a while to clean up all the stuffs related to file.
         * @summary Delete Ifc File
         * @param {string} ifcFileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ifcApiV1RoutersIfcFileDeleteIfcFile(ifcFileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ifcApiV1RoutersIfcFileDeleteIfcFile(ifcFileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Download ifc file that user uploaded.
         * @summary Download Ifc File
         * @param {string} ifcFileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ifcApiV1RoutersIfcFileDownloadIfcFile(ifcFileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFCFileOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ifcApiV1RoutersIfcFileDownloadIfcFile(ifcFileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get list of IFC files of the building.
         * @summary Get All Ifc Files
         * @param {string} buildingId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ifcApiV1RoutersIfcFileGetAllIfcFiles(buildingId: string, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedIFCFileOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ifcApiV1RoutersIfcFileGetAllIfcFiles(buildingId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update reporting status of ifc file.
         * @summary Update Reporting Status
         * @param {string} ifcFileId
         * @param {string} reportingStatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ifcApiV1RoutersIfcFileUpdateReportingStatus(ifcFileId: string, reportingStatus: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFCFileOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ifcApiV1RoutersIfcFileUpdateReportingStatus(ifcFileId, reportingStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IFCFileApi - factory interface
 * @export
 */
export const IFCFileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IFCFileApiFp(configuration)
    return {
        /**
         * Delete an IFC file. It will take a while to clean up all the stuffs related to file.
         * @summary Delete Ifc File
         * @param {string} ifcFileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ifcApiV1RoutersIfcFileDeleteIfcFile(ifcFileId: string, options?: any): AxiosPromise<ResultOUT> {
            return localVarFp.ifcApiV1RoutersIfcFileDeleteIfcFile(ifcFileId, options).then((request) => request(axios, basePath));
        },
        /**
         * Download ifc file that user uploaded.
         * @summary Download Ifc File
         * @param {string} ifcFileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ifcApiV1RoutersIfcFileDownloadIfcFile(ifcFileId: string, options?: any): AxiosPromise<IFCFileOUT> {
            return localVarFp.ifcApiV1RoutersIfcFileDownloadIfcFile(ifcFileId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get list of IFC files of the building.
         * @summary Get All Ifc Files
         * @param {string} buildingId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ifcApiV1RoutersIfcFileGetAllIfcFiles(buildingId: string, limit?: number, offset?: number, options?: any): AxiosPromise<PagedIFCFileOUT> {
            return localVarFp.ifcApiV1RoutersIfcFileGetAllIfcFiles(buildingId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Update reporting status of ifc file.
         * @summary Update Reporting Status
         * @param {string} ifcFileId
         * @param {string} reportingStatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ifcApiV1RoutersIfcFileUpdateReportingStatus(ifcFileId: string, reportingStatus: string, options?: any): AxiosPromise<IFCFileOUT> {
            return localVarFp.ifcApiV1RoutersIfcFileUpdateReportingStatus(ifcFileId, reportingStatus, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IFCFileApi - object-oriented interface
 * @export
 * @class IFCFileApi
 * @extends {BaseAPI}
 */
export class IFCFileApi extends BaseAPI {
    /**
     * Delete an IFC file. It will take a while to clean up all the stuffs related to file.
     * @summary Delete Ifc File
     * @param {string} ifcFileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IFCFileApi
     */
    public ifcApiV1RoutersIfcFileDeleteIfcFile(ifcFileId: string, options?: AxiosRequestConfig) {
        return IFCFileApiFp(this.configuration).ifcApiV1RoutersIfcFileDeleteIfcFile(ifcFileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Download ifc file that user uploaded.
     * @summary Download Ifc File
     * @param {string} ifcFileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IFCFileApi
     */
    public ifcApiV1RoutersIfcFileDownloadIfcFile(ifcFileId: string, options?: AxiosRequestConfig) {
        return IFCFileApiFp(this.configuration).ifcApiV1RoutersIfcFileDownloadIfcFile(ifcFileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list of IFC files of the building.
     * @summary Get All Ifc Files
     * @param {string} buildingId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IFCFileApi
     */
    public ifcApiV1RoutersIfcFileGetAllIfcFiles(buildingId: string, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return IFCFileApiFp(this.configuration).ifcApiV1RoutersIfcFileGetAllIfcFiles(buildingId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update reporting status of ifc file.
     * @summary Update Reporting Status
     * @param {string} ifcFileId
     * @param {string} reportingStatus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IFCFileApi
     */
    public ifcApiV1RoutersIfcFileUpdateReportingStatus(ifcFileId: string, reportingStatus: string, options?: AxiosRequestConfig) {
        return IFCFileApiFp(this.configuration).ifcApiV1RoutersIfcFileUpdateReportingStatus(ifcFileId, reportingStatus, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ImagesApi - axios parameter creator
 * @export
 */
export const ImagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add an image to a blueprint album.
         * @summary Add Existing Image To Blueprint Album
         * @param {string} blueprintId
         * @param {string} albumId
         * @param {number} order
         * @param {string} keyFile
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        caApiV1RoutersImagesAddExistingImageToBlueprintAlbum: async (blueprintId: string, albumId: string, order: number, keyFile: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'blueprintId' is not null or undefined
            assertParamExists('caApiV1RoutersImagesAddExistingImageToBlueprintAlbum', 'blueprintId', blueprintId)
            // verify required parameter 'albumId' is not null or undefined
            assertParamExists('caApiV1RoutersImagesAddExistingImageToBlueprintAlbum', 'albumId', albumId)
            // verify required parameter 'order' is not null or undefined
            assertParamExists('caApiV1RoutersImagesAddExistingImageToBlueprintAlbum', 'order', order)
            // verify required parameter 'keyFile' is not null or undefined
            assertParamExists('caApiV1RoutersImagesAddExistingImageToBlueprintAlbum', 'keyFile', keyFile)
            const localVarPath = `/api/v1/ca/images/blueprint/{blueprint_id}/albums/{album_id}/existing_images`
                .replace(`{${"blueprint_id"}}`, encodeURIComponent(String(blueprintId)))
                .replace(`{${"album_id"}}`, encodeURIComponent(String(albumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (keyFile !== undefined) {
                localVarQueryParameter['key_file'] = keyFile;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add an image to an instance album.
         * @summary Add Existing Image To Instance Album
         * @param {string} instanceId
         * @param {string} albumId
         * @param {number} order
         * @param {string} keyFile
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        caApiV1RoutersImagesAddExistingImageToInstanceAlbum: async (instanceId: string, albumId: string, order: number, keyFile: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('caApiV1RoutersImagesAddExistingImageToInstanceAlbum', 'instanceId', instanceId)
            // verify required parameter 'albumId' is not null or undefined
            assertParamExists('caApiV1RoutersImagesAddExistingImageToInstanceAlbum', 'albumId', albumId)
            // verify required parameter 'order' is not null or undefined
            assertParamExists('caApiV1RoutersImagesAddExistingImageToInstanceAlbum', 'order', order)
            // verify required parameter 'keyFile' is not null or undefined
            assertParamExists('caApiV1RoutersImagesAddExistingImageToInstanceAlbum', 'keyFile', keyFile)
            const localVarPath = `/api/v1/ca/images/instance/{instance_id}/albums/{album_id}/existing_images`
                .replace(`{${"instance_id"}}`, encodeURIComponent(String(instanceId)))
                .replace(`{${"album_id"}}`, encodeURIComponent(String(albumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (keyFile !== undefined) {
                localVarQueryParameter['key_file'] = keyFile;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add an image to a blueprint album.
         * @summary Add Image To Blueprint Album
         * @param {string} blueprintId
         * @param {string} albumId
         * @param {number} order
         * @param {File} file
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        caApiV1RoutersImagesAddImageToBlueprintAlbum: async (blueprintId: string, albumId: string, order: number, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'blueprintId' is not null or undefined
            assertParamExists('caApiV1RoutersImagesAddImageToBlueprintAlbum', 'blueprintId', blueprintId)
            // verify required parameter 'albumId' is not null or undefined
            assertParamExists('caApiV1RoutersImagesAddImageToBlueprintAlbum', 'albumId', albumId)
            // verify required parameter 'order' is not null or undefined
            assertParamExists('caApiV1RoutersImagesAddImageToBlueprintAlbum', 'order', order)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('caApiV1RoutersImagesAddImageToBlueprintAlbum', 'file', file)
            const localVarPath = `/api/v1/ca/images/blueprint/{blueprint_id}/albums/{album_id}/images`
                .replace(`{${"blueprint_id"}}`, encodeURIComponent(String(blueprintId)))
                .replace(`{${"album_id"}}`, encodeURIComponent(String(albumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add an image to an instance album.
         * @summary Add Image To Instance Album
         * @param {string} instanceId
         * @param {string} albumId
         * @param {number} order
         * @param {File} file
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        caApiV1RoutersImagesAddImageToInstanceAlbum: async (instanceId: string, albumId: string, order: number, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('caApiV1RoutersImagesAddImageToInstanceAlbum', 'instanceId', instanceId)
            // verify required parameter 'albumId' is not null or undefined
            assertParamExists('caApiV1RoutersImagesAddImageToInstanceAlbum', 'albumId', albumId)
            // verify required parameter 'order' is not null or undefined
            assertParamExists('caApiV1RoutersImagesAddImageToInstanceAlbum', 'order', order)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('caApiV1RoutersImagesAddImageToInstanceAlbum', 'file', file)
            const localVarPath = `/api/v1/ca/images/instance/{instance_id}/albums/{album_id}/images`
                .replace(`{${"instance_id"}}`, encodeURIComponent(String(instanceId)))
                .replace(`{${"album_id"}}`, encodeURIComponent(String(albumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a blueprint album.
         * @summary Create Blueprint Album
         * @param {string} blueprintId
         * @param {string} albumName
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        caApiV1RoutersImagesCreateBlueprintAlbum: async (blueprintId: string, albumName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'blueprintId' is not null or undefined
            assertParamExists('caApiV1RoutersImagesCreateBlueprintAlbum', 'blueprintId', blueprintId)
            // verify required parameter 'albumName' is not null or undefined
            assertParamExists('caApiV1RoutersImagesCreateBlueprintAlbum', 'albumName', albumName)
            const localVarPath = `/api/v1/ca/images/blueprint/{blueprint_id}/albums/{album_name}`
                .replace(`{${"blueprint_id"}}`, encodeURIComponent(String(blueprintId)))
                .replace(`{${"album_name"}}`, encodeURIComponent(String(albumName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create an instance album.
         * @summary Create Instance Album
         * @param {string} instanceId
         * @param {string} albumName
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        caApiV1RoutersImagesCreateInstanceAlbum: async (instanceId: string, albumName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('caApiV1RoutersImagesCreateInstanceAlbum', 'instanceId', instanceId)
            // verify required parameter 'albumName' is not null or undefined
            assertParamExists('caApiV1RoutersImagesCreateInstanceAlbum', 'albumName', albumName)
            const localVarPath = `/api/v1/ca/images/instance/{instance_id}/albums/{album_name}`
                .replace(`{${"instance_id"}}`, encodeURIComponent(String(instanceId)))
                .replace(`{${"album_name"}}`, encodeURIComponent(String(albumName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an image from a blueprint album.
         * @summary Delete Image From Blueprint Album
         * @param {string} imageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersImagesDeleteImageFromBlueprintAlbum: async (imageId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageId' is not null or undefined
            assertParamExists('caApiV1RoutersImagesDeleteImageFromBlueprintAlbum', 'imageId', imageId)
            const localVarPath = `/api/v1/ca/images/{image_id}`
                .replace(`{${"image_id"}}`, encodeURIComponent(String(imageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all blueprint albums.
         * @summary Get Blueprint Albums
         * @param {string} blueprintId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersImagesGetBlueprintAlbums: async (blueprintId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'blueprintId' is not null or undefined
            assertParamExists('caApiV1RoutersImagesGetBlueprintAlbums', 'blueprintId', blueprintId)
            const localVarPath = `/api/v1/ca/images/blueprint/{blueprint_id}/albums`
                .replace(`{${"blueprint_id"}}`, encodeURIComponent(String(blueprintId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all instance albums.
         * @summary Get Instance Albums
         * @param {string} instanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersImagesGetInstanceAlbums: async (instanceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('caApiV1RoutersImagesGetInstanceAlbums', 'instanceId', instanceId)
            const localVarPath = `/api/v1/ca/images/instance/{instance_id}/albums`
                .replace(`{${"instance_id"}}`, encodeURIComponent(String(instanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Registers an image to a blueprint album. It will return a presigned url where the frontend must upload the asset to.
         * @summary Register Image To Blueprint
         * @param {string} blueprintId
         * @param {string} imageFilename
         * @param {number} [order]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersImagesRegisterImageToBlueprint: async (blueprintId: string, imageFilename: string, order?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'blueprintId' is not null or undefined
            assertParamExists('caApiV1RoutersImagesRegisterImageToBlueprint', 'blueprintId', blueprintId)
            // verify required parameter 'imageFilename' is not null or undefined
            assertParamExists('caApiV1RoutersImagesRegisterImageToBlueprint', 'imageFilename', imageFilename)
            const localVarPath = `/api/v1/ca/images/blueprint/{blueprint_id}/register_image`
                .replace(`{${"blueprint_id"}}`, encodeURIComponent(String(blueprintId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (imageFilename !== undefined) {
                localVarQueryParameter['image_filename'] = imageFilename;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Registers an image to an instance album. It will return a presigned url where the frontend must upload the asset to.
         * @summary Register Image To Instance
         * @param {string} instanceId
         * @param {string} imageFilename
         * @param {number} [order]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersImagesRegisterImageToInstance: async (instanceId: string, imageFilename: string, order?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('caApiV1RoutersImagesRegisterImageToInstance', 'instanceId', instanceId)
            // verify required parameter 'imageFilename' is not null or undefined
            assertParamExists('caApiV1RoutersImagesRegisterImageToInstance', 'imageFilename', imageFilename)
            const localVarPath = `/api/v1/ca/images/instance/{instance_id}/register_image`
                .replace(`{${"instance_id"}}`, encodeURIComponent(String(instanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (imageFilename !== undefined) {
                localVarQueryParameter['image_filename'] = imageFilename;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reorder an image in a blueprint or instance album.
         * @summary Reorder Image
         * @param {string} imageId
         * @param {number} order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersImagesReorderImage: async (imageId: string, order: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageId' is not null or undefined
            assertParamExists('caApiV1RoutersImagesReorderImage', 'imageId', imageId)
            // verify required parameter 'order' is not null or undefined
            assertParamExists('caApiV1RoutersImagesReorderImage', 'order', order)
            const localVarPath = `/api/v1/ca/images/update/{image_id}`
                .replace(`{${"image_id"}}`, encodeURIComponent(String(imageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImagesApi - functional programming interface
 * @export
 */
export const ImagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImagesApiAxiosParamCreator(configuration)
    return {
        /**
         * Add an image to a blueprint album.
         * @summary Add Existing Image To Blueprint Album
         * @param {string} blueprintId
         * @param {string} albumId
         * @param {number} order
         * @param {string} keyFile
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async caApiV1RoutersImagesAddExistingImageToBlueprintAlbum(blueprintId: string, albumId: string, order: number, keyFile: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersImagesAddExistingImageToBlueprintAlbum(blueprintId, albumId, order, keyFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add an image to an instance album.
         * @summary Add Existing Image To Instance Album
         * @param {string} instanceId
         * @param {string} albumId
         * @param {number} order
         * @param {string} keyFile
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async caApiV1RoutersImagesAddExistingImageToInstanceAlbum(instanceId: string, albumId: string, order: number, keyFile: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersImagesAddExistingImageToInstanceAlbum(instanceId, albumId, order, keyFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add an image to a blueprint album.
         * @summary Add Image To Blueprint Album
         * @param {string} blueprintId
         * @param {string} albumId
         * @param {number} order
         * @param {File} file
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async caApiV1RoutersImagesAddImageToBlueprintAlbum(blueprintId: string, albumId: string, order: number, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersImagesAddImageToBlueprintAlbum(blueprintId, albumId, order, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add an image to an instance album.
         * @summary Add Image To Instance Album
         * @param {string} instanceId
         * @param {string} albumId
         * @param {number} order
         * @param {File} file
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async caApiV1RoutersImagesAddImageToInstanceAlbum(instanceId: string, albumId: string, order: number, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersImagesAddImageToInstanceAlbum(instanceId, albumId, order, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a blueprint album.
         * @summary Create Blueprint Album
         * @param {string} blueprintId
         * @param {string} albumName
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async caApiV1RoutersImagesCreateBlueprintAlbum(blueprintId: string, albumName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageAlbumOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersImagesCreateBlueprintAlbum(blueprintId, albumName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create an instance album.
         * @summary Create Instance Album
         * @param {string} instanceId
         * @param {string} albumName
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async caApiV1RoutersImagesCreateInstanceAlbum(instanceId: string, albumName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageAlbumOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersImagesCreateInstanceAlbum(instanceId, albumName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an image from a blueprint album.
         * @summary Delete Image From Blueprint Album
         * @param {string} imageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersImagesDeleteImageFromBlueprintAlbum(imageId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersImagesDeleteImageFromBlueprintAlbum(imageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all blueprint albums.
         * @summary Get Blueprint Albums
         * @param {string} blueprintId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersImagesGetBlueprintAlbums(blueprintId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImageAlbumOUT>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersImagesGetBlueprintAlbums(blueprintId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all instance albums.
         * @summary Get Instance Albums
         * @param {string} instanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersImagesGetInstanceAlbums(instanceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImageAlbumOUT>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersImagesGetInstanceAlbums(instanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Registers an image to a blueprint album. It will return a presigned url where the frontend must upload the asset to.
         * @summary Register Image To Blueprint
         * @param {string} blueprintId
         * @param {string} imageFilename
         * @param {number} [order]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersImagesRegisterImageToBlueprint(blueprintId: string, imageFilename: string, order?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageUploadOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersImagesRegisterImageToBlueprint(blueprintId, imageFilename, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Registers an image to an instance album. It will return a presigned url where the frontend must upload the asset to.
         * @summary Register Image To Instance
         * @param {string} instanceId
         * @param {string} imageFilename
         * @param {number} [order]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersImagesRegisterImageToInstance(instanceId: string, imageFilename: string, order?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageUploadOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersImagesRegisterImageToInstance(instanceId, imageFilename, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Reorder an image in a blueprint or instance album.
         * @summary Reorder Image
         * @param {string} imageId
         * @param {number} order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersImagesReorderImage(imageId: string, order: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersImagesReorderImage(imageId, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImagesApi - factory interface
 * @export
 */
export const ImagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImagesApiFp(configuration)
    return {
        /**
         * Add an image to a blueprint album.
         * @summary Add Existing Image To Blueprint Album
         * @param {string} blueprintId
         * @param {string} albumId
         * @param {number} order
         * @param {string} keyFile
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        caApiV1RoutersImagesAddExistingImageToBlueprintAlbum(blueprintId: string, albumId: string, order: number, keyFile: string, options?: any): AxiosPromise<ImageOUT> {
            return localVarFp.caApiV1RoutersImagesAddExistingImageToBlueprintAlbum(blueprintId, albumId, order, keyFile, options).then((request) => request(axios, basePath));
        },
        /**
         * Add an image to an instance album.
         * @summary Add Existing Image To Instance Album
         * @param {string} instanceId
         * @param {string} albumId
         * @param {number} order
         * @param {string} keyFile
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        caApiV1RoutersImagesAddExistingImageToInstanceAlbum(instanceId: string, albumId: string, order: number, keyFile: string, options?: any): AxiosPromise<ImageOUT> {
            return localVarFp.caApiV1RoutersImagesAddExistingImageToInstanceAlbum(instanceId, albumId, order, keyFile, options).then((request) => request(axios, basePath));
        },
        /**
         * Add an image to a blueprint album.
         * @summary Add Image To Blueprint Album
         * @param {string} blueprintId
         * @param {string} albumId
         * @param {number} order
         * @param {File} file
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        caApiV1RoutersImagesAddImageToBlueprintAlbum(blueprintId: string, albumId: string, order: number, file: File, options?: any): AxiosPromise<ImageOUT> {
            return localVarFp.caApiV1RoutersImagesAddImageToBlueprintAlbum(blueprintId, albumId, order, file, options).then((request) => request(axios, basePath));
        },
        /**
         * Add an image to an instance album.
         * @summary Add Image To Instance Album
         * @param {string} instanceId
         * @param {string} albumId
         * @param {number} order
         * @param {File} file
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        caApiV1RoutersImagesAddImageToInstanceAlbum(instanceId: string, albumId: string, order: number, file: File, options?: any): AxiosPromise<ImageOUT> {
            return localVarFp.caApiV1RoutersImagesAddImageToInstanceAlbum(instanceId, albumId, order, file, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a blueprint album.
         * @summary Create Blueprint Album
         * @param {string} blueprintId
         * @param {string} albumName
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        caApiV1RoutersImagesCreateBlueprintAlbum(blueprintId: string, albumName: string, options?: any): AxiosPromise<ImageAlbumOUT> {
            return localVarFp.caApiV1RoutersImagesCreateBlueprintAlbum(blueprintId, albumName, options).then((request) => request(axios, basePath));
        },
        /**
         * Create an instance album.
         * @summary Create Instance Album
         * @param {string} instanceId
         * @param {string} albumName
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        caApiV1RoutersImagesCreateInstanceAlbum(instanceId: string, albumName: string, options?: any): AxiosPromise<ImageAlbumOUT> {
            return localVarFp.caApiV1RoutersImagesCreateInstanceAlbum(instanceId, albumName, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an image from a blueprint album.
         * @summary Delete Image From Blueprint Album
         * @param {string} imageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersImagesDeleteImageFromBlueprintAlbum(imageId: string, options?: any): AxiosPromise<void> {
            return localVarFp.caApiV1RoutersImagesDeleteImageFromBlueprintAlbum(imageId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all blueprint albums.
         * @summary Get Blueprint Albums
         * @param {string} blueprintId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersImagesGetBlueprintAlbums(blueprintId: string, options?: any): AxiosPromise<Array<ImageAlbumOUT>> {
            return localVarFp.caApiV1RoutersImagesGetBlueprintAlbums(blueprintId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all instance albums.
         * @summary Get Instance Albums
         * @param {string} instanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersImagesGetInstanceAlbums(instanceId: string, options?: any): AxiosPromise<Array<ImageAlbumOUT>> {
            return localVarFp.caApiV1RoutersImagesGetInstanceAlbums(instanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Registers an image to a blueprint album. It will return a presigned url where the frontend must upload the asset to.
         * @summary Register Image To Blueprint
         * @param {string} blueprintId
         * @param {string} imageFilename
         * @param {number} [order]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersImagesRegisterImageToBlueprint(blueprintId: string, imageFilename: string, order?: number, options?: any): AxiosPromise<ImageUploadOUT> {
            return localVarFp.caApiV1RoutersImagesRegisterImageToBlueprint(blueprintId, imageFilename, order, options).then((request) => request(axios, basePath));
        },
        /**
         * Registers an image to an instance album. It will return a presigned url where the frontend must upload the asset to.
         * @summary Register Image To Instance
         * @param {string} instanceId
         * @param {string} imageFilename
         * @param {number} [order]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersImagesRegisterImageToInstance(instanceId: string, imageFilename: string, order?: number, options?: any): AxiosPromise<ImageUploadOUT> {
            return localVarFp.caApiV1RoutersImagesRegisterImageToInstance(instanceId, imageFilename, order, options).then((request) => request(axios, basePath));
        },
        /**
         * Reorder an image in a blueprint or instance album.
         * @summary Reorder Image
         * @param {string} imageId
         * @param {number} order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersImagesReorderImage(imageId: string, order: number, options?: any): AxiosPromise<ResultOUT> {
            return localVarFp.caApiV1RoutersImagesReorderImage(imageId, order, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImagesApi - object-oriented interface
 * @export
 * @class ImagesApi
 * @extends {BaseAPI}
 */
export class ImagesApi extends BaseAPI {
    /**
     * Add an image to a blueprint album.
     * @summary Add Existing Image To Blueprint Album
     * @param {string} blueprintId
     * @param {string} albumId
     * @param {number} order
     * @param {string} keyFile
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public caApiV1RoutersImagesAddExistingImageToBlueprintAlbum(blueprintId: string, albumId: string, order: number, keyFile: string, options?: AxiosRequestConfig) {
        return ImagesApiFp(this.configuration).caApiV1RoutersImagesAddExistingImageToBlueprintAlbum(blueprintId, albumId, order, keyFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add an image to an instance album.
     * @summary Add Existing Image To Instance Album
     * @param {string} instanceId
     * @param {string} albumId
     * @param {number} order
     * @param {string} keyFile
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public caApiV1RoutersImagesAddExistingImageToInstanceAlbum(instanceId: string, albumId: string, order: number, keyFile: string, options?: AxiosRequestConfig) {
        return ImagesApiFp(this.configuration).caApiV1RoutersImagesAddExistingImageToInstanceAlbum(instanceId, albumId, order, keyFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add an image to a blueprint album.
     * @summary Add Image To Blueprint Album
     * @param {string} blueprintId
     * @param {string} albumId
     * @param {number} order
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public caApiV1RoutersImagesAddImageToBlueprintAlbum(blueprintId: string, albumId: string, order: number, file: File, options?: AxiosRequestConfig) {
        return ImagesApiFp(this.configuration).caApiV1RoutersImagesAddImageToBlueprintAlbum(blueprintId, albumId, order, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add an image to an instance album.
     * @summary Add Image To Instance Album
     * @param {string} instanceId
     * @param {string} albumId
     * @param {number} order
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public caApiV1RoutersImagesAddImageToInstanceAlbum(instanceId: string, albumId: string, order: number, file: File, options?: AxiosRequestConfig) {
        return ImagesApiFp(this.configuration).caApiV1RoutersImagesAddImageToInstanceAlbum(instanceId, albumId, order, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a blueprint album.
     * @summary Create Blueprint Album
     * @param {string} blueprintId
     * @param {string} albumName
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public caApiV1RoutersImagesCreateBlueprintAlbum(blueprintId: string, albumName: string, options?: AxiosRequestConfig) {
        return ImagesApiFp(this.configuration).caApiV1RoutersImagesCreateBlueprintAlbum(blueprintId, albumName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create an instance album.
     * @summary Create Instance Album
     * @param {string} instanceId
     * @param {string} albumName
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public caApiV1RoutersImagesCreateInstanceAlbum(instanceId: string, albumName: string, options?: AxiosRequestConfig) {
        return ImagesApiFp(this.configuration).caApiV1RoutersImagesCreateInstanceAlbum(instanceId, albumName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an image from a blueprint album.
     * @summary Delete Image From Blueprint Album
     * @param {string} imageId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public caApiV1RoutersImagesDeleteImageFromBlueprintAlbum(imageId: string, options?: AxiosRequestConfig) {
        return ImagesApiFp(this.configuration).caApiV1RoutersImagesDeleteImageFromBlueprintAlbum(imageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all blueprint albums.
     * @summary Get Blueprint Albums
     * @param {string} blueprintId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public caApiV1RoutersImagesGetBlueprintAlbums(blueprintId: string, options?: AxiosRequestConfig) {
        return ImagesApiFp(this.configuration).caApiV1RoutersImagesGetBlueprintAlbums(blueprintId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all instance albums.
     * @summary Get Instance Albums
     * @param {string} instanceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public caApiV1RoutersImagesGetInstanceAlbums(instanceId: string, options?: AxiosRequestConfig) {
        return ImagesApiFp(this.configuration).caApiV1RoutersImagesGetInstanceAlbums(instanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Registers an image to a blueprint album. It will return a presigned url where the frontend must upload the asset to.
     * @summary Register Image To Blueprint
     * @param {string} blueprintId
     * @param {string} imageFilename
     * @param {number} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public caApiV1RoutersImagesRegisterImageToBlueprint(blueprintId: string, imageFilename: string, order?: number, options?: AxiosRequestConfig) {
        return ImagesApiFp(this.configuration).caApiV1RoutersImagesRegisterImageToBlueprint(blueprintId, imageFilename, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Registers an image to an instance album. It will return a presigned url where the frontend must upload the asset to.
     * @summary Register Image To Instance
     * @param {string} instanceId
     * @param {string} imageFilename
     * @param {number} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public caApiV1RoutersImagesRegisterImageToInstance(instanceId: string, imageFilename: string, order?: number, options?: AxiosRequestConfig) {
        return ImagesApiFp(this.configuration).caApiV1RoutersImagesRegisterImageToInstance(instanceId, imageFilename, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reorder an image in a blueprint or instance album.
     * @summary Reorder Image
     * @param {string} imageId
     * @param {number} order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public caApiV1RoutersImagesReorderImage(imageId: string, order: number, options?: AxiosRequestConfig) {
        return ImagesApiFp(this.configuration).caApiV1RoutersImagesReorderImage(imageId, order, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InventoryEPDsApi - axios parameter creator
 * @export
 */
export const InventoryEPDsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Return all certification data and its conformity.
         * @summary Certification Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersEpdCertificationData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/inventory/epds/certification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return all EPD conformity data.
         * @summary Epd Conformity Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersEpdEpdConformityData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/inventory/epds/epd_conformity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Search for EPD entry data by name of EPD entry.
         * @summary Search Epd Data
         * @param {string} term
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersEpdSearchEpdData: async (term: string, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'term' is not null or undefined
            assertParamExists('inventoryApiV1RoutersEpdSearchEpdData', 'term', term)
            const localVarPath = `/api/v1/inventory/epds/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InventoryEPDsApi - functional programming interface
 * @export
 */
export const InventoryEPDsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InventoryEPDsApiAxiosParamCreator(configuration)
    return {
        /**
         * Return all certification data and its conformity.
         * @summary Certification Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersEpdCertificationData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CertificationOut>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersEpdCertificationData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return all EPD conformity data.
         * @summary Epd Conformity Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersEpdEpdConformityData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EPDConformityOut>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersEpdEpdConformityData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Search for EPD entry data by name of EPD entry.
         * @summary Search Epd Data
         * @param {string} term
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersEpdSearchEpdData(term: string, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedInventoryEPDOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersEpdSearchEpdData(term, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InventoryEPDsApi - factory interface
 * @export
 */
export const InventoryEPDsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InventoryEPDsApiFp(configuration)
    return {
        /**
         * Return all certification data and its conformity.
         * @summary Certification Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersEpdCertificationData(options?: any): AxiosPromise<Array<CertificationOut>> {
            return localVarFp.inventoryApiV1RoutersEpdCertificationData(options).then((request) => request(axios, basePath));
        },
        /**
         * Return all EPD conformity data.
         * @summary Epd Conformity Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersEpdEpdConformityData(options?: any): AxiosPromise<Array<EPDConformityOut>> {
            return localVarFp.inventoryApiV1RoutersEpdEpdConformityData(options).then((request) => request(axios, basePath));
        },
        /**
         * Search for EPD entry data by name of EPD entry.
         * @summary Search Epd Data
         * @param {string} term
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersEpdSearchEpdData(term: string, limit?: number, offset?: number, options?: any): AxiosPromise<PagedInventoryEPDOut> {
            return localVarFp.inventoryApiV1RoutersEpdSearchEpdData(term, limit, offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InventoryEPDsApi - object-oriented interface
 * @export
 * @class InventoryEPDsApi
 * @extends {BaseAPI}
 */
export class InventoryEPDsApi extends BaseAPI {
    /**
     * Return all certification data and its conformity.
     * @summary Certification Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryEPDsApi
     */
    public inventoryApiV1RoutersEpdCertificationData(options?: AxiosRequestConfig) {
        return InventoryEPDsApiFp(this.configuration).inventoryApiV1RoutersEpdCertificationData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return all EPD conformity data.
     * @summary Epd Conformity Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryEPDsApi
     */
    public inventoryApiV1RoutersEpdEpdConformityData(options?: AxiosRequestConfig) {
        return InventoryEPDsApiFp(this.configuration).inventoryApiV1RoutersEpdEpdConformityData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Search for EPD entry data by name of EPD entry.
     * @summary Search Epd Data
     * @param {string} term
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryEPDsApi
     */
    public inventoryApiV1RoutersEpdSearchEpdData(term: string, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return InventoryEPDsApiFp(this.configuration).inventoryApiV1RoutersEpdSearchEpdData(term, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InventoryFileApi - axios parameter creator
 * @export
 */
export const InventoryFileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Uploads and processes inventory file .csv and .xlsx
         * @summary Upload And Process Inventory File
         * @param {string} buildingId
         * @param {File} file
         * @param {string} [fileType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersInventoryFileUploadAndProcessInventoryFile: async (buildingId: string, file: File, fileType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('inventoryApiV1RoutersInventoryFileUploadAndProcessInventoryFile', 'buildingId', buildingId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('inventoryApiV1RoutersInventoryFileUploadAndProcessInventoryFile', 'file', file)
            const localVarPath = `/api/v1/inventory/files/upload_file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication AuthAPIKey required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            if (buildingId !== undefined) {
                localVarQueryParameter['building_id'] = buildingId;
            }

            if (fileType !== undefined) {
                localVarQueryParameter['file_type'] = fileType;
            }


            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Uploads and processes inventory file .ifc
         * @summary Upload And Process Inventory Ifc Csv File
         * @param {string} buildingId
         * @param {File} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersInventoryFileUploadAndProcessInventoryIfcCsvFile: async (buildingId: string, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('inventoryApiV1RoutersInventoryFileUploadAndProcessInventoryIfcCsvFile', 'buildingId', buildingId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('inventoryApiV1RoutersInventoryFileUploadAndProcessInventoryIfcCsvFile', 'file', file)
            const localVarPath = `/api/v1/inventory/files/upload_ifc_csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication AuthAPIKey required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            if (buildingId !== undefined) {
                localVarQueryParameter['building_id'] = buildingId;
            }


            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InventoryFileApi - functional programming interface
 * @export
 */
export const InventoryFileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InventoryFileApiAxiosParamCreator(configuration)
    return {
        /**
         * Uploads and processes inventory file .csv and .xlsx
         * @summary Upload And Process Inventory File
         * @param {string} buildingId
         * @param {File} file
         * @param {string} [fileType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersInventoryFileUploadAndProcessInventoryFile(buildingId: string, file: File, fileType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InventoryFileOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersInventoryFileUploadAndProcessInventoryFile(buildingId, file, fileType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Uploads and processes inventory file .ifc
         * @summary Upload And Process Inventory Ifc Csv File
         * @param {string} buildingId
         * @param {File} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersInventoryFileUploadAndProcessInventoryIfcCsvFile(buildingId: string, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InventoryFileOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersInventoryFileUploadAndProcessInventoryIfcCsvFile(buildingId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InventoryFileApi - factory interface
 * @export
 */
export const InventoryFileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InventoryFileApiFp(configuration)
    return {
        /**
         * Uploads and processes inventory file .csv and .xlsx
         * @summary Upload And Process Inventory File
         * @param {string} buildingId
         * @param {File} file
         * @param {string} [fileType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersInventoryFileUploadAndProcessInventoryFile(buildingId: string, file: File, fileType?: string, options?: any): AxiosPromise<InventoryFileOUT> {
            return localVarFp.inventoryApiV1RoutersInventoryFileUploadAndProcessInventoryFile(buildingId, file, fileType, options).then((request) => request(axios, basePath));
        },
        /**
         * Uploads and processes inventory file .ifc
         * @summary Upload And Process Inventory Ifc Csv File
         * @param {string} buildingId
         * @param {File} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersInventoryFileUploadAndProcessInventoryIfcCsvFile(buildingId: string, file: File, options?: any): AxiosPromise<InventoryFileOUT> {
            return localVarFp.inventoryApiV1RoutersInventoryFileUploadAndProcessInventoryIfcCsvFile(buildingId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InventoryFileApi - object-oriented interface
 * @export
 * @class InventoryFileApi
 * @extends {BaseAPI}
 */
export class InventoryFileApi extends BaseAPI {
    /**
     * Uploads and processes inventory file .csv and .xlsx
     * @summary Upload And Process Inventory File
     * @param {string} buildingId
     * @param {File} file
     * @param {string} [fileType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryFileApi
     */
    public inventoryApiV1RoutersInventoryFileUploadAndProcessInventoryFile(buildingId: string, file: File, fileType?: string, options?: AxiosRequestConfig) {
        return InventoryFileApiFp(this.configuration).inventoryApiV1RoutersInventoryFileUploadAndProcessInventoryFile(buildingId, file, fileType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Uploads and processes inventory file .ifc
     * @summary Upload And Process Inventory Ifc Csv File
     * @param {string} buildingId
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryFileApi
     */
    public inventoryApiV1RoutersInventoryFileUploadAndProcessInventoryIfcCsvFile(buildingId: string, file: File, options?: AxiosRequestConfig) {
        return InventoryFileApiFp(this.configuration).inventoryApiV1RoutersInventoryFileUploadAndProcessInventoryIfcCsvFile(buildingId, file, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InventoryMappingEditorApi - axios parameter creator
 * @export
 */
export const InventoryMappingEditorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Sends all the objects to the oracle for automapping, then stores the results and calculates all its metrics
         * @summary Automap File
         * @param {string} ifcFileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersMappingEditorAutomapFile: async (ifcFileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ifcFileId' is not null or undefined
            assertParamExists('inventoryApiV1RoutersMappingEditorAutomapFile', 'ifcFileId', ifcFileId)
            const localVarPath = `/api/v1/inventory/editor/file/{ifc_file_id}/automap_complete`
                .replace(`{${"ifc_file_id"}}`, encodeURIComponent(String(ifcFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sends a list of objects to the oracle for automapping, then stores the results and calculates all its metrics
         * @summary Automap Objects
         * @param {string} ifcFileId
         * @param {Array<string>} objects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersMappingEditorAutomapObjects: async (ifcFileId: string, objects: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ifcFileId' is not null or undefined
            assertParamExists('inventoryApiV1RoutersMappingEditorAutomapObjects', 'ifcFileId', ifcFileId)
            // verify required parameter 'objects' is not null or undefined
            assertParamExists('inventoryApiV1RoutersMappingEditorAutomapObjects', 'objects', objects)
            const localVarPath = `/api/v1/inventory/editor/file/{ifc_file_id}/automap_objects`
                .replace(`{${"ifc_file_id"}}`, encodeURIComponent(String(ifcFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (objects) {
                localVarFormParams.set('objects', objects.join(COLLECTION_FORMATS.csv));
            }



            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the values for a specific column in a single ifc file
         * @summary Get Available Values
         * @param {string} ifcFileId
         * @param {string} [column]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersMappingEditorGetAvailableValues: async (ifcFileId: string, column?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ifcFileId' is not null or undefined
            assertParamExists('inventoryApiV1RoutersMappingEditorGetAvailableValues', 'ifcFileId', ifcFileId)
            const localVarPath = `/api/v1/inventory/editor/file/get_values/{ifc_file_id}`
                .replace(`{${"ifc_file_id"}}`, encodeURIComponent(String(ifcFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (column !== undefined) {
                localVarQueryParameter['column'] = column;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of categories, either by component or name
         * @summary Get Categories Grouped
         * @param {string} ifcFileId
         * @param {string} groupBy
         * @param {string} [mappedStatus]
         * @param {string} [ifcEntityFilter]
         * @param {string} [materialFilter]
         * @param {string} [componentFilter]
         * @param {string} [sortBy]
         * @param {string} [sortDirection]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersMappingEditorGetCategoriesGrouped: async (ifcFileId: string, groupBy: string, mappedStatus?: string, ifcEntityFilter?: string, materialFilter?: string, componentFilter?: string, sortBy?: string, sortDirection?: string, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ifcFileId' is not null or undefined
            assertParamExists('inventoryApiV1RoutersMappingEditorGetCategoriesGrouped', 'ifcFileId', ifcFileId)
            // verify required parameter 'groupBy' is not null or undefined
            assertParamExists('inventoryApiV1RoutersMappingEditorGetCategoriesGrouped', 'groupBy', groupBy)
            const localVarPath = `/api/v1/inventory/editor/file/{ifc_file_id}/grouped_by`
                .replace(`{${"ifc_file_id"}}`, encodeURIComponent(String(ifcFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (groupBy !== undefined) {
                localVarQueryParameter['group_by'] = groupBy;
            }

            if (mappedStatus !== undefined) {
                localVarQueryParameter['mapped_status'] = mappedStatus;
            }

            if (ifcEntityFilter !== undefined) {
                localVarQueryParameter['ifc_entity_filter'] = ifcEntityFilter;
            }

            if (materialFilter !== undefined) {
                localVarQueryParameter['material_filter'] = materialFilter;
            }

            if (componentFilter !== undefined) {
                localVarQueryParameter['component_filter'] = componentFilter;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sort_direction'] = sortDirection;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get mapping status of an IFC file
         * @summary Get File Status
         * @param {string} ifcFileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersMappingEditorGetFileStatus: async (ifcFileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ifcFileId' is not null or undefined
            assertParamExists('inventoryApiV1RoutersMappingEditorGetFileStatus', 'ifcFileId', ifcFileId)
            const localVarPath = `/api/v1/inventory/editor/file/{ifc_file_id}/status`
                .replace(`{${"ifc_file_id"}}`, encodeURIComponent(String(ifcFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a list of objects inside a category
         * @summary Get Objects From Category
         * @param {string} ifcFileId
         * @param {string} groupBy
         * @param {string} [sortBy]
         * @param {string} [sortDirection]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {string | null} [component]
         * @param {string | null} [material]
         * @param {string | null} [ifcEntity]
         * @param {string | null} [mappedStatus]
         * @param {string | null} [ifcEntityFilter]
         * @param {string | null} [materialFilter]
         * @param {string | null} [componentFilter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersMappingEditorGetObjectsFromCategory: async (ifcFileId: string, groupBy: string, sortBy?: string, sortDirection?: string, limit?: number, offset?: number, component?: string | null, material?: string | null, ifcEntity?: string | null, mappedStatus?: string | null, ifcEntityFilter?: string | null, materialFilter?: string | null, componentFilter?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ifcFileId' is not null or undefined
            assertParamExists('inventoryApiV1RoutersMappingEditorGetObjectsFromCategory', 'ifcFileId', ifcFileId)
            // verify required parameter 'groupBy' is not null or undefined
            assertParamExists('inventoryApiV1RoutersMappingEditorGetObjectsFromCategory', 'groupBy', groupBy)
            const localVarPath = `/api/v1/inventory/editor/file/{ifc_file_id}/grouped_by_objects`
                .replace(`{${"ifc_file_id"}}`, encodeURIComponent(String(ifcFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (groupBy !== undefined) {
                localVarQueryParameter['group_by'] = groupBy;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sort_direction'] = sortDirection;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


            if (component !== undefined) {
                localVarFormParams.set('component', component as any);
            }

            if (material !== undefined) {
                localVarFormParams.set('material', material as any);
            }

            if (ifcEntity !== undefined) {
                localVarFormParams.set('ifc_entity', ifcEntity as any);
            }

            if (mappedStatus !== undefined) {
                localVarFormParams.set('mapped_status', mappedStatus as any);
            }

            if (ifcEntityFilter !== undefined) {
                localVarFormParams.set('ifc_entity_filter', ifcEntityFilter as any);
            }

            if (materialFilter !== undefined) {
                localVarFormParams.set('material_filter', materialFilter as any);
            }

            if (componentFilter !== undefined) {
                localVarFormParams.set('component_filter', componentFilter as any);
            }


            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get detail of Inventory Component.
         * @summary Get Physical Object Details
         * @param {string} objectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersMappingEditorGetPhysicalObjectDetails: async (objectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('inventoryApiV1RoutersMappingEditorGetPhysicalObjectDetails', 'objectId', objectId)
            const localVarPath = `/api/v1/inventory/editor/object/{object_id}`
                .replace(`{${"object_id"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the details of multiple inventory components by guid.
         * @summary Get Physical Object Details With Guid
         * @param {string} ifcFileId
         * @param {string} objectGuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersMappingEditorGetPhysicalObjectDetailsWithGuid: async (ifcFileId: string, objectGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ifcFileId' is not null or undefined
            assertParamExists('inventoryApiV1RoutersMappingEditorGetPhysicalObjectDetailsWithGuid', 'ifcFileId', ifcFileId)
            // verify required parameter 'objectGuid' is not null or undefined
            assertParamExists('inventoryApiV1RoutersMappingEditorGetPhysicalObjectDetailsWithGuid', 'objectGuid', objectGuid)
            const localVarPath = `/api/v1/inventory/editor/file/{ifc_file_id}/object/{object_guid}/`
                .replace(`{${"ifc_file_id"}}`, encodeURIComponent(String(ifcFileId)))
                .replace(`{${"object_guid"}}`, encodeURIComponent(String(objectGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of Inventory Physical Objects by ifc file.
         * @summary Get Physical Objects
         * @param {string} ifcFileId
         * @param {string} [mappedStatus]
         * @param {string} [ifcEntityFilter]
         * @param {string} [materialFilter]
         * @param {string} [componentFilter]
         * @param {string} [sortBy]
         * @param {string} [sortDirection]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersMappingEditorGetPhysicalObjects: async (ifcFileId: string, mappedStatus?: string, ifcEntityFilter?: string, materialFilter?: string, componentFilter?: string, sortBy?: string, sortDirection?: string, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ifcFileId' is not null or undefined
            assertParamExists('inventoryApiV1RoutersMappingEditorGetPhysicalObjects', 'ifcFileId', ifcFileId)
            const localVarPath = `/api/v1/inventory/editor/file/{ifc_file_id}/all`
                .replace(`{${"ifc_file_id"}}`, encodeURIComponent(String(ifcFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mappedStatus !== undefined) {
                localVarQueryParameter['mapped_status'] = mappedStatus;
            }

            if (ifcEntityFilter !== undefined) {
                localVarQueryParameter['ifc_entity_filter'] = ifcEntityFilter;
            }

            if (materialFilter !== undefined) {
                localVarQueryParameter['material_filter'] = materialFilter;
            }

            if (componentFilter !== undefined) {
                localVarQueryParameter['component_filter'] = componentFilter;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sort_direction'] = sortDirection;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get details of a single category
         * @summary Get Single Category
         * @param {string} ifcFileId
         * @param {string} groupBy
         * @param {string | null} [component]
         * @param {string | null} [material]
         * @param {string | null} [ifcEntity]
         * @param {string | null} [mappedStatus]
         * @param {string | null} [ifcEntityFilter]
         * @param {string | null} [materialFilter]
         * @param {string | null} [componentFilter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersMappingEditorGetSingleCategory: async (ifcFileId: string, groupBy: string, component?: string | null, material?: string | null, ifcEntity?: string | null, mappedStatus?: string | null, ifcEntityFilter?: string | null, materialFilter?: string | null, componentFilter?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ifcFileId' is not null or undefined
            assertParamExists('inventoryApiV1RoutersMappingEditorGetSingleCategory', 'ifcFileId', ifcFileId)
            // verify required parameter 'groupBy' is not null or undefined
            assertParamExists('inventoryApiV1RoutersMappingEditorGetSingleCategory', 'groupBy', groupBy)
            const localVarPath = `/api/v1/inventory/editor/file/{ifc_file_id}/grouped_by_category`
                .replace(`{${"ifc_file_id"}}`, encodeURIComponent(String(ifcFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (groupBy !== undefined) {
                localVarQueryParameter['group_by'] = groupBy;
            }


            if (component !== undefined) {
                localVarFormParams.set('component', component as any);
            }

            if (material !== undefined) {
                localVarFormParams.set('material', material as any);
            }

            if (ifcEntity !== undefined) {
                localVarFormParams.set('ifc_entity', ifcEntity as any);
            }

            if (mappedStatus !== undefined) {
                localVarFormParams.set('mapped_status', mappedStatus as any);
            }

            if (ifcEntityFilter !== undefined) {
                localVarFormParams.set('ifc_entity_filter', ifcEntityFilter as any);
            }

            if (materialFilter !== undefined) {
                localVarFormParams.set('material_filter', materialFilter as any);
            }

            if (componentFilter !== undefined) {
                localVarFormParams.set('component_filter', componentFilter as any);
            }


            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Matches a physical object with a specific product
         * @summary Match Grouped Category
         * @param {string} ifcFileId
         * @param {string} groupBy
         * @param {string} [sortBy]
         * @param {string} [sortDirection]
         * @param {string | null} [component]
         * @param {string | null} [material]
         * @param {string | null} [ifcEntity]
         * @param {string | null} [productUuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersMappingEditorMatchGroupedCategory: async (ifcFileId: string, groupBy: string, sortBy?: string, sortDirection?: string, component?: string | null, material?: string | null, ifcEntity?: string | null, productUuid?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ifcFileId' is not null or undefined
            assertParamExists('inventoryApiV1RoutersMappingEditorMatchGroupedCategory', 'ifcFileId', ifcFileId)
            // verify required parameter 'groupBy' is not null or undefined
            assertParamExists('inventoryApiV1RoutersMappingEditorMatchGroupedCategory', 'groupBy', groupBy)
            const localVarPath = `/api/v1/inventory/editor/file/{ifc_file_id}/match/grouped_by_objects/`
                .replace(`{${"ifc_file_id"}}`, encodeURIComponent(String(ifcFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (groupBy !== undefined) {
                localVarQueryParameter['group_by'] = groupBy;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sort_direction'] = sortDirection;
            }


            if (component !== undefined) {
                localVarFormParams.set('component', component as any);
            }

            if (material !== undefined) {
                localVarFormParams.set('material', material as any);
            }

            if (ifcEntity !== undefined) {
                localVarFormParams.set('ifc_entity', ifcEntity as any);
            }

            if (productUuid !== undefined) {
                localVarFormParams.set('product_uuid', productUuid as any);
            }


            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Matches a physical object with a specific product or removes the match
         * @summary Match Single Object
         * @param {string} physicalObjectUuid
         * @param {string | null} [productUuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersMappingEditorMatchSingleObject: async (physicalObjectUuid: string, productUuid?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'physicalObjectUuid' is not null or undefined
            assertParamExists('inventoryApiV1RoutersMappingEditorMatchSingleObject', 'physicalObjectUuid', physicalObjectUuid)
            const localVarPath = `/api/v1/inventory/editor/match/{physical_object_uuid}`
                .replace(`{${"physical_object_uuid"}}`, encodeURIComponent(String(physicalObjectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (productUuid !== undefined) {
                localVarQueryParameter['product_uuid'] = productUuid;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes all automaps
         * @summary Undo Automap
         * @param {string} ifcFileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersMappingEditorUndoAutomap: async (ifcFileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ifcFileId' is not null or undefined
            assertParamExists('inventoryApiV1RoutersMappingEditorUndoAutomap', 'ifcFileId', ifcFileId)
            const localVarPath = `/api/v1/inventory/editor/file/{ifc_file_id}/undo_automap`
                .replace(`{${"ifc_file_id"}}`, encodeURIComponent(String(ifcFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InventoryMappingEditorApi - functional programming interface
 * @export
 */
export const InventoryMappingEditorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InventoryMappingEditorApiAxiosParamCreator(configuration)
    return {
        /**
         * Sends all the objects to the oracle for automapping, then stores the results and calculates all its metrics
         * @summary Automap File
         * @param {string} ifcFileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersMappingEditorAutomapFile(ifcFileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutomappingResultsOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersMappingEditorAutomapFile(ifcFileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sends a list of objects to the oracle for automapping, then stores the results and calculates all its metrics
         * @summary Automap Objects
         * @param {string} ifcFileId
         * @param {Array<string>} objects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersMappingEditorAutomapObjects(ifcFileId: string, objects: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutomappingResultsOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersMappingEditorAutomapObjects(ifcFileId, objects, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all the values for a specific column in a single ifc file
         * @summary Get Available Values
         * @param {string} ifcFileId
         * @param {string} [column]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersMappingEditorGetAvailableValues(ifcFileId: string, column?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersMappingEditorGetAvailableValues(ifcFileId, column, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get list of categories, either by component or name
         * @summary Get Categories Grouped
         * @param {string} ifcFileId
         * @param {string} groupBy
         * @param {string} [mappedStatus]
         * @param {string} [ifcEntityFilter]
         * @param {string} [materialFilter]
         * @param {string} [componentFilter]
         * @param {string} [sortBy]
         * @param {string} [sortDirection]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersMappingEditorGetCategoriesGrouped(ifcFileId: string, groupBy: string, mappedStatus?: string, ifcEntityFilter?: string, materialFilter?: string, componentFilter?: string, sortBy?: string, sortDirection?: string, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedInventoryCategoryAggregateOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersMappingEditorGetCategoriesGrouped(ifcFileId, groupBy, mappedStatus, ifcEntityFilter, materialFilter, componentFilter, sortBy, sortDirection, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get mapping status of an IFC file
         * @summary Get File Status
         * @param {string} ifcFileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersMappingEditorGetFileStatus(ifcFileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersMappingEditorGetFileStatus(ifcFileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a list of objects inside a category
         * @summary Get Objects From Category
         * @param {string} ifcFileId
         * @param {string} groupBy
         * @param {string} [sortBy]
         * @param {string} [sortDirection]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {string | null} [component]
         * @param {string | null} [material]
         * @param {string | null} [ifcEntity]
         * @param {string | null} [mappedStatus]
         * @param {string | null} [ifcEntityFilter]
         * @param {string | null} [materialFilter]
         * @param {string | null} [componentFilter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersMappingEditorGetObjectsFromCategory(ifcFileId: string, groupBy: string, sortBy?: string, sortDirection?: string, limit?: number, offset?: number, component?: string | null, material?: string | null, ifcEntity?: string | null, mappedStatus?: string | null, ifcEntityFilter?: string | null, materialFilter?: string | null, componentFilter?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedInventoryPhysicalObjectOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersMappingEditorGetObjectsFromCategory(ifcFileId, groupBy, sortBy, sortDirection, limit, offset, component, material, ifcEntity, mappedStatus, ifcEntityFilter, materialFilter, componentFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get detail of Inventory Component.
         * @summary Get Physical Object Details
         * @param {string} objectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersMappingEditorGetPhysicalObjectDetails(objectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InventoryPhysicalObjectDetailOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersMappingEditorGetPhysicalObjectDetails(objectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the details of multiple inventory components by guid.
         * @summary Get Physical Object Details With Guid
         * @param {string} ifcFileId
         * @param {string} objectGuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersMappingEditorGetPhysicalObjectDetailsWithGuid(ifcFileId: string, objectGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InventoryPhysicalObjectDetailOut>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersMappingEditorGetPhysicalObjectDetailsWithGuid(ifcFileId, objectGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get list of Inventory Physical Objects by ifc file.
         * @summary Get Physical Objects
         * @param {string} ifcFileId
         * @param {string} [mappedStatus]
         * @param {string} [ifcEntityFilter]
         * @param {string} [materialFilter]
         * @param {string} [componentFilter]
         * @param {string} [sortBy]
         * @param {string} [sortDirection]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersMappingEditorGetPhysicalObjects(ifcFileId: string, mappedStatus?: string, ifcEntityFilter?: string, materialFilter?: string, componentFilter?: string, sortBy?: string, sortDirection?: string, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedInventoryPhysicalObjectOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersMappingEditorGetPhysicalObjects(ifcFileId, mappedStatus, ifcEntityFilter, materialFilter, componentFilter, sortBy, sortDirection, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get details of a single category
         * @summary Get Single Category
         * @param {string} ifcFileId
         * @param {string} groupBy
         * @param {string | null} [component]
         * @param {string | null} [material]
         * @param {string | null} [ifcEntity]
         * @param {string | null} [mappedStatus]
         * @param {string | null} [ifcEntityFilter]
         * @param {string | null} [materialFilter]
         * @param {string | null} [componentFilter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersMappingEditorGetSingleCategory(ifcFileId: string, groupBy: string, component?: string | null, material?: string | null, ifcEntity?: string | null, mappedStatus?: string | null, ifcEntityFilter?: string | null, materialFilter?: string | null, componentFilter?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InventoryCategoryAggregateOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersMappingEditorGetSingleCategory(ifcFileId, groupBy, component, material, ifcEntity, mappedStatus, ifcEntityFilter, materialFilter, componentFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Matches a physical object with a specific product
         * @summary Match Grouped Category
         * @param {string} ifcFileId
         * @param {string} groupBy
         * @param {string} [sortBy]
         * @param {string} [sortDirection]
         * @param {string | null} [component]
         * @param {string | null} [material]
         * @param {string | null} [ifcEntity]
         * @param {string | null} [productUuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersMappingEditorMatchGroupedCategory(ifcFileId: string, groupBy: string, sortBy?: string, sortDirection?: string, component?: string | null, material?: string | null, ifcEntity?: string | null, productUuid?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InventoryCategoryAggregateOut>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersMappingEditorMatchGroupedCategory(ifcFileId, groupBy, sortBy, sortDirection, component, material, ifcEntity, productUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Matches a physical object with a specific product or removes the match
         * @summary Match Single Object
         * @param {string} physicalObjectUuid
         * @param {string | null} [productUuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersMappingEditorMatchSingleObject(physicalObjectUuid: string, productUuid?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InventoryPhysicalObjectOut>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersMappingEditorMatchSingleObject(physicalObjectUuid, productUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes all automaps
         * @summary Undo Automap
         * @param {string} ifcFileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersMappingEditorUndoAutomap(ifcFileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutomappingUndoResultsOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersMappingEditorUndoAutomap(ifcFileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InventoryMappingEditorApi - factory interface
 * @export
 */
export const InventoryMappingEditorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InventoryMappingEditorApiFp(configuration)
    return {
        /**
         * Sends all the objects to the oracle for automapping, then stores the results and calculates all its metrics
         * @summary Automap File
         * @param {string} ifcFileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersMappingEditorAutomapFile(ifcFileId: string, options?: any): AxiosPromise<AutomappingResultsOut> {
            return localVarFp.inventoryApiV1RoutersMappingEditorAutomapFile(ifcFileId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sends a list of objects to the oracle for automapping, then stores the results and calculates all its metrics
         * @summary Automap Objects
         * @param {string} ifcFileId
         * @param {Array<string>} objects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersMappingEditorAutomapObjects(ifcFileId: string, objects: Array<string>, options?: any): AxiosPromise<AutomappingResultsOut> {
            return localVarFp.inventoryApiV1RoutersMappingEditorAutomapObjects(ifcFileId, objects, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the values for a specific column in a single ifc file
         * @summary Get Available Values
         * @param {string} ifcFileId
         * @param {string} [column]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersMappingEditorGetAvailableValues(ifcFileId: string, column?: string, options?: any): AxiosPromise<void> {
            return localVarFp.inventoryApiV1RoutersMappingEditorGetAvailableValues(ifcFileId, column, options).then((request) => request(axios, basePath));
        },
        /**
         * Get list of categories, either by component or name
         * @summary Get Categories Grouped
         * @param {string} ifcFileId
         * @param {string} groupBy
         * @param {string} [mappedStatus]
         * @param {string} [ifcEntityFilter]
         * @param {string} [materialFilter]
         * @param {string} [componentFilter]
         * @param {string} [sortBy]
         * @param {string} [sortDirection]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersMappingEditorGetCategoriesGrouped(ifcFileId: string, groupBy: string, mappedStatus?: string, ifcEntityFilter?: string, materialFilter?: string, componentFilter?: string, sortBy?: string, sortDirection?: string, limit?: number, offset?: number, options?: any): AxiosPromise<PagedInventoryCategoryAggregateOut> {
            return localVarFp.inventoryApiV1RoutersMappingEditorGetCategoriesGrouped(ifcFileId, groupBy, mappedStatus, ifcEntityFilter, materialFilter, componentFilter, sortBy, sortDirection, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Get mapping status of an IFC file
         * @summary Get File Status
         * @param {string} ifcFileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersMappingEditorGetFileStatus(ifcFileId: string, options?: any): AxiosPromise<void> {
            return localVarFp.inventoryApiV1RoutersMappingEditorGetFileStatus(ifcFileId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a list of objects inside a category
         * @summary Get Objects From Category
         * @param {string} ifcFileId
         * @param {string} groupBy
         * @param {string} [sortBy]
         * @param {string} [sortDirection]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {string | null} [component]
         * @param {string | null} [material]
         * @param {string | null} [ifcEntity]
         * @param {string | null} [mappedStatus]
         * @param {string | null} [ifcEntityFilter]
         * @param {string | null} [materialFilter]
         * @param {string | null} [componentFilter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersMappingEditorGetObjectsFromCategory(ifcFileId: string, groupBy: string, sortBy?: string, sortDirection?: string, limit?: number, offset?: number, component?: string | null, material?: string | null, ifcEntity?: string | null, mappedStatus?: string | null, ifcEntityFilter?: string | null, materialFilter?: string | null, componentFilter?: string | null, options?: any): AxiosPromise<PagedInventoryPhysicalObjectOut> {
            return localVarFp.inventoryApiV1RoutersMappingEditorGetObjectsFromCategory(ifcFileId, groupBy, sortBy, sortDirection, limit, offset, component, material, ifcEntity, mappedStatus, ifcEntityFilter, materialFilter, componentFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * Get detail of Inventory Component.
         * @summary Get Physical Object Details
         * @param {string} objectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersMappingEditorGetPhysicalObjectDetails(objectId: string, options?: any): AxiosPromise<InventoryPhysicalObjectDetailOut> {
            return localVarFp.inventoryApiV1RoutersMappingEditorGetPhysicalObjectDetails(objectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the details of multiple inventory components by guid.
         * @summary Get Physical Object Details With Guid
         * @param {string} ifcFileId
         * @param {string} objectGuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersMappingEditorGetPhysicalObjectDetailsWithGuid(ifcFileId: string, objectGuid: string, options?: any): AxiosPromise<Array<InventoryPhysicalObjectDetailOut>> {
            return localVarFp.inventoryApiV1RoutersMappingEditorGetPhysicalObjectDetailsWithGuid(ifcFileId, objectGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Get list of Inventory Physical Objects by ifc file.
         * @summary Get Physical Objects
         * @param {string} ifcFileId
         * @param {string} [mappedStatus]
         * @param {string} [ifcEntityFilter]
         * @param {string} [materialFilter]
         * @param {string} [componentFilter]
         * @param {string} [sortBy]
         * @param {string} [sortDirection]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersMappingEditorGetPhysicalObjects(ifcFileId: string, mappedStatus?: string, ifcEntityFilter?: string, materialFilter?: string, componentFilter?: string, sortBy?: string, sortDirection?: string, limit?: number, offset?: number, options?: any): AxiosPromise<PagedInventoryPhysicalObjectOut> {
            return localVarFp.inventoryApiV1RoutersMappingEditorGetPhysicalObjects(ifcFileId, mappedStatus, ifcEntityFilter, materialFilter, componentFilter, sortBy, sortDirection, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Get details of a single category
         * @summary Get Single Category
         * @param {string} ifcFileId
         * @param {string} groupBy
         * @param {string | null} [component]
         * @param {string | null} [material]
         * @param {string | null} [ifcEntity]
         * @param {string | null} [mappedStatus]
         * @param {string | null} [ifcEntityFilter]
         * @param {string | null} [materialFilter]
         * @param {string | null} [componentFilter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersMappingEditorGetSingleCategory(ifcFileId: string, groupBy: string, component?: string | null, material?: string | null, ifcEntity?: string | null, mappedStatus?: string | null, ifcEntityFilter?: string | null, materialFilter?: string | null, componentFilter?: string | null, options?: any): AxiosPromise<InventoryCategoryAggregateOut> {
            return localVarFp.inventoryApiV1RoutersMappingEditorGetSingleCategory(ifcFileId, groupBy, component, material, ifcEntity, mappedStatus, ifcEntityFilter, materialFilter, componentFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * Matches a physical object with a specific product
         * @summary Match Grouped Category
         * @param {string} ifcFileId
         * @param {string} groupBy
         * @param {string} [sortBy]
         * @param {string} [sortDirection]
         * @param {string | null} [component]
         * @param {string | null} [material]
         * @param {string | null} [ifcEntity]
         * @param {string | null} [productUuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersMappingEditorMatchGroupedCategory(ifcFileId: string, groupBy: string, sortBy?: string, sortDirection?: string, component?: string | null, material?: string | null, ifcEntity?: string | null, productUuid?: string | null, options?: any): AxiosPromise<Array<InventoryCategoryAggregateOut>> {
            return localVarFp.inventoryApiV1RoutersMappingEditorMatchGroupedCategory(ifcFileId, groupBy, sortBy, sortDirection, component, material, ifcEntity, productUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Matches a physical object with a specific product or removes the match
         * @summary Match Single Object
         * @param {string} physicalObjectUuid
         * @param {string | null} [productUuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersMappingEditorMatchSingleObject(physicalObjectUuid: string, productUuid?: string | null, options?: any): AxiosPromise<Array<InventoryPhysicalObjectOut>> {
            return localVarFp.inventoryApiV1RoutersMappingEditorMatchSingleObject(physicalObjectUuid, productUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes all automaps
         * @summary Undo Automap
         * @param {string} ifcFileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersMappingEditorUndoAutomap(ifcFileId: string, options?: any): AxiosPromise<AutomappingUndoResultsOut> {
            return localVarFp.inventoryApiV1RoutersMappingEditorUndoAutomap(ifcFileId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InventoryMappingEditorApi - object-oriented interface
 * @export
 * @class InventoryMappingEditorApi
 * @extends {BaseAPI}
 */
export class InventoryMappingEditorApi extends BaseAPI {
    /**
     * Sends all the objects to the oracle for automapping, then stores the results and calculates all its metrics
     * @summary Automap File
     * @param {string} ifcFileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryMappingEditorApi
     */
    public inventoryApiV1RoutersMappingEditorAutomapFile(ifcFileId: string, options?: AxiosRequestConfig) {
        return InventoryMappingEditorApiFp(this.configuration).inventoryApiV1RoutersMappingEditorAutomapFile(ifcFileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sends a list of objects to the oracle for automapping, then stores the results and calculates all its metrics
     * @summary Automap Objects
     * @param {string} ifcFileId
     * @param {Array<string>} objects
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryMappingEditorApi
     */
    public inventoryApiV1RoutersMappingEditorAutomapObjects(ifcFileId: string, objects: Array<string>, options?: AxiosRequestConfig) {
        return InventoryMappingEditorApiFp(this.configuration).inventoryApiV1RoutersMappingEditorAutomapObjects(ifcFileId, objects, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the values for a specific column in a single ifc file
     * @summary Get Available Values
     * @param {string} ifcFileId
     * @param {string} [column]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryMappingEditorApi
     */
    public inventoryApiV1RoutersMappingEditorGetAvailableValues(ifcFileId: string, column?: string, options?: AxiosRequestConfig) {
        return InventoryMappingEditorApiFp(this.configuration).inventoryApiV1RoutersMappingEditorGetAvailableValues(ifcFileId, column, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list of categories, either by component or name
     * @summary Get Categories Grouped
     * @param {string} ifcFileId
     * @param {string} groupBy
     * @param {string} [mappedStatus]
     * @param {string} [ifcEntityFilter]
     * @param {string} [materialFilter]
     * @param {string} [componentFilter]
     * @param {string} [sortBy]
     * @param {string} [sortDirection]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryMappingEditorApi
     */
    public inventoryApiV1RoutersMappingEditorGetCategoriesGrouped(ifcFileId: string, groupBy: string, mappedStatus?: string, ifcEntityFilter?: string, materialFilter?: string, componentFilter?: string, sortBy?: string, sortDirection?: string, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return InventoryMappingEditorApiFp(this.configuration).inventoryApiV1RoutersMappingEditorGetCategoriesGrouped(ifcFileId, groupBy, mappedStatus, ifcEntityFilter, materialFilter, componentFilter, sortBy, sortDirection, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get mapping status of an IFC file
     * @summary Get File Status
     * @param {string} ifcFileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryMappingEditorApi
     */
    public inventoryApiV1RoutersMappingEditorGetFileStatus(ifcFileId: string, options?: AxiosRequestConfig) {
        return InventoryMappingEditorApiFp(this.configuration).inventoryApiV1RoutersMappingEditorGetFileStatus(ifcFileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a list of objects inside a category
     * @summary Get Objects From Category
     * @param {string} ifcFileId
     * @param {string} groupBy
     * @param {string} [sortBy]
     * @param {string} [sortDirection]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string | null} [component]
     * @param {string | null} [material]
     * @param {string | null} [ifcEntity]
     * @param {string | null} [mappedStatus]
     * @param {string | null} [ifcEntityFilter]
     * @param {string | null} [materialFilter]
     * @param {string | null} [componentFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryMappingEditorApi
     */
    public inventoryApiV1RoutersMappingEditorGetObjectsFromCategory(ifcFileId: string, groupBy: string, sortBy?: string, sortDirection?: string, limit?: number, offset?: number, component?: string | null, material?: string | null, ifcEntity?: string | null, mappedStatus?: string | null, ifcEntityFilter?: string | null, materialFilter?: string | null, componentFilter?: string | null, options?: AxiosRequestConfig) {
        return InventoryMappingEditorApiFp(this.configuration).inventoryApiV1RoutersMappingEditorGetObjectsFromCategory(ifcFileId, groupBy, sortBy, sortDirection, limit, offset, component, material, ifcEntity, mappedStatus, ifcEntityFilter, materialFilter, componentFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get detail of Inventory Component.
     * @summary Get Physical Object Details
     * @param {string} objectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryMappingEditorApi
     */
    public inventoryApiV1RoutersMappingEditorGetPhysicalObjectDetails(objectId: string, options?: AxiosRequestConfig) {
        return InventoryMappingEditorApiFp(this.configuration).inventoryApiV1RoutersMappingEditorGetPhysicalObjectDetails(objectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the details of multiple inventory components by guid.
     * @summary Get Physical Object Details With Guid
     * @param {string} ifcFileId
     * @param {string} objectGuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryMappingEditorApi
     */
    public inventoryApiV1RoutersMappingEditorGetPhysicalObjectDetailsWithGuid(ifcFileId: string, objectGuid: string, options?: AxiosRequestConfig) {
        return InventoryMappingEditorApiFp(this.configuration).inventoryApiV1RoutersMappingEditorGetPhysicalObjectDetailsWithGuid(ifcFileId, objectGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list of Inventory Physical Objects by ifc file.
     * @summary Get Physical Objects
     * @param {string} ifcFileId
     * @param {string} [mappedStatus]
     * @param {string} [ifcEntityFilter]
     * @param {string} [materialFilter]
     * @param {string} [componentFilter]
     * @param {string} [sortBy]
     * @param {string} [sortDirection]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryMappingEditorApi
     */
    public inventoryApiV1RoutersMappingEditorGetPhysicalObjects(ifcFileId: string, mappedStatus?: string, ifcEntityFilter?: string, materialFilter?: string, componentFilter?: string, sortBy?: string, sortDirection?: string, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return InventoryMappingEditorApiFp(this.configuration).inventoryApiV1RoutersMappingEditorGetPhysicalObjects(ifcFileId, mappedStatus, ifcEntityFilter, materialFilter, componentFilter, sortBy, sortDirection, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get details of a single category
     * @summary Get Single Category
     * @param {string} ifcFileId
     * @param {string} groupBy
     * @param {string | null} [component]
     * @param {string | null} [material]
     * @param {string | null} [ifcEntity]
     * @param {string | null} [mappedStatus]
     * @param {string | null} [ifcEntityFilter]
     * @param {string | null} [materialFilter]
     * @param {string | null} [componentFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryMappingEditorApi
     */
    public inventoryApiV1RoutersMappingEditorGetSingleCategory(ifcFileId: string, groupBy: string, component?: string | null, material?: string | null, ifcEntity?: string | null, mappedStatus?: string | null, ifcEntityFilter?: string | null, materialFilter?: string | null, componentFilter?: string | null, options?: AxiosRequestConfig) {
        return InventoryMappingEditorApiFp(this.configuration).inventoryApiV1RoutersMappingEditorGetSingleCategory(ifcFileId, groupBy, component, material, ifcEntity, mappedStatus, ifcEntityFilter, materialFilter, componentFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Matches a physical object with a specific product
     * @summary Match Grouped Category
     * @param {string} ifcFileId
     * @param {string} groupBy
     * @param {string} [sortBy]
     * @param {string} [sortDirection]
     * @param {string | null} [component]
     * @param {string | null} [material]
     * @param {string | null} [ifcEntity]
     * @param {string | null} [productUuid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryMappingEditorApi
     */
    public inventoryApiV1RoutersMappingEditorMatchGroupedCategory(ifcFileId: string, groupBy: string, sortBy?: string, sortDirection?: string, component?: string | null, material?: string | null, ifcEntity?: string | null, productUuid?: string | null, options?: AxiosRequestConfig) {
        return InventoryMappingEditorApiFp(this.configuration).inventoryApiV1RoutersMappingEditorMatchGroupedCategory(ifcFileId, groupBy, sortBy, sortDirection, component, material, ifcEntity, productUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Matches a physical object with a specific product or removes the match
     * @summary Match Single Object
     * @param {string} physicalObjectUuid
     * @param {string | null} [productUuid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryMappingEditorApi
     */
    public inventoryApiV1RoutersMappingEditorMatchSingleObject(physicalObjectUuid: string, productUuid?: string | null, options?: AxiosRequestConfig) {
        return InventoryMappingEditorApiFp(this.configuration).inventoryApiV1RoutersMappingEditorMatchSingleObject(physicalObjectUuid, productUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes all automaps
     * @summary Undo Automap
     * @param {string} ifcFileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryMappingEditorApi
     */
    public inventoryApiV1RoutersMappingEditorUndoAutomap(ifcFileId: string, options?: AxiosRequestConfig) {
        return InventoryMappingEditorApiFp(this.configuration).inventoryApiV1RoutersMappingEditorUndoAutomap(ifcFileId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InventoryProductsApi - axios parameter creator
 * @export
 */
export const InventoryProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * List of all products
         * @summary All Products
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersProductsAllProducts: async (limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/inventory/product/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Details of a single product
         * @summary Detail
         * @param {string} productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersProductsDetail: async (productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('inventoryApiV1RoutersProductsDetail', 'productId', productId)
            const localVarPath = `/api/v1/inventory/product/details/{product_id}`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Details of a single product for a specific file
         * @summary Details By File
         * @param {string} productId
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersProductsDetailsByFile: async (productId: string, fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('inventoryApiV1RoutersProductsDetailsByFile', 'productId', productId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('inventoryApiV1RoutersProductsDetailsByFile', 'fileId', fileId)
            const localVarPath = `/api/v1/inventory/product/details_by_file/{product_id}/{file_id}`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Search products by name.
         * @summary Search Product
         * @param {string} term
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersProductsSearchProduct: async (term: string, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'term' is not null or undefined
            assertParamExists('inventoryApiV1RoutersProductsSearchProduct', 'term', term)
            const localVarPath = `/api/v1/inventory/product/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InventoryProductsApi - functional programming interface
 * @export
 */
export const InventoryProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InventoryProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * List of all products
         * @summary All Products
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersProductsAllProducts(limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedInventoryProductOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersProductsAllProducts(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Details of a single product
         * @summary Detail
         * @param {string} productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersProductsDetail(productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InventoryProductOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersProductsDetail(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Details of a single product for a specific file
         * @summary Details By File
         * @param {string} productId
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersProductsDetailsByFile(productId: string, fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InventoryProductDetailsByFileOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersProductsDetailsByFile(productId, fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Search products by name.
         * @summary Search Product
         * @param {string} term
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersProductsSearchProduct(term: string, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedInventoryProductOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersProductsSearchProduct(term, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InventoryProductsApi - factory interface
 * @export
 */
export const InventoryProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InventoryProductsApiFp(configuration)
    return {
        /**
         * List of all products
         * @summary All Products
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersProductsAllProducts(limit?: number, offset?: number, options?: any): AxiosPromise<PagedInventoryProductOut> {
            return localVarFp.inventoryApiV1RoutersProductsAllProducts(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Details of a single product
         * @summary Detail
         * @param {string} productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersProductsDetail(productId: string, options?: any): AxiosPromise<InventoryProductOut> {
            return localVarFp.inventoryApiV1RoutersProductsDetail(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * Details of a single product for a specific file
         * @summary Details By File
         * @param {string} productId
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersProductsDetailsByFile(productId: string, fileId: string, options?: any): AxiosPromise<InventoryProductDetailsByFileOut> {
            return localVarFp.inventoryApiV1RoutersProductsDetailsByFile(productId, fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * Search products by name.
         * @summary Search Product
         * @param {string} term
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersProductsSearchProduct(term: string, limit?: number, offset?: number, options?: any): AxiosPromise<PagedInventoryProductOut> {
            return localVarFp.inventoryApiV1RoutersProductsSearchProduct(term, limit, offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InventoryProductsApi - object-oriented interface
 * @export
 * @class InventoryProductsApi
 * @extends {BaseAPI}
 */
export class InventoryProductsApi extends BaseAPI {
    /**
     * List of all products
     * @summary All Products
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryProductsApi
     */
    public inventoryApiV1RoutersProductsAllProducts(limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return InventoryProductsApiFp(this.configuration).inventoryApiV1RoutersProductsAllProducts(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Details of a single product
     * @summary Detail
     * @param {string} productId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryProductsApi
     */
    public inventoryApiV1RoutersProductsDetail(productId: string, options?: AxiosRequestConfig) {
        return InventoryProductsApiFp(this.configuration).inventoryApiV1RoutersProductsDetail(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Details of a single product for a specific file
     * @summary Details By File
     * @param {string} productId
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryProductsApi
     */
    public inventoryApiV1RoutersProductsDetailsByFile(productId: string, fileId: string, options?: AxiosRequestConfig) {
        return InventoryProductsApiFp(this.configuration).inventoryApiV1RoutersProductsDetailsByFile(productId, fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Search products by name.
     * @summary Search Product
     * @param {string} term
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryProductsApi
     */
    public inventoryApiV1RoutersProductsSearchProduct(term: string, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return InventoryProductsApiFp(this.configuration).inventoryApiV1RoutersProductsSearchProduct(term, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LocationsApi - axios parameter creator
 * @export
 */
export const LocationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the place details from the place_id.
         * @summary Place Details
         * @param {string} placeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersLocationsPlaceDetails: async (placeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'placeId' is not null or undefined
            assertParamExists('concularApiV1RoutersLocationsPlaceDetails', 'placeId', placeId)
            const localVarPath = `/api/v1/concular/locations/{place_id}/place`
                .replace(`{${"place_id"}}`, encodeURIComponent(String(placeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Search a locations from the AWS location
         * @summary Search Locations
         * @param {string} locationQuery
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersLocationsSearchLocations: async (locationQuery: string, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationQuery' is not null or undefined
            assertParamExists('concularApiV1RoutersLocationsSearchLocations', 'locationQuery', locationQuery)
            const localVarPath = `/api/v1/concular/locations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (locationQuery !== undefined) {
                localVarQueryParameter['location_query'] = locationQuery;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Place predictions based on user search.
         * @summary Suggest Places
         * @param {string} locationQuery
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersLocationsSuggestPlaces: async (locationQuery: string, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationQuery' is not null or undefined
            assertParamExists('concularApiV1RoutersLocationsSuggestPlaces', 'locationQuery', locationQuery)
            const localVarPath = `/api/v1/concular/locations/suggest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (locationQuery !== undefined) {
                localVarQueryParameter['location_query'] = locationQuery;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocationsApi - functional programming interface
 * @export
 */
export const LocationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get the place details from the place_id.
         * @summary Place Details
         * @param {string} placeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersLocationsPlaceDetails(placeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlaceDetailsOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersLocationsPlaceDetails(placeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Search a locations from the AWS location
         * @summary Search Locations
         * @param {string} locationQuery
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersLocationsSearchLocations(locationQuery: string, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedAWSLocationOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersLocationsSearchLocations(locationQuery, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Place predictions based on user search.
         * @summary Suggest Places
         * @param {string} locationQuery
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersLocationsSuggestPlaces(locationQuery: string, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedSuggestOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersLocationsSuggestPlaces(locationQuery, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LocationsApi - factory interface
 * @export
 */
export const LocationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocationsApiFp(configuration)
    return {
        /**
         * Get the place details from the place_id.
         * @summary Place Details
         * @param {string} placeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersLocationsPlaceDetails(placeId: string, options?: any): AxiosPromise<PlaceDetailsOUT> {
            return localVarFp.concularApiV1RoutersLocationsPlaceDetails(placeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Search a locations from the AWS location
         * @summary Search Locations
         * @param {string} locationQuery
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersLocationsSearchLocations(locationQuery: string, limit?: number, offset?: number, options?: any): AxiosPromise<PagedAWSLocationOUT> {
            return localVarFp.concularApiV1RoutersLocationsSearchLocations(locationQuery, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Place predictions based on user search.
         * @summary Suggest Places
         * @param {string} locationQuery
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersLocationsSuggestPlaces(locationQuery: string, limit?: number, offset?: number, options?: any): AxiosPromise<PagedSuggestOUT> {
            return localVarFp.concularApiV1RoutersLocationsSuggestPlaces(locationQuery, limit, offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocationsApi - object-oriented interface
 * @export
 * @class LocationsApi
 * @extends {BaseAPI}
 */
export class LocationsApi extends BaseAPI {
    /**
     * Get the place details from the place_id.
     * @summary Place Details
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public concularApiV1RoutersLocationsPlaceDetails(placeId: string, options?: AxiosRequestConfig) {
        return LocationsApiFp(this.configuration).concularApiV1RoutersLocationsPlaceDetails(placeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Search a locations from the AWS location
     * @summary Search Locations
     * @param {string} locationQuery
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public concularApiV1RoutersLocationsSearchLocations(locationQuery: string, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return LocationsApiFp(this.configuration).concularApiV1RoutersLocationsSearchLocations(locationQuery, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Place predictions based on user search.
     * @summary Suggest Places
     * @param {string} locationQuery
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public concularApiV1RoutersLocationsSuggestPlaces(locationQuery: string, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return LocationsApiFp(this.configuration).concularApiV1RoutersLocationsSuggestPlaces(locationQuery, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MappingEditorDynamicColumnsApi - axios parameter creator
 * @export
 */
export const MappingEditorDynamicColumnsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Get Dynamic Columns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mappingEditorApiV1RoutersDynamicColumnsGetDynamicColumns: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/mapping_editor/dynamic_columns/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MappingEditorDynamicColumnsApi - functional programming interface
 * @export
 */
export const MappingEditorDynamicColumnsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MappingEditorDynamicColumnsApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Get Dynamic Columns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mappingEditorApiV1RoutersDynamicColumnsGetDynamicColumns(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DynamicColumnOUT>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mappingEditorApiV1RoutersDynamicColumnsGetDynamicColumns(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MappingEditorDynamicColumnsApi - factory interface
 * @export
 */
export const MappingEditorDynamicColumnsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MappingEditorDynamicColumnsApiFp(configuration)
    return {
        /**
         *
         * @summary Get Dynamic Columns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mappingEditorApiV1RoutersDynamicColumnsGetDynamicColumns(options?: any): AxiosPromise<Array<DynamicColumnOUT>> {
            return localVarFp.mappingEditorApiV1RoutersDynamicColumnsGetDynamicColumns(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MappingEditorDynamicColumnsApi - object-oriented interface
 * @export
 * @class MappingEditorDynamicColumnsApi
 * @extends {BaseAPI}
 */
export class MappingEditorDynamicColumnsApi extends BaseAPI {
    /**
     *
     * @summary Get Dynamic Columns
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MappingEditorDynamicColumnsApi
     */
    public mappingEditorApiV1RoutersDynamicColumnsGetDynamicColumns(options?: AxiosRequestConfig) {
        return MappingEditorDynamicColumnsApiFp(this.configuration).mappingEditorApiV1RoutersDynamicColumnsGetDynamicColumns(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MappingEditorObjectsApi - axios parameter creator
 * @export
 */
export const MappingEditorObjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns objects by file_id.
         * @summary List Objects By File Id
         * @param {string} fileId
         * @param {ObjectListIN} objectListIN
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mappingEditorApiV1RoutersObjectsListObjectsByFileId: async (fileId: string, objectListIN: ObjectListIN, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('mappingEditorApiV1RoutersObjectsListObjectsByFileId', 'fileId', fileId)
            // verify required parameter 'objectListIN' is not null or undefined
            assertParamExists('mappingEditorApiV1RoutersObjectsListObjectsByFileId', 'objectListIN', objectListIN)
            const localVarPath = `/api/v1/mapping_editor/objects/{file_id}/list`
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(objectListIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Matches a physical object with a specific product or removes the match
         * @summary Match Objects
         * @param {string} fileId
         * @param {MatchObjectsIN} matchObjectsIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mappingEditorApiV1RoutersObjectsMatchObjects: async (fileId: string, matchObjectsIN: MatchObjectsIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('mappingEditorApiV1RoutersObjectsMatchObjects', 'fileId', fileId)
            // verify required parameter 'matchObjectsIN' is not null or undefined
            assertParamExists('mappingEditorApiV1RoutersObjectsMatchObjects', 'matchObjectsIN', matchObjectsIN)
            const localVarPath = `/api/v1/mapping_editor/objects/{file_id}/match`
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(matchObjectsIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MappingEditorObjectsApi - functional programming interface
 * @export
 */
export const MappingEditorObjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MappingEditorObjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns objects by file_id.
         * @summary List Objects By File Id
         * @param {string} fileId
         * @param {ObjectListIN} objectListIN
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mappingEditorApiV1RoutersObjectsListObjectsByFileId(fileId: string, objectListIN: ObjectListIN, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectListOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mappingEditorApiV1RoutersObjectsListObjectsByFileId(fileId, objectListIN, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Matches a physical object with a specific product or removes the match
         * @summary Match Objects
         * @param {string} fileId
         * @param {MatchObjectsIN} matchObjectsIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mappingEditorApiV1RoutersObjectsMatchObjects(fileId: string, matchObjectsIN: MatchObjectsIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectListOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mappingEditorApiV1RoutersObjectsMatchObjects(fileId, matchObjectsIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MappingEditorObjectsApi - factory interface
 * @export
 */
export const MappingEditorObjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MappingEditorObjectsApiFp(configuration)
    return {
        /**
         * Returns objects by file_id.
         * @summary List Objects By File Id
         * @param {string} fileId
         * @param {ObjectListIN} objectListIN
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mappingEditorApiV1RoutersObjectsListObjectsByFileId(fileId: string, objectListIN: ObjectListIN, page?: number, pageSize?: number, options?: any): AxiosPromise<ObjectListOUT> {
            return localVarFp.mappingEditorApiV1RoutersObjectsListObjectsByFileId(fileId, objectListIN, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Matches a physical object with a specific product or removes the match
         * @summary Match Objects
         * @param {string} fileId
         * @param {MatchObjectsIN} matchObjectsIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mappingEditorApiV1RoutersObjectsMatchObjects(fileId: string, matchObjectsIN: MatchObjectsIN, options?: any): AxiosPromise<ObjectListOUT> {
            return localVarFp.mappingEditorApiV1RoutersObjectsMatchObjects(fileId, matchObjectsIN, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MappingEditorObjectsApi - object-oriented interface
 * @export
 * @class MappingEditorObjectsApi
 * @extends {BaseAPI}
 */
export class MappingEditorObjectsApi extends BaseAPI {
    /**
     * Returns objects by file_id.
     * @summary List Objects By File Id
     * @param {string} fileId
     * @param {ObjectListIN} objectListIN
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MappingEditorObjectsApi
     */
    public mappingEditorApiV1RoutersObjectsListObjectsByFileId(fileId: string, objectListIN: ObjectListIN, page?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return MappingEditorObjectsApiFp(this.configuration).mappingEditorApiV1RoutersObjectsListObjectsByFileId(fileId, objectListIN, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Matches a physical object with a specific product or removes the match
     * @summary Match Objects
     * @param {string} fileId
     * @param {MatchObjectsIN} matchObjectsIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MappingEditorObjectsApi
     */
    public mappingEditorApiV1RoutersObjectsMatchObjects(fileId: string, matchObjectsIN: MatchObjectsIN, options?: AxiosRequestConfig) {
        return MappingEditorObjectsApiFp(this.configuration).mappingEditorApiV1RoutersObjectsMatchObjects(fileId, matchObjectsIN, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ObjectOperationsApi - axios parameter creator
 * @export
 */
export const ObjectOperationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Run Object Operations
         * @param {ObjectOperationIN} objectOperationIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mappingEditorApiV1RoutersObjectOperationsRunObjectOperations: async (objectOperationIN: ObjectOperationIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectOperationIN' is not null or undefined
            assertParamExists('mappingEditorApiV1RoutersObjectOperationsRunObjectOperations', 'objectOperationIN', objectOperationIN)
            const localVarPath = `/api/v1/mapping_editor/object_operations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(objectOperationIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ObjectOperationsApi - functional programming interface
 * @export
 */
export const ObjectOperationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ObjectOperationsApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Run Object Operations
         * @param {ObjectOperationIN} objectOperationIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mappingEditorApiV1RoutersObjectOperationsRunObjectOperations(objectOperationIN: ObjectOperationIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectOperationOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mappingEditorApiV1RoutersObjectOperationsRunObjectOperations(objectOperationIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ObjectOperationsApi - factory interface
 * @export
 */
export const ObjectOperationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ObjectOperationsApiFp(configuration)
    return {
        /**
         *
         * @summary Run Object Operations
         * @param {ObjectOperationIN} objectOperationIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mappingEditorApiV1RoutersObjectOperationsRunObjectOperations(objectOperationIN: ObjectOperationIN, options?: any): AxiosPromise<ObjectOperationOUT> {
            return localVarFp.mappingEditorApiV1RoutersObjectOperationsRunObjectOperations(objectOperationIN, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ObjectOperationsApi - object-oriented interface
 * @export
 * @class ObjectOperationsApi
 * @extends {BaseAPI}
 */
export class ObjectOperationsApi extends BaseAPI {
    /**
     *
     * @summary Run Object Operations
     * @param {ObjectOperationIN} objectOperationIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectOperationsApi
     */
    public mappingEditorApiV1RoutersObjectOperationsRunObjectOperations(objectOperationIN: ObjectOperationIN, options?: AxiosRequestConfig) {
        return ObjectOperationsApiFp(this.configuration).mappingEditorApiV1RoutersObjectOperationsRunObjectOperations(objectOperationIN, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OfflineApi - axios parameter creator
 * @export
 */
export const OfflineApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all fields
         * @summary Get Fields
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersOfflineGetFields: async (limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/ca/offline/fields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all active fieldsets
         * @summary Get Fieldsets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersOfflineGetFieldsets: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/ca/offline/fieldsets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all manufacturers.
         * @summary Get Manufacturers
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersOfflineGetManufacturers: async (limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/ca/offline/manufacturers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all product groups
         * @summary Get Product Groups
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersOfflineGetProductGroups: async (limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/ca/offline/product_groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfflineApi - functional programming interface
 * @export
 */
export const OfflineApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OfflineApiAxiosParamCreator(configuration)
    return {
        /**
         * Get all fields
         * @summary Get Fields
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersOfflineGetFields(limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedFieldOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersOfflineGetFields(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all active fieldsets
         * @summary Get Fieldsets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersOfflineGetFieldsets(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SlimFieldSetOUT>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersOfflineGetFieldsets(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all manufacturers.
         * @summary Get Manufacturers
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersOfflineGetManufacturers(limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedManufacturerOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersOfflineGetManufacturers(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all product groups
         * @summary Get Product Groups
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersOfflineGetProductGroups(limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedProductGroupOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersOfflineGetProductGroups(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OfflineApi - factory interface
 * @export
 */
export const OfflineApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OfflineApiFp(configuration)
    return {
        /**
         * Get all fields
         * @summary Get Fields
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersOfflineGetFields(limit?: number, offset?: number, options?: any): AxiosPromise<PagedFieldOUT> {
            return localVarFp.caApiV1RoutersOfflineGetFields(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all active fieldsets
         * @summary Get Fieldsets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersOfflineGetFieldsets(options?: any): AxiosPromise<Array<SlimFieldSetOUT>> {
            return localVarFp.caApiV1RoutersOfflineGetFieldsets(options).then((request) => request(axios, basePath));
        },
        /**
         * Get all manufacturers.
         * @summary Get Manufacturers
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersOfflineGetManufacturers(limit?: number, offset?: number, options?: any): AxiosPromise<PagedManufacturerOUT> {
            return localVarFp.caApiV1RoutersOfflineGetManufacturers(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all product groups
         * @summary Get Product Groups
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersOfflineGetProductGroups(limit?: number, offset?: number, options?: any): AxiosPromise<PagedProductGroupOUT> {
            return localVarFp.caApiV1RoutersOfflineGetProductGroups(limit, offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OfflineApi - object-oriented interface
 * @export
 * @class OfflineApi
 * @extends {BaseAPI}
 */
export class OfflineApi extends BaseAPI {
    /**
     * Get all fields
     * @summary Get Fields
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfflineApi
     */
    public caApiV1RoutersOfflineGetFields(limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return OfflineApiFp(this.configuration).caApiV1RoutersOfflineGetFields(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all active fieldsets
     * @summary Get Fieldsets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfflineApi
     */
    public caApiV1RoutersOfflineGetFieldsets(options?: AxiosRequestConfig) {
        return OfflineApiFp(this.configuration).caApiV1RoutersOfflineGetFieldsets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all manufacturers.
     * @summary Get Manufacturers
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfflineApi
     */
    public caApiV1RoutersOfflineGetManufacturers(limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return OfflineApiFp(this.configuration).caApiV1RoutersOfflineGetManufacturers(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all product groups
     * @summary Get Product Groups
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfflineApi
     */
    public caApiV1RoutersOfflineGetProductGroups(limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return OfflineApiFp(this.configuration).caApiV1RoutersOfflineGetProductGroups(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OnlineApi - axios parameter creator
 * @export
 */
export const OnlineApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Create Audit
         * @param {AuditIN} auditIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineAuditCreateAudit: async (auditIN: AuditIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditIN' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineAuditCreateAudit', 'auditIN', auditIN)
            const localVarPath = `/api/v1/ca/online/audit/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(auditIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete Audit
         * @param {string} auditId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineAuditDeleteAudit: async (auditId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineAuditDeleteAudit', 'auditId', auditId)
            const localVarPath = `/api/v1/ca/online/audit/{audit_id}`
                .replace(`{${"audit_id"}}`, encodeURIComponent(String(auditId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get Audit
         * @param {string} auditId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineAuditGetAudit: async (auditId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineAuditGetAudit', 'auditId', auditId)
            const localVarPath = `/api/v1/ca/online/audit/{audit_id}`
                .replace(`{${"audit_id"}}`, encodeURIComponent(String(auditId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary List Audits
         * @param {string} buildingId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineAuditListAudits: async (buildingId: string, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineAuditListAudits', 'buildingId', buildingId)
            const localVarPath = `/api/v1/ca/online/audit/buildings/<building_id>/audits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (buildingId !== undefined) {
                localVarQueryParameter['building_id'] = buildingId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary List Floors
         * @param {string} auditId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineAuditListFloors: async (auditId: string, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineAuditListFloors', 'auditId', auditId)
            const localVarPath = `/api/v1/ca/online/audit/{audit_id}/floors`
                .replace(`{${"audit_id"}}`, encodeURIComponent(String(auditId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary List Rooms
         * @param {string} auditId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineAuditListRooms: async (auditId: string, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineAuditListRooms', 'auditId', auditId)
            const localVarPath = `/api/v1/ca/online/audit/{audit_id}/rooms`
                .replace(`{${"audit_id"}}`, encodeURIComponent(String(auditId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create Blueprint
         * @param {string} auditId
         * @param {BlueprintIN} blueprintIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineBlueprintCreateBlueprint: async (auditId: string, blueprintIN: BlueprintIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineBlueprintCreateBlueprint', 'auditId', auditId)
            // verify required parameter 'blueprintIN' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineBlueprintCreateBlueprint', 'blueprintIN', blueprintIN)
            const localVarPath = `/api/v1/ca/online/blueprint/{audit_id}/blueprints/create`
                .replace(`{${"audit_id"}}`, encodeURIComponent(String(auditId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(blueprintIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete Blueprint
         * @param {string} auditId
         * @param {string} blueprintId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineBlueprintDeleteBlueprint: async (auditId: string, blueprintId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineBlueprintDeleteBlueprint', 'auditId', auditId)
            // verify required parameter 'blueprintId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineBlueprintDeleteBlueprint', 'blueprintId', blueprintId)
            const localVarPath = `/api/v1/ca/online/blueprint/{blueprint_id}/delete`
                .replace(`{${"blueprint_id"}}`, encodeURIComponent(String(blueprintId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (auditId !== undefined) {
                localVarQueryParameter['audit_id'] = auditId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Duplicate Blueprint
         * @param {string} blueprintId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineBlueprintDuplicateBlueprint: async (blueprintId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'blueprintId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineBlueprintDuplicateBlueprint', 'blueprintId', blueprintId)
            const localVarPath = `/api/v1/ca/online/blueprint/{blueprint_id}/duplicate`
                .replace(`{${"blueprint_id"}}`, encodeURIComponent(String(blueprintId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get Blueprint Details
         * @param {string} blueprintId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineBlueprintGetBlueprintDetails: async (blueprintId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'blueprintId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineBlueprintGetBlueprintDetails', 'blueprintId', blueprintId)
            const localVarPath = `/api/v1/ca/online/blueprint/{blueprint_id}/details`
                .replace(`{${"blueprint_id"}}`, encodeURIComponent(String(blueprintId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get Blueprint Images
         * @param {string} blueprintId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineBlueprintGetBlueprintImages: async (blueprintId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'blueprintId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineBlueprintGetBlueprintImages', 'blueprintId', blueprintId)
            const localVarPath = `/api/v1/ca/online/blueprint/online/blueprint/{blueprint_id}/images`
                .replace(`{${"blueprint_id"}}`, encodeURIComponent(String(blueprintId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary List Blueprint Properties
         * @param {string} blueprintId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineBlueprintListBlueprintProperties: async (blueprintId: string, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'blueprintId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineBlueprintListBlueprintProperties', 'blueprintId', blueprintId)
            const localVarPath = `/api/v1/ca/online/blueprint/{blueprint_id}/properties`
                .replace(`{${"blueprint_id"}}`, encodeURIComponent(String(blueprintId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary List Blueprints
         * @param {string} auditId
         * @param {SortOrder | null} [sortOrder]
         * @param {BlueprintListSortBy | null} [sortBy]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineBlueprintListBlueprints: async (auditId: string, sortOrder?: SortOrder | null, sortBy?: BlueprintListSortBy | null, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineBlueprintListBlueprints', 'auditId', auditId)
            const localVarPath = `/api/v1/ca/online/blueprint/{audit_id}/blueprints`
                .replace(`{${"audit_id"}}`, encodeURIComponent(String(auditId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort_order'] = sortOrder;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create Blueprint Property
         * @param {BlueprintPropertyIN} blueprintPropertyIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineBlueprintPropertyCreateBlueprintProperty: async (blueprintPropertyIN: BlueprintPropertyIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'blueprintPropertyIN' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineBlueprintPropertyCreateBlueprintProperty', 'blueprintPropertyIN', blueprintPropertyIN)
            const localVarPath = `/api/v1/ca/online/blueprint_property/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(blueprintPropertyIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete Blueprint Property
         * @param {string} blueprintPropertyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineBlueprintPropertyDeleteBlueprintProperty: async (blueprintPropertyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'blueprintPropertyId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineBlueprintPropertyDeleteBlueprintProperty', 'blueprintPropertyId', blueprintPropertyId)
            const localVarPath = `/api/v1/ca/online/blueprint_property/{blueprint_property_id}`
                .replace(`{${"blueprint_property_id"}}`, encodeURIComponent(String(blueprintPropertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get Blueprint Property
         * @param {string} blueprintPropertyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineBlueprintPropertyGetBlueprintProperty: async (blueprintPropertyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'blueprintPropertyId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineBlueprintPropertyGetBlueprintProperty', 'blueprintPropertyId', blueprintPropertyId)
            const localVarPath = `/api/v1/ca/online/blueprint_property/{blueprint_property_id}`
                .replace(`{${"blueprint_property_id"}}`, encodeURIComponent(String(blueprintPropertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update Blueprint Property
         * @param {string} blueprintPropertyId
         * @param {BlueprintPropertyUpdateIN} blueprintPropertyUpdateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineBlueprintPropertyUpdateBlueprintProperty: async (blueprintPropertyId: string, blueprintPropertyUpdateIN: BlueprintPropertyUpdateIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'blueprintPropertyId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineBlueprintPropertyUpdateBlueprintProperty', 'blueprintPropertyId', blueprintPropertyId)
            // verify required parameter 'blueprintPropertyUpdateIN' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineBlueprintPropertyUpdateBlueprintProperty', 'blueprintPropertyUpdateIN', blueprintPropertyUpdateIN)
            const localVarPath = `/api/v1/ca/online/blueprint_property/{blueprint_property_id}`
                .replace(`{${"blueprint_property_id"}}`, encodeURIComponent(String(blueprintPropertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(blueprintPropertyUpdateIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Search Blueprints
         * @param {string} auditId
         * @param {string} searchTerm
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineBlueprintSearchBlueprints: async (auditId: string, searchTerm: string, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineBlueprintSearchBlueprints', 'auditId', auditId)
            // verify required parameter 'searchTerm' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineBlueprintSearchBlueprints', 'searchTerm', searchTerm)
            const localVarPath = `/api/v1/ca/online/blueprint/{audit_id}/blueprints/search`
                .replace(`{${"audit_id"}}`, encodeURIComponent(String(auditId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['search_term'] = searchTerm;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update Blueprint
         * @param {string} blueprintId
         * @param {BlueprintUpdateIN} blueprintUpdateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineBlueprintUpdateBlueprint: async (blueprintId: string, blueprintUpdateIN: BlueprintUpdateIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'blueprintId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineBlueprintUpdateBlueprint', 'blueprintId', blueprintId)
            // verify required parameter 'blueprintUpdateIN' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineBlueprintUpdateBlueprint', 'blueprintUpdateIN', blueprintUpdateIN)
            const localVarPath = `/api/v1/ca/online/blueprint/{blueprint_id}/update`
                .replace(`{${"blueprint_id"}}`, encodeURIComponent(String(blueprintId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(blueprintUpdateIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create Field
         * @param {FieldIN} fieldIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineFieldCreateField: async (fieldIN: FieldIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldIN' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineFieldCreateField', 'fieldIN', fieldIN)
            const localVarPath = `/api/v1/ca/online/field/fields/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fieldIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete Field
         * @param {string} fieldId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineFieldDeleteField: async (fieldId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineFieldDeleteField', 'fieldId', fieldId)
            const localVarPath = `/api/v1/ca/online/field/fields/{field_id}`
                .replace(`{${"field_id"}}`, encodeURIComponent(String(fieldId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get Field
         * @param {string} fieldId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineFieldGetField: async (fieldId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineFieldGetField', 'fieldId', fieldId)
            const localVarPath = `/api/v1/ca/online/field/fields/{field_id}`
                .replace(`{${"field_id"}}`, encodeURIComponent(String(fieldId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary List Fields
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineFieldListFields: async (limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/ca/online/field/fields/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update Field
         * @param {string} fieldId
         * @param {FieldIN} fieldIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineFieldUpdateField: async (fieldId: string, fieldIN: FieldIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineFieldUpdateField', 'fieldId', fieldId)
            // verify required parameter 'fieldIN' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineFieldUpdateField', 'fieldIN', fieldIN)
            const localVarPath = `/api/v1/ca/online/field/fields/{field_id}/update`
                .replace(`{${"field_id"}}`, encodeURIComponent(String(fieldId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fieldIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create Floor
         * @param {FloorCreateIN} floorCreateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineFloorCreateFloor: async (floorCreateIN: FloorCreateIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'floorCreateIN' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineFloorCreateFloor', 'floorCreateIN', floorCreateIN)
            const localVarPath = `/api/v1/ca/online/floor/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(floorCreateIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete Floor
         * @param {string} floorId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineFloorDeleteFloor: async (floorId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'floorId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineFloorDeleteFloor', 'floorId', floorId)
            const localVarPath = `/api/v1/ca/online/floor/{floor_id}`
                .replace(`{${"floor_id"}}`, encodeURIComponent(String(floorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get Floor
         * @param {string} floorId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineFloorGetFloor: async (floorId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'floorId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineFloorGetFloor', 'floorId', floorId)
            const localVarPath = `/api/v1/ca/online/floor/{floor_id}`
                .replace(`{${"floor_id"}}`, encodeURIComponent(String(floorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update Floor
         * @param {string} floorId
         * @param {FloorUpdateIN} floorUpdateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineFloorUpdateFloor: async (floorId: string, floorUpdateIN: FloorUpdateIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'floorId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineFloorUpdateFloor', 'floorId', floorId)
            // verify required parameter 'floorUpdateIN' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineFloorUpdateFloor', 'floorUpdateIN', floorUpdateIN)
            const localVarPath = `/api/v1/ca/online/floor/{floor_id}`
                .replace(`{${"floor_id"}}`, encodeURIComponent(String(floorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(floorUpdateIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create Instance
         * @param {InstanceIN} instanceIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineInstanceCreateInstance: async (instanceIN: InstanceIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceIN' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineInstanceCreateInstance', 'instanceIN', instanceIN)
            const localVarPath = `/api/v1/ca/online/instance/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(instanceIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete Instance
         * @param {string} auditId
         * @param {string} instanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineInstanceDeleteInstance: async (auditId: string, instanceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineInstanceDeleteInstance', 'auditId', auditId)
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineInstanceDeleteInstance', 'instanceId', instanceId)
            const localVarPath = `/api/v1/ca/online/instance/{audit_id}/instances/{instance_id}`
                .replace(`{${"audit_id"}}`, encodeURIComponent(String(auditId)))
                .replace(`{${"instance_id"}}`, encodeURIComponent(String(instanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Duplicate Instance
         * @param {string} instanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineInstanceDuplicateInstance: async (instanceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineInstanceDuplicateInstance', 'instanceId', instanceId)
            const localVarPath = `/api/v1/ca/online/instance/{instance_id}/duplicate`
                .replace(`{${"instance_id"}}`, encodeURIComponent(String(instanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get Instance
         * @param {string} auditId
         * @param {string} instanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineInstanceGetInstance: async (auditId: string, instanceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineInstanceGetInstance', 'auditId', auditId)
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineInstanceGetInstance', 'instanceId', instanceId)
            const localVarPath = `/api/v1/ca/online/instance/{audit_id}/instances/{instance_id}`
                .replace(`{${"audit_id"}}`, encodeURIComponent(String(auditId)))
                .replace(`{${"instance_id"}}`, encodeURIComponent(String(instanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary List Instance Properties
         * @param {string} instanceId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineInstanceListInstanceProperties: async (instanceId: string, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineInstanceListInstanceProperties', 'instanceId', instanceId)
            const localVarPath = `/api/v1/ca/online/instance/{instance_id}/properties`
                .replace(`{${"instance_id"}}`, encodeURIComponent(String(instanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary List Instances
         * @param {string} blueprintId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineInstanceListInstances: async (blueprintId: string, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'blueprintId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineInstanceListInstances', 'blueprintId', blueprintId)
            const localVarPath = `/api/v1/ca/online/instance/{blueprint_id}/instances`
                .replace(`{${"blueprint_id"}}`, encodeURIComponent(String(blueprintId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create Instance Property
         * @param {InstancePropertyIN} instancePropertyIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineInstancePropertyCreateInstanceProperty: async (instancePropertyIN: InstancePropertyIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instancePropertyIN' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineInstancePropertyCreateInstanceProperty', 'instancePropertyIN', instancePropertyIN)
            const localVarPath = `/api/v1/ca/online/instance_property/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(instancePropertyIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete Instance Property
         * @param {string} instancePropertyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineInstancePropertyDeleteInstanceProperty: async (instancePropertyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instancePropertyId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineInstancePropertyDeleteInstanceProperty', 'instancePropertyId', instancePropertyId)
            const localVarPath = `/api/v1/ca/online/instance_property/{instance_property_id}`
                .replace(`{${"instance_property_id"}}`, encodeURIComponent(String(instancePropertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get Single Instance Property
         * @param {string} instancePropertyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineInstancePropertyGetSingleInstanceProperty: async (instancePropertyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instancePropertyId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineInstancePropertyGetSingleInstanceProperty', 'instancePropertyId', instancePropertyId)
            const localVarPath = `/api/v1/ca/online/instance_property/{instance_property_id}`
                .replace(`{${"instance_property_id"}}`, encodeURIComponent(String(instancePropertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update Instance Property
         * @param {string} instancePropertyId
         * @param {InstancePropertyUpdateIN} instancePropertyUpdateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineInstancePropertyUpdateInstanceProperty: async (instancePropertyId: string, instancePropertyUpdateIN: InstancePropertyUpdateIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instancePropertyId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineInstancePropertyUpdateInstanceProperty', 'instancePropertyId', instancePropertyId)
            // verify required parameter 'instancePropertyUpdateIN' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineInstancePropertyUpdateInstanceProperty', 'instancePropertyUpdateIN', instancePropertyUpdateIN)
            const localVarPath = `/api/v1/ca/online/instance_property/{instance_property_id}`
                .replace(`{${"instance_property_id"}}`, encodeURIComponent(String(instancePropertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(instancePropertyUpdateIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update Instance
         * @param {string} instanceId
         * @param {InstanceUpdateIN} instanceUpdateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineInstanceUpdateInstance: async (instanceId: string, instanceUpdateIN: InstanceUpdateIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineInstanceUpdateInstance', 'instanceId', instanceId)
            // verify required parameter 'instanceUpdateIN' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineInstanceUpdateInstance', 'instanceUpdateIN', instanceUpdateIN)
            const localVarPath = `/api/v1/ca/online/instance/{instance_id}/update`
                .replace(`{${"instance_id"}}`, encodeURIComponent(String(instanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(instanceUpdateIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create Room
         * @param {RoomCreateIN} roomCreateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineRoomCreateRoom: async (roomCreateIN: RoomCreateIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roomCreateIN' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineRoomCreateRoom', 'roomCreateIN', roomCreateIN)
            const localVarPath = `/api/v1/ca/online/room/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roomCreateIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete Room
         * @param {string} roomId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineRoomDeleteRoom: async (roomId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roomId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineRoomDeleteRoom', 'roomId', roomId)
            const localVarPath = `/api/v1/ca/online/room/{room_id}`
                .replace(`{${"room_id"}}`, encodeURIComponent(String(roomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get Room
         * @param {string} roomId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineRoomGetRoom: async (roomId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roomId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineRoomGetRoom', 'roomId', roomId)
            const localVarPath = `/api/v1/ca/online/room/{room_id}`
                .replace(`{${"room_id"}}`, encodeURIComponent(String(roomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update Room
         * @param {string} roomId
         * @param {RoomUpdateIN} roomUpdateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineRoomUpdateRoom: async (roomId: string, roomUpdateIN: RoomUpdateIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roomId' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineRoomUpdateRoom', 'roomId', roomId)
            // verify required parameter 'roomUpdateIN' is not null or undefined
            assertParamExists('caApiV1RoutersCaOnlineRoomUpdateRoom', 'roomUpdateIN', roomUpdateIN)
            const localVarPath = `/api/v1/ca/online/room/{room_id}`
                .replace(`{${"room_id"}}`, encodeURIComponent(String(roomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roomUpdateIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OnlineApi - functional programming interface
 * @export
 */
export const OnlineApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OnlineApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Create Audit
         * @param {AuditIN} auditIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineAuditCreateAudit(auditIN: AuditIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SlimAuditOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineAuditCreateAudit(auditIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Delete Audit
         * @param {string} auditId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineAuditDeleteAudit(auditId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineAuditDeleteAudit(auditId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get Audit
         * @param {string} auditId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineAuditGetAudit(auditId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SlimAuditOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineAuditGetAudit(auditId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary List Audits
         * @param {string} buildingId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineAuditListAudits(buildingId: string, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedSlimAuditOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineAuditListAudits(buildingId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary List Floors
         * @param {string} auditId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineAuditListFloors(auditId: string, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedFloorOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineAuditListFloors(auditId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary List Rooms
         * @param {string} auditId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineAuditListRooms(auditId: string, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedRoomOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineAuditListRooms(auditId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Create Blueprint
         * @param {string} auditId
         * @param {BlueprintIN} blueprintIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineBlueprintCreateBlueprint(auditId: string, blueprintIN: BlueprintIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SlimBlueprintOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineBlueprintCreateBlueprint(auditId, blueprintIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Delete Blueprint
         * @param {string} auditId
         * @param {string} blueprintId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineBlueprintDeleteBlueprint(auditId: string, blueprintId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineBlueprintDeleteBlueprint(auditId, blueprintId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Duplicate Blueprint
         * @param {string} blueprintId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineBlueprintDuplicateBlueprint(blueprintId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SlimBlueprintOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineBlueprintDuplicateBlueprint(blueprintId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get Blueprint Details
         * @param {string} blueprintId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineBlueprintGetBlueprintDetails(blueprintId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlueprintOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineBlueprintGetBlueprintDetails(blueprintId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get Blueprint Images
         * @param {string} blueprintId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineBlueprintGetBlueprintImages(blueprintId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImageOUT>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineBlueprintGetBlueprintImages(blueprintId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary List Blueprint Properties
         * @param {string} blueprintId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineBlueprintListBlueprintProperties(blueprintId: string, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedBlueprintPropertyOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineBlueprintListBlueprintProperties(blueprintId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary List Blueprints
         * @param {string} auditId
         * @param {SortOrder | null} [sortOrder]
         * @param {BlueprintListSortBy | null} [sortBy]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineBlueprintListBlueprints(auditId: string, sortOrder?: SortOrder | null, sortBy?: BlueprintListSortBy | null, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedSlimBlueprintOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineBlueprintListBlueprints(auditId, sortOrder, sortBy, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Create Blueprint Property
         * @param {BlueprintPropertyIN} blueprintPropertyIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineBlueprintPropertyCreateBlueprintProperty(blueprintPropertyIN: BlueprintPropertyIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlueprintPropertyOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineBlueprintPropertyCreateBlueprintProperty(blueprintPropertyIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Delete Blueprint Property
         * @param {string} blueprintPropertyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineBlueprintPropertyDeleteBlueprintProperty(blueprintPropertyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineBlueprintPropertyDeleteBlueprintProperty(blueprintPropertyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get Blueprint Property
         * @param {string} blueprintPropertyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineBlueprintPropertyGetBlueprintProperty(blueprintPropertyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlueprintPropertyOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineBlueprintPropertyGetBlueprintProperty(blueprintPropertyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Update Blueprint Property
         * @param {string} blueprintPropertyId
         * @param {BlueprintPropertyUpdateIN} blueprintPropertyUpdateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineBlueprintPropertyUpdateBlueprintProperty(blueprintPropertyId: string, blueprintPropertyUpdateIN: BlueprintPropertyUpdateIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlueprintPropertyOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineBlueprintPropertyUpdateBlueprintProperty(blueprintPropertyId, blueprintPropertyUpdateIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Search Blueprints
         * @param {string} auditId
         * @param {string} searchTerm
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineBlueprintSearchBlueprints(auditId: string, searchTerm: string, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedSlimBlueprintOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineBlueprintSearchBlueprints(auditId, searchTerm, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Update Blueprint
         * @param {string} blueprintId
         * @param {BlueprintUpdateIN} blueprintUpdateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineBlueprintUpdateBlueprint(blueprintId: string, blueprintUpdateIN: BlueprintUpdateIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SlimBlueprintOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineBlueprintUpdateBlueprint(blueprintId, blueprintUpdateIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Create Field
         * @param {FieldIN} fieldIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineFieldCreateField(fieldIN: FieldIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineFieldCreateField(fieldIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Delete Field
         * @param {string} fieldId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineFieldDeleteField(fieldId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineFieldDeleteField(fieldId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get Field
         * @param {string} fieldId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineFieldGetField(fieldId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineFieldGetField(fieldId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary List Fields
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineFieldListFields(limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedFieldOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineFieldListFields(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Update Field
         * @param {string} fieldId
         * @param {FieldIN} fieldIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineFieldUpdateField(fieldId: string, fieldIN: FieldIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineFieldUpdateField(fieldId, fieldIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Create Floor
         * @param {FloorCreateIN} floorCreateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineFloorCreateFloor(floorCreateIN: FloorCreateIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FloorOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineFloorCreateFloor(floorCreateIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Delete Floor
         * @param {string} floorId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineFloorDeleteFloor(floorId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineFloorDeleteFloor(floorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get Floor
         * @param {string} floorId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineFloorGetFloor(floorId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FloorOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineFloorGetFloor(floorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Update Floor
         * @param {string} floorId
         * @param {FloorUpdateIN} floorUpdateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineFloorUpdateFloor(floorId: string, floorUpdateIN: FloorUpdateIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FloorOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineFloorUpdateFloor(floorId, floorUpdateIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Create Instance
         * @param {InstanceIN} instanceIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineInstanceCreateInstance(instanceIN: InstanceIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstanceOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineInstanceCreateInstance(instanceIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Delete Instance
         * @param {string} auditId
         * @param {string} instanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineInstanceDeleteInstance(auditId: string, instanceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineInstanceDeleteInstance(auditId, instanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Duplicate Instance
         * @param {string} instanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineInstanceDuplicateInstance(instanceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstanceOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineInstanceDuplicateInstance(instanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get Instance
         * @param {string} auditId
         * @param {string} instanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineInstanceGetInstance(auditId: string, instanceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstanceOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineInstanceGetInstance(auditId, instanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary List Instance Properties
         * @param {string} instanceId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineInstanceListInstanceProperties(instanceId: string, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedInstancePropertyOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineInstanceListInstanceProperties(instanceId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary List Instances
         * @param {string} blueprintId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineInstanceListInstances(blueprintId: string, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedInstanceOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineInstanceListInstances(blueprintId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Create Instance Property
         * @param {InstancePropertyIN} instancePropertyIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineInstancePropertyCreateInstanceProperty(instancePropertyIN: InstancePropertyIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstancePropertyOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineInstancePropertyCreateInstanceProperty(instancePropertyIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Delete Instance Property
         * @param {string} instancePropertyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineInstancePropertyDeleteInstanceProperty(instancePropertyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineInstancePropertyDeleteInstanceProperty(instancePropertyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get Single Instance Property
         * @param {string} instancePropertyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineInstancePropertyGetSingleInstanceProperty(instancePropertyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstancePropertyOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineInstancePropertyGetSingleInstanceProperty(instancePropertyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Update Instance Property
         * @param {string} instancePropertyId
         * @param {InstancePropertyUpdateIN} instancePropertyUpdateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineInstancePropertyUpdateInstanceProperty(instancePropertyId: string, instancePropertyUpdateIN: InstancePropertyUpdateIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstancePropertyOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineInstancePropertyUpdateInstanceProperty(instancePropertyId, instancePropertyUpdateIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Update Instance
         * @param {string} instanceId
         * @param {InstanceUpdateIN} instanceUpdateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineInstanceUpdateInstance(instanceId: string, instanceUpdateIN: InstanceUpdateIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstanceOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineInstanceUpdateInstance(instanceId, instanceUpdateIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Create Room
         * @param {RoomCreateIN} roomCreateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineRoomCreateRoom(roomCreateIN: RoomCreateIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineRoomCreateRoom(roomCreateIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Delete Room
         * @param {string} roomId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineRoomDeleteRoom(roomId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineRoomDeleteRoom(roomId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get Room
         * @param {string} roomId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineRoomGetRoom(roomId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineRoomGetRoom(roomId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Update Room
         * @param {string} roomId
         * @param {RoomUpdateIN} roomUpdateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersCaOnlineRoomUpdateRoom(roomId: string, roomUpdateIN: RoomUpdateIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersCaOnlineRoomUpdateRoom(roomId, roomUpdateIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OnlineApi - factory interface
 * @export
 */
export const OnlineApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OnlineApiFp(configuration)
    return {
        /**
         *
         * @summary Create Audit
         * @param {AuditIN} auditIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineAuditCreateAudit(auditIN: AuditIN, options?: any): AxiosPromise<SlimAuditOut> {
            return localVarFp.caApiV1RoutersCaOnlineAuditCreateAudit(auditIN, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete Audit
         * @param {string} auditId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineAuditDeleteAudit(auditId: string, options?: any): AxiosPromise<void> {
            return localVarFp.caApiV1RoutersCaOnlineAuditDeleteAudit(auditId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get Audit
         * @param {string} auditId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineAuditGetAudit(auditId: string, options?: any): AxiosPromise<SlimAuditOut> {
            return localVarFp.caApiV1RoutersCaOnlineAuditGetAudit(auditId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary List Audits
         * @param {string} buildingId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineAuditListAudits(buildingId: string, limit?: number, offset?: number, options?: any): AxiosPromise<PagedSlimAuditOut> {
            return localVarFp.caApiV1RoutersCaOnlineAuditListAudits(buildingId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary List Floors
         * @param {string} auditId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineAuditListFloors(auditId: string, limit?: number, offset?: number, options?: any): AxiosPromise<PagedFloorOUT> {
            return localVarFp.caApiV1RoutersCaOnlineAuditListFloors(auditId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary List Rooms
         * @param {string} auditId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineAuditListRooms(auditId: string, limit?: number, offset?: number, options?: any): AxiosPromise<PagedRoomOUT> {
            return localVarFp.caApiV1RoutersCaOnlineAuditListRooms(auditId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Create Blueprint
         * @param {string} auditId
         * @param {BlueprintIN} blueprintIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineBlueprintCreateBlueprint(auditId: string, blueprintIN: BlueprintIN, options?: any): AxiosPromise<SlimBlueprintOUT> {
            return localVarFp.caApiV1RoutersCaOnlineBlueprintCreateBlueprint(auditId, blueprintIN, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete Blueprint
         * @param {string} auditId
         * @param {string} blueprintId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineBlueprintDeleteBlueprint(auditId: string, blueprintId: string, options?: any): AxiosPromise<void> {
            return localVarFp.caApiV1RoutersCaOnlineBlueprintDeleteBlueprint(auditId, blueprintId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Duplicate Blueprint
         * @param {string} blueprintId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineBlueprintDuplicateBlueprint(blueprintId: string, options?: any): AxiosPromise<SlimBlueprintOUT> {
            return localVarFp.caApiV1RoutersCaOnlineBlueprintDuplicateBlueprint(blueprintId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get Blueprint Details
         * @param {string} blueprintId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineBlueprintGetBlueprintDetails(blueprintId: string, options?: any): AxiosPromise<BlueprintOUT> {
            return localVarFp.caApiV1RoutersCaOnlineBlueprintGetBlueprintDetails(blueprintId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get Blueprint Images
         * @param {string} blueprintId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineBlueprintGetBlueprintImages(blueprintId: string, options?: any): AxiosPromise<Array<ImageOUT>> {
            return localVarFp.caApiV1RoutersCaOnlineBlueprintGetBlueprintImages(blueprintId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary List Blueprint Properties
         * @param {string} blueprintId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineBlueprintListBlueprintProperties(blueprintId: string, limit?: number, offset?: number, options?: any): AxiosPromise<PagedBlueprintPropertyOUT> {
            return localVarFp.caApiV1RoutersCaOnlineBlueprintListBlueprintProperties(blueprintId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary List Blueprints
         * @param {string} auditId
         * @param {SortOrder | null} [sortOrder]
         * @param {BlueprintListSortBy | null} [sortBy]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineBlueprintListBlueprints(auditId: string, sortOrder?: SortOrder | null, sortBy?: BlueprintListSortBy | null, limit?: number, offset?: number, options?: any): AxiosPromise<PagedSlimBlueprintOUT> {
            return localVarFp.caApiV1RoutersCaOnlineBlueprintListBlueprints(auditId, sortOrder, sortBy, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Create Blueprint Property
         * @param {BlueprintPropertyIN} blueprintPropertyIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineBlueprintPropertyCreateBlueprintProperty(blueprintPropertyIN: BlueprintPropertyIN, options?: any): AxiosPromise<BlueprintPropertyOUT> {
            return localVarFp.caApiV1RoutersCaOnlineBlueprintPropertyCreateBlueprintProperty(blueprintPropertyIN, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete Blueprint Property
         * @param {string} blueprintPropertyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineBlueprintPropertyDeleteBlueprintProperty(blueprintPropertyId: string, options?: any): AxiosPromise<ResultOUT> {
            return localVarFp.caApiV1RoutersCaOnlineBlueprintPropertyDeleteBlueprintProperty(blueprintPropertyId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get Blueprint Property
         * @param {string} blueprintPropertyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineBlueprintPropertyGetBlueprintProperty(blueprintPropertyId: string, options?: any): AxiosPromise<BlueprintPropertyOUT> {
            return localVarFp.caApiV1RoutersCaOnlineBlueprintPropertyGetBlueprintProperty(blueprintPropertyId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Update Blueprint Property
         * @param {string} blueprintPropertyId
         * @param {BlueprintPropertyUpdateIN} blueprintPropertyUpdateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineBlueprintPropertyUpdateBlueprintProperty(blueprintPropertyId: string, blueprintPropertyUpdateIN: BlueprintPropertyUpdateIN, options?: any): AxiosPromise<BlueprintPropertyOUT> {
            return localVarFp.caApiV1RoutersCaOnlineBlueprintPropertyUpdateBlueprintProperty(blueprintPropertyId, blueprintPropertyUpdateIN, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Search Blueprints
         * @param {string} auditId
         * @param {string} searchTerm
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineBlueprintSearchBlueprints(auditId: string, searchTerm: string, limit?: number, offset?: number, options?: any): AxiosPromise<PagedSlimBlueprintOUT> {
            return localVarFp.caApiV1RoutersCaOnlineBlueprintSearchBlueprints(auditId, searchTerm, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Update Blueprint
         * @param {string} blueprintId
         * @param {BlueprintUpdateIN} blueprintUpdateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineBlueprintUpdateBlueprint(blueprintId: string, blueprintUpdateIN: BlueprintUpdateIN, options?: any): AxiosPromise<SlimBlueprintOUT> {
            return localVarFp.caApiV1RoutersCaOnlineBlueprintUpdateBlueprint(blueprintId, blueprintUpdateIN, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Create Field
         * @param {FieldIN} fieldIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineFieldCreateField(fieldIN: FieldIN, options?: any): AxiosPromise<FieldOUT> {
            return localVarFp.caApiV1RoutersCaOnlineFieldCreateField(fieldIN, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete Field
         * @param {string} fieldId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineFieldDeleteField(fieldId: string, options?: any): AxiosPromise<void> {
            return localVarFp.caApiV1RoutersCaOnlineFieldDeleteField(fieldId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get Field
         * @param {string} fieldId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineFieldGetField(fieldId: string, options?: any): AxiosPromise<FieldOUT> {
            return localVarFp.caApiV1RoutersCaOnlineFieldGetField(fieldId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary List Fields
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineFieldListFields(limit?: number, offset?: number, options?: any): AxiosPromise<PagedFieldOUT> {
            return localVarFp.caApiV1RoutersCaOnlineFieldListFields(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Update Field
         * @param {string} fieldId
         * @param {FieldIN} fieldIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineFieldUpdateField(fieldId: string, fieldIN: FieldIN, options?: any): AxiosPromise<FieldOUT> {
            return localVarFp.caApiV1RoutersCaOnlineFieldUpdateField(fieldId, fieldIN, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Create Floor
         * @param {FloorCreateIN} floorCreateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineFloorCreateFloor(floorCreateIN: FloorCreateIN, options?: any): AxiosPromise<FloorOUT> {
            return localVarFp.caApiV1RoutersCaOnlineFloorCreateFloor(floorCreateIN, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete Floor
         * @param {string} floorId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineFloorDeleteFloor(floorId: string, options?: any): AxiosPromise<ResultOUT> {
            return localVarFp.caApiV1RoutersCaOnlineFloorDeleteFloor(floorId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get Floor
         * @param {string} floorId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineFloorGetFloor(floorId: string, options?: any): AxiosPromise<FloorOUT> {
            return localVarFp.caApiV1RoutersCaOnlineFloorGetFloor(floorId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Update Floor
         * @param {string} floorId
         * @param {FloorUpdateIN} floorUpdateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineFloorUpdateFloor(floorId: string, floorUpdateIN: FloorUpdateIN, options?: any): AxiosPromise<FloorOUT> {
            return localVarFp.caApiV1RoutersCaOnlineFloorUpdateFloor(floorId, floorUpdateIN, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Create Instance
         * @param {InstanceIN} instanceIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineInstanceCreateInstance(instanceIN: InstanceIN, options?: any): AxiosPromise<InstanceOUT> {
            return localVarFp.caApiV1RoutersCaOnlineInstanceCreateInstance(instanceIN, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete Instance
         * @param {string} auditId
         * @param {string} instanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineInstanceDeleteInstance(auditId: string, instanceId: string, options?: any): AxiosPromise<void> {
            return localVarFp.caApiV1RoutersCaOnlineInstanceDeleteInstance(auditId, instanceId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Duplicate Instance
         * @param {string} instanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineInstanceDuplicateInstance(instanceId: string, options?: any): AxiosPromise<InstanceOUT> {
            return localVarFp.caApiV1RoutersCaOnlineInstanceDuplicateInstance(instanceId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get Instance
         * @param {string} auditId
         * @param {string} instanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineInstanceGetInstance(auditId: string, instanceId: string, options?: any): AxiosPromise<InstanceOUT> {
            return localVarFp.caApiV1RoutersCaOnlineInstanceGetInstance(auditId, instanceId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary List Instance Properties
         * @param {string} instanceId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineInstanceListInstanceProperties(instanceId: string, limit?: number, offset?: number, options?: any): AxiosPromise<PagedInstancePropertyOUT> {
            return localVarFp.caApiV1RoutersCaOnlineInstanceListInstanceProperties(instanceId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary List Instances
         * @param {string} blueprintId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineInstanceListInstances(blueprintId: string, limit?: number, offset?: number, options?: any): AxiosPromise<PagedInstanceOUT> {
            return localVarFp.caApiV1RoutersCaOnlineInstanceListInstances(blueprintId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Create Instance Property
         * @param {InstancePropertyIN} instancePropertyIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineInstancePropertyCreateInstanceProperty(instancePropertyIN: InstancePropertyIN, options?: any): AxiosPromise<InstancePropertyOUT> {
            return localVarFp.caApiV1RoutersCaOnlineInstancePropertyCreateInstanceProperty(instancePropertyIN, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete Instance Property
         * @param {string} instancePropertyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineInstancePropertyDeleteInstanceProperty(instancePropertyId: string, options?: any): AxiosPromise<ResultOUT> {
            return localVarFp.caApiV1RoutersCaOnlineInstancePropertyDeleteInstanceProperty(instancePropertyId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get Single Instance Property
         * @param {string} instancePropertyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineInstancePropertyGetSingleInstanceProperty(instancePropertyId: string, options?: any): AxiosPromise<InstancePropertyOUT> {
            return localVarFp.caApiV1RoutersCaOnlineInstancePropertyGetSingleInstanceProperty(instancePropertyId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Update Instance Property
         * @param {string} instancePropertyId
         * @param {InstancePropertyUpdateIN} instancePropertyUpdateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineInstancePropertyUpdateInstanceProperty(instancePropertyId: string, instancePropertyUpdateIN: InstancePropertyUpdateIN, options?: any): AxiosPromise<InstancePropertyOUT> {
            return localVarFp.caApiV1RoutersCaOnlineInstancePropertyUpdateInstanceProperty(instancePropertyId, instancePropertyUpdateIN, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Update Instance
         * @param {string} instanceId
         * @param {InstanceUpdateIN} instanceUpdateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineInstanceUpdateInstance(instanceId: string, instanceUpdateIN: InstanceUpdateIN, options?: any): AxiosPromise<InstanceOUT> {
            return localVarFp.caApiV1RoutersCaOnlineInstanceUpdateInstance(instanceId, instanceUpdateIN, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Create Room
         * @param {RoomCreateIN} roomCreateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineRoomCreateRoom(roomCreateIN: RoomCreateIN, options?: any): AxiosPromise<RoomOUT> {
            return localVarFp.caApiV1RoutersCaOnlineRoomCreateRoom(roomCreateIN, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete Room
         * @param {string} roomId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineRoomDeleteRoom(roomId: string, options?: any): AxiosPromise<ResultOUT> {
            return localVarFp.caApiV1RoutersCaOnlineRoomDeleteRoom(roomId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get Room
         * @param {string} roomId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineRoomGetRoom(roomId: string, options?: any): AxiosPromise<RoomOUT> {
            return localVarFp.caApiV1RoutersCaOnlineRoomGetRoom(roomId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Update Room
         * @param {string} roomId
         * @param {RoomUpdateIN} roomUpdateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersCaOnlineRoomUpdateRoom(roomId: string, roomUpdateIN: RoomUpdateIN, options?: any): AxiosPromise<RoomOUT> {
            return localVarFp.caApiV1RoutersCaOnlineRoomUpdateRoom(roomId, roomUpdateIN, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OnlineApi - object-oriented interface
 * @export
 * @class OnlineApi
 * @extends {BaseAPI}
 */
export class OnlineApi extends BaseAPI {
    /**
     *
     * @summary Create Audit
     * @param {AuditIN} auditIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineAuditCreateAudit(auditIN: AuditIN, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineAuditCreateAudit(auditIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Delete Audit
     * @param {string} auditId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineAuditDeleteAudit(auditId: string, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineAuditDeleteAudit(auditId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get Audit
     * @param {string} auditId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineAuditGetAudit(auditId: string, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineAuditGetAudit(auditId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary List Audits
     * @param {string} buildingId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineAuditListAudits(buildingId: string, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineAuditListAudits(buildingId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary List Floors
     * @param {string} auditId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineAuditListFloors(auditId: string, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineAuditListFloors(auditId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary List Rooms
     * @param {string} auditId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineAuditListRooms(auditId: string, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineAuditListRooms(auditId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Create Blueprint
     * @param {string} auditId
     * @param {BlueprintIN} blueprintIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineBlueprintCreateBlueprint(auditId: string, blueprintIN: BlueprintIN, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineBlueprintCreateBlueprint(auditId, blueprintIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Delete Blueprint
     * @param {string} auditId
     * @param {string} blueprintId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineBlueprintDeleteBlueprint(auditId: string, blueprintId: string, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineBlueprintDeleteBlueprint(auditId, blueprintId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Duplicate Blueprint
     * @param {string} blueprintId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineBlueprintDuplicateBlueprint(blueprintId: string, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineBlueprintDuplicateBlueprint(blueprintId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get Blueprint Details
     * @param {string} blueprintId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineBlueprintGetBlueprintDetails(blueprintId: string, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineBlueprintGetBlueprintDetails(blueprintId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get Blueprint Images
     * @param {string} blueprintId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineBlueprintGetBlueprintImages(blueprintId: string, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineBlueprintGetBlueprintImages(blueprintId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary List Blueprint Properties
     * @param {string} blueprintId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineBlueprintListBlueprintProperties(blueprintId: string, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineBlueprintListBlueprintProperties(blueprintId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary List Blueprints
     * @param {string} auditId
     * @param {SortOrder | null} [sortOrder]
     * @param {BlueprintListSortBy | null} [sortBy]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineBlueprintListBlueprints(auditId: string, sortOrder?: SortOrder | null, sortBy?: BlueprintListSortBy | null, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineBlueprintListBlueprints(auditId, sortOrder, sortBy, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Create Blueprint Property
     * @param {BlueprintPropertyIN} blueprintPropertyIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineBlueprintPropertyCreateBlueprintProperty(blueprintPropertyIN: BlueprintPropertyIN, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineBlueprintPropertyCreateBlueprintProperty(blueprintPropertyIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Delete Blueprint Property
     * @param {string} blueprintPropertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineBlueprintPropertyDeleteBlueprintProperty(blueprintPropertyId: string, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineBlueprintPropertyDeleteBlueprintProperty(blueprintPropertyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get Blueprint Property
     * @param {string} blueprintPropertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineBlueprintPropertyGetBlueprintProperty(blueprintPropertyId: string, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineBlueprintPropertyGetBlueprintProperty(blueprintPropertyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Update Blueprint Property
     * @param {string} blueprintPropertyId
     * @param {BlueprintPropertyUpdateIN} blueprintPropertyUpdateIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineBlueprintPropertyUpdateBlueprintProperty(blueprintPropertyId: string, blueprintPropertyUpdateIN: BlueprintPropertyUpdateIN, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineBlueprintPropertyUpdateBlueprintProperty(blueprintPropertyId, blueprintPropertyUpdateIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Search Blueprints
     * @param {string} auditId
     * @param {string} searchTerm
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineBlueprintSearchBlueprints(auditId: string, searchTerm: string, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineBlueprintSearchBlueprints(auditId, searchTerm, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Update Blueprint
     * @param {string} blueprintId
     * @param {BlueprintUpdateIN} blueprintUpdateIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineBlueprintUpdateBlueprint(blueprintId: string, blueprintUpdateIN: BlueprintUpdateIN, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineBlueprintUpdateBlueprint(blueprintId, blueprintUpdateIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Create Field
     * @param {FieldIN} fieldIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineFieldCreateField(fieldIN: FieldIN, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineFieldCreateField(fieldIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Delete Field
     * @param {string} fieldId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineFieldDeleteField(fieldId: string, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineFieldDeleteField(fieldId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get Field
     * @param {string} fieldId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineFieldGetField(fieldId: string, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineFieldGetField(fieldId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary List Fields
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineFieldListFields(limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineFieldListFields(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Update Field
     * @param {string} fieldId
     * @param {FieldIN} fieldIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineFieldUpdateField(fieldId: string, fieldIN: FieldIN, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineFieldUpdateField(fieldId, fieldIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Create Floor
     * @param {FloorCreateIN} floorCreateIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineFloorCreateFloor(floorCreateIN: FloorCreateIN, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineFloorCreateFloor(floorCreateIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Delete Floor
     * @param {string} floorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineFloorDeleteFloor(floorId: string, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineFloorDeleteFloor(floorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get Floor
     * @param {string} floorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineFloorGetFloor(floorId: string, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineFloorGetFloor(floorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Update Floor
     * @param {string} floorId
     * @param {FloorUpdateIN} floorUpdateIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineFloorUpdateFloor(floorId: string, floorUpdateIN: FloorUpdateIN, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineFloorUpdateFloor(floorId, floorUpdateIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Create Instance
     * @param {InstanceIN} instanceIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineInstanceCreateInstance(instanceIN: InstanceIN, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineInstanceCreateInstance(instanceIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Delete Instance
     * @param {string} auditId
     * @param {string} instanceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineInstanceDeleteInstance(auditId: string, instanceId: string, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineInstanceDeleteInstance(auditId, instanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Duplicate Instance
     * @param {string} instanceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineInstanceDuplicateInstance(instanceId: string, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineInstanceDuplicateInstance(instanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get Instance
     * @param {string} auditId
     * @param {string} instanceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineInstanceGetInstance(auditId: string, instanceId: string, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineInstanceGetInstance(auditId, instanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary List Instance Properties
     * @param {string} instanceId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineInstanceListInstanceProperties(instanceId: string, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineInstanceListInstanceProperties(instanceId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary List Instances
     * @param {string} blueprintId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineInstanceListInstances(blueprintId: string, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineInstanceListInstances(blueprintId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Create Instance Property
     * @param {InstancePropertyIN} instancePropertyIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineInstancePropertyCreateInstanceProperty(instancePropertyIN: InstancePropertyIN, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineInstancePropertyCreateInstanceProperty(instancePropertyIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Delete Instance Property
     * @param {string} instancePropertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineInstancePropertyDeleteInstanceProperty(instancePropertyId: string, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineInstancePropertyDeleteInstanceProperty(instancePropertyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get Single Instance Property
     * @param {string} instancePropertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineInstancePropertyGetSingleInstanceProperty(instancePropertyId: string, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineInstancePropertyGetSingleInstanceProperty(instancePropertyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Update Instance Property
     * @param {string} instancePropertyId
     * @param {InstancePropertyUpdateIN} instancePropertyUpdateIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineInstancePropertyUpdateInstanceProperty(instancePropertyId: string, instancePropertyUpdateIN: InstancePropertyUpdateIN, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineInstancePropertyUpdateInstanceProperty(instancePropertyId, instancePropertyUpdateIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Update Instance
     * @param {string} instanceId
     * @param {InstanceUpdateIN} instanceUpdateIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineInstanceUpdateInstance(instanceId: string, instanceUpdateIN: InstanceUpdateIN, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineInstanceUpdateInstance(instanceId, instanceUpdateIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Create Room
     * @param {RoomCreateIN} roomCreateIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineRoomCreateRoom(roomCreateIN: RoomCreateIN, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineRoomCreateRoom(roomCreateIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Delete Room
     * @param {string} roomId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineRoomDeleteRoom(roomId: string, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineRoomDeleteRoom(roomId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get Room
     * @param {string} roomId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineRoomGetRoom(roomId: string, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineRoomGetRoom(roomId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Update Room
     * @param {string} roomId
     * @param {RoomUpdateIN} roomUpdateIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineApi
     */
    public caApiV1RoutersCaOnlineRoomUpdateRoom(roomId: string, roomUpdateIN: RoomUpdateIN, options?: AxiosRequestConfig) {
        return OnlineApiFp(this.configuration).caApiV1RoutersCaOnlineRoomUpdateRoom(roomId, roomUpdateIN, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OperationalEnergyApi - axios parameter creator
 * @export
 */
export const OperationalEnergyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Confirm the report
         * @summary Confirm Report
         * @param {string} reportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportApiV1RoutersOperationalEnergyConfirmReport: async (reportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportApiV1RoutersOperationalEnergyConfirmReport', 'reportId', reportId)
            const localVarPath = `/api/v1/report/operational_energy/{report_id}/confirm`
                .replace(`{${"report_id"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the latest CONFIRMED report if report_id is not provided. Otherwise, get the report by report_id
         * @summary Get Latest Report
         * @param {string} buildingId
         * @param {string | null} [reportId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportApiV1RoutersOperationalEnergyGetLatestReport: async (buildingId: string, reportId?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('reportApiV1RoutersOperationalEnergyGetLatestReport', 'buildingId', buildingId)
            const localVarPath = `/api/v1/report/operational_energy/latest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (buildingId !== undefined) {
                localVarQueryParameter['building_id'] = buildingId;
            }

            if (reportId !== undefined) {
                localVarQueryParameter['report_id'] = reportId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Submit a file
         * @summary Submit File
         * @param {string} buildingId
         * @param {File} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportApiV1RoutersOperationalEnergySubmitFile: async (buildingId: string, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('reportApiV1RoutersOperationalEnergySubmitFile', 'buildingId', buildingId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('reportApiV1RoutersOperationalEnergySubmitFile', 'file', file)
            const localVarPath = `/api/v1/report/operational_energy/submit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (buildingId !== undefined) {
                localVarQueryParameter['building_id'] = buildingId;
            }


            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OperationalEnergyApi - functional programming interface
 * @export
 */
export const OperationalEnergyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OperationalEnergyApiAxiosParamCreator(configuration)
    return {
        /**
         * Confirm the report
         * @summary Confirm Report
         * @param {string} reportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportApiV1RoutersOperationalEnergyConfirmReport(reportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportApiV1RoutersOperationalEnergyConfirmReport(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the latest CONFIRMED report if report_id is not provided. Otherwise, get the report by report_id
         * @summary Get Latest Report
         * @param {string} buildingId
         * @param {string | null} [reportId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportApiV1RoutersOperationalEnergyGetLatestReport(buildingId: string, reportId?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LatestReportOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportApiV1RoutersOperationalEnergyGetLatestReport(buildingId, reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Submit a file
         * @summary Submit File
         * @param {string} buildingId
         * @param {File} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportApiV1RoutersOperationalEnergySubmitFile(buildingId: string, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperationalEnergyReportSubmitOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportApiV1RoutersOperationalEnergySubmitFile(buildingId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OperationalEnergyApi - factory interface
 * @export
 */
export const OperationalEnergyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OperationalEnergyApiFp(configuration)
    return {
        /**
         * Confirm the report
         * @summary Confirm Report
         * @param {string} reportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportApiV1RoutersOperationalEnergyConfirmReport(reportId: string, options?: any): AxiosPromise<ResultOUT> {
            return localVarFp.reportApiV1RoutersOperationalEnergyConfirmReport(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the latest CONFIRMED report if report_id is not provided. Otherwise, get the report by report_id
         * @summary Get Latest Report
         * @param {string} buildingId
         * @param {string | null} [reportId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportApiV1RoutersOperationalEnergyGetLatestReport(buildingId: string, reportId?: string | null, options?: any): AxiosPromise<LatestReportOUT> {
            return localVarFp.reportApiV1RoutersOperationalEnergyGetLatestReport(buildingId, reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * Submit a file
         * @summary Submit File
         * @param {string} buildingId
         * @param {File} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportApiV1RoutersOperationalEnergySubmitFile(buildingId: string, file: File, options?: any): AxiosPromise<OperationalEnergyReportSubmitOUT> {
            return localVarFp.reportApiV1RoutersOperationalEnergySubmitFile(buildingId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OperationalEnergyApi - object-oriented interface
 * @export
 * @class OperationalEnergyApi
 * @extends {BaseAPI}
 */
export class OperationalEnergyApi extends BaseAPI {
    /**
     * Confirm the report
     * @summary Confirm Report
     * @param {string} reportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationalEnergyApi
     */
    public reportApiV1RoutersOperationalEnergyConfirmReport(reportId: string, options?: AxiosRequestConfig) {
        return OperationalEnergyApiFp(this.configuration).reportApiV1RoutersOperationalEnergyConfirmReport(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the latest CONFIRMED report if report_id is not provided. Otherwise, get the report by report_id
     * @summary Get Latest Report
     * @param {string} buildingId
     * @param {string | null} [reportId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationalEnergyApi
     */
    public reportApiV1RoutersOperationalEnergyGetLatestReport(buildingId: string, reportId?: string | null, options?: AxiosRequestConfig) {
        return OperationalEnergyApiFp(this.configuration).reportApiV1RoutersOperationalEnergyGetLatestReport(buildingId, reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Submit a file
     * @summary Submit File
     * @param {string} buildingId
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationalEnergyApi
     */
    public reportApiV1RoutersOperationalEnergySubmitFile(buildingId: string, file: File, options?: AxiosRequestConfig) {
        return OperationalEnergyApiFp(this.configuration).reportApiV1RoutersOperationalEnergySubmitFile(buildingId, file, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrganisationAccessRequestsApi - axios parameter creator
 * @export
 */
export const OrganisationAccessRequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get latest status of user organisation request.
         * @summary Check Org Access Request Status
         * @param {string} organisationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationAccessRequestCheckOrgAccessRequestStatus: async (organisationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('concularApiV1RoutersOrganisationAccessRequestCheckOrgAccessRequestStatus', 'organisationId', organisationId)
            const localVarPath = `/api/v1/concular/organisation_requests/{organisation_id}/status`
                .replace(`{${"organisation_id"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Organisation admin can decline the user request to join the organisation.
         * @summary Decline User Request For Organisation
         * @param {string} orgAccessReqId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationAccessRequestDeclineUserRequestForOrganisation: async (orgAccessReqId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgAccessReqId' is not null or undefined
            assertParamExists('concularApiV1RoutersOrganisationAccessRequestDeclineUserRequestForOrganisation', 'orgAccessReqId', orgAccessReqId)
            const localVarPath = `/api/v1/concular/organisation_requests/{org_access_req_id}`
                .replace(`{${"org_access_req_id"}}`, encodeURIComponent(String(orgAccessReqId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Organisation admin can get all the pending invites and request for an organisation.
         * @summary Get All Requests
         * @param {string} organisationId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationAccessRequestGetAllRequests: async (organisationId: string, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('concularApiV1RoutersOrganisationAccessRequestGetAllRequests', 'organisationId', organisationId)
            const localVarPath = `/api/v1/concular/organisation_requests/{organisation_id}/all`
                .replace(`{${"organisation_id"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Organisation admin can invite new users (as an admin or a member) to join the organisation. User will be notified by email.
         * @summary Invite User Or Admin For Org
         * @param {string} organisationId
         * @param {OrganisationInviteeIN} organisationInviteeIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationAccessRequestInviteUserOrAdminForOrg: async (organisationId: string, organisationInviteeIN: OrganisationInviteeIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('concularApiV1RoutersOrganisationAccessRequestInviteUserOrAdminForOrg', 'organisationId', organisationId)
            // verify required parameter 'organisationInviteeIN' is not null or undefined
            assertParamExists('concularApiV1RoutersOrganisationAccessRequestInviteUserOrAdminForOrg', 'organisationInviteeIN', organisationInviteeIN)
            const localVarPath = `/api/v1/concular/organisation_requests/{organisation_id}/invite`
                .replace(`{${"organisation_id"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organisationInviteeIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Organisation admin can accept request of user to join the organisation.
         * @summary Request Accept
         * @param {OrganisationRequestAcceptIN} organisationRequestAcceptIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationAccessRequestRequestAccept: async (organisationRequestAcceptIN: OrganisationRequestAcceptIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationRequestAcceptIN' is not null or undefined
            assertParamExists('concularApiV1RoutersOrganisationAccessRequestRequestAccept', 'organisationRequestAcceptIN', organisationRequestAcceptIN)
            const localVarPath = `/api/v1/concular/organisation_requests/accept`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organisationRequestAcceptIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * User can request to join organisation. But organisation must have to configure domain. If organisation domain and user\'s email domain match then it will display organisations that user can request to join. Organisation admins will be notified by email.
         * @summary Request To Join Organisation
         * @param {string} organisationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationAccessRequestRequestToJoinOrganisation: async (organisationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('concularApiV1RoutersOrganisationAccessRequestRequestToJoinOrganisation', 'organisationId', organisationId)
            const localVarPath = `/api/v1/concular/organisation_requests/{organisation_id}/join`
                .replace(`{${"organisation_id"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganisationAccessRequestsApi - functional programming interface
 * @export
 */
export const OrganisationAccessRequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganisationAccessRequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get latest status of user organisation request.
         * @summary Check Org Access Request Status
         * @param {string} organisationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersOrganisationAccessRequestCheckOrgAccessRequestStatus(organisationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationAccessRequestOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersOrganisationAccessRequestCheckOrgAccessRequestStatus(organisationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Organisation admin can decline the user request to join the organisation.
         * @summary Decline User Request For Organisation
         * @param {string} orgAccessReqId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersOrganisationAccessRequestDeclineUserRequestForOrganisation(orgAccessReqId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersOrganisationAccessRequestDeclineUserRequestForOrganisation(orgAccessReqId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Organisation admin can get all the pending invites and request for an organisation.
         * @summary Get All Requests
         * @param {string} organisationId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersOrganisationAccessRequestGetAllRequests(organisationId: string, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedOrganisationRequestAllOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersOrganisationAccessRequestGetAllRequests(organisationId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Organisation admin can invite new users (as an admin or a member) to join the organisation. User will be notified by email.
         * @summary Invite User Or Admin For Org
         * @param {string} organisationId
         * @param {OrganisationInviteeIN} organisationInviteeIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersOrganisationAccessRequestInviteUserOrAdminForOrg(organisationId: string, organisationInviteeIN: OrganisationInviteeIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationInviteeOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersOrganisationAccessRequestInviteUserOrAdminForOrg(organisationId, organisationInviteeIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Organisation admin can accept request of user to join the organisation.
         * @summary Request Accept
         * @param {OrganisationRequestAcceptIN} organisationRequestAcceptIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersOrganisationAccessRequestRequestAccept(organisationRequestAcceptIN: OrganisationRequestAcceptIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserOrganisationOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersOrganisationAccessRequestRequestAccept(organisationRequestAcceptIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * User can request to join organisation. But organisation must have to configure domain. If organisation domain and user\'s email domain match then it will display organisations that user can request to join. Organisation admins will be notified by email.
         * @summary Request To Join Organisation
         * @param {string} organisationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersOrganisationAccessRequestRequestToJoinOrganisation(organisationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationAccessRequestOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersOrganisationAccessRequestRequestToJoinOrganisation(organisationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganisationAccessRequestsApi - factory interface
 * @export
 */
export const OrganisationAccessRequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganisationAccessRequestsApiFp(configuration)
    return {
        /**
         * Get latest status of user organisation request.
         * @summary Check Org Access Request Status
         * @param {string} organisationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationAccessRequestCheckOrgAccessRequestStatus(organisationId: string, options?: any): AxiosPromise<OrganisationAccessRequestOUT> {
            return localVarFp.concularApiV1RoutersOrganisationAccessRequestCheckOrgAccessRequestStatus(organisationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Organisation admin can decline the user request to join the organisation.
         * @summary Decline User Request For Organisation
         * @param {string} orgAccessReqId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationAccessRequestDeclineUserRequestForOrganisation(orgAccessReqId: string, options?: any): AxiosPromise<ResultOUT> {
            return localVarFp.concularApiV1RoutersOrganisationAccessRequestDeclineUserRequestForOrganisation(orgAccessReqId, options).then((request) => request(axios, basePath));
        },
        /**
         * Organisation admin can get all the pending invites and request for an organisation.
         * @summary Get All Requests
         * @param {string} organisationId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationAccessRequestGetAllRequests(organisationId: string, limit?: number, offset?: number, options?: any): AxiosPromise<PagedOrganisationRequestAllOUT> {
            return localVarFp.concularApiV1RoutersOrganisationAccessRequestGetAllRequests(organisationId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Organisation admin can invite new users (as an admin or a member) to join the organisation. User will be notified by email.
         * @summary Invite User Or Admin For Org
         * @param {string} organisationId
         * @param {OrganisationInviteeIN} organisationInviteeIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationAccessRequestInviteUserOrAdminForOrg(organisationId: string, organisationInviteeIN: OrganisationInviteeIN, options?: any): AxiosPromise<OrganisationInviteeOUT> {
            return localVarFp.concularApiV1RoutersOrganisationAccessRequestInviteUserOrAdminForOrg(organisationId, organisationInviteeIN, options).then((request) => request(axios, basePath));
        },
        /**
         * Organisation admin can accept request of user to join the organisation.
         * @summary Request Accept
         * @param {OrganisationRequestAcceptIN} organisationRequestAcceptIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationAccessRequestRequestAccept(organisationRequestAcceptIN: OrganisationRequestAcceptIN, options?: any): AxiosPromise<UserOrganisationOUT> {
            return localVarFp.concularApiV1RoutersOrganisationAccessRequestRequestAccept(organisationRequestAcceptIN, options).then((request) => request(axios, basePath));
        },
        /**
         * User can request to join organisation. But organisation must have to configure domain. If organisation domain and user\'s email domain match then it will display organisations that user can request to join. Organisation admins will be notified by email.
         * @summary Request To Join Organisation
         * @param {string} organisationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationAccessRequestRequestToJoinOrganisation(organisationId: string, options?: any): AxiosPromise<OrganisationAccessRequestOUT> {
            return localVarFp.concularApiV1RoutersOrganisationAccessRequestRequestToJoinOrganisation(organisationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganisationAccessRequestsApi - object-oriented interface
 * @export
 * @class OrganisationAccessRequestsApi
 * @extends {BaseAPI}
 */
export class OrganisationAccessRequestsApi extends BaseAPI {
    /**
     * Get latest status of user organisation request.
     * @summary Check Org Access Request Status
     * @param {string} organisationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationAccessRequestsApi
     */
    public concularApiV1RoutersOrganisationAccessRequestCheckOrgAccessRequestStatus(organisationId: string, options?: AxiosRequestConfig) {
        return OrganisationAccessRequestsApiFp(this.configuration).concularApiV1RoutersOrganisationAccessRequestCheckOrgAccessRequestStatus(organisationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Organisation admin can decline the user request to join the organisation.
     * @summary Decline User Request For Organisation
     * @param {string} orgAccessReqId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationAccessRequestsApi
     */
    public concularApiV1RoutersOrganisationAccessRequestDeclineUserRequestForOrganisation(orgAccessReqId: string, options?: AxiosRequestConfig) {
        return OrganisationAccessRequestsApiFp(this.configuration).concularApiV1RoutersOrganisationAccessRequestDeclineUserRequestForOrganisation(orgAccessReqId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Organisation admin can get all the pending invites and request for an organisation.
     * @summary Get All Requests
     * @param {string} organisationId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationAccessRequestsApi
     */
    public concularApiV1RoutersOrganisationAccessRequestGetAllRequests(organisationId: string, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return OrganisationAccessRequestsApiFp(this.configuration).concularApiV1RoutersOrganisationAccessRequestGetAllRequests(organisationId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Organisation admin can invite new users (as an admin or a member) to join the organisation. User will be notified by email.
     * @summary Invite User Or Admin For Org
     * @param {string} organisationId
     * @param {OrganisationInviteeIN} organisationInviteeIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationAccessRequestsApi
     */
    public concularApiV1RoutersOrganisationAccessRequestInviteUserOrAdminForOrg(organisationId: string, organisationInviteeIN: OrganisationInviteeIN, options?: AxiosRequestConfig) {
        return OrganisationAccessRequestsApiFp(this.configuration).concularApiV1RoutersOrganisationAccessRequestInviteUserOrAdminForOrg(organisationId, organisationInviteeIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Organisation admin can accept request of user to join the organisation.
     * @summary Request Accept
     * @param {OrganisationRequestAcceptIN} organisationRequestAcceptIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationAccessRequestsApi
     */
    public concularApiV1RoutersOrganisationAccessRequestRequestAccept(organisationRequestAcceptIN: OrganisationRequestAcceptIN, options?: AxiosRequestConfig) {
        return OrganisationAccessRequestsApiFp(this.configuration).concularApiV1RoutersOrganisationAccessRequestRequestAccept(organisationRequestAcceptIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * User can request to join organisation. But organisation must have to configure domain. If organisation domain and user\'s email domain match then it will display organisations that user can request to join. Organisation admins will be notified by email.
     * @summary Request To Join Organisation
     * @param {string} organisationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationAccessRequestsApi
     */
    public concularApiV1RoutersOrganisationAccessRequestRequestToJoinOrganisation(organisationId: string, options?: AxiosRequestConfig) {
        return OrganisationAccessRequestsApiFp(this.configuration).concularApiV1RoutersOrganisationAccessRequestRequestToJoinOrganisation(organisationId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrganisationDomainsApi - axios parameter creator
 * @export
 */
export const OrganisationDomainsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create and attach domain with organisation. Domains can be used to suggest organisation to the user based on their email domain.
         * @summary Create Org Domain
         * @param {OrganisationDomainIN} organisationDomainIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationDomainCreateOrgDomain: async (organisationDomainIN: OrganisationDomainIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationDomainIN' is not null or undefined
            assertParamExists('concularApiV1RoutersOrganisationDomainCreateOrgDomain', 'organisationDomainIN', organisationDomainIN)
            const localVarPath = `/api/v1/concular/org_domains/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organisationDomainIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove domain from the organisation.
         * @summary Delete Org Domain
         * @param {string} orgDomainId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationDomainDeleteOrgDomain: async (orgDomainId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgDomainId' is not null or undefined
            assertParamExists('concularApiV1RoutersOrganisationDomainDeleteOrgDomain', 'orgDomainId', orgDomainId)
            const localVarPath = `/api/v1/concular/org_domains/{org_domain_id}`
                .replace(`{${"org_domain_id"}}`, encodeURIComponent(String(orgDomainId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all the domains that are associated with organisation.
         * @summary Get All Org Domain
         * @param {string} organisationId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationDomainGetAllOrgDomain: async (organisationId: string, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('concularApiV1RoutersOrganisationDomainGetAllOrgDomain', 'organisationId', organisationId)
            const localVarPath = `/api/v1/concular/org_domains/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organisationId !== undefined) {
                localVarQueryParameter['organisation_id'] = organisationId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the organisation domain.
         * @summary Update Org Domain
         * @param {string} orgDomainId
         * @param {OrganisationDomainIN} organisationDomainIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationDomainUpdateOrgDomain: async (orgDomainId: string, organisationDomainIN: OrganisationDomainIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgDomainId' is not null or undefined
            assertParamExists('concularApiV1RoutersOrganisationDomainUpdateOrgDomain', 'orgDomainId', orgDomainId)
            // verify required parameter 'organisationDomainIN' is not null or undefined
            assertParamExists('concularApiV1RoutersOrganisationDomainUpdateOrgDomain', 'organisationDomainIN', organisationDomainIN)
            const localVarPath = `/api/v1/concular/org_domains/{org_domain_id}`
                .replace(`{${"org_domain_id"}}`, encodeURIComponent(String(orgDomainId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organisationDomainIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganisationDomainsApi - functional programming interface
 * @export
 */
export const OrganisationDomainsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganisationDomainsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create and attach domain with organisation. Domains can be used to suggest organisation to the user based on their email domain.
         * @summary Create Org Domain
         * @param {OrganisationDomainIN} organisationDomainIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersOrganisationDomainCreateOrgDomain(organisationDomainIN: OrganisationDomainIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationDomainOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersOrganisationDomainCreateOrgDomain(organisationDomainIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Remove domain from the organisation.
         * @summary Delete Org Domain
         * @param {string} orgDomainId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersOrganisationDomainDeleteOrgDomain(orgDomainId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersOrganisationDomainDeleteOrgDomain(orgDomainId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all the domains that are associated with organisation.
         * @summary Get All Org Domain
         * @param {string} organisationId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersOrganisationDomainGetAllOrgDomain(organisationId: string, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedOrganisationDomainOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersOrganisationDomainGetAllOrgDomain(organisationId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the organisation domain.
         * @summary Update Org Domain
         * @param {string} orgDomainId
         * @param {OrganisationDomainIN} organisationDomainIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersOrganisationDomainUpdateOrgDomain(orgDomainId: string, organisationDomainIN: OrganisationDomainIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationDomainOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersOrganisationDomainUpdateOrgDomain(orgDomainId, organisationDomainIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganisationDomainsApi - factory interface
 * @export
 */
export const OrganisationDomainsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganisationDomainsApiFp(configuration)
    return {
        /**
         * Create and attach domain with organisation. Domains can be used to suggest organisation to the user based on their email domain.
         * @summary Create Org Domain
         * @param {OrganisationDomainIN} organisationDomainIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationDomainCreateOrgDomain(organisationDomainIN: OrganisationDomainIN, options?: any): AxiosPromise<OrganisationDomainOUT> {
            return localVarFp.concularApiV1RoutersOrganisationDomainCreateOrgDomain(organisationDomainIN, options).then((request) => request(axios, basePath));
        },
        /**
         * Remove domain from the organisation.
         * @summary Delete Org Domain
         * @param {string} orgDomainId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationDomainDeleteOrgDomain(orgDomainId: string, options?: any): AxiosPromise<ResultOUT> {
            return localVarFp.concularApiV1RoutersOrganisationDomainDeleteOrgDomain(orgDomainId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all the domains that are associated with organisation.
         * @summary Get All Org Domain
         * @param {string} organisationId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationDomainGetAllOrgDomain(organisationId: string, limit?: number, offset?: number, options?: any): AxiosPromise<PagedOrganisationDomainOUT> {
            return localVarFp.concularApiV1RoutersOrganisationDomainGetAllOrgDomain(organisationId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the organisation domain.
         * @summary Update Org Domain
         * @param {string} orgDomainId
         * @param {OrganisationDomainIN} organisationDomainIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationDomainUpdateOrgDomain(orgDomainId: string, organisationDomainIN: OrganisationDomainIN, options?: any): AxiosPromise<OrganisationDomainOUT> {
            return localVarFp.concularApiV1RoutersOrganisationDomainUpdateOrgDomain(orgDomainId, organisationDomainIN, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganisationDomainsApi - object-oriented interface
 * @export
 * @class OrganisationDomainsApi
 * @extends {BaseAPI}
 */
export class OrganisationDomainsApi extends BaseAPI {
    /**
     * Create and attach domain with organisation. Domains can be used to suggest organisation to the user based on their email domain.
     * @summary Create Org Domain
     * @param {OrganisationDomainIN} organisationDomainIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationDomainsApi
     */
    public concularApiV1RoutersOrganisationDomainCreateOrgDomain(organisationDomainIN: OrganisationDomainIN, options?: AxiosRequestConfig) {
        return OrganisationDomainsApiFp(this.configuration).concularApiV1RoutersOrganisationDomainCreateOrgDomain(organisationDomainIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Remove domain from the organisation.
     * @summary Delete Org Domain
     * @param {string} orgDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationDomainsApi
     */
    public concularApiV1RoutersOrganisationDomainDeleteOrgDomain(orgDomainId: string, options?: AxiosRequestConfig) {
        return OrganisationDomainsApiFp(this.configuration).concularApiV1RoutersOrganisationDomainDeleteOrgDomain(orgDomainId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all the domains that are associated with organisation.
     * @summary Get All Org Domain
     * @param {string} organisationId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationDomainsApi
     */
    public concularApiV1RoutersOrganisationDomainGetAllOrgDomain(organisationId: string, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return OrganisationDomainsApiFp(this.configuration).concularApiV1RoutersOrganisationDomainGetAllOrgDomain(organisationId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the organisation domain.
     * @summary Update Org Domain
     * @param {string} orgDomainId
     * @param {OrganisationDomainIN} organisationDomainIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationDomainsApi
     */
    public concularApiV1RoutersOrganisationDomainUpdateOrgDomain(orgDomainId: string, organisationDomainIN: OrganisationDomainIN, options?: AxiosRequestConfig) {
        return OrganisationDomainsApiFp(this.configuration).concularApiV1RoutersOrganisationDomainUpdateOrgDomain(orgDomainId, organisationDomainIN, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrganisationTypesApi - axios parameter creator
 * @export
 */
export const OrganisationTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all organisation types
         * @summary Get Organisation Types
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationsTypeGetOrganisationTypes: async (limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/concular/organisation_types/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganisationTypesApi - functional programming interface
 * @export
 */
export const OrganisationTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganisationTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * Get all organisation types
         * @summary Get Organisation Types
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersOrganisationsTypeGetOrganisationTypes(limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedOrganisationTypeOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersOrganisationsTypeGetOrganisationTypes(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganisationTypesApi - factory interface
 * @export
 */
export const OrganisationTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganisationTypesApiFp(configuration)
    return {
        /**
         * Get all organisation types
         * @summary Get Organisation Types
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationsTypeGetOrganisationTypes(limit?: number, offset?: number, options?: any): AxiosPromise<PagedOrganisationTypeOUT> {
            return localVarFp.concularApiV1RoutersOrganisationsTypeGetOrganisationTypes(limit, offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganisationTypesApi - object-oriented interface
 * @export
 * @class OrganisationTypesApi
 * @extends {BaseAPI}
 */
export class OrganisationTypesApi extends BaseAPI {
    /**
     * Get all organisation types
     * @summary Get Organisation Types
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationTypesApi
     */
    public concularApiV1RoutersOrganisationsTypeGetOrganisationTypes(limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return OrganisationTypesApiFp(this.configuration).concularApiV1RoutersOrganisationsTypeGetOrganisationTypes(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrganisationsApi - axios parameter creator
 * @export
 */
export const OrganisationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create an organisation. Creator will be an organisation admin.
         * @summary Create Organisation
         * @param {OrganisationIN} organisationIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationsCreateOrganisation: async (organisationIN: OrganisationIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationIN' is not null or undefined
            assertParamExists('concularApiV1RoutersOrganisationsCreateOrganisation', 'organisationIN', organisationIN)
            const localVarPath = `/api/v1/concular/organisations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organisationIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an organisation.
         * @summary Delete Organisation
         * @param {string} organisationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationsDeleteOrganisation: async (organisationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('concularApiV1RoutersOrganisationsDeleteOrganisation', 'organisationId', organisationId)
            const localVarPath = `/api/v1/concular/organisations/{organisation_id}`
                .replace(`{${"organisation_id"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API will return all the organisations of the user.
         * @summary Get All Organisations
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationsGetAllOrganisations: async (limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/concular/organisations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication AuthAPIKey required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Available user roles for an organisation.
         * @summary Get All Roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationsGetAllRoles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/concular/organisations/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * It will return a list of the organisations that related to a user\'s email domain. Organisation should have domain configured.
         * @summary Get User Domain Related Organisations
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationsGetUserDomainRelatedOrganisations: async (limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/concular/organisations/related/domain`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an organisation. You can only pass the fields that you want to update.
         * @summary Update Organisation
         * @param {string} organisationId
         * @param {OrganisationUpdateIN} organisationUpdateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationsUpdateOrganisation: async (organisationId: string, organisationUpdateIN: OrganisationUpdateIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('concularApiV1RoutersOrganisationsUpdateOrganisation', 'organisationId', organisationId)
            // verify required parameter 'organisationUpdateIN' is not null or undefined
            assertParamExists('concularApiV1RoutersOrganisationsUpdateOrganisation', 'organisationUpdateIN', organisationUpdateIN)
            const localVarPath = `/api/v1/concular/organisations/{organisation_id}`
                .replace(`{${"organisation_id"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organisationUpdateIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload logo for the organisation.
         * @summary Upload Organisation Logo
         * @param {string} organisationId
         * @param {File} logo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationsUploadOrganisationLogo: async (organisationId: string, logo: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('concularApiV1RoutersOrganisationsUploadOrganisationLogo', 'organisationId', organisationId)
            // verify required parameter 'logo' is not null or undefined
            assertParamExists('concularApiV1RoutersOrganisationsUploadOrganisationLogo', 'logo', logo)
            const localVarPath = `/api/v1/concular/organisations/{organisation_id}/logo`
                .replace(`{${"organisation_id"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (logo !== undefined) {
                localVarFormParams.append('logo', logo as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganisationsApi - functional programming interface
 * @export
 */
export const OrganisationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganisationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create an organisation. Creator will be an organisation admin.
         * @summary Create Organisation
         * @param {OrganisationIN} organisationIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersOrganisationsCreateOrganisation(organisationIN: OrganisationIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersOrganisationsCreateOrganisation(organisationIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an organisation.
         * @summary Delete Organisation
         * @param {string} organisationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersOrganisationsDeleteOrganisation(organisationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersOrganisationsDeleteOrganisation(organisationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API will return all the organisations of the user.
         * @summary Get All Organisations
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersOrganisationsGetAllOrganisations(limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedOrganisationOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersOrganisationsGetAllOrganisations(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Available user roles for an organisation.
         * @summary Get All Roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersOrganisationsGetAllRoles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserRoleOUT>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersOrganisationsGetAllRoles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * It will return a list of the organisations that related to a user\'s email domain. Organisation should have domain configured.
         * @summary Get User Domain Related Organisations
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersOrganisationsGetUserDomainRelatedOrganisations(limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedOrganisationOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersOrganisationsGetUserDomainRelatedOrganisations(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an organisation. You can only pass the fields that you want to update.
         * @summary Update Organisation
         * @param {string} organisationId
         * @param {OrganisationUpdateIN} organisationUpdateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersOrganisationsUpdateOrganisation(organisationId: string, organisationUpdateIN: OrganisationUpdateIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersOrganisationsUpdateOrganisation(organisationId, organisationUpdateIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upload logo for the organisation.
         * @summary Upload Organisation Logo
         * @param {string} organisationId
         * @param {File} logo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersOrganisationsUploadOrganisationLogo(organisationId: string, logo: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersOrganisationsUploadOrganisationLogo(organisationId, logo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganisationsApi - factory interface
 * @export
 */
export const OrganisationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganisationsApiFp(configuration)
    return {
        /**
         * Create an organisation. Creator will be an organisation admin.
         * @summary Create Organisation
         * @param {OrganisationIN} organisationIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationsCreateOrganisation(organisationIN: OrganisationIN, options?: any): AxiosPromise<OrganisationOUT> {
            return localVarFp.concularApiV1RoutersOrganisationsCreateOrganisation(organisationIN, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an organisation.
         * @summary Delete Organisation
         * @param {string} organisationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationsDeleteOrganisation(organisationId: string, options?: any): AxiosPromise<ResultOUT> {
            return localVarFp.concularApiV1RoutersOrganisationsDeleteOrganisation(organisationId, options).then((request) => request(axios, basePath));
        },
        /**
         * This API will return all the organisations of the user.
         * @summary Get All Organisations
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationsGetAllOrganisations(limit?: number, offset?: number, options?: any): AxiosPromise<PagedOrganisationOUT> {
            return localVarFp.concularApiV1RoutersOrganisationsGetAllOrganisations(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Available user roles for an organisation.
         * @summary Get All Roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationsGetAllRoles(options?: any): AxiosPromise<Array<UserRoleOUT>> {
            return localVarFp.concularApiV1RoutersOrganisationsGetAllRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * It will return a list of the organisations that related to a user\'s email domain. Organisation should have domain configured.
         * @summary Get User Domain Related Organisations
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationsGetUserDomainRelatedOrganisations(limit?: number, offset?: number, options?: any): AxiosPromise<PagedOrganisationOUT> {
            return localVarFp.concularApiV1RoutersOrganisationsGetUserDomainRelatedOrganisations(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an organisation. You can only pass the fields that you want to update.
         * @summary Update Organisation
         * @param {string} organisationId
         * @param {OrganisationUpdateIN} organisationUpdateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationsUpdateOrganisation(organisationId: string, organisationUpdateIN: OrganisationUpdateIN, options?: any): AxiosPromise<OrganisationOUT> {
            return localVarFp.concularApiV1RoutersOrganisationsUpdateOrganisation(organisationId, organisationUpdateIN, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload logo for the organisation.
         * @summary Upload Organisation Logo
         * @param {string} organisationId
         * @param {File} logo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersOrganisationsUploadOrganisationLogo(organisationId: string, logo: File, options?: any): AxiosPromise<OrganisationOUT> {
            return localVarFp.concularApiV1RoutersOrganisationsUploadOrganisationLogo(organisationId, logo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganisationsApi - object-oriented interface
 * @export
 * @class OrganisationsApi
 * @extends {BaseAPI}
 */
export class OrganisationsApi extends BaseAPI {
    /**
     * Create an organisation. Creator will be an organisation admin.
     * @summary Create Organisation
     * @param {OrganisationIN} organisationIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationsApi
     */
    public concularApiV1RoutersOrganisationsCreateOrganisation(organisationIN: OrganisationIN, options?: AxiosRequestConfig) {
        return OrganisationsApiFp(this.configuration).concularApiV1RoutersOrganisationsCreateOrganisation(organisationIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an organisation.
     * @summary Delete Organisation
     * @param {string} organisationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationsApi
     */
    public concularApiV1RoutersOrganisationsDeleteOrganisation(organisationId: string, options?: AxiosRequestConfig) {
        return OrganisationsApiFp(this.configuration).concularApiV1RoutersOrganisationsDeleteOrganisation(organisationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API will return all the organisations of the user.
     * @summary Get All Organisations
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationsApi
     */
    public concularApiV1RoutersOrganisationsGetAllOrganisations(limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return OrganisationsApiFp(this.configuration).concularApiV1RoutersOrganisationsGetAllOrganisations(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Available user roles for an organisation.
     * @summary Get All Roles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationsApi
     */
    public concularApiV1RoutersOrganisationsGetAllRoles(options?: AxiosRequestConfig) {
        return OrganisationsApiFp(this.configuration).concularApiV1RoutersOrganisationsGetAllRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * It will return a list of the organisations that related to a user\'s email domain. Organisation should have domain configured.
     * @summary Get User Domain Related Organisations
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationsApi
     */
    public concularApiV1RoutersOrganisationsGetUserDomainRelatedOrganisations(limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return OrganisationsApiFp(this.configuration).concularApiV1RoutersOrganisationsGetUserDomainRelatedOrganisations(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an organisation. You can only pass the fields that you want to update.
     * @summary Update Organisation
     * @param {string} organisationId
     * @param {OrganisationUpdateIN} organisationUpdateIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationsApi
     */
    public concularApiV1RoutersOrganisationsUpdateOrganisation(organisationId: string, organisationUpdateIN: OrganisationUpdateIN, options?: AxiosRequestConfig) {
        return OrganisationsApiFp(this.configuration).concularApiV1RoutersOrganisationsUpdateOrganisation(organisationId, organisationUpdateIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload logo for the organisation.
     * @summary Upload Organisation Logo
     * @param {string} organisationId
     * @param {File} logo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationsApi
     */
    public concularApiV1RoutersOrganisationsUploadOrganisationLogo(organisationId: string, logo: File, options?: AxiosRequestConfig) {
        return OrganisationsApiFp(this.configuration).concularApiV1RoutersOrganisationsUploadOrganisationLogo(organisationId, logo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PowerBIApi - axios parameter creator
 * @export
 */
export const PowerBIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Get Embed Params
         * @param {string} reportId
         * @param {string} [additionalDatasetId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportApiV1RoutersPowerbiGetEmbedParams: async (reportId: string, additionalDatasetId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportApiV1RoutersPowerbiGetEmbedParams', 'reportId', reportId)
            const localVarPath = `/api/v1/report/powerbi/embed-params/{report_id}`
                .replace(`{${"report_id"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (additionalDatasetId !== undefined) {
                localVarQueryParameter['additional_dataset_id'] = additionalDatasetId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PowerBIApi - functional programming interface
 * @export
 */
export const PowerBIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PowerBIApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Get Embed Params
         * @param {string} reportId
         * @param {string} [additionalDatasetId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportApiV1RoutersPowerbiGetEmbedParams(reportId: string, additionalDatasetId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmbedConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportApiV1RoutersPowerbiGetEmbedParams(reportId, additionalDatasetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PowerBIApi - factory interface
 * @export
 */
export const PowerBIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PowerBIApiFp(configuration)
    return {
        /**
         *
         * @summary Get Embed Params
         * @param {string} reportId
         * @param {string} [additionalDatasetId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportApiV1RoutersPowerbiGetEmbedParams(reportId: string, additionalDatasetId?: string, options?: any): AxiosPromise<EmbedConfig> {
            return localVarFp.reportApiV1RoutersPowerbiGetEmbedParams(reportId, additionalDatasetId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PowerBIApi - object-oriented interface
 * @export
 * @class PowerBIApi
 * @extends {BaseAPI}
 */
export class PowerBIApi extends BaseAPI {
    /**
     *
     * @summary Get Embed Params
     * @param {string} reportId
     * @param {string} [additionalDatasetId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerBIApi
     */
    public reportApiV1RoutersPowerbiGetEmbedParams(reportId: string, additionalDatasetId?: string, options?: AxiosRequestConfig) {
        return PowerBIApiFp(this.configuration).reportApiV1RoutersPowerbiGetEmbedParams(reportId, additionalDatasetId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProductEditorApi - axios parameter creator
 * @export
 */
export const ProductEditorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This endpoint checks the combination of the compliance of multiple epd groups.
         * @summary Check Epd Groups
         * @param {EPDGroupsCheckIN} ePDGroupsCheckIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersProductEditorCheckEpdGroups: async (ePDGroupsCheckIN: EPDGroupsCheckIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ePDGroupsCheckIN' is not null or undefined
            assertParamExists('inventoryApiV1RoutersProductEditorCheckEpdGroups', 'ePDGroupsCheckIN', ePDGroupsCheckIN)
            const localVarPath = `/api/v1/inventory/product_editor/check_epd_groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ePDGroupsCheckIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint checks if the given epd specifications have the same dataset_source and conformity. Also, it returns a list of certifications that are compliant for this epd group.
         * @summary Check Epd Specifications
         * @param {EPDSpecificationCheckIN} ePDSpecificationCheckIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersProductEditorCheckEpdSpecifications: async (ePDSpecificationCheckIN: EPDSpecificationCheckIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ePDSpecificationCheckIN' is not null or undefined
            assertParamExists('inventoryApiV1RoutersProductEditorCheckEpdSpecifications', 'ePDSpecificationCheckIN', ePDSpecificationCheckIN)
            const localVarPath = `/api/v1/inventory/product_editor/check_epd_specifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ePDSpecificationCheckIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete Custom Building Product
         * @param {string} productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersProductEditorDeleteCustomBuildingProduct: async (productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('inventoryApiV1RoutersProductEditorDeleteCustomBuildingProduct', 'productId', productId)
            const localVarPath = `/api/v1/inventory/product_editor/{product_id}`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Edit Custom Building Product
         * @param {string} productId
         * @param {CustomBuildingProductEditIN} customBuildingProductEditIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersProductEditorEditCustomBuildingProduct: async (productId: string, customBuildingProductEditIN: CustomBuildingProductEditIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('inventoryApiV1RoutersProductEditorEditCustomBuildingProduct', 'productId', productId)
            // verify required parameter 'customBuildingProductEditIN' is not null or undefined
            assertParamExists('inventoryApiV1RoutersProductEditorEditCustomBuildingProduct', 'customBuildingProductEditIN', customBuildingProductEditIN)
            const localVarPath = `/api/v1/inventory/product_editor/{product_id}`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customBuildingProductEditIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get Categories
         * @param {string | null} [categorySystem]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersProductEditorGetCategories: async (categorySystem?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/inventory/product_editor/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (categorySystem !== undefined) {
                localVarQueryParameter['category_system'] = categorySystem;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get Cpx Values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersProductEditorGetCpxValues: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/inventory/product_editor/cpx_values`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get Epd Dataset Sources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersProductEditorGetEpdDatasetSources: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/inventory/product_editor/epd_dataset_sources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get Epd Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersProductEditorGetEpdTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/inventory/product_editor/epd_types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint registers a custom building product to the database.
         * @summary Register Custom Building Product
         * @param {CustomBuildingProductIN} customBuildingProductIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersProductEditorRegisterCustomBuildingProduct: async (customBuildingProductIN: CustomBuildingProductIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customBuildingProductIN' is not null or undefined
            assertParamExists('inventoryApiV1RoutersProductEditorRegisterCustomBuildingProduct', 'customBuildingProductIN', customBuildingProductIN)
            const localVarPath = `/api/v1/inventory/product_editor/building_products/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customBuildingProductIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Search Epds
         * @param {EPDSearchIN} ePDSearchIN
         * @param {SortOrder | null} [sortOrder]
         * @param {EPDSearchSortBy | null} [sortBy]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersProductEditorSearchEpds: async (ePDSearchIN: EPDSearchIN, sortOrder?: SortOrder | null, sortBy?: EPDSearchSortBy | null, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ePDSearchIN' is not null or undefined
            assertParamExists('inventoryApiV1RoutersProductEditorSearchEpds', 'ePDSearchIN', ePDSearchIN)
            const localVarPath = `/api/v1/inventory/product_editor/search/epds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort_order'] = sortOrder;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ePDSearchIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Search Materials
         * @param {MaterialSearchIN} materialSearchIN
         * @param {SortOrder | null} [sortOrder]
         * @param {MaterialSearchSortBy | null} [sortBy]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersProductEditorSearchMaterials: async (materialSearchIN: MaterialSearchIN, sortOrder?: SortOrder | null, sortBy?: MaterialSearchSortBy | null, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'materialSearchIN' is not null or undefined
            assertParamExists('inventoryApiV1RoutersProductEditorSearchMaterials', 'materialSearchIN', materialSearchIN)
            const localVarPath = `/api/v1/inventory/product_editor/search/materials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort_order'] = sortOrder;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(materialSearchIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductEditorApi - functional programming interface
 * @export
 */
export const ProductEditorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductEditorApiAxiosParamCreator(configuration)
    return {
        /**
         * This endpoint checks the combination of the compliance of multiple epd groups.
         * @summary Check Epd Groups
         * @param {EPDGroupsCheckIN} ePDGroupsCheckIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersProductEditorCheckEpdGroups(ePDGroupsCheckIN: EPDGroupsCheckIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EPDGroupsCheckOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersProductEditorCheckEpdGroups(ePDGroupsCheckIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint checks if the given epd specifications have the same dataset_source and conformity. Also, it returns a list of certifications that are compliant for this epd group.
         * @summary Check Epd Specifications
         * @param {EPDSpecificationCheckIN} ePDSpecificationCheckIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersProductEditorCheckEpdSpecifications(ePDSpecificationCheckIN: EPDSpecificationCheckIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EpdSpecificationCheckOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersProductEditorCheckEpdSpecifications(ePDSpecificationCheckIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Delete Custom Building Product
         * @param {string} productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersProductEditorDeleteCustomBuildingProduct(productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersProductEditorDeleteCustomBuildingProduct(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Edit Custom Building Product
         * @param {string} productId
         * @param {CustomBuildingProductEditIN} customBuildingProductEditIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersProductEditorEditCustomBuildingProduct(productId: string, customBuildingProductEditIN: CustomBuildingProductEditIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomBuildingProductEditOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersProductEditorEditCustomBuildingProduct(productId, customBuildingProductEditIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get Categories
         * @param {string | null} [categorySystem]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersProductEditorGetCategories(categorySystem?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoryOUT>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersProductEditorGetCategories(categorySystem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get Cpx Values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersProductEditorGetCpxValues(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CPXValueOUT>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersProductEditorGetCpxValues(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get Epd Dataset Sources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersProductEditorGetEpdDatasetSources(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersProductEditorGetEpdDatasetSources(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get Epd Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersProductEditorGetEpdTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersProductEditorGetEpdTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint registers a custom building product to the database.
         * @summary Register Custom Building Product
         * @param {CustomBuildingProductIN} customBuildingProductIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersProductEditorRegisterCustomBuildingProduct(customBuildingProductIN: CustomBuildingProductIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomBuildingProductOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersProductEditorRegisterCustomBuildingProduct(customBuildingProductIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Search Epds
         * @param {EPDSearchIN} ePDSearchIN
         * @param {SortOrder | null} [sortOrder]
         * @param {EPDSearchSortBy | null} [sortBy]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersProductEditorSearchEpds(ePDSearchIN: EPDSearchIN, sortOrder?: SortOrder | null, sortBy?: EPDSearchSortBy | null, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedEPDSearchOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersProductEditorSearchEpds(ePDSearchIN, sortOrder, sortBy, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Search Materials
         * @param {MaterialSearchIN} materialSearchIN
         * @param {SortOrder | null} [sortOrder]
         * @param {MaterialSearchSortBy | null} [sortBy]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersProductEditorSearchMaterials(materialSearchIN: MaterialSearchIN, sortOrder?: SortOrder | null, sortBy?: MaterialSearchSortBy | null, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedMaterialSearchOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersProductEditorSearchMaterials(materialSearchIN, sortOrder, sortBy, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductEditorApi - factory interface
 * @export
 */
export const ProductEditorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductEditorApiFp(configuration)
    return {
        /**
         * This endpoint checks the combination of the compliance of multiple epd groups.
         * @summary Check Epd Groups
         * @param {EPDGroupsCheckIN} ePDGroupsCheckIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersProductEditorCheckEpdGroups(ePDGroupsCheckIN: EPDGroupsCheckIN, options?: any): AxiosPromise<EPDGroupsCheckOUT> {
            return localVarFp.inventoryApiV1RoutersProductEditorCheckEpdGroups(ePDGroupsCheckIN, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint checks if the given epd specifications have the same dataset_source and conformity. Also, it returns a list of certifications that are compliant for this epd group.
         * @summary Check Epd Specifications
         * @param {EPDSpecificationCheckIN} ePDSpecificationCheckIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersProductEditorCheckEpdSpecifications(ePDSpecificationCheckIN: EPDSpecificationCheckIN, options?: any): AxiosPromise<EpdSpecificationCheckOUT> {
            return localVarFp.inventoryApiV1RoutersProductEditorCheckEpdSpecifications(ePDSpecificationCheckIN, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete Custom Building Product
         * @param {string} productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersProductEditorDeleteCustomBuildingProduct(productId: string, options?: any): AxiosPromise<ResultOUT> {
            return localVarFp.inventoryApiV1RoutersProductEditorDeleteCustomBuildingProduct(productId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit Custom Building Product
         * @param {string} productId
         * @param {CustomBuildingProductEditIN} customBuildingProductEditIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersProductEditorEditCustomBuildingProduct(productId: string, customBuildingProductEditIN: CustomBuildingProductEditIN, options?: any): AxiosPromise<CustomBuildingProductEditOUT> {
            return localVarFp.inventoryApiV1RoutersProductEditorEditCustomBuildingProduct(productId, customBuildingProductEditIN, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get Categories
         * @param {string | null} [categorySystem]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersProductEditorGetCategories(categorySystem?: string | null, options?: any): AxiosPromise<Array<CategoryOUT>> {
            return localVarFp.inventoryApiV1RoutersProductEditorGetCategories(categorySystem, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get Cpx Values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersProductEditorGetCpxValues(options?: any): AxiosPromise<Array<CPXValueOUT>> {
            return localVarFp.inventoryApiV1RoutersProductEditorGetCpxValues(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get Epd Dataset Sources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersProductEditorGetEpdDatasetSources(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.inventoryApiV1RoutersProductEditorGetEpdDatasetSources(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get Epd Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersProductEditorGetEpdTypes(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.inventoryApiV1RoutersProductEditorGetEpdTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint registers a custom building product to the database.
         * @summary Register Custom Building Product
         * @param {CustomBuildingProductIN} customBuildingProductIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersProductEditorRegisterCustomBuildingProduct(customBuildingProductIN: CustomBuildingProductIN, options?: any): AxiosPromise<CustomBuildingProductOUT> {
            return localVarFp.inventoryApiV1RoutersProductEditorRegisterCustomBuildingProduct(customBuildingProductIN, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Search Epds
         * @param {EPDSearchIN} ePDSearchIN
         * @param {SortOrder | null} [sortOrder]
         * @param {EPDSearchSortBy | null} [sortBy]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersProductEditorSearchEpds(ePDSearchIN: EPDSearchIN, sortOrder?: SortOrder | null, sortBy?: EPDSearchSortBy | null, page?: number, options?: any): AxiosPromise<PagedEPDSearchOUT> {
            return localVarFp.inventoryApiV1RoutersProductEditorSearchEpds(ePDSearchIN, sortOrder, sortBy, page, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Search Materials
         * @param {MaterialSearchIN} materialSearchIN
         * @param {SortOrder | null} [sortOrder]
         * @param {MaterialSearchSortBy | null} [sortBy]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersProductEditorSearchMaterials(materialSearchIN: MaterialSearchIN, sortOrder?: SortOrder | null, sortBy?: MaterialSearchSortBy | null, page?: number, options?: any): AxiosPromise<PagedMaterialSearchOUT> {
            return localVarFp.inventoryApiV1RoutersProductEditorSearchMaterials(materialSearchIN, sortOrder, sortBy, page, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductEditorApi - object-oriented interface
 * @export
 * @class ProductEditorApi
 * @extends {BaseAPI}
 */
export class ProductEditorApi extends BaseAPI {
    /**
     * This endpoint checks the combination of the compliance of multiple epd groups.
     * @summary Check Epd Groups
     * @param {EPDGroupsCheckIN} ePDGroupsCheckIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductEditorApi
     */
    public inventoryApiV1RoutersProductEditorCheckEpdGroups(ePDGroupsCheckIN: EPDGroupsCheckIN, options?: AxiosRequestConfig) {
        return ProductEditorApiFp(this.configuration).inventoryApiV1RoutersProductEditorCheckEpdGroups(ePDGroupsCheckIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint checks if the given epd specifications have the same dataset_source and conformity. Also, it returns a list of certifications that are compliant for this epd group.
     * @summary Check Epd Specifications
     * @param {EPDSpecificationCheckIN} ePDSpecificationCheckIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductEditorApi
     */
    public inventoryApiV1RoutersProductEditorCheckEpdSpecifications(ePDSpecificationCheckIN: EPDSpecificationCheckIN, options?: AxiosRequestConfig) {
        return ProductEditorApiFp(this.configuration).inventoryApiV1RoutersProductEditorCheckEpdSpecifications(ePDSpecificationCheckIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Delete Custom Building Product
     * @param {string} productId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductEditorApi
     */
    public inventoryApiV1RoutersProductEditorDeleteCustomBuildingProduct(productId: string, options?: AxiosRequestConfig) {
        return ProductEditorApiFp(this.configuration).inventoryApiV1RoutersProductEditorDeleteCustomBuildingProduct(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Edit Custom Building Product
     * @param {string} productId
     * @param {CustomBuildingProductEditIN} customBuildingProductEditIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductEditorApi
     */
    public inventoryApiV1RoutersProductEditorEditCustomBuildingProduct(productId: string, customBuildingProductEditIN: CustomBuildingProductEditIN, options?: AxiosRequestConfig) {
        return ProductEditorApiFp(this.configuration).inventoryApiV1RoutersProductEditorEditCustomBuildingProduct(productId, customBuildingProductEditIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get Categories
     * @param {string | null} [categorySystem]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductEditorApi
     */
    public inventoryApiV1RoutersProductEditorGetCategories(categorySystem?: string | null, options?: AxiosRequestConfig) {
        return ProductEditorApiFp(this.configuration).inventoryApiV1RoutersProductEditorGetCategories(categorySystem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get Cpx Values
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductEditorApi
     */
    public inventoryApiV1RoutersProductEditorGetCpxValues(options?: AxiosRequestConfig) {
        return ProductEditorApiFp(this.configuration).inventoryApiV1RoutersProductEditorGetCpxValues(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get Epd Dataset Sources
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductEditorApi
     */
    public inventoryApiV1RoutersProductEditorGetEpdDatasetSources(options?: AxiosRequestConfig) {
        return ProductEditorApiFp(this.configuration).inventoryApiV1RoutersProductEditorGetEpdDatasetSources(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get Epd Types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductEditorApi
     */
    public inventoryApiV1RoutersProductEditorGetEpdTypes(options?: AxiosRequestConfig) {
        return ProductEditorApiFp(this.configuration).inventoryApiV1RoutersProductEditorGetEpdTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint registers a custom building product to the database.
     * @summary Register Custom Building Product
     * @param {CustomBuildingProductIN} customBuildingProductIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductEditorApi
     */
    public inventoryApiV1RoutersProductEditorRegisterCustomBuildingProduct(customBuildingProductIN: CustomBuildingProductIN, options?: AxiosRequestConfig) {
        return ProductEditorApiFp(this.configuration).inventoryApiV1RoutersProductEditorRegisterCustomBuildingProduct(customBuildingProductIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Search Epds
     * @param {EPDSearchIN} ePDSearchIN
     * @param {SortOrder | null} [sortOrder]
     * @param {EPDSearchSortBy | null} [sortBy]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductEditorApi
     */
    public inventoryApiV1RoutersProductEditorSearchEpds(ePDSearchIN: EPDSearchIN, sortOrder?: SortOrder | null, sortBy?: EPDSearchSortBy | null, page?: number, options?: AxiosRequestConfig) {
        return ProductEditorApiFp(this.configuration).inventoryApiV1RoutersProductEditorSearchEpds(ePDSearchIN, sortOrder, sortBy, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Search Materials
     * @param {MaterialSearchIN} materialSearchIN
     * @param {SortOrder | null} [sortOrder]
     * @param {MaterialSearchSortBy | null} [sortBy]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductEditorApi
     */
    public inventoryApiV1RoutersProductEditorSearchMaterials(materialSearchIN: MaterialSearchIN, sortOrder?: SortOrder | null, sortBy?: MaterialSearchSortBy | null, page?: number, options?: AxiosRequestConfig) {
        return ProductEditorApiFp(this.configuration).inventoryApiV1RoutersProductEditorSearchMaterials(materialSearchIN, sortOrder, sortBy, page, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReportBuildingsApi - axios parameter creator
 * @export
 */
export const ReportBuildingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Delete Building Exported Certification
         * @param {string} buildingId
         * @param {string} reportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportApiV1RoutersBuildingDeleteBuildingExportedCertification: async (buildingId: string, reportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('reportApiV1RoutersBuildingDeleteBuildingExportedCertification', 'buildingId', buildingId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportApiV1RoutersBuildingDeleteBuildingExportedCertification', 'reportId', reportId)
            const localVarPath = `/api/v1/report/buildings/{building_id}/reports/{report_id}`
                .replace(`{${"building_id"}}`, encodeURIComponent(String(buildingId)))
                .replace(`{${"report_id"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Embed Url
         * @param {string} retoolAppId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportApiV1RoutersBuildingEmbedUrl: async (retoolAppId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'retoolAppId' is not null or undefined
            assertParamExists('reportApiV1RoutersBuildingEmbedUrl', 'retoolAppId', retoolAppId)
            const localVarPath = `/api/v1/report/buildings/retool/embed_url/{retool_app_id}`
                .replace(`{${"retool_app_id"}}`, encodeURIComponent(String(retoolAppId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get Building Exported Certifications
         * @param {string} buildingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportApiV1RoutersBuildingGetBuildingExportedCertifications: async (buildingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('reportApiV1RoutersBuildingGetBuildingExportedCertifications', 'buildingId', buildingId)
            const localVarPath = `/api/v1/report/buildings/{building_id}/reports`
                .replace(`{${"building_id"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportBuildingsApi - functional programming interface
 * @export
 */
export const ReportBuildingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportBuildingsApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Delete Building Exported Certification
         * @param {string} buildingId
         * @param {string} reportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportApiV1RoutersBuildingDeleteBuildingExportedCertification(buildingId: string, reportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportApiV1RoutersBuildingDeleteBuildingExportedCertification(buildingId, reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Embed Url
         * @param {string} retoolAppId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportApiV1RoutersBuildingEmbedUrl(retoolAppId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RetoolEmbedOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportApiV1RoutersBuildingEmbedUrl(retoolAppId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get Building Exported Certifications
         * @param {string} buildingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportApiV1RoutersBuildingGetBuildingExportedCertifications(buildingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BuildingReportOUT>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportApiV1RoutersBuildingGetBuildingExportedCertifications(buildingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportBuildingsApi - factory interface
 * @export
 */
export const ReportBuildingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportBuildingsApiFp(configuration)
    return {
        /**
         *
         * @summary Delete Building Exported Certification
         * @param {string} buildingId
         * @param {string} reportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportApiV1RoutersBuildingDeleteBuildingExportedCertification(buildingId: string, reportId: string, options?: any): AxiosPromise<ResultOUT> {
            return localVarFp.reportApiV1RoutersBuildingDeleteBuildingExportedCertification(buildingId, reportId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Embed Url
         * @param {string} retoolAppId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportApiV1RoutersBuildingEmbedUrl(retoolAppId: string, options?: any): AxiosPromise<RetoolEmbedOUT> {
            return localVarFp.reportApiV1RoutersBuildingEmbedUrl(retoolAppId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get Building Exported Certifications
         * @param {string} buildingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportApiV1RoutersBuildingGetBuildingExportedCertifications(buildingId: string, options?: any): AxiosPromise<Array<BuildingReportOUT>> {
            return localVarFp.reportApiV1RoutersBuildingGetBuildingExportedCertifications(buildingId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportBuildingsApi - object-oriented interface
 * @export
 * @class ReportBuildingsApi
 * @extends {BaseAPI}
 */
export class ReportBuildingsApi extends BaseAPI {
    /**
     *
     * @summary Delete Building Exported Certification
     * @param {string} buildingId
     * @param {string} reportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportBuildingsApi
     */
    public reportApiV1RoutersBuildingDeleteBuildingExportedCertification(buildingId: string, reportId: string, options?: AxiosRequestConfig) {
        return ReportBuildingsApiFp(this.configuration).reportApiV1RoutersBuildingDeleteBuildingExportedCertification(buildingId, reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Embed Url
     * @param {string} retoolAppId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportBuildingsApi
     */
    public reportApiV1RoutersBuildingEmbedUrl(retoolAppId: string, options?: AxiosRequestConfig) {
        return ReportBuildingsApiFp(this.configuration).reportApiV1RoutersBuildingEmbedUrl(retoolAppId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get Building Exported Certifications
     * @param {string} buildingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportBuildingsApi
     */
    public reportApiV1RoutersBuildingGetBuildingExportedCertifications(buildingId: string, options?: AxiosRequestConfig) {
        return ReportBuildingsApiFp(this.configuration).reportApiV1RoutersBuildingGetBuildingExportedCertifications(buildingId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RepositoriesApi - axios parameter creator
 * @export
 */
export const RepositoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Get Audits By Building Id
         * @param {string} buildingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersRepositoryGetAuditsByBuildingId: async (buildingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('caApiV1RoutersRepositoryGetAuditsByBuildingId', 'buildingId', buildingId)
            const localVarPath = `/api/v1/ca/repositories/building/{building_id}/audits`
                .replace(`{${"building_id"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all fields
         * @summary Get Fields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersRepositoryGetFields: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/ca/repositories/fields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all active fieldsets
         * @summary Get Fieldsets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersRepositoryGetFieldsets: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/ca/repositories/fieldsets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all manufacturers.
         * @summary Get Manufacturers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersRepositoryGetManufacturers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/ca/repositories/manufacturers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all product groups
         * @summary Get Product Groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersRepositoryGetProductGroups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/ca/repositories/product_groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all product groups without field options
         * @summary Get Slim Product Groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersRepositoryGetSlimProductGroups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/ca/repositories/product_groups/slim`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all product groups without field options
         * @summary Get Slim Product Groups Id
         * @param {string} productGroupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersRepositoryGetSlimProductGroupsId: async (productGroupId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productGroupId' is not null or undefined
            assertParamExists('caApiV1RoutersRepositoryGetSlimProductGroupsId', 'productGroupId', productGroupId)
            const localVarPath = `/api/v1/ca/repositories/product_groups/slim/{product_group_id}`
                .replace(`{${"product_group_id"}}`, encodeURIComponent(String(productGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RepositoriesApi - functional programming interface
 * @export
 */
export const RepositoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RepositoriesApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Get Audits By Building Id
         * @param {string} buildingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersRepositoryGetAuditsByBuildingId(buildingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AuditOUT>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersRepositoryGetAuditsByBuildingId(buildingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all fields
         * @summary Get Fields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersRepositoryGetFields(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FieldOUT>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersRepositoryGetFields(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all active fieldsets
         * @summary Get Fieldsets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersRepositoryGetFieldsets(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SlimFieldSetOUT>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersRepositoryGetFieldsets(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all manufacturers.
         * @summary Get Manufacturers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersRepositoryGetManufacturers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ManufacturerOUT>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersRepositoryGetManufacturers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all product groups
         * @summary Get Product Groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersRepositoryGetProductGroups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductGroupOUT>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersRepositoryGetProductGroups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all product groups without field options
         * @summary Get Slim Product Groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersRepositoryGetSlimProductGroups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SlimProductGroupOUT>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersRepositoryGetSlimProductGroups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all product groups without field options
         * @summary Get Slim Product Groups Id
         * @param {string} productGroupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersRepositoryGetSlimProductGroupsId(productGroupId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SlimProductGroupOUT>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersRepositoryGetSlimProductGroupsId(productGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RepositoriesApi - factory interface
 * @export
 */
export const RepositoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RepositoriesApiFp(configuration)
    return {
        /**
         *
         * @summary Get Audits By Building Id
         * @param {string} buildingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersRepositoryGetAuditsByBuildingId(buildingId: string, options?: any): AxiosPromise<Array<AuditOUT>> {
            return localVarFp.caApiV1RoutersRepositoryGetAuditsByBuildingId(buildingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all fields
         * @summary Get Fields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersRepositoryGetFields(options?: any): AxiosPromise<Array<FieldOUT>> {
            return localVarFp.caApiV1RoutersRepositoryGetFields(options).then((request) => request(axios, basePath));
        },
        /**
         * Get all active fieldsets
         * @summary Get Fieldsets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersRepositoryGetFieldsets(options?: any): AxiosPromise<Array<SlimFieldSetOUT>> {
            return localVarFp.caApiV1RoutersRepositoryGetFieldsets(options).then((request) => request(axios, basePath));
        },
        /**
         * Get all manufacturers.
         * @summary Get Manufacturers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersRepositoryGetManufacturers(options?: any): AxiosPromise<Array<ManufacturerOUT>> {
            return localVarFp.caApiV1RoutersRepositoryGetManufacturers(options).then((request) => request(axios, basePath));
        },
        /**
         * Get all product groups
         * @summary Get Product Groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersRepositoryGetProductGroups(options?: any): AxiosPromise<Array<ProductGroupOUT>> {
            return localVarFp.caApiV1RoutersRepositoryGetProductGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * Get all product groups without field options
         * @summary Get Slim Product Groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersRepositoryGetSlimProductGroups(options?: any): AxiosPromise<Array<SlimProductGroupOUT>> {
            return localVarFp.caApiV1RoutersRepositoryGetSlimProductGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * Get all product groups without field options
         * @summary Get Slim Product Groups Id
         * @param {string} productGroupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersRepositoryGetSlimProductGroupsId(productGroupId: string, options?: any): AxiosPromise<Array<SlimProductGroupOUT>> {
            return localVarFp.caApiV1RoutersRepositoryGetSlimProductGroupsId(productGroupId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RepositoriesApi - object-oriented interface
 * @export
 * @class RepositoriesApi
 * @extends {BaseAPI}
 */
export class RepositoriesApi extends BaseAPI {
    /**
     *
     * @summary Get Audits By Building Id
     * @param {string} buildingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepositoriesApi
     */
    public caApiV1RoutersRepositoryGetAuditsByBuildingId(buildingId: string, options?: AxiosRequestConfig) {
        return RepositoriesApiFp(this.configuration).caApiV1RoutersRepositoryGetAuditsByBuildingId(buildingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all fields
     * @summary Get Fields
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepositoriesApi
     */
    public caApiV1RoutersRepositoryGetFields(options?: AxiosRequestConfig) {
        return RepositoriesApiFp(this.configuration).caApiV1RoutersRepositoryGetFields(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all active fieldsets
     * @summary Get Fieldsets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepositoriesApi
     */
    public caApiV1RoutersRepositoryGetFieldsets(options?: AxiosRequestConfig) {
        return RepositoriesApiFp(this.configuration).caApiV1RoutersRepositoryGetFieldsets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all manufacturers.
     * @summary Get Manufacturers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepositoriesApi
     */
    public caApiV1RoutersRepositoryGetManufacturers(options?: AxiosRequestConfig) {
        return RepositoriesApiFp(this.configuration).caApiV1RoutersRepositoryGetManufacturers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all product groups
     * @summary Get Product Groups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepositoriesApi
     */
    public caApiV1RoutersRepositoryGetProductGroups(options?: AxiosRequestConfig) {
        return RepositoriesApiFp(this.configuration).caApiV1RoutersRepositoryGetProductGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all product groups without field options
     * @summary Get Slim Product Groups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepositoriesApi
     */
    public caApiV1RoutersRepositoryGetSlimProductGroups(options?: AxiosRequestConfig) {
        return RepositoriesApiFp(this.configuration).caApiV1RoutersRepositoryGetSlimProductGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all product groups without field options
     * @summary Get Slim Product Groups Id
     * @param {string} productGroupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepositoriesApi
     */
    public caApiV1RoutersRepositoryGetSlimProductGroupsId(productGroupId: string, options?: AxiosRequestConfig) {
        return RepositoriesApiFp(this.configuration).caApiV1RoutersRepositoryGetSlimProductGroupsId(productGroupId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Materials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersSearchEngineMaterials: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/inventory/search_engine/search/materials/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Product Din Categories
         * @param {InventoryApiV1RoutersSearchEngineProductDinCategoriesLevelEnum} [level]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersSearchEngineProductDinCategories: async (level?: InventoryApiV1RoutersSearchEngineProductDinCategoriesLevelEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/inventory/search_engine/search/product-din-categories/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (level !== undefined) {
                localVarQueryParameter['level'] = level;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Search Products
         * @param {ProductSearchInput} productSearchInput
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersSearchEngineSearchProducts: async (productSearchInput: ProductSearchInput, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productSearchInput' is not null or undefined
            assertParamExists('inventoryApiV1RoutersSearchEngineSearchProducts', 'productSearchInput', productSearchInput)
            const localVarPath = `/api/v1/inventory/search_engine/search/products/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productSearchInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Materials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersSearchEngineMaterials(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SimpleMaterialOut>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersSearchEngineMaterials(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Product Din Categories
         * @param {InventoryApiV1RoutersSearchEngineProductDinCategoriesLevelEnum} [level]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersSearchEngineProductDinCategories(level?: InventoryApiV1RoutersSearchEngineProductDinCategoriesLevelEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchEngineCategoryOUT>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersSearchEngineProductDinCategories(level, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Search Products
         * @param {ProductSearchInput} productSearchInput
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersSearchEngineSearchProducts(productSearchInput: ProductSearchInput, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedProductSearchOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersSearchEngineSearchProducts(productSearchInput, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchApiFp(configuration)
    return {
        /**
         *
         * @summary Materials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersSearchEngineMaterials(options?: any): AxiosPromise<Array<SimpleMaterialOut>> {
            return localVarFp.inventoryApiV1RoutersSearchEngineMaterials(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Product Din Categories
         * @param {InventoryApiV1RoutersSearchEngineProductDinCategoriesLevelEnum} [level]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersSearchEngineProductDinCategories(level?: InventoryApiV1RoutersSearchEngineProductDinCategoriesLevelEnum, options?: any): AxiosPromise<Array<SearchEngineCategoryOUT>> {
            return localVarFp.inventoryApiV1RoutersSearchEngineProductDinCategories(level, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Search Products
         * @param {ProductSearchInput} productSearchInput
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersSearchEngineSearchProducts(productSearchInput: ProductSearchInput, page?: number, pageSize?: number, options?: any): AxiosPromise<PaginatedProductSearchOutput> {
            return localVarFp.inventoryApiV1RoutersSearchEngineSearchProducts(productSearchInput, page, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
    /**
     *
     * @summary Materials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public inventoryApiV1RoutersSearchEngineMaterials(options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).inventoryApiV1RoutersSearchEngineMaterials(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Product Din Categories
     * @param {InventoryApiV1RoutersSearchEngineProductDinCategoriesLevelEnum} [level]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public inventoryApiV1RoutersSearchEngineProductDinCategories(level?: InventoryApiV1RoutersSearchEngineProductDinCategoriesLevelEnum, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).inventoryApiV1RoutersSearchEngineProductDinCategories(level, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Search Products
     * @param {ProductSearchInput} productSearchInput
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public inventoryApiV1RoutersSearchEngineSearchProducts(productSearchInput: ProductSearchInput, page?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).inventoryApiV1RoutersSearchEngineSearchProducts(productSearchInput, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const InventoryApiV1RoutersSearchEngineProductDinCategoriesLevelEnum = {
    _1: 'level_1',
    _2: 'level_2',
    _3: 'level_3'
} as const;
export type InventoryApiV1RoutersSearchEngineProductDinCategoriesLevelEnum = typeof InventoryApiV1RoutersSearchEngineProductDinCategoriesLevelEnum[keyof typeof InventoryApiV1RoutersSearchEngineProductDinCategoriesLevelEnum];


/**
 * SummaryApi - axios parameter creator
 * @export
 */
export const SummaryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * CSV Exporter for summary table
         * @summary Export Object Calculation Csv
         * @param {string} fileType
         * @param {ColumnValueSchemaIN} columnValueSchemaIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersSummaryExportObjectCalculationCsv: async (fileType: string, columnValueSchemaIN: ColumnValueSchemaIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileType' is not null or undefined
            assertParamExists('inventoryApiV1RoutersSummaryExportObjectCalculationCsv', 'fileType', fileType)
            // verify required parameter 'columnValueSchemaIN' is not null or undefined
            assertParamExists('inventoryApiV1RoutersSummaryExportObjectCalculationCsv', 'columnValueSchemaIN', columnValueSchemaIN)
            const localVarPath = `/api/v1/inventory/summary/{file_type}/export`
                .replace(`{${"file_type"}}`, encodeURIComponent(String(fileType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(columnValueSchemaIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the available columns to display
         * @summary Get Available Columns
         * @param {ColumnConfiguration | null} [config]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersSummaryGetAvailableColumns: async (config?: ColumnConfiguration | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/inventory/summary/available_columns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (config !== undefined) {
                localVarQueryParameter['config'] = config;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the distinct values for the given columns
         * @summary Get Column Value Distinct
         * @param {ColumnValueSchemaIN} columnValueSchemaIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersSummaryGetColumnValueDistinct: async (columnValueSchemaIN: ColumnValueSchemaIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'columnValueSchemaIN' is not null or undefined
            assertParamExists('inventoryApiV1RoutersSummaryGetColumnValueDistinct', 'columnValueSchemaIN', columnValueSchemaIN)
            const localVarPath = `/api/v1/inventory/summary/distinct_values`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(columnValueSchemaIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the groups to display
         * @summary Get Friendly Name Groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersSummaryGetFriendlyNameGroups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/inventory/summary/friendly_name_groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the information inside the summary table.
         * @summary Get Info Table
         * @param {SummaryTableDataSchemaIn} summaryTableDataSchemaIn
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersSummaryGetInfoTable: async (summaryTableDataSchemaIn: SummaryTableDataSchemaIn, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'summaryTableDataSchemaIn' is not null or undefined
            assertParamExists('inventoryApiV1RoutersSummaryGetInfoTable', 'summaryTableDataSchemaIn', summaryTableDataSchemaIn)
            const localVarPath = `/api/v1/inventory/summary/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(summaryTableDataSchemaIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Refresh summary file
         * @summary Refresh Summary File
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersSummaryRefreshSummaryFile: async (fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('inventoryApiV1RoutersSummaryRefreshSummaryFile', 'fileId', fileId)
            const localVarPath = `/api/v1/inventory/summary/refresh/file/{file_id}`
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SummaryApi - functional programming interface
 * @export
 */
export const SummaryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SummaryApiAxiosParamCreator(configuration)
    return {
        /**
         * CSV Exporter for summary table
         * @summary Export Object Calculation Csv
         * @param {string} fileType
         * @param {ColumnValueSchemaIN} columnValueSchemaIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersSummaryExportObjectCalculationCsv(fileType: string, columnValueSchemaIN: ColumnValueSchemaIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersSummaryExportObjectCalculationCsv(fileType, columnValueSchemaIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the available columns to display
         * @summary Get Available Columns
         * @param {ColumnConfiguration | null} [config]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersSummaryGetAvailableColumns(config?: ColumnConfiguration | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AvailableColumnsOUT>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersSummaryGetAvailableColumns(config, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the distinct values for the given columns
         * @summary Get Column Value Distinct
         * @param {ColumnValueSchemaIN} columnValueSchemaIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersSummaryGetColumnValueDistinct(columnValueSchemaIN: ColumnValueSchemaIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersSummaryGetColumnValueDistinct(columnValueSchemaIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all the groups to display
         * @summary Get Friendly Name Groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersSummaryGetFriendlyNameGroups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FriendlyNameGroupOUT>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersSummaryGetFriendlyNameGroups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the information inside the summary table.
         * @summary Get Info Table
         * @param {SummaryTableDataSchemaIn} summaryTableDataSchemaIn
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersSummaryGetInfoTable(summaryTableDataSchemaIn: SummaryTableDataSchemaIn, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pageddict>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersSummaryGetInfoTable(summaryTableDataSchemaIn, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Refresh summary file
         * @summary Refresh Summary File
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryApiV1RoutersSummaryRefreshSummaryFile(fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryApiV1RoutersSummaryRefreshSummaryFile(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SummaryApi - factory interface
 * @export
 */
export const SummaryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SummaryApiFp(configuration)
    return {
        /**
         * CSV Exporter for summary table
         * @summary Export Object Calculation Csv
         * @param {string} fileType
         * @param {ColumnValueSchemaIN} columnValueSchemaIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersSummaryExportObjectCalculationCsv(fileType: string, columnValueSchemaIN: ColumnValueSchemaIN, options?: any): AxiosPromise<void> {
            return localVarFp.inventoryApiV1RoutersSummaryExportObjectCalculationCsv(fileType, columnValueSchemaIN, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the available columns to display
         * @summary Get Available Columns
         * @param {ColumnConfiguration | null} [config]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersSummaryGetAvailableColumns(config?: ColumnConfiguration | null, options?: any): AxiosPromise<Array<AvailableColumnsOUT>> {
            return localVarFp.inventoryApiV1RoutersSummaryGetAvailableColumns(config, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the distinct values for the given columns
         * @summary Get Column Value Distinct
         * @param {ColumnValueSchemaIN} columnValueSchemaIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersSummaryGetColumnValueDistinct(columnValueSchemaIN: ColumnValueSchemaIN, options?: any): AxiosPromise<object> {
            return localVarFp.inventoryApiV1RoutersSummaryGetColumnValueDistinct(columnValueSchemaIN, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the groups to display
         * @summary Get Friendly Name Groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersSummaryGetFriendlyNameGroups(options?: any): AxiosPromise<Array<FriendlyNameGroupOUT>> {
            return localVarFp.inventoryApiV1RoutersSummaryGetFriendlyNameGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the information inside the summary table.
         * @summary Get Info Table
         * @param {SummaryTableDataSchemaIn} summaryTableDataSchemaIn
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersSummaryGetInfoTable(summaryTableDataSchemaIn: SummaryTableDataSchemaIn, limit?: number, offset?: number, options?: any): AxiosPromise<Pageddict> {
            return localVarFp.inventoryApiV1RoutersSummaryGetInfoTable(summaryTableDataSchemaIn, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Refresh summary file
         * @summary Refresh Summary File
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryApiV1RoutersSummaryRefreshSummaryFile(fileId: string, options?: any): AxiosPromise<void> {
            return localVarFp.inventoryApiV1RoutersSummaryRefreshSummaryFile(fileId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SummaryApi - object-oriented interface
 * @export
 * @class SummaryApi
 * @extends {BaseAPI}
 */
export class SummaryApi extends BaseAPI {
    /**
     * CSV Exporter for summary table
     * @summary Export Object Calculation Csv
     * @param {string} fileType
     * @param {ColumnValueSchemaIN} columnValueSchemaIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SummaryApi
     */
    public inventoryApiV1RoutersSummaryExportObjectCalculationCsv(fileType: string, columnValueSchemaIN: ColumnValueSchemaIN, options?: AxiosRequestConfig) {
        return SummaryApiFp(this.configuration).inventoryApiV1RoutersSummaryExportObjectCalculationCsv(fileType, columnValueSchemaIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the available columns to display
     * @summary Get Available Columns
     * @param {ColumnConfiguration | null} [config]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SummaryApi
     */
    public inventoryApiV1RoutersSummaryGetAvailableColumns(config?: ColumnConfiguration | null, options?: AxiosRequestConfig) {
        return SummaryApiFp(this.configuration).inventoryApiV1RoutersSummaryGetAvailableColumns(config, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the distinct values for the given columns
     * @summary Get Column Value Distinct
     * @param {ColumnValueSchemaIN} columnValueSchemaIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SummaryApi
     */
    public inventoryApiV1RoutersSummaryGetColumnValueDistinct(columnValueSchemaIN: ColumnValueSchemaIN, options?: AxiosRequestConfig) {
        return SummaryApiFp(this.configuration).inventoryApiV1RoutersSummaryGetColumnValueDistinct(columnValueSchemaIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the groups to display
     * @summary Get Friendly Name Groups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SummaryApi
     */
    public inventoryApiV1RoutersSummaryGetFriendlyNameGroups(options?: AxiosRequestConfig) {
        return SummaryApiFp(this.configuration).inventoryApiV1RoutersSummaryGetFriendlyNameGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the information inside the summary table.
     * @summary Get Info Table
     * @param {SummaryTableDataSchemaIn} summaryTableDataSchemaIn
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SummaryApi
     */
    public inventoryApiV1RoutersSummaryGetInfoTable(summaryTableDataSchemaIn: SummaryTableDataSchemaIn, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return SummaryApiFp(this.configuration).inventoryApiV1RoutersSummaryGetInfoTable(summaryTableDataSchemaIn, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Refresh summary file
     * @summary Refresh Summary File
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SummaryApi
     */
    public inventoryApiV1RoutersSummaryRefreshSummaryFile(fileId: string, options?: AxiosRequestConfig) {
        return SummaryApiFp(this.configuration).inventoryApiV1RoutersSummaryRefreshSummaryFile(fileId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get user profile related information.
         * @summary Get User Profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersUserGetUserProfile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/concular/user/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get user sso token for userback. So that user do not need to add credentials on userback
         * @summary Get User Sso Token For Userback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersUserGetUserSsoTokenForUserback: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/concular/user/userback/sso-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Resend verification email to user
         * @summary Resend Verification Email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersUserResendVerificationEmail: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/concular/user/verification-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearerUnverifiedEmail required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update user profile information like, first name, last name and term of service.
         * @summary Update User Profile
         * @param {UserProfileIN} userProfileIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersUserUpdateUserProfile: async (userProfileIN: UserProfileIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfileIN' is not null or undefined
            assertParamExists('concularApiV1RoutersUserUpdateUserProfile', 'userProfileIN', userProfileIN)
            const localVarPath = `/api/v1/concular/user/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfileIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * Get user profile related information.
         * @summary Get User Profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersUserGetUserProfile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersUserGetUserProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get user sso token for userback. So that user do not need to add credentials on userback
         * @summary Get User Sso Token For Userback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersUserGetUserSsoTokenForUserback(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserBackTokenOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersUserGetUserSsoTokenForUserback(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Resend verification email to user
         * @summary Resend Verification Email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersUserResendVerificationEmail(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersUserResendVerificationEmail(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update user profile information like, first name, last name and term of service.
         * @summary Update User Profile
         * @param {UserProfileIN} userProfileIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersUserUpdateUserProfile(userProfileIN: UserProfileIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersUserUpdateUserProfile(userProfileIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * Get user profile related information.
         * @summary Get User Profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersUserGetUserProfile(options?: any): AxiosPromise<UserProfileOUT> {
            return localVarFp.concularApiV1RoutersUserGetUserProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * Get user sso token for userback. So that user do not need to add credentials on userback
         * @summary Get User Sso Token For Userback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersUserGetUserSsoTokenForUserback(options?: any): AxiosPromise<UserBackTokenOUT> {
            return localVarFp.concularApiV1RoutersUserGetUserSsoTokenForUserback(options).then((request) => request(axios, basePath));
        },
        /**
         * Resend verification email to user
         * @summary Resend Verification Email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersUserResendVerificationEmail(options?: any): AxiosPromise<void> {
            return localVarFp.concularApiV1RoutersUserResendVerificationEmail(options).then((request) => request(axios, basePath));
        },
        /**
         * Update user profile information like, first name, last name and term of service.
         * @summary Update User Profile
         * @param {UserProfileIN} userProfileIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersUserUpdateUserProfile(userProfileIN: UserProfileIN, options?: any): AxiosPromise<UserProfileOUT> {
            return localVarFp.concularApiV1RoutersUserUpdateUserProfile(userProfileIN, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * Get user profile related information.
     * @summary Get User Profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public concularApiV1RoutersUserGetUserProfile(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).concularApiV1RoutersUserGetUserProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get user sso token for userback. So that user do not need to add credentials on userback
     * @summary Get User Sso Token For Userback
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public concularApiV1RoutersUserGetUserSsoTokenForUserback(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).concularApiV1RoutersUserGetUserSsoTokenForUserback(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Resend verification email to user
     * @summary Resend Verification Email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public concularApiV1RoutersUserResendVerificationEmail(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).concularApiV1RoutersUserResendVerificationEmail(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update user profile information like, first name, last name and term of service.
     * @summary Update User Profile
     * @param {UserProfileIN} userProfileIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public concularApiV1RoutersUserUpdateUserProfile(userProfileIN: UserProfileIN, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).concularApiV1RoutersUserUpdateUserProfile(userProfileIN, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserAuditsApi - axios parameter creator
 * @export
 */
export const UserAuditsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns all the available roles for the UserAudit model.
         * @summary Get All Roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersUserAuditGetAllRoles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/ca/user_audits/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the members of a specific audit.
         * @summary Get Audit Members
         * @param {string} auditId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersUserAuditGetAuditMembers: async (auditId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('caApiV1RoutersUserAuditGetAuditMembers', 'auditId', auditId)
            const localVarPath = `/api/v1/ca/user_audits/members`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (auditId !== undefined) {
                localVarQueryParameter['audit_id'] = auditId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the audits that the user is a member of for online usage.
         * @summary List User Audits For Online Usage
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersUserAuditListUserAuditsForOnlineUsage: async (limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/ca/user_audits/online`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes a user from a specific audit. This endpoint can be used by both an audit manager and the member itself.
         * @summary Remove Member From Audit
         * @param {string} auditId
         * @param {number} memberId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersUserAuditRemoveMemberFromAudit: async (auditId: string, memberId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('caApiV1RoutersUserAuditRemoveMemberFromAudit', 'auditId', auditId)
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('caApiV1RoutersUserAuditRemoveMemberFromAudit', 'memberId', memberId)
            const localVarPath = `/api/v1/ca/user_audits/members`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (auditId !== undefined) {
                localVarQueryParameter['audit_id'] = auditId;
            }

            if (memberId !== undefined) {
                localVarQueryParameter['member_id'] = memberId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a user audit instance. Written for updating the role of a user in an audit.
         * @summary Update User Audit
         * @param {string} auditId
         * @param {number} memberId
         * @param {UserAuditUpdateIN} userAuditUpdateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersUserAuditUpdateUserAudit: async (auditId: string, memberId: number, userAuditUpdateIN: UserAuditUpdateIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('caApiV1RoutersUserAuditUpdateUserAudit', 'auditId', auditId)
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('caApiV1RoutersUserAuditUpdateUserAudit', 'memberId', memberId)
            // verify required parameter 'userAuditUpdateIN' is not null or undefined
            assertParamExists('caApiV1RoutersUserAuditUpdateUserAudit', 'userAuditUpdateIN', userAuditUpdateIN)
            const localVarPath = `/api/v1/ca/user_audits/audits/{audit_id}/members/{member_id}`
                .replace(`{${"audit_id"}}`, encodeURIComponent(String(auditId)))
                .replace(`{${"member_id"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAuditUpdateIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserAuditsApi - functional programming interface
 * @export
 */
export const UserAuditsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserAuditsApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns all the available roles for the UserAudit model.
         * @summary Get All Roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersUserAuditGetAllRoles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserAuditRoleOUT>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersUserAuditGetAllRoles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all the members of a specific audit.
         * @summary Get Audit Members
         * @param {string} auditId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersUserAuditGetAuditMembers(auditId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AuditMemberOUT>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersUserAuditGetAuditMembers(auditId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all the audits that the user is a member of for online usage.
         * @summary List User Audits For Online Usage
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersUserAuditListUserAuditsForOnlineUsage(limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedSlimUserAuditOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersUserAuditListUserAuditsForOnlineUsage(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes a user from a specific audit. This endpoint can be used by both an audit manager and the member itself.
         * @summary Remove Member From Audit
         * @param {string} auditId
         * @param {number} memberId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersUserAuditRemoveMemberFromAudit(auditId: string, memberId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersUserAuditRemoveMemberFromAudit(auditId, memberId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a user audit instance. Written for updating the role of a user in an audit.
         * @summary Update User Audit
         * @param {string} auditId
         * @param {number} memberId
         * @param {UserAuditUpdateIN} userAuditUpdateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caApiV1RoutersUserAuditUpdateUserAudit(auditId: string, memberId: number, userAuditUpdateIN: UserAuditUpdateIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuditMemberOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caApiV1RoutersUserAuditUpdateUserAudit(auditId, memberId, userAuditUpdateIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserAuditsApi - factory interface
 * @export
 */
export const UserAuditsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserAuditsApiFp(configuration)
    return {
        /**
         * Returns all the available roles for the UserAudit model.
         * @summary Get All Roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersUserAuditGetAllRoles(options?: any): AxiosPromise<Array<UserAuditRoleOUT>> {
            return localVarFp.caApiV1RoutersUserAuditGetAllRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the members of a specific audit.
         * @summary Get Audit Members
         * @param {string} auditId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersUserAuditGetAuditMembers(auditId: string, options?: any): AxiosPromise<Array<AuditMemberOUT>> {
            return localVarFp.caApiV1RoutersUserAuditGetAuditMembers(auditId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the audits that the user is a member of for online usage.
         * @summary List User Audits For Online Usage
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersUserAuditListUserAuditsForOnlineUsage(limit?: number, offset?: number, options?: any): AxiosPromise<PagedSlimUserAuditOUT> {
            return localVarFp.caApiV1RoutersUserAuditListUserAuditsForOnlineUsage(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes a user from a specific audit. This endpoint can be used by both an audit manager and the member itself.
         * @summary Remove Member From Audit
         * @param {string} auditId
         * @param {number} memberId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersUserAuditRemoveMemberFromAudit(auditId: string, memberId: number, options?: any): AxiosPromise<ResultOUT> {
            return localVarFp.caApiV1RoutersUserAuditRemoveMemberFromAudit(auditId, memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a user audit instance. Written for updating the role of a user in an audit.
         * @summary Update User Audit
         * @param {string} auditId
         * @param {number} memberId
         * @param {UserAuditUpdateIN} userAuditUpdateIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caApiV1RoutersUserAuditUpdateUserAudit(auditId: string, memberId: number, userAuditUpdateIN: UserAuditUpdateIN, options?: any): AxiosPromise<AuditMemberOUT> {
            return localVarFp.caApiV1RoutersUserAuditUpdateUserAudit(auditId, memberId, userAuditUpdateIN, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserAuditsApi - object-oriented interface
 * @export
 * @class UserAuditsApi
 * @extends {BaseAPI}
 */
export class UserAuditsApi extends BaseAPI {
    /**
     * Returns all the available roles for the UserAudit model.
     * @summary Get All Roles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAuditsApi
     */
    public caApiV1RoutersUserAuditGetAllRoles(options?: AxiosRequestConfig) {
        return UserAuditsApiFp(this.configuration).caApiV1RoutersUserAuditGetAllRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the members of a specific audit.
     * @summary Get Audit Members
     * @param {string} auditId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAuditsApi
     */
    public caApiV1RoutersUserAuditGetAuditMembers(auditId: string, options?: AxiosRequestConfig) {
        return UserAuditsApiFp(this.configuration).caApiV1RoutersUserAuditGetAuditMembers(auditId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the audits that the user is a member of for online usage.
     * @summary List User Audits For Online Usage
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAuditsApi
     */
    public caApiV1RoutersUserAuditListUserAuditsForOnlineUsage(limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return UserAuditsApiFp(this.configuration).caApiV1RoutersUserAuditListUserAuditsForOnlineUsage(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes a user from a specific audit. This endpoint can be used by both an audit manager and the member itself.
     * @summary Remove Member From Audit
     * @param {string} auditId
     * @param {number} memberId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAuditsApi
     */
    public caApiV1RoutersUserAuditRemoveMemberFromAudit(auditId: string, memberId: number, options?: AxiosRequestConfig) {
        return UserAuditsApiFp(this.configuration).caApiV1RoutersUserAuditRemoveMemberFromAudit(auditId, memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a user audit instance. Written for updating the role of a user in an audit.
     * @summary Update User Audit
     * @param {string} auditId
     * @param {number} memberId
     * @param {UserAuditUpdateIN} userAuditUpdateIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAuditsApi
     */
    public caApiV1RoutersUserAuditUpdateUserAudit(auditId: string, memberId: number, userAuditUpdateIN: UserAuditUpdateIN, options?: AxiosRequestConfig) {
        return UserAuditsApiFp(this.configuration).caApiV1RoutersUserAuditUpdateUserAudit(auditId, memberId, userAuditUpdateIN, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserBuildingsApi - axios parameter creator
 * @export
 */
export const UserBuildingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all the roles for the user building. For example [{1: \"Guest\"}]
         * @summary Get All Roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersUserBuildingsGetAllRoles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/concular/user_buildings/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * As a building member, you can see list of all the users in the building.
         * @summary Get Building Users
         * @param {string} buildingId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersUserBuildingsGetBuildingUsers: async (buildingId: string, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('concularApiV1RoutersUserBuildingsGetBuildingUsers', 'buildingId', buildingId)
            const localVarPath = `/api/v1/concular/user_buildings/{building_id}/members`
                .replace(`{${"building_id"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all the buildings of the user.
         * @summary Get User Buildings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersUserBuildingsGetUserBuildings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/concular/user_buildings/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * User can leave the building.
         * @summary Leave Building
         * @param {string} userBuildingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersUserBuildingsLeaveBuilding: async (userBuildingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userBuildingId' is not null or undefined
            assertParamExists('concularApiV1RoutersUserBuildingsLeaveBuilding', 'userBuildingId', userBuildingId)
            const localVarPath = `/api/v1/concular/user_buildings/{user_building_id}`
                .replace(`{${"user_building_id"}}`, encodeURIComponent(String(userBuildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserBuildingsApi - functional programming interface
 * @export
 */
export const UserBuildingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserBuildingsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get all the roles for the user building. For example [{1: \"Guest\"}]
         * @summary Get All Roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersUserBuildingsGetAllRoles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserRoleOUT>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersUserBuildingsGetAllRoles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * As a building member, you can see list of all the users in the building.
         * @summary Get Building Users
         * @param {string} buildingId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersUserBuildingsGetBuildingUsers(buildingId: string, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedBuildingMemberOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersUserBuildingsGetBuildingUsers(buildingId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all the buildings of the user.
         * @summary Get User Buildings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersUserBuildingsGetUserBuildings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserBuildingOUT>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersUserBuildingsGetUserBuildings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * User can leave the building.
         * @summary Leave Building
         * @param {string} userBuildingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersUserBuildingsLeaveBuilding(userBuildingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersUserBuildingsLeaveBuilding(userBuildingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserBuildingsApi - factory interface
 * @export
 */
export const UserBuildingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserBuildingsApiFp(configuration)
    return {
        /**
         * Get all the roles for the user building. For example [{1: \"Guest\"}]
         * @summary Get All Roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersUserBuildingsGetAllRoles(options?: any): AxiosPromise<Array<UserRoleOUT>> {
            return localVarFp.concularApiV1RoutersUserBuildingsGetAllRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * As a building member, you can see list of all the users in the building.
         * @summary Get Building Users
         * @param {string} buildingId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersUserBuildingsGetBuildingUsers(buildingId: string, limit?: number, offset?: number, options?: any): AxiosPromise<PagedBuildingMemberOUT> {
            return localVarFp.concularApiV1RoutersUserBuildingsGetBuildingUsers(buildingId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all the buildings of the user.
         * @summary Get User Buildings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersUserBuildingsGetUserBuildings(options?: any): AxiosPromise<Array<UserBuildingOUT>> {
            return localVarFp.concularApiV1RoutersUserBuildingsGetUserBuildings(options).then((request) => request(axios, basePath));
        },
        /**
         * User can leave the building.
         * @summary Leave Building
         * @param {string} userBuildingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersUserBuildingsLeaveBuilding(userBuildingId: string, options?: any): AxiosPromise<ResultOUT> {
            return localVarFp.concularApiV1RoutersUserBuildingsLeaveBuilding(userBuildingId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserBuildingsApi - object-oriented interface
 * @export
 * @class UserBuildingsApi
 * @extends {BaseAPI}
 */
export class UserBuildingsApi extends BaseAPI {
    /**
     * Get all the roles for the user building. For example [{1: \"Guest\"}]
     * @summary Get All Roles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserBuildingsApi
     */
    public concularApiV1RoutersUserBuildingsGetAllRoles(options?: AxiosRequestConfig) {
        return UserBuildingsApiFp(this.configuration).concularApiV1RoutersUserBuildingsGetAllRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * As a building member, you can see list of all the users in the building.
     * @summary Get Building Users
     * @param {string} buildingId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserBuildingsApi
     */
    public concularApiV1RoutersUserBuildingsGetBuildingUsers(buildingId: string, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return UserBuildingsApiFp(this.configuration).concularApiV1RoutersUserBuildingsGetBuildingUsers(buildingId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all the buildings of the user.
     * @summary Get User Buildings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserBuildingsApi
     */
    public concularApiV1RoutersUserBuildingsGetUserBuildings(options?: AxiosRequestConfig) {
        return UserBuildingsApiFp(this.configuration).concularApiV1RoutersUserBuildingsGetUserBuildings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * User can leave the building.
     * @summary Leave Building
     * @param {string} userBuildingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserBuildingsApi
     */
    public concularApiV1RoutersUserBuildingsLeaveBuilding(userBuildingId: string, options?: AxiosRequestConfig) {
        return UserBuildingsApiFp(this.configuration).concularApiV1RoutersUserBuildingsLeaveBuilding(userBuildingId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserOrganisationsApi - axios parameter creator
 * @export
 */
export const UserOrganisationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Admin can change role of existing user for an organisation.
         * @summary Change User Role
         * @param {string} organisationId
         * @param {UserOrganisationRoleIN} userOrganisationRoleIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersUserOrganisationsChangeUserRole: async (organisationId: string, userOrganisationRoleIN: UserOrganisationRoleIN, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('concularApiV1RoutersUserOrganisationsChangeUserRole', 'organisationId', organisationId)
            // verify required parameter 'userOrganisationRoleIN' is not null or undefined
            assertParamExists('concularApiV1RoutersUserOrganisationsChangeUserRole', 'userOrganisationRoleIN', userOrganisationRoleIN)
            const localVarPath = `/api/v1/concular/user_organisation/{organisation_id}`
                .replace(`{${"organisation_id"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userOrganisationRoleIN, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get number of admins withing the organisation. Organisation require at least on admin. If there is a only admin then it can\'t change the role to user.
         * @summary Get Num Of Admins For Organisation
         * @param {string} organisationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersUserOrganisationsGetNumOfAdminsForOrganisation: async (organisationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('concularApiV1RoutersUserOrganisationsGetNumOfAdminsForOrganisation', 'organisationId', organisationId)
            const localVarPath = `/api/v1/concular/user_organisation/{organisation_id}/counts/admin`
                .replace(`{${"organisation_id"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * As an organisation admin, you can see list of all the users (admin/member) in the organisation.
         * @summary Get Organisation Users
         * @param {string} organisationId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersUserOrganisationsGetOrganisationUsers: async (organisationId: string, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('concularApiV1RoutersUserOrganisationsGetOrganisationUsers', 'organisationId', organisationId)
            const localVarPath = `/api/v1/concular/user_organisation/{organisation_id}/members`
                .replace(`{${"organisation_id"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all the details of an organisation.
         * @summary Get User Organisation
         * @param {string} organisationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersUserOrganisationsGetUserOrganisation: async (organisationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('concularApiV1RoutersUserOrganisationsGetUserOrganisation', 'organisationId', organisationId)
            const localVarPath = `/api/v1/concular/user_organisation/{organisation_id}/user`
                .replace(`{${"organisation_id"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member can leave the organisation.
         * @summary Leave Organisation
         * @param {string} organisationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersUserOrganisationsLeaveOrganisation: async (organisationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('concularApiV1RoutersUserOrganisationsLeaveOrganisation', 'organisationId', organisationId)
            const localVarPath = `/api/v1/concular/user_organisation/{organisation_id}/`
                .replace(`{${"organisation_id"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * As an org admin I want to remove user from organisation.
         * @summary Remove User From Organisation
         * @param {string} organisationId
         * @param {string} username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersUserOrganisationsRemoveUserFromOrganisation: async (organisationId: string, username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('concularApiV1RoutersUserOrganisationsRemoveUserFromOrganisation', 'organisationId', organisationId)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('concularApiV1RoutersUserOrganisationsRemoveUserFromOrganisation', 'username', username)
            const localVarPath = `/api/v1/concular/user_organisation/{organisation_id}/{username}`
                .replace(`{${"organisation_id"}}`, encodeURIComponent(String(organisationId)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserOrganisationsApi - functional programming interface
 * @export
 */
export const UserOrganisationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserOrganisationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Admin can change role of existing user for an organisation.
         * @summary Change User Role
         * @param {string} organisationId
         * @param {UserOrganisationRoleIN} userOrganisationRoleIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersUserOrganisationsChangeUserRole(organisationId: string, userOrganisationRoleIN: UserOrganisationRoleIN, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserOrganisationMemberOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersUserOrganisationsChangeUserRole(organisationId, userOrganisationRoleIN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get number of admins withing the organisation. Organisation require at least on admin. If there is a only admin then it can\'t change the role to user.
         * @summary Get Num Of Admins For Organisation
         * @param {string} organisationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersUserOrganisationsGetNumOfAdminsForOrganisation(organisationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersUserOrganisationsGetNumOfAdminsForOrganisation(organisationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * As an organisation admin, you can see list of all the users (admin/member) in the organisation.
         * @summary Get Organisation Users
         * @param {string} organisationId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersUserOrganisationsGetOrganisationUsers(organisationId: string, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedUserOrganisationMemberOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersUserOrganisationsGetOrganisationUsers(organisationId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all the details of an organisation.
         * @summary Get User Organisation
         * @param {string} organisationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersUserOrganisationsGetUserOrganisation(organisationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserOrganisationOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersUserOrganisationsGetUserOrganisation(organisationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member can leave the organisation.
         * @summary Leave Organisation
         * @param {string} organisationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersUserOrganisationsLeaveOrganisation(organisationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersUserOrganisationsLeaveOrganisation(organisationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * As an org admin I want to remove user from organisation.
         * @summary Remove User From Organisation
         * @param {string} organisationId
         * @param {string} username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concularApiV1RoutersUserOrganisationsRemoveUserFromOrganisation(organisationId: string, username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOUT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concularApiV1RoutersUserOrganisationsRemoveUserFromOrganisation(organisationId, username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserOrganisationsApi - factory interface
 * @export
 */
export const UserOrganisationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserOrganisationsApiFp(configuration)
    return {
        /**
         * Admin can change role of existing user for an organisation.
         * @summary Change User Role
         * @param {string} organisationId
         * @param {UserOrganisationRoleIN} userOrganisationRoleIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersUserOrganisationsChangeUserRole(organisationId: string, userOrganisationRoleIN: UserOrganisationRoleIN, options?: any): AxiosPromise<UserOrganisationMemberOUT> {
            return localVarFp.concularApiV1RoutersUserOrganisationsChangeUserRole(organisationId, userOrganisationRoleIN, options).then((request) => request(axios, basePath));
        },
        /**
         * Get number of admins withing the organisation. Organisation require at least on admin. If there is a only admin then it can\'t change the role to user.
         * @summary Get Num Of Admins For Organisation
         * @param {string} organisationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersUserOrganisationsGetNumOfAdminsForOrganisation(organisationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.concularApiV1RoutersUserOrganisationsGetNumOfAdminsForOrganisation(organisationId, options).then((request) => request(axios, basePath));
        },
        /**
         * As an organisation admin, you can see list of all the users (admin/member) in the organisation.
         * @summary Get Organisation Users
         * @param {string} organisationId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersUserOrganisationsGetOrganisationUsers(organisationId: string, limit?: number, offset?: number, options?: any): AxiosPromise<PagedUserOrganisationMemberOUT> {
            return localVarFp.concularApiV1RoutersUserOrganisationsGetOrganisationUsers(organisationId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all the details of an organisation.
         * @summary Get User Organisation
         * @param {string} organisationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersUserOrganisationsGetUserOrganisation(organisationId: string, options?: any): AxiosPromise<UserOrganisationOUT> {
            return localVarFp.concularApiV1RoutersUserOrganisationsGetUserOrganisation(organisationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Member can leave the organisation.
         * @summary Leave Organisation
         * @param {string} organisationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersUserOrganisationsLeaveOrganisation(organisationId: string, options?: any): AxiosPromise<ResultOUT> {
            return localVarFp.concularApiV1RoutersUserOrganisationsLeaveOrganisation(organisationId, options).then((request) => request(axios, basePath));
        },
        /**
         * As an org admin I want to remove user from organisation.
         * @summary Remove User From Organisation
         * @param {string} organisationId
         * @param {string} username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concularApiV1RoutersUserOrganisationsRemoveUserFromOrganisation(organisationId: string, username: string, options?: any): AxiosPromise<ResultOUT> {
            return localVarFp.concularApiV1RoutersUserOrganisationsRemoveUserFromOrganisation(organisationId, username, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserOrganisationsApi - object-oriented interface
 * @export
 * @class UserOrganisationsApi
 * @extends {BaseAPI}
 */
export class UserOrganisationsApi extends BaseAPI {
    /**
     * Admin can change role of existing user for an organisation.
     * @summary Change User Role
     * @param {string} organisationId
     * @param {UserOrganisationRoleIN} userOrganisationRoleIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserOrganisationsApi
     */
    public concularApiV1RoutersUserOrganisationsChangeUserRole(organisationId: string, userOrganisationRoleIN: UserOrganisationRoleIN, options?: AxiosRequestConfig) {
        return UserOrganisationsApiFp(this.configuration).concularApiV1RoutersUserOrganisationsChangeUserRole(organisationId, userOrganisationRoleIN, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get number of admins withing the organisation. Organisation require at least on admin. If there is a only admin then it can\'t change the role to user.
     * @summary Get Num Of Admins For Organisation
     * @param {string} organisationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserOrganisationsApi
     */
    public concularApiV1RoutersUserOrganisationsGetNumOfAdminsForOrganisation(organisationId: string, options?: AxiosRequestConfig) {
        return UserOrganisationsApiFp(this.configuration).concularApiV1RoutersUserOrganisationsGetNumOfAdminsForOrganisation(organisationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * As an organisation admin, you can see list of all the users (admin/member) in the organisation.
     * @summary Get Organisation Users
     * @param {string} organisationId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserOrganisationsApi
     */
    public concularApiV1RoutersUserOrganisationsGetOrganisationUsers(organisationId: string, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return UserOrganisationsApiFp(this.configuration).concularApiV1RoutersUserOrganisationsGetOrganisationUsers(organisationId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all the details of an organisation.
     * @summary Get User Organisation
     * @param {string} organisationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserOrganisationsApi
     */
    public concularApiV1RoutersUserOrganisationsGetUserOrganisation(organisationId: string, options?: AxiosRequestConfig) {
        return UserOrganisationsApiFp(this.configuration).concularApiV1RoutersUserOrganisationsGetUserOrganisation(organisationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member can leave the organisation.
     * @summary Leave Organisation
     * @param {string} organisationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserOrganisationsApi
     */
    public concularApiV1RoutersUserOrganisationsLeaveOrganisation(organisationId: string, options?: AxiosRequestConfig) {
        return UserOrganisationsApiFp(this.configuration).concularApiV1RoutersUserOrganisationsLeaveOrganisation(organisationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * As an org admin I want to remove user from organisation.
     * @summary Remove User From Organisation
     * @param {string} organisationId
     * @param {string} username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserOrganisationsApi
     */
    public concularApiV1RoutersUserOrganisationsRemoveUserFromOrganisation(organisationId: string, username: string, options?: AxiosRequestConfig) {
        return UserOrganisationsApiFp(this.configuration).concularApiV1RoutersUserOrganisationsRemoveUserFromOrganisation(organisationId, username, options).then((request) => request(this.axios, this.basePath));
    }
}
