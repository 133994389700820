import React from "react";
import { TreeViewNode } from ".";
import { IconChevronRight, IconChevronUp } from "@tabler/icons-react";
import Tooltip from "components/Tooltip";
import clsx from "clsx";

const TreeNode = ({
  node,
  onToggle,
  onClick,
  highlightNode,
  children,
}: {
  node: TreeViewNode;
  onToggle: (node: TreeViewNode) => TreeViewNode | void;
  onClick?: (node: TreeViewNode) => TreeViewNode | void;
  highlightNode?: boolean;
  children?: React.ReactNode;
}) => {
  const hasChildren = node.childrenNodes && node.childrenNodes.length > 0;

  function shouldHighlightNode() {
    return node.activeNode && highlightNode;
  }

  return (
    <li>
      <div
        className={clsx(
          "flex px-2 cursor-pointer",
          shouldHighlightNode() ? "bg-indigo-50 rounded-md" : ""
        )}
      >
        <div className="min-w-[18px] mr-1 py-2">
          {hasChildren && (
            <span
              className="cursor-pointer text-gray-500"
              onClick={() => onToggle(node)}
            >
              {node.isOpen ? (
                <IconChevronUp width={18} height={18} />
              ) : (
                <IconChevronRight width={18} height={18} />
              )}
            </span>
          )}
        </div>
        <div
          className="flex overflow-hidden py-2"
          onClick={() => onClick?.(node)}
        >
          <span>{node.icon}</span>
          <div className="text-gray-800 text-sm font-medium truncate">
            {children ? (
              children
            ) : (
              <Tooltip content={node.name} truncate>
                {node.name}
              </Tooltip>
            )}
          </div>
          {node.detail ? (
            <span className="px-2 py-[1px] bg-gray-200 text-xs font-medium rounded-lg ml-1.5">
              {node.detail}
            </span>
          ) : null}
        </div>
      </div>
      {hasChildren && node.isOpen && (
        <ul className="pl-3">
          {node.childrenNodes.map((child, index) => (
            <TreeNode
              key={index}
              node={child}
              onToggle={onToggle}
              onClick={onClick}
              highlightNode={highlightNode}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

export default TreeNode;
