import React from "react";
import { observer } from "mobx-react-lite";
import { CollectionIcon } from "@heroicons/react/outline";
import useFeatureFlag from "hooks/useFeatureFlag";
import { useTranslation } from "react-i18next";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";
import GroupByPropertyBrowser from "../GroupByPropertyBrowser";
import { PropertyBrowserTreeNode } from "api-client";
import { IconArrowsSort, IconFilter } from "@tabler/icons-react";
import FilterByPropertyBrowser from "../FilterByPropertyBrowser";
import SortByPropertyBrowser from "../SortByPropertyBrowser";

function SimpleView({ properties }: { properties: PropertyBrowserTreeNode[] }) {
  const { isFeatureVisible } = useFeatureFlag();
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";

  const selectedItemStyle =
    "flex text-indigo-700 bg-indigo-100 rounded-3xl px-2 py-[1px] items-center mr-2";

  return (
    <div className="flex">
      {isFeatureVisible("mapping_editor_groupby") ? (
        <div className={selectedItemStyle}>
          <CollectionIcon width={17} className="mr-1" strokeWidth={3} />
          <span className="mr-1">{t("mappingEditor.grouped")}:</span>
          {dynamicMEStore.selectedGroupByItems
            .map((item) => (isEng ? item.name : item.name_de))
            .join(", ")}
          <GroupByPropertyBrowser items={properties} />
        </div>
      ) : null}
      <div className={selectedItemStyle}>
        <IconFilter width={17} className="mr-1" stroke={3} />
        <span className="mr-1">{t("mappingEditor.filtered")}:</span>
        {dynamicMEStore.selectedFilterByItems?.rules
          .map((rec) => {
            if (rec.property && rec.value)
              return isEng ? rec.property?.name : rec.property?.name_de;
          })
          .filter((rec) => rec)
          .join(", ")}
        <FilterByPropertyBrowser items={properties} />
      </div>
      <div className={selectedItemStyle}>
        <IconArrowsSort width={17} className="mr-1" stroke={3} />
        <span className="mr-1">{t("mappingEditor.sorted")}:</span>
        {dynamicMEStore.selectedSortedByItems
          .map((item) => {
            if (item.property)
              return isEng ? item.property?.name : item.property?.name_de;
          })
          .filter((rec) => rec)
          .join(", ")}
        <SortByPropertyBrowser items={properties} />
      </div>
    </div>
  );
}

export default observer(SimpleView);
