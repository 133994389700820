import {
  CertificationOut,
  EPDConformityOut,
  InventoryPhysicalObjectDetailOut,
  MaterialResourceOUT,
  categoriesAPI,
  inventoryEPDsApi,
  inventoryMappingEditorAPI,
  repositoriesApi,
  searchAPI,
} from "api-client";
import { FileMappingStatusType } from "./types";
import { makeAutoObservable } from "mobx";
import { AxiosResponse } from "axios";
import { getLocalAuthHeader } from "api-client-local/utils";

class IFCMappingStore {
  id = "";
  errorMessage = "";
  showMapping = false;
  objectDetail: InventoryPhysicalObjectDetailOut | undefined;
  fileMappingStatus: FileMappingStatusType | undefined;
  mappingPerformed = false;
  certificationOptions: CertificationOut[] = [];
  conformityOptions: EPDConformityOut[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setObjectDetail(objectDetail: InventoryPhysicalObjectDetailOut | undefined) {
    this.objectDetail = objectDetail;
  }

  setMappingPerformed(performed: boolean) {
    this.mappingPerformed = performed;
  }

  setFileMappingStatus(fileMappingStatus: FileMappingStatusType) {
    this.fileMappingStatus = fileMappingStatus;
  }

  setErrorMessage(message: string) {
    this.errorMessage = message;
  }

  clearErrorMessage() {
    this.errorMessage = "";
  }

  setCertificationOptions(certificationOptions: CertificationOut[]) {
    this.certificationOptions = certificationOptions;
  }

  setConformityOptions(conformityOptions: EPDConformityOut[]) {
    this.conformityOptions = conformityOptions;
  }

  async getObjectsMappingStatus(ifc_id: string | undefined) {
    if (!ifc_id) return;
    this.clearErrorMessage();
    const authHeader = await getLocalAuthHeader();
    this.setFileMappingStatus(undefined);
    await inventoryMappingEditorAPI
      .inventoryApiV1RoutersMappingEditorGetFileStatus(ifc_id, authHeader)
      .then((response: AxiosResponse) => {
        this.setFileMappingStatus(response.data);
      })
      .catch((error) => {
        console.error(error, "error.getObjectsMappingStatus");
        this.setErrorMessage((error as Error).message);
      });
  }

  async getCategoriesGrouped(
    ifcFileId: string,
    groupBy: string,
    mappedStatus?: string,
    ifcEntityFilter?: string,
    materialFilter?: string,
    componentFilter?: string,
    sortBy?: string,
    sortDirection?: string,
    limit?: number,
    offset?: number
  ) {
    const authHeader = await getLocalAuthHeader();
    return await inventoryMappingEditorAPI
      .inventoryApiV1RoutersMappingEditorGetCategoriesGrouped(
        ifcFileId,
        groupBy,
        mappedStatus,
        ifcEntityFilter,
        materialFilter,
        componentFilter,
        sortBy,
        sortDirection,
        limit,
        offset,
        authHeader
      )
      .then(async (response: AxiosResponse) => {
        const receivedData = response.data.items;
        return {
          items: receivedData,
          count: response.data.count,
        };
      })
      .catch((error) => console.error("getCategoriesGrouped.error", error));
  }

  async getAllObjects(
    ifcID: string,
    mappedStatus?: string,
    ifcEntityFilter?: string,
    materialFilter?: string,
    componentFilter?: string,
    sortBy?: string,
    sortDirection?: string,
    limit?: number,
    offset?: number
  ) {
    const authHeader = await getLocalAuthHeader();
    return await inventoryMappingEditorAPI
      .inventoryApiV1RoutersMappingEditorGetPhysicalObjects(
        ifcID,
        mappedStatus,
        ifcEntityFilter,
        materialFilter,
        componentFilter,
        sortBy,
        sortDirection,
        limit,
        offset,
        authHeader
      )
      .then((response: AxiosResponse) => {
        return {
          items: response.data.items,
          count: response.data.count,
        };
      })
      .catch((err) => console.error("getAllObjects.error", err));
  }

  async getObjectsFromCategory(
    ifcID: string,
    groupBy: string,
    sortBy?: string,
    sortDirection?: string,
    limit?: number,
    offset?: number,
    component?: string,
    material?: string,
    ifc_entity?: string,
    mappedStatus?: string,
    ifcEntityFilter?: string,
    materialFilter?: string,
    componentFilter?: string
  ) {
    const authHeader = await getLocalAuthHeader();
    return await inventoryMappingEditorAPI
      .inventoryApiV1RoutersMappingEditorGetObjectsFromCategory(
        ifcID,
        groupBy,
        sortBy,
        sortDirection,
        limit,
        offset,
        component,
        material,
        ifc_entity,
        mappedStatus,
        ifcEntityFilter,
        materialFilter,
        componentFilter,
        authHeader
      )
      .then((response: AxiosResponse) => {
        return {
          items: response.data.items,
          count: response.data.count,
        };
      })
      .catch((err) => console.error("getObjectsFromCategory.error", err));
  }

  async getSingleCategory(
    ifcFileId: string,
    groupBy: string,
    component?: string,
    material?: string,
    ifcEntity?: string,
    mappedStatus?: string,
    ifcEntityFilter?: string,
    materialFilter?: string,
    componentFilter?: string
  ) {
    const authHeader = await getLocalAuthHeader();
    return await inventoryMappingEditorAPI
      .inventoryApiV1RoutersMappingEditorGetSingleCategory(
        ifcFileId,
        groupBy,
        component,
        material,
        ifcEntity,
        mappedStatus,
        ifcEntityFilter,
        materialFilter,
        componentFilter,
        authHeader
      )
      .catch((response: AxiosResponse) => {
        return response.data;
      })
      .catch((err) => console.error("getSingleCategory.error", err));
  }

  async getObjectDetails(objectId: string) {
    const authHeader = await getLocalAuthHeader();
    inventoryMappingEditorAPI
      .inventoryApiV1RoutersMappingEditorGetPhysicalObjectDetails(
        objectId,
        authHeader
      )
      .then((response: AxiosResponse) => this.setObjectDetail(response.data))
      .catch((err) => console.log("getObjectDetails.error", err));
  }

  async getCategories(categorySystem: string) {
    const authHeader = await getLocalAuthHeader();
    return await categoriesAPI
      .caApiV1RoutersCategoryCategories(categorySystem, authHeader)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("getCategories.error", error);
      });
  }

  async fetchCertificationData() {
    const authHeader = await getLocalAuthHeader();
    return await inventoryEPDsApi
      .inventoryApiV1RoutersEpdCertificationData(authHeader)
      .then((response) => {
        this.setCertificationOptions(response.data);
        return response.data;
      })
      .catch((error) =>
        console.error("Failed to fetchCertificationData:", error)
      );
  }

  async fetchConformities() {
    const authHeader = await getLocalAuthHeader();
    return await inventoryEPDsApi
      .inventoryApiV1RoutersEpdEpdConformityData(authHeader)
      .then((response) => {
        this.setConformityOptions(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log("getConformities.error", error);
      });
  }

  async getMaterials() {
    const authHeader = await getLocalAuthHeader();
    return await searchAPI
      .inventoryApiV1RoutersSearchEngineMaterials(authHeader)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("getMaterials.error", error);
      });
  }

  async getManufacturers() {
    const authHeader = await getLocalAuthHeader();
    return await repositoriesApi
      .caApiV1RoutersRepositoryGetManufacturers(authHeader)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("getManufacturers.error", error);
      });
  }

  async matchObject(ifc_id: string, objectId: string, productId?: string) {
    const authHeader = await getLocalAuthHeader();
    return await inventoryMappingEditorAPI
      .inventoryApiV1RoutersMappingEditorMatchSingleObject(
        objectId,
        productId,
        authHeader
      )
      .then((response: AxiosResponse) => {
        this.getObjectsMappingStatus(ifc_id);
        this.setMappingPerformed(true);
        return response.data;
      })
      .catch((error) => {
        console.error(error, "error.warningMatchObject");
        this.setErrorMessage((error as Error).message);
      });
  }

  async matchGroup(
    ifcFileId: string,
    groupBy: string,
    sortBy?: string,
    sortDirection?: string,
    component?: string,
    material?: string,
    ifcEntity?: string,
    productUuid?: string
  ) {
    const authHeader = await getLocalAuthHeader();
    return await inventoryMappingEditorAPI
      .inventoryApiV1RoutersMappingEditorMatchGroupedCategory(
        ifcFileId,
        groupBy,
        sortBy,
        sortDirection,
        component,
        material,
        ifcEntity,
        productUuid,
        authHeader
      )
      .then((response: AxiosResponse) => {
        this.getObjectsMappingStatus(ifcFileId);
        this.setMappingPerformed(true);
        return response.data;
      })
      .catch((error) => {
        console.error(error, "error.warningMatchGroup");
        this.setErrorMessage((error as Error).message);
      });
  }

  async autoMappIFCFile(ifcFileId: string) {
    const authHeader = await getLocalAuthHeader();
    await inventoryMappingEditorAPI
      .inventoryApiV1RoutersMappingEditorAutomapFile(ifcFileId, authHeader)
      .then(() => {
        this.getObjectsMappingStatus(ifcFileId);
        this.setMappingPerformed(true);
      })
      .catch((error) => {
        console.error(error, "error.autoMappObjetcs");
        this.setErrorMessage((error as Error).message);
      });
  }

  async undoMappingIFCFile(ifcFileId: string) {
    const authHeader = await getLocalAuthHeader();
    await inventoryMappingEditorAPI
      .inventoryApiV1RoutersMappingEditorUndoAutomap(ifcFileId, authHeader)
      .then(() => {
        this.getObjectsMappingStatus(ifcFileId);
        this.setMappingPerformed(true);
      })
      .catch((error) => {
        console.error(error, "error.undoMappingIFCFile");
        this.setErrorMessage((error as Error).message);
      });
  }

  async updateFileMappingStatus(ifcFile: MaterialResourceOUT | undefined) {
    ifcFile?.id
      ? await this.getObjectsMappingStatus(ifcFile?.id)
      : this.setFileMappingStatus(undefined);
  }

  async getAvailableFilterValues(ifcFileId: string, column?: string) {
    const authHeader = await getLocalAuthHeader();
    return await inventoryMappingEditorAPI
      .inventoryApiV1RoutersMappingEditorGetAvailableValues(
        ifcFileId,
        column,
        authHeader
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error, "error.getAvailableFilterValues");
        this.setErrorMessage((error as Error).message);
      });
  }

  // Singleton instance of IFCMappingStore
  static instance: IFCMappingStore;

  static getInstance(): IFCMappingStore {
    if (!IFCMappingStore.instance) {
      IFCMappingStore.instance = new IFCMappingStore();
    }
    return IFCMappingStore.instance;
  }
}

export const ifcMappingStore = IFCMappingStore.getInstance();
