import { ObjectOperationIN } from "./../../../api-client/generated/api";
import { EMPTY_FILTER_RULE } from "./../../../features/MappingTools/DynamicMappingEditor/const";
import { DropdownOption } from "components/DropdownCheckbox";
import { Row } from "@tanstack/react-table";
import { makeAutoObservable } from "mobx";
import {
  ColumnSourceEnum,
  ColumnToDisplay,
  DynamicColumnOUT,
  FileOperationIN,
  Filtering,
  FilteringRule,
  FilteringRuleENUM,
  MatchObjectsIN,
  ObjectListIN,
  ObjectListOUT,
  OperationType,
  PropertyBrowserTreeNode,
  SingleObject,
  SortDirectionENUM,
  Sorting,
  dynamicMappingEditorApi,
  fileOperationsApi,
  mappingEditorDynamicColumns,
  mappingEditorObjectsApi,
  objectOperationsApi,
} from "api-client";
import { getLocalAuthHeader } from "api-client-local/utils";
import { FilteringOption, SortingOption } from "./types";
import { SortProps } from "features/MappingTools/DynamicMappingEditor/Filters/SortByPropertyBrowser";
import { BGItem } from "components/ButtonGroups";
import { FILTER_VIEW_OPTIONS } from "features/MappingTools/DynamicMappingEditor/Filters";
import { EMPTY_SORT_ROW } from "features/MappingTools/DynamicMappingEditor/const";
import { FilterRule } from "features/MappingTools/DynamicMappingEditor/Filters/FilterByPropertyBrowser";
import { ifcMappingStore } from "../IFCMappingStore";

const COLUMNS = {
  columns_to_display: [
    {
      column_key: "physical_object.width",
      column_source: "PhysicalObject",
    },
    {
      column_key: "physical_object.height",
      column_source: "PhysicalObject",
    },
    {
      column_key: "physical_object.length",
      column_source: "PhysicalObject",
    },
    {
      column_key: "physical_object.area",
      column_source: "PhysicalObject",
    },
    {
      column_key: "physical_object.volume",
      column_source: "PhysicalObject",
    },
    {
      column_key: "physical_object.component",
      column_source: "PhysicalObject",
    },
    {
      column_key: "match_calculations.mass",
      column_source: "SummaryTable",
    },
    {
      column_key: "match_calculations.gwp_total",
      column_source: "SummaryTable",
    },
    {
      column_key: "physical_object.ifc_entity",
      column_source: "PhysicalObject",
    },
  ],
};

export interface ColumnGroup {
  group_name: string;
  group_name_de: string;
  columns: DynamicColumnOUT[];
}

class DMEStore {
  currentRow: Row<unknown> | undefined = undefined;
  selectedRows: SingleObject[] = [];
  selectedRowIndexes: string[] = [];
  selectAllChecked: boolean = false;
  dataFetchLoading: boolean = false;
  data: ObjectListOUT = {
    items: [],
    count: 0,
  };
  openedProductDetailsPopup: boolean = false;
  openedObjectDetails: boolean = false;
  selectedGroupByItems: DropdownOption[] = [];
  selectedFilterByItems: FilteringOption = {
    logical_operator: "AND",
    rules: [{ ...EMPTY_FILTER_RULE }],
  };
  selectedSortedByItems: SortProps[] = [{ ...EMPTY_SORT_ROW }];
  columnGroups: ColumnGroup[] = [];
  openFilter: boolean = false;
  openSort: boolean = false;
  selectedFilterView: BGItem | undefined = FILTER_VIEW_OPTIONS[0];
  validFilterRules: Filtering | null = null;
  validSortingRules: Sorting[] | null = null;
  showMappingLoading: boolean = false;
  matchForSelectedItems: boolean = false;

  setCurrentRow(currentRow: Row<unknown>) {
    this.currentRow = currentRow;
  }

  setSelectedRows(selectedRowsIndexes: string[]) {
    this.selectedRowIndexes = selectedRowsIndexes;
    this.selectedRows = selectedRowsIndexes.map((index) => {
      return this.data.items[Number(index)];
    });
  }

  setSelectAllChecked(selectAllChecked: boolean) {
    this.selectAllChecked = selectAllChecked;
  }

  setData(data: ObjectListOUT) {
    this.data = data;
  }

  setColumnGroups(columnGroups: ColumnGroup[]) {
    this.columnGroups = columnGroups;
  }

  setDataFetchLoading(dataFetchLoading: boolean) {
    this.dataFetchLoading = dataFetchLoading;
  }

  setOpenedProductDetailsPopup(openedDetailsPopup: boolean) {
    this.openedProductDetailsPopup = openedDetailsPopup;
  }

  setOpenedObjectDetails(openedObjectDetails: boolean) {
    this.openedObjectDetails = openedObjectDetails;
  }

  setSelectedGroupByItems(selectedGroupByItems: DropdownOption[]) {
    this.selectedGroupByItems = selectedGroupByItems;
  }

  setSelectedFilterByItems(selectedFilterByItems: FilteringOption) {
    this.selectedFilterByItems = selectedFilterByItems;
    this.getFilterProps();
  }

  setSelectedSortedByItems(selectedSortedByItems: SortProps[]) {
    this.selectedSortedByItems = selectedSortedByItems;
    this.getSortingProps();
  }

  setOpenFilter(openFilter: boolean) {
    openFilter && this.setOpenSort(false);
    this.openFilter = openFilter;
  }

  setOpenSort(openSort: boolean) {
    openSort && this.setOpenFilter(false);
    this.openSort = openSort;
  }

  setSelectedFilterView(selectedFilterView: BGItem | undefined) {
    this.selectedFilterView = selectedFilterView;
  }

  setShowMappingLoading(showMappingLoading: boolean) {
    this.showMappingLoading = showMappingLoading;
  }

  setMatchForSelectedItems(matchForSelectedItems: boolean) {
    this.matchForSelectedItems = matchForSelectedItems;
  }

  resetData() {
    this.data = {
      items: [],
      count: 0,
    };
  }

  private updateData(receivedData: ObjectListOUT, update?: boolean) {
    if (receivedData && !receivedData.items) return;
    if (update && receivedData) {
      this.setData(receivedData);
    } else if (!update && receivedData) {
      this.setData({
        items: [...this.data.items, ...receivedData.items],
        count: receivedData.count,
      });
    }
  }

  private validFilter(rule: FilterRule) {
    return rule.property && rule.value;
  }

  private getFilterProps() {
    const rules = this.selectedFilterByItems.rules
      .map((item) => {
        if (this.validFilter(item)) {
          return {
            column_key: item.property?.column_key as string,
            column_source: item.property?.column_source as ColumnSourceEnum,
            operator: item.operator as FilteringRuleENUM,
            value: item.value,
            name: item.property?.name,
            name_de: item.property?.name_de,
          };
        }
      })
      .filter((rec) => rec);
    if (rules.length) {
      this.validFilterRules = {
        logical_operator: this.selectedFilterByItems.logical_operator,
        rules: rules as FilteringRule[],
      };
    } else {
      if (this.validFilterRules) this.validFilterRules = null;
    }
  }

  private getSortingProps() {
    const validatedRows = this.selectedSortedByItems
      .map((item) => {
        if (item.property)
          return {
            column_key: item.property?.column_key as string,
            column_source: item.property?.column_source as ColumnSourceEnum,
            direction: item.sort as SortDirectionENUM,
          };
      })
      .filter((rec) => rec);
    if (validatedRows.length) {
      this.validSortingRules = validatedRows as SortingOption[];
    } else {
      if (this.validSortingRules) this.validSortingRules = null;
    }
  }

  async fetchObjectsList(start: number, update?: boolean, ifc_id?: string) {
    if (!ifc_id || this.dataFetchLoading) return;
    const authHeader = await getLocalAuthHeader();
    this.setDataFetchLoading(true);
    const props = {
      ...COLUMNS,
    } as ObjectListIN;
    if (this.validFilterRules) {
      props.filtering = this.validFilterRules;
    }
    if (this.validSortingRules) {
      props.sorting = this.validSortingRules;
    }
    const page = Math.round(start / 100) + 1;
    const receivedData =
      await mappingEditorObjectsApi.mappingEditorApiV1RoutersObjectsListObjectsByFileId(
        ifc_id,
        props,
        page,
        100,
        authHeader
      );
    this.updateData(receivedData.data, update);
    this.setDataFetchLoading(false);
  }

  private getDistictColumnGroups(data: DynamicColumnOUT[]) {
    return data
      .map((item) => ({
        group_name: item.group_name,
        group_name_de: item.group_name_de,
        columns: [],
      }))
      .filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.group_name === value.group_name &&
              t.group_name_de === value.group_name_de
          )
      );
  }

  private createColumnsGroups(
    data: DynamicColumnOUT[],
    distinctGroups: ColumnGroup[]
  ) {
    return distinctGroups.map((item) => ({
      ...item,
      columns: data.filter((rec) => rec.group_name === item.group_name),
    }));
  }

  async fetchColumns() {
    const authHeader = await getLocalAuthHeader();
    const dynamicColumns =
      await mappingEditorDynamicColumns.mappingEditorApiV1RoutersDynamicColumnsGetDynamicColumns(
        authHeader
      );
    const distinctGroups = this.getDistictColumnGroups(dynamicColumns.data);
    this.setColumnGroups(
      this.createColumnsGroups(dynamicColumns.data, distinctGroups)
    );
  }

  async matchProduct(
    file_id: string | undefined,
    product_id: string | null,
    object_id?: string
  ) {
    if (!file_id) return;
    const authHeader = await getLocalAuthHeader();
    this.setShowMappingLoading(true);
    const props: MatchObjectsIN = {
      product_id,
      columns_to_display: COLUMNS.columns_to_display as ColumnToDisplay[],
      object_ids: [object_id ?? (this.currentRow?.original as SingleObject).id],
    };
    return await mappingEditorObjectsApi
      .mappingEditorApiV1RoutersObjectsMatchObjects(file_id, props, authHeader)
      .then((response) => this.updateObjects(response.data.items))
      .catch((error) => console.error("error.ObjectsMatchObjects", error))
      .finally(() => this.onDoneMatching(file_id));
  }

  private onDoneMatching = async (file_id: string | undefined) => {
    await ifcMappingStore.getObjectsMappingStatus(file_id);
    this.setShowMappingLoading(false);
  };

  private updateObjects(updatedObjects: SingleObject[]) {
    updatedObjects.map((rec) => {
      const _items = [...this.data.items];
      const foundedIndex = _items.findIndex((item) => item.id === rec?.id);
      _items[foundedIndex] = { ...rec };
      this.setData({
        count: this.data.count,
        items: _items,
      });
    });
  }

  filterWithAvailableColumns(data: PropertyBrowserTreeNode[]) {
    return data
      .map((parent) => {
        const updatedParent = { ...parent };
        updatedParent.children = parent.children
          ?.map((child) => {
            const updatedChild = { ...child };
            const filteredChild = child.children
              ?.map((grandchild) => {
                if (
                  COLUMNS.columns_to_display.find(
                    (item) => item.column_key === grandchild.column_key
                  )
                ) {
                  return grandchild;
                } else {
                  return null;
                }
              })
              .filter(Boolean) as PropertyBrowserTreeNode[];
            updatedChild.children = filteredChild;
            return updatedChild;
          })
          .filter((item) => item.children?.length);

        return updatedParent;
      })
      .filter((item) => item?.children?.length);
  }

  filterNotSelectedItems(
    data: PropertyBrowserTreeNode[],
    selectedItems: SortProps[] | FilterRule[]
  ) {
    return data
      .map((parent) => {
        const updatedParent = { ...parent };
        updatedParent.children = parent.children
          ?.map((child) => {
            const updatedChild = { ...child };
            const filteredChild = child.children
              ?.map((grandchild) => {
                if (
                  selectedItems.find(
                    (item) =>
                      item.property?.column_key === grandchild.column_key
                  ) === undefined
                ) {
                  return grandchild;
                } else {
                  return null;
                }
              })
              .filter(Boolean) as PropertyBrowserTreeNode[];
            updatedChild.children = filteredChild;
            return updatedChild;
          })
          .filter((item) => item.children?.length);

        return updatedParent;
      })
      .filter((item) => item?.children?.length);
  }

  async getProperties(file_id: string | undefined) {
    if (!file_id) return;
    const authHeader = await getLocalAuthHeader();
    return await dynamicMappingEditorApi
      .mappingEditorApiV1RoutersIndexFilterPropertyBrowserFull(
        file_id,
        authHeader
      )
      .then((response) => response.data)
      .catch((error) =>
        console.error("error.FilterPropertyBrowserFull", error)
      );
  }

  async runFileOperation(
    file_id: string | undefined,
    operation_type: OperationType,
    produc_id?: string
  ) {
    if (!file_id) return;
    const authHeader = await getLocalAuthHeader();
    this.setShowMappingLoading(true);
    const props: FileOperationIN = {
      operation_type: operation_type,
      columns_to_display: COLUMNS.columns_to_display as ColumnToDisplay[],
    };
    if (this.validFilterRules) props.filtering = this.validFilterRules;
    if (produc_id) props.product_uuid = produc_id;
    await fileOperationsApi
      .mappingEditorApiV1RoutersFileOperationsRunFileOperations(
        file_id,
        props,
        authHeader
      )
      .catch((error) =>
        console.error(error, "error.FileOperationsRunFileOperations")
      )
      .finally(() => this.onDoneMatching(file_id));
  }

  async runSelectedObjectOperations(
    file_id: string | undefined,
    operation_type: OperationType,
    produc_id?: string
  ) {
    const authHeader = await getLocalAuthHeader();
    this.setShowMappingLoading(true);
    const props: ObjectOperationIN = {
      operation_type: operation_type,
      columns_to_display: COLUMNS.columns_to_display as ColumnToDisplay[],
      object_ids: this.selectedRows.map((item) => item.id),
      product_uuid: produc_id,
    };
    await objectOperationsApi
      .mappingEditorApiV1RoutersObjectOperationsRunObjectOperations(
        props,
        authHeader
      )
      .then((response) => this.updateObjects(response.data.items))
      .catch((error) =>
        console.error(error, "error.FileOperationsRunFileOperations")
      )
      .finally(() => this.onDoneMatching(file_id));
  }

  constructor() {
    makeAutoObservable(this);
  }

  static instance: DMEStore;

  static getInstance(): DMEStore {
    if (!DMEStore.instance) {
      DMEStore.instance = new DMEStore();
    }
    return DMEStore.instance;
  }
}

export const dynamicMEStore = DMEStore.getInstance();
