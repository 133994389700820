import { useTranslation } from "react-i18next";
import useFeatureFlag from "./useFeatureFlag";
import { useRecoilValue } from "recoil";
import { auditsAtom } from "store/atoms/audits";
import { buildingStore } from "store/Building/BuildingStore";
import useCAOnlyFeature from "./useCAOnlyFeature";
import { useConnectionStatus } from "./useConnectionStatus";

const useBuildingTabOptions = () => {
  const { t } = useTranslation();
  const { isFeatureVisible } = useFeatureFlag();
  const isInvetoryEnabled = isFeatureVisible("inventory");
  const isLcaEnabled = isFeatureVisible("lca_dashboard");
  const isSavingsPotentialEnabled = isFeatureVisible("savings_potential");
  const iscircularityEnabled = isFeatureVisible("circularity_dashboard");
  const isAuditOnlineEnabled = isFeatureVisible("audit_online");
  const isVariantsEnabled = isFeatureVisible("show_variants_tab");
  const isExportsenabled = isFeatureVisible("exports_tab");
  const isSalesApprovalEnabled = isFeatureVisible("sales_approval_tab");
  const isNetworkAvailable = useConnectionStatus();
  const { caOnlyFeature } = useCAOnlyFeature();

  const audits = useRecoilValue(auditsAtom);

  const hasAnyAudit = () => {
    return (
      buildingStore.materialResources.items.filter(
        (item) => item.file_type === "Audit"
      ).length > 0
    );
  };

  const getCaOnlyOptions = () => {
    return [
      {
        item: t("buildingPassport.audits"),
        path: "audits",
      },
      ...(isNetworkAvailable && isAuditOnlineEnabled
        ? [
            {
              item: t("buildingPassport.auditsOnline"),
              path: "audits-online",
            },
          ]
        : []),
    ];
  };

  const options = [
    ...(caOnlyFeature
      ? getCaOnlyOptions()
      : [
          {
            item: t("buildingPassport.passport"),
            path: "passport",
          },
          ...(isInvetoryEnabled
            ? [
                {
                  item: t("buildingInventory.inventory"),
                  path: "inventory",
                },
              ]
            : []),
          {
            item: t("buildingPassport.materialResources"),
            path: "material-resources",
          },
          ...(isSalesApprovalEnabled && hasAnyAudit()
            ? [{ item: t("commons.salesApproval"), path: "sales-approval" }]
            : []),
          ...(isVariantsEnabled
            ? [
                {
                  item: t("variants.title"),
                  path: "variants",
                },
              ]
            : []),
          ...(audits && audits.length > 0
            ? [
                {
                  item: t("buildingPassport.audits"),
                  path: "audits",
                },
              ]
            : []),
          ...(isNetworkAvailable &&
          isAuditOnlineEnabled &&
          audits &&
          audits.length > 0
            ? [
                {
                  item: t("buildingPassport.auditsOnline"),
                  path: "audits-online",
                },
              ]
            : []),
          ...(isLcaEnabled
            ? [
                {
                  item: t("dashboards.lca"),
                  path: "lca",
                },
              ]
            : []),
          ...(iscircularityEnabled
            ? [
                {
                  item: t("dashboards.circularity"),
                  path: "circularity",
                },
              ]
            : []),
          ...(isExportsenabled &&
          buildingStore.currentBuilding?.exports_available
            ? [
                {
                  item: t("exports.title"),
                  path: "exports",
                },
              ]
            : []),

          ...(isSavingsPotentialEnabled
            ? [
                {
                  item: t("dashboards.savings_potential"),
                  path: "savings-potential",
                },
              ]
            : []),
        ]),
  ];

  return { options };
};

export default useBuildingTabOptions;
