import React from "react";
import { observer } from "mobx-react-lite";
import SearchSortTable from "components/Table";
import { ColumnDef, Row } from "@tanstack/react-table";
import useTableColumns from "./useTableColumns";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";
import { useParams } from "react-router";
import { searchStore } from "store/IfcMapping/SearchStore";
import ProductSearch from "../ProductSearch";
import MEObjectDetail from "../ObjectDetailsSlideOver";
import { MaterialFileObject } from "store/IfcMapping/types";
import BulkActionBar from "./layout/BulkActionBar";
import FiltersBar from "./Filters";
import useMappingEditor from "./useMappingEditor";
import { InventoryCategoryAggregateOut } from "api-client";

const DynamicMappingEditor = observer(() => {
  const { ifc_id } = useParams();
  const { contextMenuOptions } = useMappingEditor();

  React.useEffect(() => {
    (async () => await dynamicMEStore.fetchColumns())();
    return () => dynamicMEStore.resetData();
  }, []);

  const getColumns = () => {
    return useTableColumns();
  };

  const getData = async (start: number, _: number, update?: boolean) => {
    await dynamicMEStore.fetchColumns();
    await dynamicMEStore.fetchObjectsList(start, update, ifc_id);
  };

  const onClickCell = (row: Row<unknown>) => {
    dynamicMEStore.setCurrentRow(row);
    dynamicMEStore.setOpenedObjectDetails(true);
  };

  async function matchProductAndUpdate(product_id: string) {
    if (dynamicMEStore.matchForSelectedItems) {
      if (dynamicMEStore.selectAllChecked) {
        await dynamicMEStore.runFileOperation(
          ifc_id,
          "manual_match",
          product_id
        );
      } else {
        await dynamicMEStore.runSelectedObjectOperations(
          ifc_id,
          "manual_match",
          product_id
        );
      }
    } else {
      await dynamicMEStore.matchProduct(ifc_id, product_id);
    }
  }

  if (!dynamicMEStore.columnGroups.length) return <></>;
  return (
    <div className="h-screen flex flex-col items-center">
      <FiltersBar />
      <SearchSortTable
        getColumns={getColumns as () => ColumnDef<unknown, unknown>[]}
        data={dynamicMEStore.data.items.map((item) => ({
          ...item.values,
          product_match: item.product_match,
        }))}
        hasGroupHeader
        enableColumnResizing
        totalDBRowCount={dynamicMEStore.data.count}
        dataFetchLoading={dynamicMEStore.dataFetchLoading}
        fetchData={getData}
        enableTooltip
        scrollDivClassName="!max-h-[calc(100vh-145px)] w-full"
        customColumnStyle="!py-0 px-2 !font-medium h-[40px] cursor-pointer"
        headerDivStyle="!py-1.5"
        headerColumnStyle="!py-0"
        customContainerStyle="border-b"
        groupHeaderClassName="!pt-0.5 !pb-0.5"
        enableRowSelection
        onRowSelect={(e) => dynamicMEStore.setSelectedRows(e)}
        noFooterBar
        hasHoverStyle
        onRowClick={onClickCell}
        menuOptions={contextMenuOptions}
        selectedRows={dynamicMEStore.selectedRowIndexes}
        selectAllChecked={dynamicMEStore.selectAllChecked}
      />
      <BulkActionBar />
      {searchStore.openProductSearch && (
        <ProductSearch
          open={searchStore.openProductSearch}
          close={() => searchStore.setOpenProductSearch(false)}
          currentRow={
            dynamicMEStore.currentRow?.original as InventoryCategoryAggregateOut
          }
          matchProductAndUpdate={matchProductAndUpdate}
          isDynamic
        />
      )}
      <MEObjectDetail
        open={dynamicMEStore.openedObjectDetails}
        close={() => dynamicMEStore.setOpenedObjectDetails(false)}
        // refresh={updateCategoryMappingStatus}
        object={dynamicMEStore.currentRow?.original as MaterialFileObject}
      />
    </div>
  );
});

export default React.memo(DynamicMappingEditor);
