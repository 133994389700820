import React, { useEffect, useState } from "react";
import MainLayout from "layouts/MainLayout";
import { Outlet, useLocation, useParams } from "react-router";
import Tabs from "components/Tabs";
import { useRecoilValue } from "recoil";
import { auditsAtom } from "store/atoms/audits";
import useFeatureFlag from "hooks/useFeatureFlag";
import { observer } from "mobx-react-lite";
import { buildingStore } from "store/Building/BuildingStore";
import { useBuildings } from "features/Building/useBuildings";
import BlueprintsLayout from "layouts/BlueprintsLayout";
import useBuildingTabOptions from "hooks/useBuildingTabOptions";

const Building = observer(() => {
  const audits = useRecoilValue(auditsAtom);
  const location = useLocation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const { isFeatureVisible } = useFeatureFlag();
  const isExportsenabled = isFeatureVisible("exports_tab");

  const { id: BuildingId } = useParams();
  const { refreshOnChangeBuilding } = useBuildings();

  const { options } = useBuildingTabOptions();

  useEffect(() => {
    buildingStore.resetBuildingMappingStatus();
    buildingStore.checkRecalculatingStatus(BuildingId);
    (async () => await refreshOnChangeBuilding())();
  }, [buildingStore.currentBuilding?.id]);

  useEffect(() => {
    if (location) {
      const currentPath = location.pathname.split("/")[3];
      const currentItem = options.findIndex((item) =>
        item.path?.includes(currentPath)
      );
      setCurrentIndex(currentItem);
    }
  }, [
    location,
    audits && audits.length > 0,
    isExportsenabled,
    buildingStore.buildingMappingStatus.hasReport,
  ]);

  useEffect(() => {
    if (!buildingStore.materialResources.loading) {
      buildingStore.getBuildingMappingStatus();
    }
  }, [location]);

  return location.pathname.includes("blueprints") ? (
    <BlueprintsLayout>
      <Outlet />
    </BlueprintsLayout>
  ) : (
    <MainLayout>
      <div className={"sticky z-10 w-full"}>
        <Tabs
          vertical={false}
          isLink
          currentIndex={currentIndex}
          options={options}
          listClassName={"h-[52px] px-3"}
        />
      </div>
      <Outlet />
    </MainLayout>
  );
});

export default Building;
