import {
  ContextMenu,
  Entity,
  NavCubePlugin,
  PickResult,
  TreeViewPlugin,
  Viewer,
  XKTLoaderPlugin,
} from "@xeokit/xeokit-sdk";
import { bimViewerStore } from "store/BimViewerStore";
import bimViewerMenuItems from "./BimViewerMenuItems";

export const hierarchies = {
  containment: "containment",
  storeys: "storeys",
  types: "types",
};

export default function useBimViewer() {
  const contextMenuItems = bimViewerMenuItems();

  function createViewer(canvasId: string) {
    const viewer = new Viewer({
      canvasId: canvasId,
      transparent: true,
    });
    viewer.scene.gammaInput = true;
    viewer.scene.gammaOutput = true;
    viewer.cameraControl.followPointer = true;
    viewer.scene.camera.eye = [10.45, 17.38, -98.31];
    viewer.scene.camera.look = [43.09, 0.5, -26.76];
    viewer.scene.camera.up = [0.06, 0.96, 0.16];
    viewer.cameraFlight.fitFOV = 25;
    viewer.scene.xrayMaterial.fillAlpha = 0.1;
    viewer.scene.xrayMaterial.fillColor = [0, 0, 0];
    viewer.scene.xrayMaterial.edgeAlpha = 0.4;
    viewer.scene.xrayMaterial.edgeColor = [2, 0, 0];
    viewer.scene.highlightMaterial.fill = false;
    viewer.scene.highlightMaterial.fillAlpha = 0.3;
    viewer.scene.highlightMaterial.edgeColor = [1, 1, 0];
    viewer.scene.selectedMaterial.fill = true;
    viewer.scene.selectedMaterial.edges = true;
    viewer.scene.selectedMaterial.fillAlpha = 0.5;
    viewer.scene.selectedMaterial.edgeAlpha = 0.6;
    viewer.scene.selectedMaterial.edgeColor = [0, 1, 1];
    return viewer;
  }

  function loadFile(fileSrc: string) {
    const viewer = bimViewerStore.viewer;
    if (!viewer) return;
    const xktLoader = new XKTLoaderPlugin(viewer);
    const sceneModel = xktLoader.load({
      src: fileSrc,
      edges: true,
      rotation: [0, 0, 0],
      scale: [2, 2, 2],
      origin: [40, -20, 20],
    });

    sceneModel.on("loaded", () => {
      performance.now();
      bimViewerStore.initialZoomToIfcSite();
    });

    return sceneModel;
  }

  function addTreeView(
    treeViewContainer: HTMLDivElement | null,
    hierarchy: "containment" | "storeys" | "types"
  ) {
    const viewer = bimViewerStore.viewer;
    if (!treeViewContainer || !viewer) return;
    const treeView = new TreeViewPlugin(viewer, {
      containerElement: treeViewContainer,
      autoExpandDepth: 1, // Initially expand tree three storeys deep
      hierarchy: hierarchy,
      sortNodes: true,
    });

    treeNodeTitleClickAction(treeView);
    createTreeViewContextMenu(treeView);
    return treeView;
  }

  function createTreeViewContextMenu(treeView: TreeViewPlugin) {
    const treeViewContextMenu = new ContextMenu({
      items: [
        [contextMenuItems.viewFitTree, contextMenuItems.viewFitAllTree],
        [
          contextMenuItems.hideTree,
          contextMenuItems.hideOthersTree,
          contextMenuItems.hideAll,
        ],
        [
          contextMenuItems.show,
          contextMenuItems.showOthers,
          contextMenuItems.showAll,
        ],
        [
          contextMenuItems.xRayTree,
          contextMenuItems.undoXrayTree,
          contextMenuItems.xRayOthersTree,
          contextMenuItems.resetXray,
        ],
        [
          contextMenuItems.selectTree,
          contextMenuItems.undoSelectTree,
          contextMenuItems.clearSelection,
        ],
      ],
    });

    treeView.on("contextmenu", (e) => {
      treeViewContextMenu.context = {
        viewer: e.viewer,
        treeViewPlugin: e.treeViewPlugin,
        treeViewNode: e.treeViewNode,
        entity: e.viewer.scene.objects[e.treeViewNode.objectId],
      };

      treeViewContextMenu.show(e.event.pageX, e.event.pageY);
    });
  }

  function treeNodeTitleClickAction(treeView: TreeViewPlugin) {
    const viewer = bimViewerStore.viewer;
    return treeView.on("nodeTitleClicked", (e) => {
      if (!viewer) return;
      const scene = viewer.scene;
      const objectIds: string[] = [];
      e.treeViewPlugin.withNodeTree(e.treeViewNode, (treeViewNode) => {
        if (treeViewNode.objectId) {
          objectIds.push(treeViewNode.objectId as string);
        }
      });
      e.treeViewPlugin.unShowNode();
      scene.setObjectsXRayed(scene.objectIds, true);
      scene.setObjectsVisible(scene.objectIds, true);
      scene.setObjectsXRayed(objectIds, false);
      viewer.cameraFlight.flyTo(
        {
          aabb: scene.getAABB(objectIds),
          duration: 0.5,
        },
        () => {
          setTimeout(function () {
            scene.setObjectsVisible(scene.xrayedObjectIds, false);
            scene.setObjectsXRayed(scene.xrayedObjectIds, false);
          }, 500);
        }
      );
    });
  }

  function handleObjectsOnclick(onClick: (object: Entity) => void) {
    const viewer = bimViewerStore.viewer;
    if (!viewer) return;
    let lastEntity: Entity | null = null;
    viewer.cameraControl.on("picked", (pickResult) => {
      if (!pickResult.entity) {
        return;
      }
      if (!lastEntity || pickResult.entity.id !== lastEntity.id) {
        if (lastEntity) {
          lastEntity.highlighted = false;
        }

        lastEntity = pickResult.entity;
        pickResult.entity.highlighted = true;
        onClick(pickResult.entity);
      }
    });
  }

  function setObjectHighlightStatus(object: Entity, status: boolean) {
    object.highlighted = status;
  }

  function addNavCube() {
    const viewer = bimViewerStore.viewer;
    if (!viewer) return;
    const navCube = new NavCubePlugin(viewer, {
      canvasId: "myNavCubeCanvas",
      visible: true,
      backColor: "#fff",
      frontColor: "#fff",
      leftColor: "#fff",
      rightColor: "#fff",
      topColor: "#fff",
      bottomColor: "#fff",
    });
    return navCube;
  }

  function createObjectsContextMenu() {
    return new ContextMenu({
      items: [
        [
          contextMenuItems.viewFit,
          contextMenuItems.viewFitAll,
          contextMenuItems.showInTree,
        ],
        [
          contextMenuItems.hide,
          contextMenuItems.hideOthers,
          contextMenuItems.hideAll,
          contextMenuItems.showAll,
        ],
        [
          contextMenuItems.xRay,
          contextMenuItems.undoXray,
          contextMenuItems.xRayOthers,
          contextMenuItems.resetXray,
        ],
        [
          contextMenuItems.select,
          contextMenuItems.undoSelect,
          contextMenuItems.clearSelection,
        ],
      ],
      enabled: true,
    });
  }

  function createCanvasContextMenu() {
    const viewer = bimViewerStore.viewer;
    if (!viewer) return;
    return new ContextMenu({
      enabled: true,
      context: {
        viewer: viewer,
      },
      items: [
        [contextMenuItems.hideAll, contextMenuItems.showAll],
        [contextMenuItems.viewFitAll],
      ],
    });
  }

  function controlRightMenuOnViewer(
    objectContextMenu: ContextMenu,
    treeView: TreeViewPlugin | undefined,
    canvasContextMenu: ContextMenu | undefined
  ) {
    const viewer = bimViewerStore.viewer;
    if (!viewer) return;
    viewer.cameraControl.on("rightClick", function (e) {
      const hit: PickResult | null = viewer.scene.pick({
        canvasPos: e.canvasPos,
      });

      if (hit && hit.entity && hit.entity.isObject) {
        objectContextMenu.context = {
          viewer: viewer,
          treeViewPlugin: treeView,
          entity: hit.entity,
        };

        objectContextMenu.show(e.event.pageX, e.event.pageY);
      } else if (canvasContextMenu) {
        canvasContextMenu.context = {
          viewer: viewer,
        };
        canvasContextMenu.show(e.event.pageX, e.event.pageY);
      }

      e.event.preventDefault();
    });
  }

  return {
    createViewer,
    loadFile,
    addTreeView,
    handleObjectsOnclick,
    addNavCube,
    createObjectsContextMenu,
    createCanvasContextMenu,
    controlRightMenuOnViewer,
    setObjectHighlightStatus,
  };
}
