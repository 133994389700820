import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Heading from "components/Heading";
import TagsInput from "components/TagsInput";
import Button from "components/Button";
import {
  CheckIcon,
  MailIcon,
  PlusIcon,
  TrashIcon,
  UsersIcon,
  XIcon,
} from "@heroicons/react/solid";
import SearchSortTable from "components/Table";
import SimpleSelect from "components/SimpleSelect";
import Badge from "components/Badge";
import InviteMember from "./InviteMember";
import {
  organisationAccessRequestApi,
  organisationDomainsApi,
  userOrganisationsApi,
} from "api-client";
import ConfirmButton from "components/ConfirmButton";
import moment from "moment";
import SettingsLayout from "./SettingsLayout";
import { userRoles } from "../consts.s";
import { useAuth0 } from "@auth0/auth0-react";
import { AxiosResponse } from "axios";
import { ColumnDef } from "@tanstack/react-table";
import useToast from "hooks/useToast";
import { IconX } from "@tabler/icons-react";
import Tooltip from "components/Tooltip";
import { organisationStore } from "store/OrganisationStore";
import { observer } from "mobx-react-lite";
import { userStore } from "store/UserStore";

export default observer(function OrganisationUserManagement({
  open,
  close,
}: {
  open: boolean;
  close: () => void;
}) {
  const { t } = useTranslation();
  const { user } = useAuth0();
  const { authHeader } = userStore;
  const [domains, setDomains] = useState([]);
  const [members, setMembers] = useState([]);
  const [requestedUsers, setRequestedUsers] = useState([]);
  const toast = useToast();
  const { clickedOrganisation } = organisationStore;

  const getAllMembers = async () => {
    if (!clickedOrganisation?.id || !authHeader) return;
    return await userOrganisationsApi
      .concularApiV1RoutersUserOrganisationsGetOrganisationUsers(
        clickedOrganisation.id,
        100,
        0,
        authHeader
      )
      .then((response: AxiosResponse) => {
        setMembers(response.data.items);
      })
      .catch((err) => toast(err.message, "warning"));
  };

  const getAllRequestedUsers = async () => {
    if (!clickedOrganisation?.id || !authHeader) return;
    organisationAccessRequestApi
      .concularApiV1RoutersOrganisationAccessRequestGetAllRequests(
        clickedOrganisation?.id,
        100,
        0,
        authHeader
      )
      .then((response: AxiosResponse) => {
        setRequestedUsers(response.data.items);
      })
      .catch((err) => toast(err.message, "warning"));
  };

  const getAllDomains = async () => {
    if (!clickedOrganisation?.id || !authHeader) return;
    return await organisationDomainsApi
      .concularApiV1RoutersOrganisationDomainGetAllOrgDomain(
        clickedOrganisation?.id,
        100,
        0,
        authHeader
      )
      .then((response: AxiosResponse) => {
        const items = response?.data?.items;
        setDomains(
          items.map((item: { id: string; domain: string }) => ({
            id: item.id,
            name: item.domain,
          }))
        );
      })
      .catch((err) => toast(err.message, "warning"));
  };

  const addDomain = async (domain: string) => {
    if (!clickedOrganisation?.id || !authHeader) return;
    return await organisationDomainsApi
      .concularApiV1RoutersOrganisationDomainCreateOrgDomain(
        {
          organisation_id: clickedOrganisation?.id,
          domain: domain,
        },
        authHeader
      )
      .then(() => {
        getAllDomains();
      })
      .catch((err) => toast(err.message, "warning"));
  };

  const removeDomain = (domainId: string) => {
    if (!authHeader) return;
    organisationDomainsApi
      .concularApiV1RoutersOrganisationDomainDeleteOrgDomain(
        domainId,
        authHeader
      )
      .then(() => {
        getAllDomains();
      })
      .catch((err) => toast(err.message, "warning"));
  };

  const changeUserRole = async (username: string, role: number) => {
    if (!clickedOrganisation?.id || !authHeader) return;
    return await userOrganisationsApi
      .concularApiV1RoutersUserOrganisationsChangeUserRole(
        clickedOrganisation?.id,
        { username, role },
        authHeader
      )
      .then(() => getAllMembers())
      .catch((err) => toast(err.message, "warning"));
  };

  const removeUser = async (username: string) => {
    if (!clickedOrganisation?.id || !authHeader) return;
    return await userOrganisationsApi
      .concularApiV1RoutersUserOrganisationsRemoveUserFromOrganisation(
        clickedOrganisation?.id,
        username,
        authHeader
      )
      .then(() => {
        getAllMembers();
        toast(
          t("organisationSettings.members.removeMemberSuccessMsg"),
          "success"
        );
      })
      .catch((err) => toast(err.message, "warning"));
  };

  const confirmRequest = (id: string) => {
    if (!authHeader) return;
    organisationAccessRequestApi
      .concularApiV1RoutersOrganisationAccessRequestRequestAccept(
        { id },
        authHeader
      )
      .then(() => {
        getAllMembers();
        getAllRequestedUsers();
      })
      .catch((err) => toast(err.message, "warning"));
  };

  const declineRequest = (id: string) => {
    if (!authHeader) return;
    organisationAccessRequestApi
      .concularApiV1RoutersOrganisationAccessRequestDeclineUserRequestForOrganisation(
        id,
        authHeader
      )
      .then(() => {
        getAllMembers();
        getAllRequestedUsers();
      })
      .catch((err) => toast(err.message, "warning"));
  };

  const resendInvitation = async (email: string) => {
    if (!clickedOrganisation?.id || !authHeader) return;
    return await organisationAccessRequestApi
      .concularApiV1RoutersOrganisationAccessRequestInviteUserOrAdminForOrg(
        clickedOrganisation?.id,
        { email },
        authHeader
      )
      .then(() => {
        getAllRequestedUsers();
        toast(
          t("organisationSettings.members.resendInvitationSuccessMsg"),
          "success"
        );
      })
      .catch((err) => toast(err.message, "warning"));
  };

  useEffect(() => {
    getAllDomains();
    getAllMembers();
    getAllRequestedUsers();
  }, []);

  const getColumns = () => {
    const columns = useMemo(
      () => [
        {
          accessorFn: (row: { user: string }) => row.user,
          id: "name",
          cell: (info: {
            getValue: () => { first_name: string; last_name: string };
          }) => (
            <div>
              {`${info?.getValue()?.first_name || "-"} ${
                info?.getValue()?.last_name || ""
              }` ?? ""}
            </div>
          ),
          header: () => <div>{t("commons.name")}</div>,
          enableColumnFilter: false,
          enableSorting: false,
        },
        {
          accessorFn: (row: { user: { email: string } }) => row.user.email,
          id: "email",
          cell: (info: { getValue: () => string }) => (
            <Tooltip truncate className="max-w-[150px]">
              {info.getValue()}
            </Tooltip>
          ),
          header: () => <div>{t("commons.email")}</div>,
          enableColumnFilter: false,
          enableSorting: false,
        },
        {
          accessorFn: (row: string) => row,
          id: "role",
          cell: (info: {
            getValue: () => {
              role: string | number | undefined;
              user: { username: string; email: string };
            };
          }) => (
            <SimpleSelect
              options={userRoles}
              current={info.getValue().role}
              containerClassName="min-w-[100px]"
              noMargin
              onChange={(value) =>
                value &&
                changeUserRole(info.getValue().user.username, Number(value))
              }
              disable={user?.email === info.getValue().user.email}
            />
          ),
          header: () => <div>{t("organisationSettings.members.role")}</div>,
          enableColumnFilter: false,
          enableSorting: false,
        },
        {
          id: "remove",
          accessorFn: (row: { user: string }) => row.user,
          cell: (info: {
            getValue: () => { username: string; email: string };
          }) => (
            <div className="flex items-center">
              <ConfirmButton
                leadingIcon={<TrashIcon />}
                onConfirm={() => removeUser(info.getValue().username)}
                disable={user?.email === info.getValue().email}
                className="mr-2"
              >
                {t("organisationSettings.members.removeUser")}
              </ConfirmButton>
              <div className="w-5 !z-30">
                {user?.email === info.getValue().email ? (
                  <Tooltip
                    content={t("organisationSettings.members.removeInfo")}
                    tooltipClassName="!w-[230px]"
                  />
                ) : null}
              </div>
            </div>
          ),
          header: () => <div> {t("organisationSettings.members.remove")}</div>,
          enableColumnFilter: false,
          enableSorting: false,
        },
      ],
      []
    );
    return columns;
  };

  const getUsersColumns = () => {
    const columns = [
      {
        accessorFn: (row: { user: string }) => row.user,
        id: "name",
        cell: (info: {
          getValue: () => { first_name: string; last_name: string };
        }) => (
          <div>
            {`${info?.getValue()?.first_name || "-"} ${
              info?.getValue()?.last_name || ""
            }` ?? ""}
          </div>
        ),
        header: () => <div>{t("commons.name")}</div>,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorFn: (row: string) => row,
        id: "email",
        cell: (info: {
          getValue: () => { email: string; user: { email: string } };
        }) => (
          <Tooltip truncate className="max-w-[160px]">
            {info.getValue()?.user
              ? info.getValue().user.email
              : info.getValue().email}
          </Tooltip>
        ),
        header: () => <div>{t("commons.email")}</div>,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorFn: (row: string) => row,
        id: "status",
        cell: (info: {
          getValue: () => {
            invited_by: string;
            modified: moment.MomentInput;
          };
        }) =>
          info?.getValue().invited_by ? (
            <Badge type="attention" size="small" className="text-sm min-w-max">
              {`${t("organisationSettings.members.invitedOn")} ${moment(
                info?.getValue().modified
              ).format("DD.MM.YY")}`}
            </Badge>
          ) : (
            <Badge type="attention" size="small" className="text-sm min-w-max">
              {`${t("organisationSettings.members.requestedOn")} ${moment(
                info?.getValue().modified
              ).format("DD.MM.YY")}`}
            </Badge>
          ),
        header: () => <div>{t("commons.status")}</div>,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorFn: (row: string) => row,
        id: "action",
        cell: (info: {
          getValue: () => {
            invited_by: string;
            user: { email: string };
            email: string;
            id: string;
          };
        }) =>
          info?.getValue().invited_by ? (
            <Button
              type="secondary"
              size="small"
              leadingIcon={<MailIcon />}
              width="fit-content"
              className="min-w-max"
              onClick={() =>
                resendInvitation(
                  info?.getValue()?.user
                    ? info?.getValue().user?.email
                    : info?.getValue().email
                )
              }
            >
              {t("organisationSettings.members.resendInvitation")}
            </Button>
          ) : (
            <div className="flex justify-between max-w-[200px]">
              <Button
                type="secondary"
                width="fit-content"
                onClick={() => confirmRequest(info.getValue().id)}
                leadingIcon={<CheckIcon />}
                size="small"
                className="min-w-max"
              >
                {t("organisationSettings.members.confirmRequest")}
              </Button>
              <Button
                type="danger"
                width="fit-content"
                onClick={() => declineRequest(info.getValue().id)}
                leadingIcon={<XIcon width={18} />}
                size="small"
                className="!rounded-md ml-1 border-none"
              ></Button>
            </div>
          ),
        header: () => <div>{t("organisationSettings.members.action")}</div>,
        enableColumnFilter: false,
        enableSorting: false,
        size: 180,
      },
    ];
    const memoedColumns = useMemo(() => columns, []);
    return memoedColumns;
  };

  const [openInvite, setOpenInvite] = useState(false);
  const showInvite = () => setOpenInvite((state) => !state);

  useEffect(() => {
    getAllRequestedUsers();
  }, [openInvite]);

  return (
    <SettingsLayout open={open} close={close}>
      {Header(close)}

      <div className="p-5 text-sm h-[calc(100vh-200px)] overflow-y-scroll scrollbar">
        <div className="border-b pb-5">
          <div className="font-semibold mb-6 text-lg">
            {t("organisationSettings.members.invitationSettings")}
          </div>
          <div className="text-sm text-gray-700">
            {t("organisationSettings.members.emailDomains")}
          </div>
          <TagsInput
            items={domains}
            description={t(
              "organisationSettings.members.emailDomainsDescription"
            )}
            addItem={addDomain}
            removeItem={removeDomain}
            regEx={new RegExp("^@[a-zA-Z0-9.-]+$")}
          />
        </div>
        <div className="border-b pb-5 pt-5">
          <div className="flex justify-between items-center mb-5">
            <div className="font-semibold text-lg">
              {t("organisationSettings.members.invitationsAndRequests")}
            </div>
            <Button
              type="primary"
              width="fit-content"
              leadingIcon={<PlusIcon />}
              onClick={showInvite}
            >
              {t("organisationSettings.members.inviteMembers")}
            </Button>
            {openInvite && (
              <InviteMember open={openInvite} close={showInvite} />
            )}
          </div>
          {requestedUsers && requestedUsers.length > 0 ? (
            <SearchSortTable
              data={requestedUsers}
              getColumns={
                getUsersColumns as () => ColumnDef<unknown, unknown>[]
              }
              borderedStyle
            />
          ) : (
            <div>{t("organisationSettings.members.noInvitationMsg")}</div>
          )}
        </div>
        <div className="border-b pb-5 pt-5">
          <div className="font-semibold mb-6 text-lg">
            {t("organisationSettings.members.members")}
          </div>
          <SearchSortTable
            data={members}
            getColumns={getColumns as () => ColumnDef<unknown, unknown>[]}
            borderedStyle
          />
        </div>
      </div>
    </SettingsLayout>
  );
});

function Header(onClose: () => void) {
  const { t } = useTranslation();
  return (
    <div className="flex p-5 border-b border-gray-300 items-center justify-between">
      <div className="flex">
        <UsersIcon width={20} />
        <Heading size="title" className="pl-2 !mb-0">
          {t("organisationSettings.members.userManagement")}
        </Heading>
      </div>
      <IconX
        className="cursor-pointer text-gray-500 hover:text-gray-700"
        onClick={onClose}
      />
    </div>
  );
}
