import React from "react";

/**
 * This hook will be responsible only for to check if we
 * want to show only CA related features into the app
 * e.g; CA, CA-Online tabs, and no LCA data or API should be called
 * @returns boolean value wither ca-only feature should sow or not
 */
const useCAOnlyFeature = () => {
  const [caOnlyFeature, setCaOnlyFeature] = React.useState(false);

  React.useEffect(() => {
    const flagValue = process.env.REACT_APP_NAVIGATION_CONFIG;
    setCaOnlyFeature(flagValue !== undefined && flagValue.includes("ca-only"));
  }, []);

  return { caOnlyFeature };
};

export default useCAOnlyFeature;
