import { EmbedConfig, powerBIAPI } from "api-client";
import { PowerBIEmbed } from "powerbi-client-react";
import {
  Embed,
  IDashboardEmbedConfiguration,
  IQnaEmbedConfiguration,
  IReportCreateConfiguration,
  IReportEmbedConfiguration,
  ITileEmbedConfiguration,
  IVisualEmbedConfiguration,
  models,
} from "powerbi-client";
import React, { useState } from "react";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { buildingStore } from "store/Building/BuildingStore";
import EmptyState from "components/EmptyState";
import { userStore } from "store/UserStore";
import { buildingSettingsStore } from "store/Building/BuildingSettingsStore";
import useFeatureFlag from "hooks/useFeatureFlag";

export interface PowerBIReportProps {
  reportID: string | undefined;
  containerClassName?: string;
  filters?: models.ReportLevelFilters[] | models.OnLoadFilters;
  slicers?: models.ISlicer[];
  reloadOnChangeNRF?: boolean;
  getEmbeddedComponentHandler?: (embeddedComponent: Embed) => void;
}

const PowerBIReport = (props: PowerBIReportProps) => {
  const { authHeader } = userStore;
  const { reportID, containerClassName } = props;
  const [reportConfig, setReportConfig] = useState<object | null>(null);
  const [dashboardRendered, setDashboardRendered] = useState<boolean>(false);
  const { currentBuilding } = buildingStore;
  const { isFeatureVisible } = useFeatureFlag();
  const debugPaneEnabled = isFeatureVisible("powerbi_debug_pane");

  React.useEffect(() => {
    loadDashboard();
  }, [reportID]);

  React.useEffect(() => {
    if (
      props.reloadOnChangeNRF &&
      buildingSettingsStore.buildingAlreadyUpdated
    ) {
      loadDashboard();
    }
  }, [buildingStore.currentBuilding?.net_floor_area]);

  async function loadDashboard() {
    if (!reportID) return;
    setReportConfig(null);
    setDashboardRendered(false);
    await getReport(reportID);
  }

  const eventHandlersMap = new Map([
    [
      "rendered",
      function () {
        setDashboardRendered(true);
      },
    ],
  ]);

  React.useEffect(() => {
    if (buildingStore.isRecalculationInProgress(currentBuilding?.id)) {
      setDashboardRendered(false);
    }
  }, [buildingStore.recalculatingLoading.length, currentBuilding?.id]);

  const getReport = async (reportId: string) => {
    if (!authHeader) return;
    await powerBIAPI
      .reportApiV1RoutersPowerbiGetEmbedParams(reportId, undefined, authHeader)
      .then((response) => {
        const config = getPowerBIEmbedConfig(
          response.data,
          debugPaneEnabled,
          props.filters
        );
        setReportConfig(config);
      })
      .catch((err) =>
        console.error(err, "error.reportApiV1RoutersPowerbiGetEmbedParams")
      );
  };

  const isDashboardLoading =
    buildingStore.isRecalculationInProgress(currentBuilding?.id) ||
    !dashboardRendered;

  const dashboardVisible =
    dashboardRendered &&
    !buildingStore.isRecalculationInProgress(currentBuilding?.id);

  return (
    <div className={containerClassName || "pt-6"}>
      <div className={clsx("rounded-md overflow-hidden")}>
        {isDashboardLoading && (
          <EmptyState type="chart" loading={isDashboardLoading} />
        )}
        <div className={`${dashboardVisible ? "visible" : "hidden"}`}>
          {reportConfig && (
            <PowerBIEmbed
              key={reportID}
              embedConfig={reportConfig}
              cssClassName={"power-bi-container"}
              eventHandlers={eventHandlersMap}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const getPowerBIEmbedConfig = (
  reportInfo: EmbedConfig,
  debugPaneEnabled: boolean,
  filters?: models.ReportLevelFilters[] | models.OnLoadFilters,
  slicers?: models.ISlicer[]
):
  | object
  | IReportEmbedConfiguration
  | IDashboardEmbedConfiguration
  | ITileEmbedConfiguration
  | IQnaEmbedConfiguration
  | IVisualEmbedConfiguration
  | IReportCreateConfiguration
  | null => {
  if (!reportInfo) {
    return null;
  }
  if (!reportInfo.reportConfig) {
    return null;
  }

  return {
    type: "report",
    id: reportInfo.reportConfig[0]?.reportId as string,
    embedUrl: reportInfo.reportConfig[0]?.embedUrl as string,
    accessToken: reportInfo.token as string,
    tokenType: models.TokenType.Embed,
    settings: {
      navContentPaneEnabled: debugPaneEnabled,
      filterPaneEnabled: debugPaneEnabled,
      background: models.BackgroundType.Transparent,
    },
    filters: filters,
    slicers: slicers,
  };
};

export default observer(PowerBIReport);
