import React from "react";
import { observer } from "mobx-react-lite";
import SimpleSelect from "components/SimpleSelect";
import { buildingStore } from "store/Building/BuildingStore";
import { useTranslation } from "react-i18next";
import Alert from "components/Alert";
import { BuildingFormOnchange } from "./GeneralInformation";
import { buildingSettingsStore } from "store/Building/BuildingSettingsStore";
import { ifcMappingStore } from "store/IfcMapping/IFCMappingStore";
import { useComplianceInfo } from "hooks/useComplianceInfo";
import { getModuleStringTotal } from "utils";

interface ComplianceProps extends BuildingFormOnchange {
  type: "edit" | "add";
}

export default observer(function ComplianceSettings({
  handleChange,
  type,
}: ComplianceProps) {
  const { t } = useTranslation();
  const { currentBuilding } = buildingStore;
  const [currentConformity, setCurrentConformity] = React.useState<string>();
  const { getConformitiesOfCertification } = useComplianceInfo();

  const onChangeCertification = (
    value: string | undefined,
    name: string | undefined
  ) => {
    handleChange("", "conformity_id");
    handleChange(value, name);
  };

  React.useEffect(() => {
    getConformities();
  }, [buildingSettingsStore.buildingFormDetails["certification_id"]]);

  React.useEffect(() => {
    setCurrentConformity(
      buildingSettingsStore.buildingFormDetails["conformity_id"]
    );
  }, [buildingSettingsStore.buildingFormDetails["conformity_id"]]);

  const getConformities = () => {
    if (
      !buildingSettingsStore.buildingFormDetails["certification_id"] ||
      buildingSettingsStore.buildingFormDetails["conformity_id"]
    )
      return;
    const currentItems = getConformitiesOfCertification(
      buildingSettingsStore.buildingFormDetails["certification_id"]
    );
    if (currentItems.length === 1) {
      handleChange(currentItems[0].id, "conformity_id");
    } else {
      handleChange("", "conformity_id");
    }
  };

  const isInProgress =
    buildingStore.isRecalculationInProgress(currentBuilding?.id) &&
    type === "edit";

  const moduleString = t(
    `commons.${getModuleStringTotal(
      buildingStore.getBuildingCertificationName(
        buildingSettingsStore.buildingFormDetails["certification_id"]
      )
    )}`
  );

  return (
    <>
      <div className="text-xl font-semibold mt-5 mb-2 border-t pt-4">
        {t("buildingPassport.complianceSettings")}
      </div>
      <Alert
        type="info"
        description={
          isInProgress
            ? t("buildingPassport.complianceAlertPending")
            : t("buildingPassport.complianceAlert")
        }
      />
      <div className="grid grid-cols-2 pt-4 gap-4">
        <div>
          <SimpleSelect
            options={ifcMappingStore.certificationOptions as []}
            labelName={t("buildingPassport.aspiredCertification")}
            placeholder={t("buildingPassport.aspiredCertification")}
            containerClassName="w-full"
            noMargin
            required
            onChange={onChangeCertification}
            name="certification_id"
            current={
              buildingSettingsStore.buildingFormDetails["certification_id"]
            }
            disable={isInProgress}
            testID="certification_select"
          />
          <div className="text-gray-500 text-sm">
            {t("buildingPassport.aspiredCertificationSubtitle")}
          </div>
        </div>
        <div>
          <SimpleSelect
            options={getConformitiesOfCertification(
              buildingSettingsStore.buildingFormDetails["certification_id"]
            )}
            labelName={t("buildingPassport.conformity")}
            placeholder={t("buildingPassport.conformity")}
            containerClassName="w-full"
            noMargin
            onChange={handleChange}
            name="conformity_id"
            current={currentConformity}
            showMandatoryAlert={buildingSettingsStore.showMandatoryAlert}
            required
            disable={isInProgress}
            testID="conformity_select"
          />
          <div className="text-gray-500 text-sm">
            {t("buildingPassport.conformitySubtitle")}
          </div>
        </div>
      </div>
      <Alert
        type="info"
        description={t("buildingPassport.moduleAlert", {
          moduleString: moduleString,
        })}
        className="mt-4"
      />
    </>
  );
});
