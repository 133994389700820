import { ExportCertificationIN, buildingsApi } from "api-client";
import { makeAutoObservable } from "mobx";
import { getLocalAuthHeader } from "api-client-local/utils";
import { ifcMappingStore } from "./IfcMapping/IFCMappingStore";

export interface ApiResponse {
  success: boolean;
  message: {
    message: string;
  };
}

export type DGNBVersionValue = {
  id: string;
  value: string;
  value_de: string;
  isChecked: boolean;
};

export type AdditionalInfo = {
  buildingType: string;
  levelOfRequirements?: string;
  buildingClass?: string;
  energeticStandard?: string;
};

export type CertExporter = {
  certSystem: string | undefined;
  dgnbVersion: string | undefined;
  dgnbVersionValues?: DGNBVersionValue[];
  additionalInfo: AdditionalInfo;
};

export class CertExportStore {
  activeStep = 0;
  additionalInfo: AdditionalInfo = {
    buildingType: "",
    levelOfRequirements: "",
    buildingClass: "",
    energeticStandard: "",
  };
  certExporter: CertExporter = {
    certSystem: "",
    dgnbVersion: "",
    dgnbVersionValues: this.initDgnbVersionValues,
    additionalInfo: this.additionalInfo,
  };
  base_amounts_matrix_key = "";
  certification_id = "";
  conformity_id = "";

  setCertAndConformityId(certName: string) {
    if (!ifcMappingStore.certificationOptions?.length) {
      throw Error("Certification options are not available");
    }

    const foundCert = ifcMappingStore.certificationOptions.find(
      (co) => co.name === certName
    );

    this.setCertificationId(foundCert?.id ?? "");
    this.setConformityId(foundCert?.conformities[0].id ?? "");

    console.log(
      "ids",
      "cert: " + this.certification_id + " - conf: " + this.conformity_id
    );
  }

  setCertificationId(certification_id: string) {
    this.certification_id = certification_id;
  }

  setConformityId(conformity_id: string) {
    this.conformity_id = conformity_id;
  }

  get initDgnbVersionValues(): DGNBVersionValue[] {
    return [
      {
        id: "version-lca",
        value: "Use simplified version of LCA",
        value_de: "Vereinfachte Version der Ökobilanz verwenden",
        isChecked: false,
      },
      {
        id: "version-tec-1-4",
        value: "Reduce TGA factor to 1.1 (TEC 1.4)",
        value_de: "Reduzierung des TGA-Faktors auf 1,1 (TEC 1,4)",
        isChecked: false,
      },
    ];
  }

  get shouldShowBackButton(): boolean {
    if (this.activeStep === 0 || this.activeStep === 4) return false;

    if (this.activeStep > 0 && this.activeStep !== 4) return true;

    return true;
  }

  steps: string[] = [
    "Certification system",
    "DGNB Version",
    "Configure DGNB Version export",
    "Additional Information",
    "Create Excel file",
  ];

  constructor() {
    makeAutoObservable(this);
  }

  handleCheckboxChange = (id: string) => {
    this.certExporter.dgnbVersionValues =
      this.certExporter.dgnbVersionValues?.map((checkbox) =>
        checkbox.id === id
          ? { ...checkbox, isChecked: !checkbox.isChecked }
          : checkbox
      );

    this.certExporter = {
      ...this.certExporter,
      dgnbVersionValues: [...(this.certExporter.dgnbVersionValues ?? [])],
    };
  };

  setAdditionalInfoFieldAndValue(
    field: keyof AdditionalInfo,
    value: string | undefined
  ) {
    this.additionalInfo = {
      ...this.additionalInfo,
      [field]: value,
    };
    this.certExporter = {
      ...this.certExporter,
      additionalInfo: this.additionalInfo,
    };
  }

  setCertExportFieldAndValue(
    field: keyof CertExporter,
    value: string | undefined | DGNBVersionValue[]
  ) {
    this.certExporter = {
      ...this.certExporter,
      [field]: value,
    };
  }

  handleOnDropdownChange(
    field: keyof AdditionalInfo,
    items: {
      id: string;
      name: string;
      name_de?: string;
    }[],
    value: string | undefined
  ) {
    if (value) {
      const selectedValue = items.find((ct) => ct.id === value);
      this.setAdditionalInfoFieldAndValue(
        field,
        selectedValue?.name ?? selectedValue?.name_de
      );
    }
  }

  // logData() {
  //   console.log("Cert Exporter:: ", JSON.stringify(this.certExporter));
  // }

  setCertExporter(certExporter: CertExporter) {
    this.certExporter = certExporter;
  }

  setActiveStep(step: number) {
    this.activeStep = step;
  }

  resetData() {
    this.activeStep = 0;
    this.additionalInfo = {
      buildingClass: "",
      buildingType: "",
      energeticStandard: "",
      levelOfRequirements: "",
    };
    this.certExporter = {
      certSystem: "",
      dgnbVersion: "",
      dgnbVersionValues: this.initDgnbVersionValues,
      additionalInfo: this.additionalInfo,
    };
  }

  getBaseAmountMatrixKey() {
    if (this.additionalInfo.buildingType.includes("Residential")) {
      this.base_amounts_matrix_key =
        this.additionalInfo.energeticStandard?.includes("< 40")
          ? "residential_under_40"
          : "residential_over_40";
      return;
    }

    const buildingClass = this.additionalInfo.buildingClass;
    if (this.additionalInfo.levelOfRequirements?.includes("PLUS")) {
      if (
        buildingClass?.includes("K1") ||
        buildingClass?.includes("K4") ||
        buildingClass?.includes("K5")
      ) {
        this.base_amounts_matrix_key = "non_residential_k1_k4_k5_plus";
      } else if (
        buildingClass?.includes("K2") ||
        buildingClass?.includes("K3")
      ) {
        this.base_amounts_matrix_key = "non_residential_k2_k3_plus";
      } else {
        this.base_amounts_matrix_key = "non_residential_k6_plus";
      }
    } else if (
      buildingClass?.includes("K1") ||
      buildingClass?.includes("K4") ||
      buildingClass?.includes("K5")
    ) {
      this.base_amounts_matrix_key = "non_residential_k1_k4_k5_premium";
    } else if (buildingClass?.includes("K2") || buildingClass?.includes("K3")) {
      this.base_amounts_matrix_key = "non_residential_k2_k3_premium";
    } else {
      this.base_amounts_matrix_key = "non_residential_k6_premium";
    }
  }

  handleErrors(bId: string) {
    if (!bId) {
      throw Error("Building id is not available!");
    }

    if (!this.certification_id) {
      throw Error("Certification id not found!");
    }

    if (!this.conformity_id) {
      throw Error("Conformity id not found!");
    }
  }

  async createCertificationExporterFromApi(buildingId: string) {
    const authHeader = await getLocalAuthHeader();

    if (!buildingId || !this.certification_id || !this.conformity_id) {
      return this.handleErrors(buildingId);
    }

    if (certExportStore.certExporter.dgnbVersion?.includes("2018")) {
      const options = {
        use_simplified_version_lca:
          this.certExporter.dgnbVersionValues?.[0].isChecked ?? false,
        reduce_tga_factor_to_1_1:
          this.certExporter.dgnbVersionValues?.[1].isChecked ?? false,
        base_amounts_matrix_key: "",
      };

      const exportCertificationIN: ExportCertificationIN = {
        certification_id: this.certification_id,
        conformity_id: this.conformity_id,
        options: options,
      };
      console.log("exportCertificationIN: ", exportCertificationIN);
      return await buildingsApi.concularApiV1RoutersBuildingsExportCertification(
        buildingId,
        exportCertificationIN,
        authHeader
      );
    }

    this.getBaseAmountMatrixKey();
    console.log("this.base_amounts_matrix_key: ", this.base_amounts_matrix_key);
    const options = {
      use_simplified_version_lca: false,
      reduce_tga_factor_to_1_1: false,
      base_amounts_matrix_key: this.base_amounts_matrix_key,
    };
    const exportCertificationIN: ExportCertificationIN = {
      certification_id: this.certification_id,
      conformity_id: this.conformity_id,
      options: options,
    };
    console.log("exportCertificationIN: ", exportCertificationIN);
    return await buildingsApi.concularApiV1RoutersBuildingsExportCertification(
      buildingId,
      exportCertificationIN,
      authHeader
    );
  }

  get isFirstStep() {
    return this.activeStep === 0;
  }

  static instance: CertExportStore;

  static getInstance(): CertExportStore {
    if (!CertExportStore.instance) {
      CertExportStore.instance = new CertExportStore();
    }
    return CertExportStore.instance;
  }
}

export const certExportStore = CertExportStore.getInstance();
