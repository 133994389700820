import React, { useEffect } from "react";
import { IconArrowRight, IconSettings } from "@tabler/icons-react";
import { ColumnDef, SortingState } from "@tanstack/react-table";
import Button from "components/Button";
import Heading from "components/Heading";
import SearchSortTable from "components/Table";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import InventoryTableColumns from "./InventoryTableColumns";
import { inventoryStore } from "store/InventoryStore";
import { observer } from "mobx-react-lite";
import { useInventory } from "./useInventory";
import useFeatureFlag from "hooks/useFeatureFlag";
import CustomizeColumns from "./CustomizeColumns";
import { skeleton } from "utils";
import { buildingStore } from "store/Building/BuildingStore";
import EmptyState from "components/EmptyState";

const DEFAULT_SORT = [
  {
    id: "object_name",
    desc: false,
  },
];

const BuildingInventory = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { materialResources } = buildingStore;
  const [initialSetupLoading, setInitialSetupLoading] = React.useState(true);
  const [openSettings, setOpenSettings] = React.useState(false);
  const { currentBuilding } = buildingStore;
  const { getColumnsAndStates, getColumnsVisibility, getDebugColumns } =
    useInventory();
  const { isFeatureVisible } = useFeatureFlag();

  const fetchData = async () => {
    if (currentBuilding?.id && initialSetupLoading) {
      inventoryStore.setBuildingId(currentBuilding?.id);
      await getColumnsAndStates();
      await inventoryStore.fetchColumnData(inventoryStore.columnFilters);
      setInitialSetupLoading(false);
    }
  };

  useEffect(() => {
    getDebugColumns();
  }, []);

  useEffect(() => {
    (async () => await fetchData())();
  }, [currentBuilding?.id]);

  useEffect(() => {
    if (initialSetupLoading) return;
    (async () => {
      inventoryStore.setLoadingMore(true);
      await inventoryStore.fetchColumnData(inventoryStore.columnFilters);
      inventoryStore.setLoadingMore(false);
    })();

    !isFeatureVisible("inventory_customize_columns") &&
      localStorage.removeItem("inventory_settings");
  }, [inventoryStore.columnFilters, inventoryStore.sorting]);

  const handleSortChanged = (e: SortingState) => inventoryStore.setSorting(e);

  const fetchLoadMoreData = async (start: number) => {
    if (inventoryStore.loadingMore) return;
    inventoryStore.setOffset(start);
    await inventoryStore.loadMoreData(inventoryStore.columnFilters);
  };

  function buttons() {
    if (initialSetupLoading || inventoryStore.filterLoading) return <></>;
    return (
      <div className="flex">
        {isFeatureVisible("inventory_customize_columns") ? (
          <Button
            type="secondary"
            leadingIcon={<IconSettings width={20} />}
            width="fit-content"
            className="ml-4"
            onClick={() => setOpenSettings(true)}
            testID="customizecolumns_button"
            disable={buildingStore.isRecalculationInProgress(
              currentBuilding?.id
            )}
          >
            {t("buildingInventory.customizeColumns")}
          </Button>
        ) : null}
        <Button
          type="primary"
          trailingIcon={<IconArrowRight width={20} />}
          width="fit-content"
          className="ml-4"
          onClick={() => navigate("../material-resources/", { replace: true })}
          testID="manageinventory_button"
        >
          {t("buildingInventory.manageInventory")}
        </Button>
      </div>
    );
  }

  function content() {
    if (!buildingStore.buildingMappingStatus.hasReport)
      return (
        <EmptyState
          type="inventory"
          loading={buildingStore.isRecalculationInProgress(currentBuilding?.id)}
        />
      );

    return (
      <div>
        <div className="md:flex justify-between mb-3">
          <div>
            <Heading size="title">{t("buildingInventory.inventory")}</Heading>
            <Heading size="subtitle">{t("buildingInventory.subtitle")}</Heading>
          </div>
          {buttons()}
        </div>
        {buildingStore.isRecalculationInProgress(currentBuilding?.id) ? (
          <EmptyState
            type="inventory"
            loading={
              buildingStore.isRecalculationInProgress(currentBuilding?.id) ||
              inventoryStore.filterLoading
            }
          />
        ) : (
          <SearchSortTable
            getColumns={
              InventoryTableColumns as () => ColumnDef<unknown, unknown>[]
            }
            enableColumnResizing
            borderedStyle
            data={inventoryStore.data}
            hasGroupHeader
            totalDBRowCount={inventoryStore.totalCount}
            dataFetchLoading={inventoryStore.loadingMore}
            fetchData={fetchLoadMoreData}
            scrollDivClassName="scrollbar"
            zebraStriped
            hiddenColumns={getColumnsVisibility()}
            fetchSize={inventoryStore.limit}
            onSortChanged={handleSortChanged}
            enableTooltip
            defaultSort={DEFAULT_SORT}
          />
        )}
      </div>
    );
  }

  return (
    <div className="p-6">
      {materialResources.loading || initialSetupLoading
        ? skeleton("h-80")
        : content()}

      <CustomizeColumns
        open={openSettings}
        close={() => setOpenSettings(false)}
      />
    </div>
  );
});

export default BuildingInventory;
