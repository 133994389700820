import DashboardActiveFiles, {
  BaseDashboardProps,
} from "./DashboardActiveFiles";

const TABLE_NAME = "public concular_building";

const DashboardCircularity = (props: BaseDashboardProps) => {
  const reportID = process.env.REACT_APP_POWER_BI_CIRCULARITY_DASHBOARD_ID;

  return (
    <DashboardActiveFiles
      reportID={reportID}
      containerClassName={props.containerClassName}
      buildingsTable={TABLE_NAME}
      filesTable={TABLE_NAME}
    />
  );
};

export default DashboardCircularity;
