import React from "react";
import { IconPlus } from "@tabler/icons-react";
import Button from "components/Button";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { searchStore } from "store/IfcMapping/SearchStore";
import { componentsStore } from "store/IfcMapping/ComponentsStore";
import { useMappingTools } from "features/MappingTools/useMappingTools";

function EmptyStateButtons({
  enableProductEditorCreation,
}: {
  enableProductEditorCreation: boolean;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { openBuildingProductEditor } = useMappingTools();

  const openComponentEditor = () =>
    navigate(`${location.pathname}/component-editor`);

  if (
    componentsStore.openedSearchInCE &&
    searchStore.selectedTab.type === "COMPONENT"
  )
    return <></>;
  return (
    <div className="mt-4 flex gap-4">
      {searchStore.selectedTab.type === "PRODUCT" ? (
        <Button
          width="fit-content"
          type="primary"
          leadingIcon={<IconPlus />}
          onClick={openBuildingProductEditor}
        >
          {t("mapping.createBuildingProduct")}
        </Button>
      ) : (
        <Button
          width="fit-content"
          type="primary"
          leadingIcon={<IconPlus />}
          onClick={openComponentEditor}
        >
          {t("mapping.createComponent")}
        </Button>
      )}
      {enableProductEditorCreation ? (
        <Button
          width="fit-content"
          type="secondary"
          leadingIcon={<IconPlus />}
          onClick={openBuildingProductEditor}
        >
          {t("mapping.createBuildingProduct")}
        </Button>
      ) : null}
    </div>
  );
}

export default observer(EmptyStateButtons);
