import React from "react";
import { IconCube, IconSparkles } from "@tabler/icons-react";
import {
  InventoryCategoryAggregateOut,
  InventoryPhysicalObjectOut,
  InventoryProductDetailsByFileOut,
  ProductSearchOutput,
} from "api-client";
import { DropdownOption } from "components/DropdownCheckbox";
import { useTranslation } from "react-i18next";
import { CustomProduct } from "store/IfcMapping/BuildingProductStore";
import { mappingEditorStore } from "store/IfcMapping/MEStore";
import { useNavigate, useParams } from "react-router";
import { ifcMappingStore } from "store/IfcMapping/IFCMappingStore";
import { searchStore } from "store/IfcMapping/SearchStore";
import { componentsStore } from "store/IfcMapping/ComponentsStore";
import { COMPONENT_DATA_LOCAL_KEY, SEARCH_TABS_LIST } from "./consts";

export const useMappingTools = () => {
  const { t } = useTranslation();
  const { ifc_id } = useParams();
  const navigate = useNavigate();

  const mappingOptions = [
    {
      name: t("mapping.object"),
      name_de: t("mapping.object"),
      id: "object",
      icon: <IconCube width={20} className="mr-2" />,
    },
    {
      name: "Material",
      name_de: "Material",
      id: "material",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-5 h-5 mr-2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3"
          />
        </svg>
      ),
    },
  ];

  const groupByOptions: DropdownOption[] = [
    {
      id: "component",
      name: t("mapping.objectName"),
      name_de: t("mapping.objectName"),
    },
    {
      id: "ifc_entity",
      name: t("mapping.ifcClass"),
      name_de: t("mapping.ifcClass"),
    },
    {
      id: "material",
      name: t("mapping.material"),
      name_de: t("mapping.material"),
    },
  ];

  const mappingStatusOptions: DropdownOption[] = [
    {
      id: "AUTO",
      name: t("mapping.statusOptions.auto.name"),
      icon: <IconSparkles className="w-4" />,
      title: t("mapping.statusOptions.auto.title"),
    },
    {
      id: "MANUAL",
      name: t("mapping.statusOptions.manual.name"),
      icon: <span className="rounded bg-green-500 w-2 h-2 block" />,
      title: t("mapping.statusOptions.manual.title"),
    },
    {
      id: "PENDING",
      name: t("mapping.statusOptions.pending.name"),
      title: t("mapping.statusOptions.pending.title"),
    },
  ];

  const productAdditionalInfo = (
    product:
      | ProductSearchOutput
      | InventoryProductDetailsByFileOut
      | undefined
      | CustomProduct,
    tags?: boolean
  ) => [
    {
      label: `DIN 276 ${t("mapping.category")}`,
      value: (product as ProductSearchOutput)?.din_category
        ? `${(product as ProductSearchOutput)?.din_category_number ?? ""} ${
            (product as ProductSearchOutput)?.din_category ?? "-"
          }`
        : `${
            (product as InventoryProductDetailsByFileOut)
              ?.din_category_level_1_number ?? ""
          } ${
            (product as InventoryProductDetailsByFileOut)
              ?.din_category_level_1 ?? "-"
          }`,
    },
    {
      label: t("mapping.lifeSpan"),
      value: product?.life_span ?? "-",
    },
    {
      label: t("mapping.isExternal"),
      value:
        typeof product?.is_external === "boolean"
          ? t(`mapping.${String(product?.is_external).toLowerCase()}`)
          : "-",
    },
    {
      label: t("mapping.loadBearing"),
      value:
        typeof product?.is_load_bearing === "boolean"
          ? t(`mapping.${String(product?.is_load_bearing).toLowerCase()}`)
          : "-",
    },
    ...(tags
      ? [
          {
            label: t("mapping.tags_automapper"),
            value:
              (product as InventoryProductDetailsByFileOut)?.automapping_tags
                ?.map((item) => item)
                .join(", ") || "-",
          },
        ]
      : []),
  ];

  const mappingMatch = async (
    product_id: string,
    current_row?: InventoryCategoryAggregateOut
  ): Promise<InventoryPhysicalObjectOut[] | undefined> => {
    const currentItem =
      current_row ??
      (mappingEditorStore.currentRow.row?.original as
        | InventoryCategoryAggregateOut
        | undefined);

    if (!currentItem?.id) return;
    let response;
    if (!ifc_id) return;
    mappingEditorStore.setMappingMatchLoading(true);
    if (currentItem?.total_objects && currentItem.total_objects > 1) {
      const groupBy = mappingEditorStore.groupBy;
      if (!groupBy) return;
      response = await ifcMappingStore.matchGroup(
        ifc_id,
        groupBy.join(",") || "",
        "",
        "",
        groupBy.find((item) => item === "component") &&
          (currentItem.component as string),
        groupBy.find((item) => item === "material") &&
          (currentItem.material as string),
        groupBy.find((item) => item === "ifc_entity") &&
          (currentItem.ifc_entity as string),
        product_id
      );
    } else {
      response = await ifcMappingStore.matchObject(
        ifc_id,
        currentItem.id,
        product_id
      );
    }
    mappingEditorStore.setMappingMatchLoading(false);
    return response;
  };

  function onCloseEditor(tabIndex: number, shouldCloseSearch?: boolean) {
    navigate(-1);
    if (shouldCloseSearch) {
      searchStore.setOpenProductSearch(false);
      componentsStore.setOpenedSearchInCE(false);
    } else {
      searchStore.setSelectedTab(SEARCH_TABS_LIST[tabIndex]);
      searchStore.setOpenProductSearch(true);
    }
  }

  const openBuildingProductEditor = () => {
    if (componentsStore.openedSearchInCE) {
      localStorage.setItem(
        COMPONENT_DATA_LOCAL_KEY,
        JSON.stringify({
          data: componentsStore.data,
          step: componentsStore.activeStep,
        })
      );
    }
    navigate(
      `${location.pathname.replace("/component-editor", "")}/product-editor`
    );
  };

  return {
    mappingMatch,
    mappingOptions,
    groupByOptions,
    mappingStatusOptions,
    productAdditionalInfo,
    onCloseEditor,
    openBuildingProductEditor,
  };
};
