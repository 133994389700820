import React from "react";
import DynamicMappingEditor from "features/MappingTools/DynamicMappingEditor";
import MappingEditorHeader from "features/MappingTools/DynamicMappingEditor/layout/MappingEditorHeader";
import MappingEditorFooter from "features/MappingTools/DynamicMappingEditor/layout/MappingEditorFooter";

const MappingEditorPage = () => {
  return (
    <div className="fixed top-0 left-0 bg-white w-screen min-h-screen max-h-min z-20">
      <MappingEditorHeader />
      <DynamicMappingEditor />
      <MappingEditorFooter />
    </div>
  );
};

export default MappingEditorPage;
