import React from "react";
import { useTranslation } from "react-i18next";
import LCPReport from "./LCPTiles/LCPReports";
import Heading from "components/Heading";
import LifeCycleTile from "./LCPTiles/LifeCycleTile";
import BuildingDataTile from "./LCPTiles/BuildingDataTile";
import LocationTile from "./LCPTiles/LocationTile";
import { buildingStore } from "store/Building/BuildingStore";
import { observer } from "mobx-react-lite";

const BuildingDetail = observer(() => {
  const { t } = useTranslation();
  const { currentBuilding } = buildingStore;

  return (
    <>
      {currentBuilding?.name ? (
        <div className="pb-10 p-4 md:p-5">
          <Heading size={"title"}>
            {t("buildingPassport.buildingInformation")}
          </Heading>
          <div className="md:grid grid-cols-1 md:grid-cols-6 gap-4 flex-wrap pt-2">
            <LifeCycleTile />
            <BuildingDataTile />
            <LocationTile />
          </div>
          <LCPReport />
        </div>
      ) : (
        skeleton()
      )}
    </>
  );
});

export default React.memo(BuildingDetail);

function skeleton() {
  return (
    <div className="animate-pulse p-5">
      <div className="md:grid grid-cols-1 md:grid-cols-6 gap-4 flex-wrap w-full mt-11">
        <div className="h-[175px] bg-gray-200 rounded-lg dark:bg-gray-700 w-full mb-4 col-span-2"></div>
        <div className="h-[175px] bg-gray-200 rounded-lg dark:bg-gray-700 w-full mb-4 col-span-3"></div>
        <div className="h-[175px] bg-gray-200 rounded-lg dark:bg-gray-700 w-full mb-4 col-span-1"></div>
      </div>
      <div>
        <div className="h-56 w-full bg-gray-200 rounded-lg dark:bg-gray-700 mb-4"></div>
      </div>
    </div>
  );
}
