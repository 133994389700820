import React from "react";
import { observer } from "mobx-react-lite";
import {
  InventoryCategoryAggregateOut,
  InventoryPhysicalObjectDetailOut,
} from "api-client";
import { MaterialFileObject } from "store/IfcMapping/types";
import SlideOver from "components/SlideOver";
import ProductSearch from "../ProductSearch";
import { detailViewV2Store } from "store/IfcMapping/DetailViewV2Store";
import MappedProduct from "./MappedProduct";
import ButtonGroups, { BGItem } from "components/ButtonGroups";
import DetailViewV2Contents from "./DetailsViewContents";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { detailViewSkeleton } from "../utils";
import Tabs from "components/Tabs";
import EmptyState from "components/EmptyState";
import Button from "components/Button";
import { IconExternalLink } from "@tabler/icons-react";
import { NO_OBJECT_DATA_DOCS_URL } from "../consts";

interface DVV2Props {
  open: boolean;
  close: () => void;
  physicalObjectDetails: InventoryPhysicalObjectDetailOut[];
  unmatch?: () => Promise<void>;
  refreshAfterMatching?: (item: MaterialFileObject) => void;
}

type TabIds = "info-tab" | "reports-tab" | "ifc-properties";

let tabItems: BGItem[] = [
  { id: "info-tab", name: "Info", name_de: "Info" },
  { id: "reports-tab", name: "Reports", name_de: "Auswertungen" },
  {
    id: "ifc-properties",
    name: "IFC-properties",
    name_de: "IFC-Eigenschaften",
  },
];

const DetailsView = ({
  open,
  close,
  physicalObjectDetails,
  unmatch,
  refreshAfterMatching,
}: DVV2Props) => {
  const [openSearch, setOpenSearch] = React.useState<boolean>(false);
  const [selectedTreeView, setSelectedTreeView] = React.useState<BGItem>(
    tabItems[0]
  );

  const location = useLocation();
  const { t } = useTranslation();

  const fetchDataAsync = async () => {
    if (physicalObjectDetails?.length && open) {
      await detailViewV2Store.fetchDetailViewData();
    }
  };

  React.useEffect(() => {
    if (open) {
      detailViewV2Store.resetDetailViewData();
      detailViewV2Store.setObjectId(physicalObjectDetails?.[0]?.id);
      handleOnChangeTab(tabItems[0]);
    }
  }, [
    physicalObjectDetails?.[0]?.product_match,
    physicalObjectDetails?.[0]?.id,
    open,
  ]);

  React.useEffect(() => {
    if (!String(location.state).toUpperCase().includes(".IFC")) {
      tabItems = tabItems.filter((item) => item.id !== "ifc-properties");
    } else if (!tabItems.find((item) => item.id === "ifc-properties")) {
      tabItems.push({
        id: "ifc-properties",
        name: "IFC-properties",
        name_de: "IFC-Eigenschaften",
      });
    }
  }, [location?.state]);

  const rootViewRef = React.useRef<HTMLDivElement>(null);
  const scrollToTop = () => rootViewRef?.current?.scrollTo({ top: 0 });

  const handleOnChangeTab = (e?: BGItem) => {
    if (!e) return;
    scrollToTop();
    setSelectedTreeView(e);
    switch (e.id as TabIds) {
      case "info-tab":
        detailViewV2Store.setCurrentIndex(0);
        break;

      case "reports-tab":
        detailViewV2Store.setCurrentIndex(1);
        break;

      case "ifc-properties":
        detailViewV2Store.setCurrentIndex(2);
        break;

      default:
        detailViewV2Store.setCurrentIndex(0);
        break;
    }
    detailViewV2Store.setContentBasedOnTabIndex();
  };

  const [multiObjectsTabIndex, setMultiObjectsTabIndex] = React.useState(0);
  const handleOnChange = (index: number) => {
    setMultiObjectsTabIndex(index);
    handleOnChangeTab(tabItems[0]);
    detailViewV2Store.setCurrentIndex(0);
    detailViewV2Store.setContentBasedOnTabIndex();
    detailViewV2Store.setObjectId(physicalObjectDetails[index].id);
    detailViewV2Store.resetOnObjectTabChange();
    fetchDataAsync();
  };

  function buttonGroups(isObjectTabs: boolean) {
    return (
      <div
        className={`sticky ${
          isObjectTabs ? "top-[112px]" : "top-[111px]"
        } z-30 pt-2 pb-4 bg-white`}
      >
        <ButtonGroups
          disabled={detailViewV2Store.loading}
          containerClassName="cursor-pointer"
          items={tabItems}
          onChange={handleOnChangeTab}
          current={selectedTreeView}
        />
      </div>
    );
  }

  function mappedProductContents() {
    return (
      <MappedProduct
        className="border-b-0"
        inputClassName={"bg-white"}
        label={t("detailView.infoTab.mappedBuildingProduct")}
        product={physicalObjectDetails[0]?.product_match}
        object={physicalObjectDetails[0]}
        onClickSearch={() => setOpenSearch?.(true)}
        unmatch={unmatch}
        showTooltip={false}
      />
    );
  }

  function detailListContentWithTab(): React.ReactNode {
    const tabOptions =
      physicalObjectDetails.map((rec: InventoryPhysicalObjectDetailOut) => ({
        item: rec.material as string,
        content: (
          <>
            <div className="bg-gray-50 border-b border-t p-4 sticky top-0 z-30">
              {mappedProductContents()}
            </div>
            <div className="px-4">
              {buttonGroups(true)}
              <DetailViewV2Contents
                physicalObjectDetails={rec}
                onReloadReportTiles={fetchDataAsync}
              />
            </div>
          </>
        ),
      })) ?? [];

    return (
      <Tabs
        currentIndex={multiObjectsTabIndex}
        options={tabOptions}
        listClassName={
          "h-[52px] px-3 pl-5 border-b border-gray-300 bg-white z-30"
        }
        containerClassName="!px-0 border-none !relative"
        onChange={handleOnChange}
        showArrows
      />
    );
  }

  function content() {
    if (physicalObjectDetails.length > 1) return detailListContentWithTab();
    if (physicalObjectDetails.length === 1)
      return (
        <div>
          <div className="bg-gray-50 border-b p-4 sticky top-0 z-30">
            {mappedProductContents()}
          </div>
          <div className="px-4">
            {buttonGroups(false)}
            {detailViewV2Store.loading
              ? detailViewSkeleton()
              : physicalObjectDetails.length === 1 && (
                  <DetailViewV2Contents
                    physicalObjectDetails={physicalObjectDetails?.[0]}
                    onReloadReportTiles={fetchDataAsync}
                  />
                )}
          </div>
        </div>
      );
    return (
      <EmptyState
        type="data"
        noBorder
        title={t("emptyState.objectDataNotAvailable")}
        alertText={t("emptyState.objectDataNotAvailableAlert")}
        alertType="info"
        button={
          <Button
            type="secondary"
            width="fit-content"
            trailingIcon={<IconExternalLink />}
            onClick={() =>
              window.open(
                NO_OBJECT_DATA_DOCS_URL,
                "_blank",
                "noopener,noreferrer"
              )
            }
          >
            {t("emptyState.showDocs")}
          </Button>
        }
        containerClassName="h-full"
      />
    );
  }

  return (
    <>
      <SlideOver
        open={open}
        close={close}
        containerClassName={`sm:top-[69px] w-[480px] shadow-slate-400`}
        title={
          <span className="break-all !w-[300px]">
            {physicalObjectDetails.length
              ? physicalObjectDetails[0].component
              : ""}
          </span>
        }
        className={"!p-0"}
      >
        <div
          ref={rootViewRef}
          className="overflow-y-auto max-h-[calc(100vh-120px)] relative z-30 h-full"
        >
          {content()}
        </div>
      </SlideOver>
      {openSearch && (
        <ProductSearch
          open={openSearch}
          close={() => setOpenSearch(false)}
          currentRow={
            physicalObjectDetails?.[0] as unknown as InventoryCategoryAggregateOut
          }
          refresh={refreshAfterMatching}
        />
      )}
    </>
  );
};

export default observer(DetailsView);
