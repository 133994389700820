import DropdownCheckbox, { DropdownOption } from "components/DropdownCheckbox";
import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { searchStore } from "store/IfcMapping/SearchStore";

export default observer(function DINCategoryFilter({
  selected,
  setSelected,
  removingItem,
  handleRemoveItem,
}: {
  selected?: DropdownOption[];
  setSelected: React.Dispatch<React.SetStateAction<DropdownOption[]>>;
  removingItem: DropdownOption | undefined;
  handleRemoveItem: () => void;
}) {
  const { t } = useTranslation();

  const onChange = (value: DropdownOption[]) => setSelected(value);

  React.useEffect(() => {
    (async () => {
      await searchStore.getDinCategories();
    })();
  }, []);

  if (!searchStore.filtersOptions.DIN_categories) return <></>;
  return (
    <DropdownCheckbox<DropdownOption>
      displayKey={"name"}
      required={true}
      handleSelect={onChange}
      labelName={`DIN 276 ${t("mapping.categories")}`}
      name="DIN_categories"
      items={searchStore.filtersOptions.DIN_categories}
      removeSelected={removingItem}
      handleRemoveSelected={handleRemoveItem}
      containerClassName="mr-0 sm:mr-3 min-w-[146px]"
      checkedItems={selected}
      optionsClassName="!max-w-fit"
      noMargin
    />
  );
});
