import {
  // Icon3dCubeSphere,
  // IconAlertCircle,
  IconCircle,
  IconCircleCheck,
  // IconCircleX,
  // IconFilter,
  // IconPlayerPlay,
  // IconSearch,
  // IconSparkles,
} from "@tabler/icons-react";
import { Row } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";

const useMappingEditor = () => {
  const { t } = useTranslation();

  const checkRow = (row?: Row<unknown>) => {
    if (!row) return;
    const selectedIndexes = [
      ...dynamicMEStore.selectedRowIndexes,
      String(row?.index),
    ].sort();
    dynamicMEStore.setSelectedRows(Array.from(new Set(selectedIndexes)));
  };

  const unCheckRow = (row?: Row<unknown>) => {
    if (!row) return;
    const selectedIndexes = dynamicMEStore.selectedRowIndexes.filter(
      (item) => item != String(row.index)
    );
    dynamicMEStore.setSelectedRows(selectedIndexes);
  };

  const contextMenuOptions = [
    {
      title: t("mappingEditor.checkProductLink"),
      options: [
        {
          label: t("mappingEditor.markAsChecked"),
          onClick: checkRow,
          icon: <IconCircleCheck size={18} />,
        },
        {
          label: t("mappingEditor.markAsNotChecked"),
          onClick: unCheckRow,
          icon: <IconCircle size={18} />,
        },
      ],
    },
    // {
    //   title: t("mappingEditor.defineRelevance"),
    //   options: [
    //     {
    //       label: t("mappingEditor.markAsRelevant"),
    //       onClick: checkRow,
    //       icon: <IconAlertCircle size={18} />,
    //     },
    //     {
    //       label: t("mappingEditor.markAsNotRelevant"),
    //       onClick: checkRow,
    //       icon: <IconCircleX size={18} />,
    //     },
    //   ],
    // },
    // {
    //   title: t("mappingEditor.performProductLinking"),
    //   options: [
    //     {
    //       label: t("mappingEditor.linkRegularlyBased"),
    //       onClick: checkRow,
    //       icon: <IconPlayerPlay size={18} />,
    //     },
    //     {
    //       label: t("mappingEditor.linkAIBased"),
    //       onClick: checkRow,
    //       icon: <IconSparkles size={18} />,
    //     },
    //     {
    //       label: t("mappingEditor.linkManually"),
    //       onClick: checkRow,
    //       icon: <IconSearch size={18} />,
    //     },
    //   ],
    // },
    // {
    //   title: t("mappingEditor.miscellaneous"),
    //   options: [
    //     {
    //       label: t("mappingEditor.highlightIn3DView"),
    //       onClick: checkRow,
    //       icon: <Icon3dCubeSphere size={18} />,
    //     },
    //     {
    //       label: t("mappingEditor.preFilterInTable"),
    //       onClick: checkRow,
    //       icon: <IconFilter size={18} />,
    //     },
    //   ],
    // },
  ];

  return { contextMenuOptions };
};

export default useMappingEditor;
