import { models } from "powerbi-client";

export const create_default_file_filter = (table: string, values: string[]) => {
  const filterDefaultID = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: table,
      column: "input_file_id",
    },
    operator: "In",
    filterType: models.FilterType.Basic,
    values: values,
  };

  return filterDefaultID;
};

export const create_module_filter = (table: string, values: string[]) => {
  const filterDefaultID = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: table,
      column: "name",
    },
    operator: "In",
    filterType: models.FilterType.Basic,
    values: values,
  };

  return filterDefaultID;
};

export const create_building_filter = (
  table: string,
  building_id: string | undefined | null
) => {
  const filterCurrentBuilding = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: table,
      column: "building_id",
    },
    operator: "In",
    filterType: models.FilterType.Basic,
    values: [building_id],
  };

  return filterCurrentBuilding;
};
