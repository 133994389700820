import React from "react";
import { observer } from "mobx-react-lite";
import DashboardActiveFiles, {
  BaseDashboardProps,
} from "./DashboardActiveFiles";

const BUILDINGS_TABLE = "buildings";
const FILES_TABLE = "ifc files";
const MODULES_TABLE = "epd modules";

const DashboardPassport = (props: BaseDashboardProps) => {
  const reportID = process.env.REACT_APP_POWER_BI_MAIN_DASHBOARD_ID;

  return (
    <DashboardActiveFiles
      reportID={reportID}
      containerClassName={props.containerClassName}
      buildingsTable={BUILDINGS_TABLE}
      filesTable={FILES_TABLE}
      modulesTable={MODULES_TABLE}
      reloadOnChangeNRF
    />
  );
};

export default observer(DashboardPassport);
