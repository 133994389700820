import { DropdownOption } from "components/DropdownBrowser";
import React from "react";
import { observer } from "mobx-react-lite";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";
import DropdownBrowser from "components/DropdownBrowser";
import { useTranslation } from "react-i18next";
import { PropertyBrowserTreeNode } from "api-client";

export default observer(function GroupByPropertyBrowser({
  items,
}: {
  items: PropertyBrowserTreeNode[];
}) {
  const { i18n, t } = useTranslation();
  const isEng = i18n.language === "en";
  const availableColumnsProperties =
    dynamicMEStore.filterWithAvailableColumns(items);

  const onChange = (value: DropdownOption[]) =>
    dynamicMEStore.setSelectedGroupByItems(value);

  React.useEffect(() => {
    (async () => {
      //TODO: add fetch request
    })();
  }, []);

  return (
    <DropdownBrowser
      displayKey={isEng ? "name" : "name_de"}
      handleSelect={onChange}
      items={availableColumnsProperties as DropdownOption[]}
      checkedItems={dynamicMEStore.selectedGroupByItems}
      placeholder={t("mappingEditor.findProperty")}
      optionsClassName="!max-w-fit min-w-[246px]"
      containerClassName="ml-1"
    />
  );
});
