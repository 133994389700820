import React, { useEffect, useState } from "react";
import EmptyState from "components/EmptyState";
import { observer } from "mobx-react-lite";
import Retool from "react-retool";
import { retoolStore } from "store/RetoolStore";
import { buildingStore } from "store/Building/BuildingStore";

interface EmbededApp {
  url: string;
  embeddedData: object;
}

export default observer(function EmbedApp(props: EmbededApp) {
  const [url, setUrl] = useState("");

  useEffect(() => {
    buildingStore.setCurrentBuilding(undefined);
    async function getUrl() {
      setUrl("");
      const data = await retoolStore.getEmbedUrl(props.url);
      setUrl(data.embedUrl);
    }
    getUrl();
  }, [props.embeddedData]);

  return (
    <div className="h-screen">
      {url ? (
        <Retool url={url} data={props.embeddedData} />
      ) : (
        <EmptyState type="table" loading containerClassName="m-6" />
      )}
    </div>
  );
});
