import React from "react";
import clsx from "clsx";
import Tooltip from "components/Tooltip";
import useFeatureFlag from "hooks/useFeatureFlag";
import { useTranslation } from "react-i18next";
import { RELEASE_NOTES_URL } from "utils";

const DGNBItem = ({ disabled }: { disabled: boolean }) => {
  const { t } = useTranslation();
  const { isFeatureVisible } = useFeatureFlag();

  return (
    <div key={0} className="w-full flex flex-row items-center justify-between">
      <div
        className={clsx(
          disabled ? "text-gray-400" : "text-gray-900",
          "font-[400]"
        )}
      >
        {t("DGNB.export_dgnb")}
      </div>

      {!isFeatureVisible("activate_dgnb_pass") ? (
        <Tooltip
          content={
            <div
              dangerouslySetInnerHTML={{
                __html: t("DGNB.disable_dgnb_pass", {
                  link: RELEASE_NOTES_URL,
                }),
              }}
            ></div>
          }
        />
      ) : (
        disabled && <Tooltip content={t("DGNB.export_disable_msg")} />
      )}
    </div>
  );
};

export default DGNBItem;
