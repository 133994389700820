import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import DeleteBuilding from "../BuildingPassport/DeleteBuilding";
import Button from "components/Button";
import clsx from "clsx";
import { TrashIcon } from "@heroicons/react/outline";
import { PlusIcon } from "@heroicons/react/solid";
import { buildingStore } from "store/Building/BuildingStore";
import {
  BuildingIN,
  BuildingOUT,
  buildingsApi,
  BuildingUpdateIN,
  PlaceDetailsOUT,
} from "api-client";
import moment from "moment";
import { upcomingOperationList } from "features/Building/consts.d";
import useToast from "hooks/useToast";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router";
import { DGNBStore } from "store/DGNBStore";
import { organisationStore } from "store/OrganisationStore";
import { buildingSettingsStore } from "store/Building/BuildingSettingsStore";
import { userStore } from "store/UserStore";

export default observer(function Footer({
  building,
  type,
  onMaxLimitWarning,
  close,
}: {
  building: BuildingOUT | undefined;
  type: "add" | "edit";
  onMaxLimitWarning?: React.Dispatch<React.SetStateAction<boolean>>;
  close: () => void;
}) {
  const { t } = useTranslation();
  const showDeletePopup = React.useCallback(() => setOpenDeletePopup(true), []);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const { currentOrganisation } = organisationStore;
  const { currentBuilding, buildingTypes } = buildingStore;
  const { authHeader } = userStore;
  const toast = useToast();
  const navigate = useNavigate();
  const dgnbStore = DGNBStore.getInstance();
  const [submitLoading, setSubmitLoading] = useState(false);

  React.useEffect(() => {
    buildingSettingsStore.buildingAlreadyUpdated &&
      toast(t("addBuilding.successEditCertification"), "success");
  }, [buildingStore.currentBuilding?.certification_id]);

  function removeImages() {
    if (!authHeader) return;
    buildingSettingsStore.imagesToRemove.length &&
      building?.id &&
      buildingsApi
        .concularApiV1RoutersBuildingsDeleteImgBuilding(
          building?.id,
          { images: buildingSettingsStore.imagesToRemove },
          authHeader
        )
        .then(() => buildingStore.getOrganisationBuildings())
        .catch((err) => console.log(err));
  }

  function prefillDGNBFields() {
    dgnbStore.prefilDGNBFromBuildingFields(
      buildingSettingsStore.buildingFormDetails,
      buildingTypes
    );
  }

  async function addBuilding(uploadedImages?: []) {
    if (
      !currentOrganisation?.id ||
      !buildingSettingsStore.buildingFormDetails.name
    )
      return;
    const data: BuildingIN = {
      organisation_id: currentOrganisation.id,
      name: buildingSettingsStore.buildingFormDetails.name,
    };
    buildingUpdateIN(data);
    if (uploadedImages?.length) data["images"] = uploadedImages;
    await fetchAdd(data);
  }

  async function fetchAdd(data: BuildingIN) {
    if (!authHeader) return;
    await buildingsApi
      .concularApiV1RoutersBuildingsCreateOrgBuilding(data, authHeader)
      .then(async (response) => {
        await onSuccessAdd(response);
      })
      .catch((err) => onErrorAdd(err));
  }

  async function onSuccessAdd(response: AxiosResponse<BuildingOUT>) {
    buildingSettingsStore.updateBuildingDetails({});
    localStorage.removeItem("add_building");
    await buildingStore.getOrganisationBuildings();
    buildingStore.setCurrentBuilding(response.data);
    navigate(`/buildings/${response.data.id}`);
    close();
    toast(t("addBuilding.successAdd"), "success");
    prefillDGNBFields();
  }

  function onErrorAdd(error: {
    response: { status: number };
    message: string;
  }) {
    if (error?.response && error.response.status === 412) {
      close();
      toast(t("dashboard.max_building_heading"), "warning");
      onMaxLimitWarning?.(true);
    } else {
      toast(error.message, "warning");
    }
  }

  async function editBuilding(uploadedImages?: string[]) {
    if (!building?.id || !authHeader) return;
    if (uploadedImages?.length) {
      await buildingsApi
        .concularApiV1RoutersBuildingsUpdateImgBuilding(
          building?.id,
          {
            images: uploadedImages,
          },
          authHeader
        )
        .catch((err) => {
          console.log(err);
        });
    }
    const data: BuildingUpdateIN = {
      name: buildingSettingsStore.buildingFormDetails.name,
    };
    buildingUpdateIN(data);
    buildingsApi
      .concularApiV1RoutersBuildingsUpdateOrgBuilding(
        building?.id,
        data,
        authHeader
      )
      .then(async (response) => {
        buildingSettingsStore.setBuildingAlreadyUpdated(true);
        toast(t("addBuilding.successEdit"), "success");
        await buildingStore.getOrganisationBuildings();
        buildingStore.setCurrentBuilding(response.data);
        prefillDGNBFields();
        buildingSettingsStore.setCompliantObjects({});
        close();
        await buildingStore
          .fetchCheckDatasetCompliance(
            currentBuilding?.id,
            data.certification_id,
            data.conformity_id
          )
          .then((response) => {
            buildingSettingsStore.setCompliantObjects({
              compliant: response?.compliant_objects,
              total: response?.total_objects,
            });
          });
        await buildingStore.getCurrentBuildingDetails();
      })
      .catch((err) => {
        console.log(err);
        toast(err.message, "warning");
      });
  }

  function buildingUpdateIN(data: BuildingUpdateIN) {
    if (
      buildingSettingsStore.buildingFormDetails.upcoming_operation !==
        upcomingOperationList[0].id &&
      buildingSettingsStore.buildingFormDetails.construction_year
    )
      data["construction_year"] =
        buildingSettingsStore.buildingFormDetails.construction_year;
    if (buildingSettingsStore.buildingFormDetails.address) {
      const addressValues = buildingSettingsStore.buildingFormDetails
        .address as PlaceDetailsOUT;
      data["address"] = {
        country_code: addressValues.country_code,
        house_number: addressValues.house_number,
        latitude: addressValues.latitude,
        longitude: addressValues.longitude,
        postal_code: addressValues.postal_code,
        region: addressValues.region,
        street: addressValues.street,
      };
    }
    if (buildingSettingsStore.buildingFormDetails.building_type_id)
      data["building_type_id"] =
        buildingSettingsStore.buildingFormDetails.building_type_id;
    if (buildingSettingsStore.buildingFormDetails.gross_floor_area)
      data["gross_floor_area"] = Number(
        buildingSettingsStore.buildingFormDetails.gross_floor_area
      );
    if (buildingSettingsStore.buildingFormDetails.net_floor_area)
      data["net_floor_area"] = Number(
        buildingSettingsStore.buildingFormDetails.net_floor_area
      );
    if (buildingSettingsStore.buildingFormDetails.upcoming_operation)
      data["upcoming_operation"] =
        buildingSettingsStore.buildingFormDetails.upcoming_operation;
    if (buildingSettingsStore.buildingFormDetails.start_date)
      data["start_date"] = moment(
        buildingSettingsStore.buildingFormDetails.start_date
      ).format("yyyy-MM-DD");
    if (buildingSettingsStore.buildingFormDetails.end_date)
      data["end_date"] = moment(
        buildingSettingsStore.buildingFormDetails.end_date
      ).format("yyyy-MM-DD");
    if (
      buildingSettingsStore.buildingFormDetails.certification_id &&
      !buildingStore.isRecalculationInProgress(currentBuilding?.id)
    )
      data["certification_id"] =
        buildingSettingsStore.buildingFormDetails.certification_id;
    if (
      buildingSettingsStore.buildingFormDetails.conformity_id &&
      !buildingStore.isRecalculationInProgress(currentBuilding?.id)
    )
      data["conformity_id"] =
        buildingSettingsStore.buildingFormDetails.conformity_id;
    data["auto_premap"] = buildingSettingsStore.buildingFormDetails.auto_premap;
    data["premapping_enabled"] =
      buildingSettingsStore.buildingFormDetails.premapping_enabled;
    //TODO: uncomment after BE update
    // data["ifc_import_split_multi_objects"] =
    // buildingSettingsStore.buildingFormDetails.ifc_import_split_multi_objects;
  }

  function AddEditBuildingWithImages() {
    if (
      !buildingSettingsStore.buildingFormDetails.selectedImages ||
      !authHeader
    )
      return;
    setSubmitLoading(true);
    return buildingsApi
      .concularApiV1RoutersBuildingsUploadBuildingImages(
        buildingSettingsStore.buildingFormDetails.selectedImages,
        authHeader
      )
      .then((response: AxiosResponse) => {
        const uploadedImages = response.data.map(
          (item: { id: string }) => item.id
        );
        buildingSettingsStore.updateBuildingDetails({
          ...buildingSettingsStore.buildingFormDetails,
          images: uploadedImages,
        });
        type === "add"
          ? addBuilding(uploadedImages)
          : editBuilding(uploadedImages);
      })
      .catch((err) => console.log(err));
  }

  function isAddOrEdit() {
    return type === "add" ? addBuilding() : editBuilding();
  }

  const submit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (buildingSettingsStore.showMandatoryAlert) return;
    removeImages();
    buildingSettingsStore.buildingFormDetails.selectedImages?.length
      ? AddEditBuildingWithImages()
      : isAddOrEdit();
  };

  return (
    <div
      className={clsx(
        "px-6 py-4 bg-gray-50 flex fixed left-0 right-0 bottom-0 justify-between border-t border-gray-100"
      )}
    >
      {type === "add" && (
        <Button type="gray" onClick={close} width="fit-content">
          {t("commons.cancel")}
        </Button>
      )}
      {type === "edit" && (
        <Button
          type="danger"
          leadingIcon={<TrashIcon />}
          width="fit-content"
          onClick={showDeletePopup}
        >
          {t("addBuilding.deleteBuilding")}
        </Button>
      )}
      <Button
        type="primary"
        onClick={submit}
        width="fit-content"
        leadingIcon={type === "add" ? <PlusIcon /> : null}
        testID="addbuildingform_button"
        loading={submitLoading}
        disable={submitLoading || buildingSettingsStore.showMandatoryAlert}
      >
        {type === "add"
          ? t("addBuilding.addBuilding")
          : t("addBuilding.updateBuilding")}
      </Button>
      {openDeletePopup && (
        <DeleteBuilding open={openDeletePopup} close={setOpenDeletePopup} />
      )}
    </div>
  );
});
