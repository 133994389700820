import React from "react";
import TableIcon from "assets/images/icons/table-gray-icon.svg";
import InventoryIcon from "assets/images/icons/inventory-gray-icon.svg";
import ChartIcon from "assets/images/icons/chart-gray-icon.svg";
import {
  IconAlertTriangle,
  IconArrowRight,
  IconInfoCircle,
  IconRefresh,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import { observer } from "mobx-react-lite";
import { buildingStore } from "store/Building/BuildingStore";
import { useNavigate } from "react-router";
import LoadingIcon from "components/LoadingIcon";
import clsx from "clsx";
import NoDBIcon from "assets/images/icons/database-off-icon.svg";
import { buildingSettingsStore } from "store/Building/BuildingSettingsStore";

interface EmptyStateProps {
  type?: "chart" | "table" | "inventory" | "data";
  title?: string;
  alertText?: string;
  alertType?: "attention" | "info";
  loading?: boolean;
  containerClassName?: string;
  noBorder?: boolean;
  hideMEButton?: boolean;
  icon?: string;
  button?: React.ReactNode;
}

const reasonType = {
  noFile: "noFile",
  noActiveFile: "noActiveFile",
  noMappedObjects: "noMappedObjects",
  noComplientObjects: "noComplientObjects",
};

export default observer(function EmptyState(props: EmptyStateProps) {
  const { t } = useTranslation();
  const { materialResources } = buildingStore;
  const navigate = useNavigate();
  const { currentBuilding, buildingMappingStatus } = buildingStore;

  const getIconSrc = () => {
    switch (props.type) {
      case "chart":
        return ChartIcon;
      case "inventory":
        return InventoryIcon;
      case "table":
        return TableIcon;
      case "data":
        return NoDBIcon;
      default:
        return props.icon;
    }
  };

  const getReason = (): string => {
    if (!materialResources.items.length) return reasonType.noFile;
    if (!currentBuilding?.active_ifc_files?.length)
      return reasonType.noActiveFile;
    if (
      currentBuilding?.active_ifc_files?.length &&
      !buildingStore.buildingMappingStatus.hasMappedObjects
    )
      return reasonType.noMappedObjects;
    if (
      currentBuilding?.active_ifc_files?.length &&
      !buildingStore.buildingMappingStatus.hasComplientsObjects
    )
      return reasonType.noComplientObjects;
    else {
      return "";
    }
  };

  return (
    <div
      className={clsx(
        "p-6 flex flex-col justify-center items-center",
        props.noBorder ? "" : "bg-gray-100 rounded-md border border-gray-300",
        props.containerClassName
      )}
    >
      {props.loading ||
      materialResources.loading ||
      buildingMappingStatus.loading ? (
        <div className="h-64 flex flex-col justify-center items-center">
          <LoadingIcon type="primary" className="my-4 !w-20 !h-20" />
          <div className="mb-4 text-gray-500">
            {t("emptyState.loadingTitle")}
          </div>
          <Button
            leadingIcon={<IconRefresh />}
            width="fit-content"
            className="hidden mt-4"
          >
            {t("emptyState.reloadReport")}
          </Button>
        </div>
      ) : (
        <>
          <img
            alt={"empty-state"}
            src={getIconSrc()}
            width={129}
            height={83}
            className="w-fit"
          />
          <div className="text-gray-500 mt-4">
            {props.title ?? t("emptyState.noReports")}
          </div>
          <div className="max-w-[500px] text-xs font-medium">
            {([reasonType.noFile, reasonType.noActiveFile].includes(
              getReason()
            ) &&
              props.alertText === undefined) ||
            props.alertType === "info" ? (
              <div className="flex text-indigo-700 my-8">
                <IconInfoCircle
                  width={25}
                  height={25}
                  stroke={3}
                  className="text-indigo-600 bg-indigo-100 p-1 w-10 rounded-full mr-2"
                />
                <div>{props.alertText ?? t(`emptyState.${getReason()}`)}</div>
              </div>
            ) : null}
            {([
              reasonType.noMappedObjects,
              reasonType.noComplientObjects,
            ].includes(getReason()) &&
              props.alertText === undefined) ||
            props.alertType === "attention" ? (
              <div className="flex text-yellow-700 my-8">
                <IconAlertTriangle
                  width={25}
                  height={25}
                  stroke={3}
                  className="text-yellow-600 bg-yellow-100 p-1 w-10 rounded-full mr-2"
                />
                <div>{props.alertText ?? t(`emptyState.${getReason()}`)}</div>
              </div>
            ) : null}
          </div>
          <div className="flex gap-4">
            {getReason() === reasonType.noComplientObjects && !props.button ? (
              <Button
                type="secondary"
                width="fit-content"
                onClick={() => buildingSettingsStore.setOpenEditBuilding(true)}
              >
                {t("emptyState.changeBuildingSettings")}
              </Button>
            ) : null}
            {!props.hideMEButton && !props.button && (
              <Button
                type="primary"
                width="fit-content"
                trailingIcon={<IconArrowRight />}
                onClick={() =>
                  navigate("../material-resources", { replace: true })
                }
              >
                {t("emptyState.openMaterialSources")}
              </Button>
            )}
            {props.button}
          </div>
        </>
      )}
    </div>
  );
});
