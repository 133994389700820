import React from "react";
import clsx from "clsx";
import FooterRowCount from "./FooterRowCount";
import { searchStore } from "store/IfcMapping/SearchStore";
import { observer } from "mobx-react-lite";

const ProductsListContainer = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  const containerRef = React.useRef<HTMLDivElement | null>(null); // Ref for the search scroll container

  const fetchNextPage = async () => {
    if (searchStore.productSearchLoading) return;
    try {
      searchStore.setProductSearchLoading(true);
      const nextPage = searchStore.currentSearchPage + 1;
      await searchStore.getProducts(nextPage);
    } catch (error) {
      console.error("Error fetching search Products: ", error);
    } finally {
      searchStore.setProductSearchLoading(false);
    }
  };

  const handleScroll = () => {
    const container = containerRef.current;
    if (
      container &&
      container.scrollHeight - container.scrollTop === container.clientHeight
    ) {
      // User has scrolled to the bottom
      if (
        searchStore.searchResults.count > searchStore.searchResults.items.length
      ) {
        // Fetch the next page if total items are less than the count
        fetchNextPage();
      }
    }
  };

  React.useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      // Clean up the event listener when the component is unmounted
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  return (
    <>
      <div
        ref={containerRef}
        className={clsx(
          "relative overflow-y-scroll h-[calc(100vh-355px)] pb-10",
          className
        )}
      >
        {children}
      </div>
      <div className="h-6">
        <FooterRowCount />
      </div>
    </>
  );
};

export default observer(ProductsListContainer);
