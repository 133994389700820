import React from "react";
import { DropdownOption, DropdownWithSearchProps } from ".";
import Checkbox from "components/Checkbox";
import { Combobox } from "@headlessui/react";
import clsx from "clsx";
import { IconMinus, IconPlus } from "@tabler/icons-react";

interface OptionItem<T> extends Omit<DropdownWithSearchProps<T>, "items"> {
  item: T;
  index: number;
  handleOnClick: (checked: boolean, item: T) => void;
  details?: { name: string; name_de: string };
  query: string;
  hasChildren?: boolean;
}

interface TreeViewNode {
  id: string;
  name?: string;
  isOpen: boolean;
  childrenNodes?: TreeViewNode[];
  checked?: boolean;
}

function OptionItem<Item extends object>(props: OptionItem<Item>) {
  const { item, index, handleOnClick, details, query } = props;

  const createNodeElement = (item?: DropdownOption): TreeViewNode => {
    return {
      ...item,
      id: item?.id as string,
      name: item?.name,
      isOpen: false,
      childrenNodes: item?.children?.map((rec) => createNodeElement(rec)) ?? [],
      checked: Boolean(
        props.checkedItems?.find(
          (rec) => (rec as DropdownOption).id === (item as DropdownOption).id
        )
      ),
    };
  };

  React.useEffect(() => {
    setTreeNode(createNodeElement(item as DropdownOption));
  }, [props.query]);

  const [treeNode, setTreeNode] = React.useState<TreeViewNode>(
    createNodeElement(item as DropdownOption)
  );

  const toggleNode = (clickedNode: TreeViewNode) => {
    setTreeNode((prevTreeData) => {
      if (prevTreeData === clickedNode) {
        return { ...prevTreeData, isOpen: !prevTreeData.isOpen };
      } else if (
        prevTreeData.childrenNodes &&
        prevTreeData.childrenNodes.length > 0
      ) {
        return {
          ...prevTreeData,
          childrenNodes: toggleNodeInTree(
            prevTreeData.childrenNodes,
            clickedNode
          ),
        };
      } else {
        return prevTreeData;
      }
    });
  };

  const toggleNodeInTree = (
    childrenNodes: TreeViewNode[],
    clickedNode: TreeViewNode
  ): TreeViewNode[] => {
    return childrenNodes.map((child) => {
      if (child === clickedNode) {
        return { ...child, isOpen: !child.isOpen };
      } else if (child.childrenNodes && child.childrenNodes.length > 0) {
        return {
          ...child,
          childrenNodes: toggleNodeInTree(child.childrenNodes, clickedNode),
        };
      } else {
        return child;
      }
    });
  };

  const isChecked = () => {
    return Boolean(
      props.checkedItems?.find(
        (rec) => (rec as DropdownOption).id === (item as DropdownOption).id
      )
    );
  };

  function itemContent(i: number, item: Item) {
    return (
      <Combobox.Option
        key={i}
        className={() =>
          `relative pr-4 text-gray-900 ${
            props.disabled ? "cursor-not-allowed" : "cursor-pointer"
          } ${details ? "py-1.5" : "py-0.5"}`
        }
        value={item}
        disabled={props.disabled}
      >
        {({ selected }) => (
          <>
            <label
              className={`flex truncate items-center pl-3 ${
                selected ? "font-medium" : "font-normal"
              } ${
                props.disabled
                  ? "opacity-50 cursor-not-allowed"
                  : "opacity-100 cursor-pointer"
              }`}
            >
              <Checkbox
                id={String(i)}
                checked={isChecked()}
                readOnly
                onChange={(value) =>
                  handleOnClick(value.currentTarget.checked, item)
                }
                disabled={props.disabled}
              />
              <div
                className={clsx("pl-2", {
                  "flex items-center": !details,
                })}
              >
                {(item as unknown as DropdownOption).icon && (
                  <div className="mr-2 min-w-[16px] flex justify-center">
                    {(item as unknown as DropdownOption).icon}
                  </div>
                )}

                {props.displayKey in item
                  ? (item[props.displayKey] as unknown as string)
                  : null}

                {details ? (
                  <div className="text-gray-500 whitespace-normal">
                    {details && props.displayKey === "name"
                      ? details?.name
                      : details?.name_de ?? details?.name}
                    {String(isChecked())}
                  </div>
                ) : null}
              </div>
            </label>
          </>
        )}
      </Combobox.Option>
    );
  }

  return (
    <>
      <li>
        <div
          className={clsx(
            "flex cursor-pointer items-center hover:bg-gray-100",
            props.hasChildren && !query ? "pl-3" : ""
          )}
        >
          <div
            className={clsx(
              props.hasChildren && !query
                ? "min-w-[18px]"
                : "bg-red-200 min-w-[2px]"
            )}
          >
            {treeNode.childrenNodes?.length && !query ? (
              <span
                className="cursor-pointer text-gray-500"
                onClick={() => toggleNode(treeNode)}
              >
                {treeNode.isOpen ? (
                  <IconMinus width={18} height={18} />
                ) : (
                  <IconPlus width={18} height={18} />
                )}
              </span>
            ) : null}
          </div>
          <div className="flex overflow-hidden w-full">
            <div className="text-gray-800 text-sm font-medium truncate w-full">
              {itemContent(index, item)}
            </div>
          </div>
        </div>
        {treeNode.childrenNodes &&
        treeNode.isOpen &&
        !query &&
        treeNode.id === (item as DropdownOption).id ? (
          <ul className="w-full ml-3">
            {treeNode.childrenNodes.map((child, index) => (
              <OptionItem
                key={index}
                item={child as Item}
                index={index}
                handleOnClick={handleOnClick}
                disabled={props.disabled}
                displayKey={props.displayKey}
                details={details}
                checkedItems={props.checkedItems}
                query={query}
                hasChildren={props.hasChildren}
              />
            ))}
          </ul>
        ) : null}
      </li>
    </>
  );
}

export default OptionItem;
