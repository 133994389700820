import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import useBimViewer, { hierarchies } from "./useBimViewer";
import { Entity } from "@xeokit/xeokit-sdk";
import { observer } from "mobx-react-lite";
import { bimViewerStore } from "store/BimViewerStore";
import { useParams } from "react-router";
import Button from "components/Button";
import {
  IconChevronsLeft,
  IconChevronsRight,
  IconCrop,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import ButtonGroups from "components/ButtonGroups";
import bimViewerMenuItems from "./BimViewerMenuItems";
import DetailsView from "features/MappingTools/ObjectDetailsSlideOver/DetailsView";
import { buildingStore } from "store/Building/BuildingStore";

interface TreeViewProps {
  id: string;
  name?: string;
}

const hierarchyItems = [
  { id: hierarchies.containment, name: "IFC objects" },
  { id: hierarchies.types, name: "IFC classes" },
  { id: hierarchies.storeys, name: "Storeys" },
];

export default observer(function BimViewer() {
  const viewerRef = useRef<HTMLCanvasElement>(null);
  const treeViewContainment = useRef<HTMLDivElement>(null);
  const treeViewTypes = useRef<HTMLDivElement>(null);
  const treeViewStoreys = useRef<HTMLDivElement>(null);
  const [openDetailView, setOpenDetailView] = useState<boolean>(false);
  const [openTreeView, setOpenTreeView] = useState<boolean>(true);
  const logic = useBimViewer();
  const { ifc_id } = useParams();
  const { materialResources } = buildingStore;
  const fileSrc = materialResources.items.find(
    (item) => item.id === ifc_id
  )?.file_processed_xkt;
  const { t } = useTranslation();
  const [selectedTreeView, setSelectedTreeView] = useState<TreeViewProps>(
    hierarchyItems[0]
  );
  const menu = bimViewerMenuItems();

  const containersReady = () =>
    viewerRef.current &&
    treeViewContainment.current &&
    treeViewStoreys.current &&
    treeViewTypes.current;

  useEffect(() => {
    if (containersReady() && fileSrc) {
      const viewer = logic.createViewer("bimViewerCanvas");
      bimViewerStore.setViewer(viewer);
      logic.loadFile(fileSrc);
      const treeView = logic.addTreeView(
        treeViewContainment.current,
        "containment"
      );
      logic.addTreeView(treeViewTypes.current, "types");
      logic.addTreeView(treeViewStoreys.current, "storeys");
      logic.addNavCube();
      const objectContextMenu = logic.createObjectsContextMenu();
      const canvasContextMenu = logic.createCanvasContextMenu();
      logic.controlRightMenuOnViewer(
        objectContextMenu,
        treeView,
        canvasContextMenu
      );
      logic.handleObjectsOnclick(goToObjectDetail);

      return () => {
        viewer.destroy();
        objectContextMenu.destroy();
        canvasContextMenu?.destroy();
      };
    }
  }, [materialResources]);

  async function goToObjectDetail(object: Entity) {
    if (!ifc_id) return;
    await bimViewerStore.fetchDetailsInfo(ifc_id, String(object.id));
    bimViewerStore.setCurrentObject(object);
    setOpenDetailView(true);
  }

  function closeDetailView() {
    setOpenDetailView(false);
    if (bimViewerStore.currentObject)
      logic.setObjectHighlightStatus(bimViewerStore.currentObject, false);
  }

  function fileExplorer() {
    return (
      <div
        className={clsx(
          "fixed h-full transition-all duration-500 top-17 w-fit",
          `${openTreeView ? "left-0" : "-left-[350px]"}`
        )}
      >
        <div className="flex h-full">
          <div className="shadow-md w-[350px] bg-white bg-opacity-70">
            <div
              className={clsx(
                "flex justify-between border-b border-gray-100 w-full bg-white font-semibold text-lg text-gray-900 py-2 px-5"
              )}
            >
              <div>{t("bimViewer.fileExplorer")}</div>
              <IconChevronsLeft
                className="cursor-pointer text-gray-500 hover:text-gray-900 transition"
                onClick={() => setOpenTreeView(false)}
              />
            </div>
            <ButtonGroups
              containerClassName="px-6 pt-4"
              items={hierarchyItems}
              onChange={(e) => e && setSelectedTreeView(e)}
              current={selectedTreeView}
            />
            <div
              id="treeViewContainment"
              ref={treeViewContainment}
              className={clsx(
                "px-6 py-4 bg-white bg-opacity-50 w-full h-full overflow-y-scroll treeViewContainer",
                hierarchies.containment === selectedTreeView.id
                  ? "visible"
                  : "hidden"
              )}
            ></div>
            <div
              id="treeViewStoreys"
              ref={treeViewStoreys}
              className={clsx(
                "px-6 py-4 bg-white bg-opacity-50 w-full h-full overflow-y-scroll treeViewContainer",
                hierarchies.storeys === selectedTreeView.id
                  ? "visible"
                  : "hidden"
              )}
            ></div>
            <div
              id="treeViewTypes"
              ref={treeViewTypes}
              className={clsx(
                "px-6 py-4 bg-white bg-opacity-50 w-full h-full overflow-y-scroll treeViewContainer",
                hierarchies.types === selectedTreeView.id ? "visible" : "hidden"
              )}
            ></div>
          </div>
          <div className="bg-white h-fit m-5 rounded-md p-3 shadow-sm flex">
            {!openTreeView ? (
              <Button
                type="gray"
                onClick={() => setOpenTreeView(true)}
                width="fit-content"
                className="mr-3"
                trailingIcon={<IconChevronsRight />}
              >
                {t("bimViewer.openExplorer")}
              </Button>
            ) : null}
            {bimViewerStore.viewer && (
              <Button
                type="gray"
                onClick={menu.viewFitAllFunction}
                width="fit-content"
                trailingIcon={<IconCrop />}
              >
                {t("bimViewer.viewFit")}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }

  const refreshAfterMatching = async () => {
    if (!ifc_id) return;
    await bimViewerStore.fetchDetailsInfo(
      ifc_id,
      String(bimViewerStore.currentObject?.id)
    );
  };

  return (
    <div className="flex relative h-full">
      <canvas
        ref={viewerRef}
        id="bimViewerCanvas"
        className="bg-indigo-50 w-full h-[100vh]"
      ></canvas>
      <canvas id="myNavCubeCanvas"></canvas>
      {fileExplorer()}
      <DetailsView
        open={openDetailView}
        close={closeDetailView}
        physicalObjectDetails={
          bimViewerStore.objectDetail ? [...bimViewerStore.objectDetail] : []
        }
        refreshAfterMatching={refreshAfterMatching}
      />
    </div>
  );
});
