import React, { Fragment, ReactNode, useEffect } from "react";
import { Combobox, Transition } from "@headlessui/react";
import useSwitch from "hooks/useSwitch";
import { useTranslation } from "react-i18next";
import useInvalidInput from "hooks/useInvalidInput";
import { slugify } from "utils";
import clsx from "clsx";
import NotFoundImg from "assets/images/icons/not-found.svg";
import { IconChevronDown, IconSearch } from "@tabler/icons-react";
import OptionItem from "./OptionItem";

export interface DropdownOption {
  id?: string | number;
  name: string;
  name_de?: string;
  icon?: ReactNode;
  title?: string;
  title_de?: string;
  value?: string;
  children?: DropdownOption[];
}
export interface DropdownWithSearchProps<T>
  extends React.HTMLProps<HTMLInputElement> {
  labelName?: string;
  items: T[];
  displayKey: keyof T;
  detailKey?: keyof T;
  handleChange?: (value: string) => void;
  onReset?: () => void | Promise<void>;
  handleSelect?: (value: T[], name?: string) => void;
  className?: string;
  containerClassName?: string;
  optionsClassName?: string;
  removeSelected?: T | null;
  handleRemoveSelected?: () => void;
  name?: string;
  height?: "small" | "large" | "middle";
  noMargin?: boolean;
  labelWithSearch?: boolean;
  labelWithOutSearch?: boolean;
  hideOptionsSearch?: boolean;
  checkedItems?: T[];
  searchClassName?: string;
  disabled?: boolean;
}

export default function DropdownCheckbox<Item extends object>(
  props: DropdownWithSearchProps<Item>
) {
  const [selectedItems, setSelectedItems] = React.useState<Item[]>([]);
  const [query, setQuery] = React.useState("");
  const [showError, switchShowError] = useSwitch(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!props.checkedItems) return;
    setSelectedItems(props.checkedItems);
  }, [props.checkedItems]);

  function checkQueryExists(item: Item, filteredList: Item[]) {
    let resolved;
    if (props.displayKey in item) {
      resolved = item[props.displayKey];
    } else return;
    const foundedItem = slugify(resolved as unknown as string).includes(
      slugify(query)
    );
    foundedItem && filteredList.push(item);
    if ((item as DropdownOption).children?.length) {
      (item as DropdownOption).children?.map((rec) =>
        checkQueryExists(rec as Item, filteredList)
      );
    }
  }

  function filterWithQuery() {
    const filteredList: Item[] = [];
    props.items.map((item) => {
      checkQueryExists(item, filteredList);
    });
    return filteredList;
  }

  const filteredItems: Item[] = query === "" ? props.items : filterWithQuery();

  const details =
    filteredItems?.map(
      (item) =>
        props.detailKey &&
        (item[props.detailKey] as unknown as {
          name: string;
          name_de: string;
        })
    ) ?? [];

  useInvalidInput(inputRef, (isInvalid) => {
    if (isInvalid) {
      switchShowError.on();
    } else {
      switchShowError.off();
    }
  });

  useEffect(() => {
    if (!props.removeSelected || !props.checkedItems) return;
    const items = props.checkedItems.filter(
      (item: Item) => item !== props.removeSelected
    );
    setSelectedItems([...items]);
    props.handleRemoveSelected?.();
    props.handleSelect?.(items);
  }, [props.removeSelected]);

  function searchField() {
    return (
      <Combobox.Input
        ref={inputRef}
        onSelect={(e: React.ChangeEvent<HTMLInputElement>) => {
          props.handleChange && props.handleChange(e.target.value);
        }}
        data-invalid={"false"}
        required={props.required}
        autoCorrect="off"
        autoComplete="off"
        onInvalid={() => switchShowError.on()}
        name={props.name}
        className={`w-full pl-1 lg:text-sm leading-5 ring-0 outline-none border-none focus:ring-0 rounded-md ${props.searchClassName}`}
        displayValue={(item: Item) => {
          let result;
          if (!item) return "";
          if (props.displayKey in item) {
            result = item[props.displayKey];
          } else return "";
          return (result as unknown as string).toString();
        }}
        onChange={(event) => {
          switchShowError.off();
          setQuery(event.target.value);
        }}
        placeholder={props.placeholder}
      />
    );
  }

  const handleOnClick = (checked: boolean, item: Item) => {
    let selected = [...selectedItems];
    if (checked) {
      selected.push(item);
    } else {
      selected = selectedItems.filter(
        (rec) =>
          (rec as unknown as DropdownOption).id !==
          (item as unknown as DropdownOption).id
      );
    }
    props.handleSelect?.(selected);
    setSelectedItems(selected);
  };

  function emptyState() {
    return (
      <div className="flex justify-center">
        <div className="w-[300px] flex flex-col -z-10 py-4 items-center justify-center">
          <span>
            <img src={NotFoundImg} className="pt-2" />
          </span>
          <span className="font-normal text-sm text-gray-500 p-4 text-center">
            {t("commons.onDropDownNoSearchResult")}
          </span>
        </div>
      </div>
    );
  }

  const hasChildren = () => {
    return Boolean(
      filteredItems.find((item) => (item as unknown as DropdownOption).children)
    );
  };

  function optionListItems() {
    return (
      <Combobox.Options
        className={clsx(
          "absolute z-10 my-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base !p-0 !max-w-fit",
          "shadow-lg border border-gray-300 min-w-auto w-auto max-w-md focus:outline-none sm:text-sm",
          props.optionsClassName,
          { "cursor-not-allowed": props.disabled }
        )}
      >
        {!props.labelWithSearch && !props.hideOptionsSearch && (
          <div className="sticky top-0 bg-gray-50 p-1 z-10 border-b border-gray-200">
            <div
              className={clsx(
                "bg-white border-gray-300 mx-2 mt-1 border rounded-md mb-2",
                "flex justify-between pl-2 items-center pr-3 text-gray-900"
              )}
            >
              {searchField()}
              <IconSearch width={18} className="text-gray-400" />
            </div>
          </div>
        )}
        {filteredItems?.length === 0 && query !== ""
          ? emptyState()
          : filteredItems?.map((item, i) => (
              <OptionItem
                key={i}
                item={item}
                index={i}
                handleOnClick={handleOnClick}
                disabled={props.disabled}
                displayKey={props.displayKey}
                details={details[i]}
                checkedItems={selectedItems}
                query={query}
                hasChildren={hasChildren()}
              />
            ))}
      </Combobox.Options>
    );
  }

  return (
    <div
      className={`${props.containerClassName ?? ""}${
        props.noMargin ? " mb-0" : " mb-4"
      }`}
    >
      <Combobox value={selectedItems} onChange={() => null}>
        <div className={clsx("mt-1", props.className)}>
          <div
            className={clsx(
              "flex items-center cursor-pointer overflow-hidden rounded-md font-medium text-sm",
              "border  text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white h-large",
              showError ? "border border-red-700 text-red-700" : "",
              selectedItems?.length &&
                !props.labelWithSearch &&
                !props.labelWithOutSearch
                ? "border-indigo-700"
                : "border-gray-300",
              {
                "h-large": props.height === "large",
                "!h-middle": props.height === "middle",
                "!h-small": props.height === "small",
              }
            )}
          >
            <Combobox.Button
              className={clsx(
                "flex justify-between w-full h-full inset-y-0 right-0 items-center px-3",
                selectedItems?.length &&
                  !props.labelWithSearch &&
                  !props.labelWithOutSearch
                  ? "text-indigo-700 bg-indigo-100"
                  : "text-gray-700 bg-white"
              )}
            >
              {({ open }) => (
                <div className="flex justify-between w-full items-center">
                  <div>
                    {props.labelName && (
                      <label
                        className="cursor-pointer pr-1"
                        htmlFor={props.name as string}
                      >
                        {props.labelName}
                      </label>
                    )}
                    {props.labelWithSearch && searchField()}
                  </div>
                  <IconChevronDown
                    className={clsx(
                      "h-5 w-4",
                      selectedItems?.length &&
                        !props.labelWithSearch &&
                        !props.labelWithOutSearch
                        ? "text-indigo-800"
                        : "text-gray-500",
                      open ? "rotate-180" : ""
                    )}
                    aria-hidden="true"
                  />
                </div>
              )}
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            {optionListItems()}
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}
