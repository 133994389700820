import Alert from "components/Alert";
import Badge from "components/Badge";
import Heading from "components/Heading";
import DashboardVariant from "features/ReportDashboards/DashboardVariant";
import { useTranslation } from "react-i18next";

const Variants = () => {
  const { t } = useTranslation();
  return (
    <div className="p-6 flex flex-col space-y-4">
      <div className="flex w-fit space-x-3">
        <Heading size="title">{t("variants.title")}</Heading>
        <Badge className="mt-1" type="info">
          {t("variants.beta")}
        </Badge>
      </div>
      <Alert
        className="border-yellow-100"
        description={t("variants.alertMessage")}
        type="attention"
      />
      <DashboardVariant />
    </div>
  );
};

export default Variants;
