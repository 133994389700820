import { AuditIN, AuditOUT } from "api-client";
import axios, { AxiosRequestConfig } from "axios";
import { AxiosError } from "axios/index";
import { PerformSyncProps } from "./performSync";
import { db } from "api-client-local/db";

export interface ErrorLocation {
  loc: (string | number)[];
  msg: string;
  type: string;
}

export async function getLocalAudit(audit_id: string) {
  const foundAudit = (await db.audits.get(audit_id)) as AuditOUT | null;
  if (!foundAudit) {
    throw Error("Audit not found locally.");
  }
  return foundAudit;
}

export async function handleAuditUpdate(auditIN: AuditIN, options: unknown) {
  const response = await axios.put<AuditOUT>(
    `${process.env.REACT_APP_API_BASE_URL}/api/v1/ca/audits/update`,
    auditIN,
    options as AxiosRequestConfig<unknown>
  );
  const data = response.data;
  console.log("(SYNC) Audit update successful.", data);
}

export function handleSyncError(props: PerformSyncProps) {
  const { setMissingFields, setStatus, error } = props;
  if (!error) {
    return;
  }
  if (
    typeof error === "object" &&
    error !== null &&
    "response" in error &&
    error
  ) {
    const res = (error as unknown as AxiosError).response as unknown as {
      data: { detail: ErrorLocation[] };
      status: number;
    };
    if (res.status === 422) {
      setStatus("missingFields");
      setMissingFields(res.data.detail);
      setTimeout(() => {
        setStatus("initial");
        setMissingFields([]);
      }, 10000);
    }
  } else {
    setStatus("error");
    setTimeout(() => {
      setStatus("initial");
    }, 2500);
  }
}
