import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./languages/i18n";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { FlagsmithProvider } from "flagsmith-react";

if (
  ["prod", "staging", "dev"].includes(process.env.REACT_APP_ENV || "") &&
  process.env.REACT_APP_SENTRY_DSN
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

function getFlagsmithEnvId() {
  if (process.env.REACT_APP_ENV === "prod")
    return process.env.REACT_APP_FLAGSMITH_KEY_PROD;
  if (process.env.REACT_APP_ENV === "staging")
    return process.env.REACT_APP_FLAGSMITH_KEY_STAGING;
  return process.env.REACT_APP_FLAGSMITH_KEY;
}

root.render(
  <FlagsmithProvider environmentId={getFlagsmithEnvId()}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </FlagsmithProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
