import React from "react";
import clsx from "clsx";

interface Truncate {
  children: string;
  className?: string;
}

export default function Truncate(props: Truncate) {
  const [isTextTruncated, setIsTextTruncated] = React.useState(false);
  const textRef = React.useRef<HTMLDivElement>(null);

  const checkTextTruncation = () => {
    const isTruncated =
      textRef.current &&
      textRef.current.scrollWidth > textRef.current.clientWidth;
    setIsTextTruncated(Boolean(isTruncated));
  };

  return (
    <div className="group relative transition-all delay-400">
      <div
        className={clsx(
          "-top-7 -left-2 px-2 py-1.5 text-xs rounded",
          "bg-gray-700 whitespace-nowrap z-20 text-white cursor-default",
          `absolute invisible  ${
            isTextTruncated ? "group-hover:animate-tooltip-show" : ""
          } `
        )}
        onClick={(e) => e.stopPropagation()}
      >
        {props.children}
      </div>
      <div
        className={`${props.className} truncate`}
        onMouseEnter={checkTextTruncation}
        ref={textRef}
      >
        {props.children}
      </div>
    </div>
  );
}
