export const PROPERTY_RULE_OPERATORS = [
  { id: "IS", name: "is", name_de: "Ist" },
  { id: "IS_NOT", name: "is not", name_de: "Ist nicht" },
  { id: "CONTAINS", name: "Contains", name_de: "Enthält" },
  { id: "DOES_NOT_CONTAIN", name: "Contains not", name_de: "Enthält nicht" },
  { id: "STARTS_WITH", name: "Starts with", name_de: "Beginn mit" },
  { id: "ENDS_WITH", name: "Ends with", name_de: "Endet mit" },
  { id: "IS_EMPTY", name: "Is empty", name_de: "Ist leer" },
  { id: "IS_NOT_EMPTY", name: "Is not empty", name_de: "Ist nicht leer" },
];

export const LOGICAL_OPERATORS = [
  { id: "AND", name: "and", name_de: "Und" },
  { id: "OR", name: "or", name_de: "Oder" },
];

export const EMPTY_FILTER_RULE = {
  property: undefined,
  operator: "IS",
  value: "",
};

export const EMPTY_SORT_ROW = {
  id: "0",
  property: undefined,
  sort: "ASC",
};

export const SORT_OPTIONS = [
  { id: "ASC", name: "Ascending", name_de: "Aufsteigend" },
  { id: "DESC", name: "Descending", name_de: "Absteigend" },
];

export const DME_HELP_URL =
  "https://concular.notion.site/Wie-kann-ich-Produktverkn-pfungen-im-Mapping-Editor-ausf-hren-8951b61aef2f4a4c965f9ba22e60521a?pvs=4";
