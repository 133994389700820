import React from "react";
import RevitIcon from "assets/images/icons/revit.svg";
import { IconDownload } from "@tabler/icons-react";
import Button from "components/Button";
import { useTranslation } from "react-i18next";

export default function RevitPluginDownloadAlert() {
  const { t } = useTranslation();

  const downloadPlugin = () =>
    window.location.replace(process.env.REACT_APP_REVIT_APP as string);

  return (
    <div className="flex w-full items-center justify-between bg-gray-100 p-5 rounded-md mt-6">
      <div className="flex items-center">
        <img src={RevitIcon} width={32} />
        <span className="text-sm font-medium text-gray-700 pl-4">
          {t("revit.downloadRevitTitle")}
        </span>
      </div>
      <Button
        type="secondary"
        leadingIcon={<IconDownload />}
        width="fit-content"
        onClick={downloadPlugin}
        testID="downloadPlugin_button"
      >
        {t("revit.downloadPlugin")}
      </Button>
    </div>
  );
}
